import React, { Component } from "react";

import { MDBBtn, MDBSpinner, MDBModal, toast } from "mdbreact";

import VenueKeypoolsTable from "../../../components/NetworkMgmt/Venues/Datatables/VenueKeypoolsTable";

import AddKeypool from "../../../components/NetworkMgmt/Forms/AddKeypool/AddKeypool";

import { withTranslation } from "react-i18next";

import axios from "../../../axios";

class ViewVenueKeypools extends Component {
    constructor(props) {
        super(props);
        this.state = {
            active: false,
            loading: false,
            modalAddServicePlan: false,
            disabled:
                sessionStorage.getItem("customerSupport") > 0 ? true : false,
        };
    }

    toggleAddKeypool = () => {
        this.setState({
            modalAddKeypool: !this.state.modalAddKeypool,
        });
    };

    submitAddKeypool = (ssid, venueID, scope, displayOrder) => {
        this.setState({ add_keypool: true });
        let body;
        if (scope === "global") {
            body = {
                AccessToken: sessionStorage.getItem("AccessToken"),
                SSID: ssid,
                Scope: scope,
                DisplayOrder: displayOrder,
            };
        } else {
            body = {
                AccessToken: sessionStorage.getItem("AccessToken"),
                SSID: ssid,
                VenueID: this.props.venueID,
                DisplayOrder: displayOrder,
            };
        }

        axios
            .post("/networkoperatorconsole/keypools/addKeypool", body, {
                headers: {},
            })
            .then(
                (response) => {
                    toast.success(response.data.message, {
                        closeButton: false,
                    });
                    this.toggleAddKeypool();
                    this.props.updateName(
                        this.props.venueName,
                        this.props.venueID
                    );
                    this.props.getVenueDetails(this.props.venueID);
                    this.setState({ add_keypool: false });
                },
                (error) => {
                    toast.error("There was an error!", {
                        closeButton: false,
                    });
                    this.toggleAddKeypool();
                    this.setState({ add_keypool: false });
                }
            );
    };

    render() {
        return (
            <>
                <MDBModal
                    isOpen={this.state.modalAddKeypool}
                    toggle={this.toggle}
                    size="md"
                    className="form"
                    centered
                >
                    <AddKeypool
                        toggleAddKeypool={this.toggleAddKeypool}
                        venueName={this.props.venueName}
                        submitAddKeypool={this.submitAddKeypool}
                        add_keypool={this.state.add_keypool}
                        loadingAddKeypool={this.state.loadingAddKeypool}
                    />
                </MDBModal>
                {this.props.keyPoolList !== undefined ? (
                    <>
                        <div className="servicePlans">
                            <VenueKeypoolsTable
                                keyPoolList={this.props.keyPoolList}
                                refreshed={this.props.refreshed}
                                disablePlanConfirm={
                                    this.props.disablePlanConfirm
                                }
                            />
                        </div>
                    </>
                ) : (
                    <MDBSpinner small />
                )}
                <MDBBtn
                    color="danger"
                    onClick={this.toggleAddKeypool}
                    className="btn-right"
                    disabled={this.state.disabled}
                >
                    Add Keypool
                </MDBBtn>
            </>
        );
    }
}

export default withTranslation()(ViewVenueKeypools);
