import React, { Component } from "react";

import { MDBBtn, MDBRow, MDBCol, MDBSpinner } from "mdbreact";

import axios from "../../../axios";

import { DateInput } from "semantic-ui-calendar-react";

import BandwidthUsageTable from "../../../components/VenueMgmt/Support/Datatables/BandwidthUsageTable";

import SelectSearch, { fuzzySearch } from "react-select-search";

class Transactions extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dateStart: undefined,
            dateEnd: undefined,
            usageUnitOptions: [
                { value: "mib", name: "MB" },
                { value: "gib", name: "GB" },
            ],
        };
    }

    handleChange = (event, { name, value }) => {
        if (this.state.hasOwnProperty(name)) {
            this.setState({ [name]: value });
        }
    };

    findTransactions = (event) => {
        event.preventDefault();

        if (this.state.dateStart !== undefined) {
            this.setState({ loading: true });
            let body = {
                AccessToken: sessionStorage.getItem("AccessToken"),
                VenueID: sessionStorage.getItem("venueID"),
                "UsageReportStartDateUTC.ISO8601": new Date(
                    this.state.dateStart
                ).toISOString(),
            };
            axios
                .post(
                    "/networkoperatorconsole/venues/listVenueUsageData",
                    body,
                    {
                        headers: {},
                    }
                )
                .then(
                    (response) => {
                        this.setState({ usageData: response.data.UsageData });
                        this.setState({ loading: false });
                    },
                    (error) => {
                        this.setState({ loading: false });
                    }
                );
        }
    };

    handleSelectUsageUnits = (e) => {
        this.setState({
            selectedUsageUnits: e,
            usage: undefined,
        });
        if (this.state.dateStart !== undefined) {
            this.setState({ loading: true });
            let body = {
                AccessToken: sessionStorage.getItem("AccessToken"),
                VenueID: sessionStorage.getItem("venueID"),
                "UsageReportStartDateUTC.ISO8601": new Date(
                    this.state.dateStart
                ).toISOString(),
            };
            axios
                .post(
                    "/networkoperatorconsole/venues/listVenueUsageData",
                    body,
                    {
                        headers: {},
                    }
                )
                .then(
                    (response) => {
                        this.setState({ usageData: response.data.UsageData });
                        this.setState({ loading: false });
                    },
                    (error) => {
                        this.setState({ loading: false });
                    }
                );
        }
    };

    render() {
        return (
            <>
                <form
                    className="needs-validation"
                    onSubmit={this.findTransactions}
                    noValidate
                >
                    <MDBRow>
                        <MDBCol col="6">
                            <label
                                htmlFor="defaultFormLoginEmailEx"
                                className="black-text"
                            >
                                Select Date
                            </label>
                            <DateInput
                                dateFormat="MM/DD/YYYY"
                                name="dateStart"
                                placeholder="MM/DD/YYYY"
                                value={this.state.dateStart}
                                iconPosition="left"
                                onChange={this.handleChange}
                                className="dateTimePicker"
                                minDate={
                                    new Date(
                                        new Date().setDate(
                                            new Date().getDate() - 60
                                        )
                                    )
                                }
                                maxDate={new Date()}
                            />
                        </MDBCol>
                        <MDBCol col="6">
                            <label
                                htmlFor="defaultFormLoginEmailEx"
                                className="black-text"
                            >
                                Usage Units
                            </label>
                            <SelectSearch
                                id="usageUnits"
                                search={true}
                                filterOptions={fuzzySearch}
                                options={this.state.usageUnitOptions}
                                inputId="usageUnitsArray"
                                classNamePrefix="unit-select form-control"
                                onChange={(e) => this.handleSelectUsageUnits(e)}
                                value={this.state.selectedUsageUnits}
                            />
                        </MDBCol>
                    </MDBRow>
                    <br />
                    {this.state.usageData !== undefined ? (
                        <>
                            {this.state.loading === true ? (
                                <MDBSpinner small />
                            ) : (
                                <BandwidthUsageTable
                                    usageData={this.state.usageData}
                                    selectedUsageUnits={
                                        this.state.selectedUsageUnits
                                    }
                                />
                            )}
                        </>
                    ) : (
                        <BandwidthUsageTable usageData={this.state.usageData} />
                    )}
                    <div className="text-right submitButton">
                        <MDBBtn color="danger" type="submit">
                            {this.state.loading === true ? (
                                <div
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                >
                                    <span className="sr-only">Loading...</span>
                                </div>
                            ) : (
                                "Get Bandwidth Usage"
                            )}
                        </MDBBtn>
                    </div>
                </form>
            </>
        );
    }
}

export default Transactions;
