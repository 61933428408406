import React from "react";

import { MDBDataTableV5, MDBIcon } from "mdbreact";

import { useTranslation } from "react-i18next";

export default function Basic(props) {
    const { t } = useTranslation();
    let userAttributes;
    let i = 0;

    let datatable;
    [datatable] = React.useState("");
    if (props.servicePlanData !== undefined) {
        userAttributes = [];
        for (const key of Object.keys(props.servicePlanData)) {
            // console.log(props.servicePlanData[key]["ServicePlanID"])
            userAttributes.push({
                servicePlanName: props.servicePlanData[key]["ServicePlanName"],
                servicePlanDescription:
                    props.servicePlanData[key]["ServicePlanDescription"],
                downloadkbps: props.servicePlanData[key]["DownloadKbps"],
                uploadkbps: props.servicePlanData[key]["UploadKbps"],
                defaultPlanWired:
                    props.defaultVenueConfig[
                        "DefaultServicePlanID_GlobalWirelessPlusLocalWiredAccess"
                    ] === props.servicePlanData[key]["ServicePlanID"] ? (
                        <MDBIcon icon="check" />
                    ) : (
                        ""
                    ),
                defaultPlanWireless:
                    props.defaultVenueConfig[
                        "DefaultServicePlanID_LocalWirelessAccess"
                    ] === props.servicePlanData[key]["ServicePlanID"] ? (
                        <MDBIcon icon="check" />
                    ) : (
                        ""
                    ),
                smscode: props.servicePlanData[key]["SMSCode"],
                billingCycleHours:
                    props.servicePlanData[key]["BillingCycleHours"],
                billingCyclePrice:
                    props.currencySymbol +
                    props.servicePlanData[key][
                        "BillingCyclePrice." + props.currencyName
                    ] /
                        100,
                trialCycles: props.servicePlanData[key]["TrialCycles"],
                clickEvent: () =>
                    props.modifyServicePlan(
                        key,
                        props.servicePlanData[key]["ServicePlanID"]
                    ),
            });
            i = i + 1;
        }
    }

    datatable = {
        columns: [
            {
                label: "Name",
                field: "servicePlanName",
            },
            {
                label: "Description",
                field: "servicePlanDescription",
            },
            {
                label: "Download(kbps)",
                field: "downloadkbps",
            },
            {
                label: "Upload(kbps)",
                field: "uploadkbps",
            },
            {
                label: "Default Wired",
                field: "defaultPlanWired",
            },
            {
                label: "Default Wireless",
                field: "defaultPlanWireless",
            },
            {
                label: "SMS Code",
                field: "smscode",
            },
            {
                label: "Billing Cycle Hours",
                field: "billingCycleHours",
            },
            {
                label: "Billing Cycle Price",
                field: "billingCyclePrice",
            },
            {
                label: "Trial Cycles",
                field: "trialCycles",
            },
        ],
        rows: userAttributes,
    };
    return (
        <MDBDataTableV5
            responsive
            hover
            data={datatable}
            searchBottom={false}
            paging={false}
            className="modifyStaff"
            info={false}
            noRecordsFoundLabel={t("recentservice.nomatches")}
        />
    );
}
