import React, { Component } from "react";

import { MDBBtn, MDBModalBody, MDBModalHeader, MDBRow, MDBCol } from "mdbreact";

import axios from "../../../../axios";

import { withTranslation } from "react-i18next";

import "react-phone-number-input/style.css";

import PhoneInput, {
    isPossiblePhoneNumber,
    isValidPhoneNumber,
} from "react-phone-number-input";

import $ from "jquery";

import SelectSearch, { fuzzySearch } from "react-select-search";

class AddNewResident extends Component {
    constructor(props) {
        super(props);

        this.state = {
            Email: "",
            sms: false,
            voice: false,
            invalidPhone: null,
            loading: false,
            userFound: false,
            languageOptions: [
                { value: "en-us", name: "English" },
                { value: "es-xl", name: "Spanish" },
            ],
            roleOptions: [
                { value: "VenueAdministrator", name: "Venue Administrator" },
                // { value: 'LeasingAgent', name: 'Leasing Agent'}
            ],
        };
    }

    componentDidMount() {
        this.getVenueDetails(this.props.venueID);
    }

    validateEmail = (email) => {
        this.setState({ loading: true });
        let body = {
            AccessToken: sessionStorage.getItem("AccessToken"),
            EmailAddress: email,
            VenueID: this.props.venueID,
        };
        axios
            .post("/venuemgmtconsole/subscribers/validateEmailAddress", body, {
                headers: {},
            })
            .then((response) => {
                this.setState({
                    firstName: response.data.GivenName,
                    lastName: response.data.FamilyName,
                    phoneNumber: response.data["PhoneNumber.E164"],
                    language: response.data.locale,
                    invalidPhone: undefined,
                    userFound: true,
                });
                this.setState({ loading: false });
            })
            .catch((error) => {
                this.setState({ error: true });
                this.setState({ loading: false });
                this.setState({ userFound: false });
            });
    };

    getVenueDetails = (index) => {
        let body = {
            AccessToken: sessionStorage.getItem("AccessToken"),
            VenueID: index,
            UserTimeZone: new Date().getTimezoneOffset(),
        };
        this.setState({
            unitData: undefined,
            expiringData: undefined,
            searchData: undefined,
            value: "",
        });
        axios
            .post("/venuemgmtconsole/venues/listVenueDetails", body, {
                headers: {},
            })
            .then((response) => {
                this.setState({
                    unitData:
                        response.data[index][
                            "Units-Building-Floor-List-Subscribers"
                        ],
                });
            })
            .catch((error) => {
                this.getVenueDetails(index);
                this.setState({ error: true });
            });
    };

    handleSelectChangeLanguage = (value) => {
        if (value !== null) {
            this.setState({ selectedLanguage: value });
        }
    };

    handleSelectChangeRole = (value) => {
        if (value !== null) {
            this.setState({ selectedRole: value });
        }
    };

    submitHandler = (event) => {
        event.preventDefault();
        $("#selectLanguage").removeClass("is-invalid");
        event.target.className += " was-validated";
        if (this.state.selectedLanguage === undefined) {
            this.setState({
                invalidLanguage: "Please provide a valid language",
            });
            $("#selectLanguage").addClass("is-invalid");
        } else {
            $("#selectLanguage").addClass("is-valid");
        }
        if (
            this.state.invalidPhone === null ||
            this.state.invalidPhone !== undefined
        ) {
            this.setState({
                invalidPhone: "Please provide a valid phone number",
            });
            $(".PhoneInput").addClass("invalid");
        }
        if (
            this.state.invalidPhone === undefined &&
            this.state.firstName !== undefined &&
            this.state.lastName !== undefined &&
            this.state.phoneNumber !== undefined &&
            this.state.selectedLanguage !== undefined
        ) {
            this.props.submitHandler(
                this.state.dateEnd,
                this.state.email,
                this.state.firstName,
                this.state.lastName,
                this.state.phoneNumber,
                this.state.selectedLanguage,
                this.state.sms,
                this.state.voice,
                "VenueAdministrator",
                this.props.venueID
            );
        }
    };

    toggleCheckboxValueSMS = () => {
        this.setState({ sms: !this.state.sms });
    };

    toggleCheckboxValueVoice = () => {
        this.setState({ voice: !this.state.voice });
    };

    changeHandler = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };

    changeHandlerPhone = (event) => {
        this.setState({ phoneNumber: event });
        var value = event
            ? isPossiblePhoneNumber(event)
                ? undefined
                : "Please provide a valid phone number"
            : "Phone number required";
        this.setState({ invalidPhone: value });
    };
    createMask = () => {
        this.setState({
            phoneNumber: this.state.phoneNumber.replace(/\D/g, ""),
        });
    };

    setEndDate = () => {
        var endDateYear = new Date(this.state.dateStart).getFullYear() + 1;
        var endDate = new Date(this.state.dateStart).setFullYear(endDateYear);
        this.setState({
            dateEnd: new Date(endDate).toISOString().slice(0, 10),
        });
    };

    render() {
        const { t } = this.props;
        return (
            <>
                <MDBModalHeader toggle={this.props.toggleAddStaff}>
                    <h4>Add Venue Administrator</h4>
                    <p>{t("addnewresident.required")}</p>
                </MDBModalHeader>
                <MDBModalBody>
                    <form
                        className="needs-validation"
                        onSubmit={this.submitHandler}
                        noValidate
                    >
                        <MDBRow>
                            <MDBCol col="6" sm="12" lg="6">
                                <label
                                    htmlFor="defaultFormLoginEmailEx"
                                    className="black-text"
                                >
                                    {t("addnewresident.email")}
                                </label>
                                <input
                                    required
                                    id="email"
                                    type="email"
                                    className="form-control"
                                    onBlur={(event) =>
                                        this.validateEmail(this.state.email)
                                    }
                                    onChange={(event) =>
                                        this.setState({
                                            email: event.target.value,
                                        })
                                    }
                                    value={this.state.email}
                                />
                                <div className="invalid-feedback">
                                    Please provide a valid email.
                                </div>
                            </MDBCol>
                            <MDBCol col="6"></MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol sm="12" lg="6">
                                <label
                                    htmlFor="defaultFormLoginEmailEx"
                                    className="black-text"
                                >
                                    {t("addnewresident.firstname")}
                                </label>
                                <input
                                    required
                                    name="firstName"
                                    id="firstName"
                                    className={
                                        this.state.loading === true
                                            ? "form-control loading"
                                            : "form-control"
                                    }
                                    onChange={this.changeHandler}
                                    value={this.state.firstName}
                                    disabled={this.state.userFound}
                                />
                                <div className="invalid-feedback">
                                    Please provide a valid first name.
                                </div>
                            </MDBCol>
                            <MDBCol sm="12" lg="6">
                                <label
                                    htmlFor="defaultFormLoginEmailEx"
                                    className="black-text"
                                >
                                    {t("addnewresident.lastname")}
                                </label>
                                <input
                                    required
                                    className={
                                        this.state.loading === true
                                            ? "form-control loading"
                                            : "form-control"
                                    }
                                    name="lastName"
                                    id="lastName"
                                    onChange={this.changeHandler}
                                    value={this.state.lastName}
                                    disabled={this.state.userFound}
                                />
                                <div className="invalid-feedback">
                                    Please provide a valid last name.
                                </div>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol col="6" sm="12" lg="6">
                                <label
                                    htmlFor="defaultFormLoginEmailEx"
                                    className="black-text"
                                >
                                    {t("addnewresident.phone")}*
                                </label>
                                <PhoneInput
                                    placeholder={t("addnewresident.enterphone")}
                                    value={this.state.phoneNumber}
                                    onChange={this.changeHandlerPhone}
                                    name="phoneNumber"
                                    id="phoneNumber"
                                    className={
                                        this.state.loading === true
                                            ? "form-control loading"
                                            : "form-control"
                                    }
                                    required
                                    error={
                                        this.state.phoneNumber
                                            ? isValidPhoneNumber(
                                                  this.state.phoneNumber
                                              )
                                                ? undefined
                                                : "Invalid phone number"
                                            : "Phone number required"
                                    }
                                    disabled={this.state.userFound}
                                />
                                {this.state.invalidPhone !== null ? (
                                    <div className="invalid">
                                        {this.state.invalidPhone}
                                    </div>
                                ) : (
                                    ""
                                )}
                            </MDBCol>
                            <MDBCol sm="12" lg="6">
                                <div>
                                    <label
                                        htmlFor="defaultFormLoginEmailEx"
                                        className="black-text"
                                    >
                                        {t("addnewresident.language")}
                                    </label>
                                    <SelectSearch
                                        id="selectLanguage"
                                        search={true}
                                        filterOptions={fuzzySearch}
                                        options={this.state.languageOptions}
                                        isOptionSelected={true}
                                        classNamePrefix="language-select"
                                        onChange={
                                            this.handleSelectChangeLanguage
                                        }
                                        value={this.state.selectedLanguage}
                                        placeholder={
                                            this.state.selectedLanguage !==
                                            undefined
                                                ? this.state
                                                      .selectedLanguage ===
                                                  "en-us"
                                                    ? "English"
                                                    : "Spanish"
                                                : "Select..."
                                        }
                                    />
                                    <div className="invalid-feedback">
                                        Please provide a valid language.
                                    </div>
                                </div>
                            </MDBCol>
                        </MDBRow>
                        <div className="text-right submitButton">
                            <MDBBtn color="danger" type="submit">
                                {this.props.loading === true ? (
                                    <div
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                    >
                                        <span className="sr-only">
                                            Loading...
                                        </span>
                                    </div>
                                ) : (
                                    t("addstaff.addstaff")
                                )}
                            </MDBBtn>
                        </div>
                    </form>
                </MDBModalBody>
            </>
        );
    }
}

export default withTranslation()(AddNewResident);
