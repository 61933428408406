import * as React from "react";
import { DataGrid, GridToolbarQuickFilter } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import Popper from "@mui/material/Popper";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
function QuickSearchToolbar() {
    return (
        <Box
            sx={{
                p: 0.5,
                pb: 0,
            }}
        >
            <GridToolbarQuickFilter />
        </Box>
    );
}
function isOverflown(element) {
    return (
        element.scrollHeight > element.clientHeight ||
        element.scrollWidth > element.clientWidth
    );
}
const GridCellExpand = React.memo(function GridCellExpand(props) {
    const { width, value } = props;
    const wrapper = React.useRef(null);
    const cellDiv = React.useRef(null);
    const cellValue = React.useRef(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [showFullCell, setShowFullCell] = React.useState(false);
    const [showPopper, setShowPopper] = React.useState(false);
    const handleMouseEnter = () => {
        const isCurrentlyOverflown = isOverflown(cellValue.current);
        setShowPopper(isCurrentlyOverflown);
        setAnchorEl(cellDiv.current);
        setShowFullCell(true);
    };
    const handleMouseLeave = () => {
        setShowFullCell(false);
    };
    React.useEffect(() => {
        if (!showFullCell) {
            return undefined;
        }
        function handleKeyDown(nativeEvent) {
            // IE11, Edge (prior to using Bink?) use 'Esc'
            if (nativeEvent.key === "Escape" || nativeEvent.key === "Esc") {
                setShowFullCell(false);
            }
        }
        document.addEventListener("keydown", handleKeyDown);
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [setShowFullCell, showFullCell]);
    return (
        <Box
            ref={wrapper}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            sx={{
                alignItems: "center",
                lineHeight: "24px",
                width: "100%",
                height: "100%",
                position: "relative",
                display: "flex",
            }}
        >
            <Box
                ref={cellDiv}
                sx={{
                    height: "100%",
                    width,
                    display: "block",
                    position: "absolute",
                    top: 0,
                }}
            />
            <Box
                ref={cellValue}
                sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                }}
            >
                {value}
            </Box>
            {showPopper && (
                <Popper
                    open={showFullCell && anchorEl !== null}
                    anchorEl={anchorEl}
                    style={{ width, marginLeft: -17 }}
                >
                    <Paper
                        elevation={1}
                        style={{ minHeight: wrapper.current.offsetHeight - 3 }}
                    >
                        <Typography variant="body2" style={{ padding: 8 }}>
                            {value}
                        </Typography>
                    </Paper>
                </Popper>
            )}
        </Box>
    );
});
function renderCellExpand(params) {
    return (
        <GridCellExpand
            value={params.formattedValue || ""}
            width={displayTextWidth(
                params.formattedValue,
                ".9rem Proxima Nova Rg"
            )}
        />
    );
}
function displayTextWidth(text, font) {
    let canvas =
        displayTextWidth.canvas ||
        (displayTextWidth.canvas = document.createElement("canvas"));
    let context = canvas.getContext("2d");
    context.font = font;
    let metrics = context.measureText(text);
    return metrics.width + 25;
}
export default function Basic(props) {
    let userAttributes;
    let i = 0;
    let columns = [];
    let rows = [];
    if (props.recentresidents !== undefined) {
        userAttributes = [];
        for (const key of Object.keys(props.recentresidents)) {
            if (props.recentresidents[key]["EndDateTimeUTC.ISO8601"] !== "") {
                userAttributes.push({
                    id: key,
                    unitID: props.recentresidents[key]["UnitID"],
                    building: props.recentresidents[key]["UnitBuilding"],
                    floor: props.recentresidents[key]["UnitFloor"],
                    name:
                        props.recentresidents[key]["SubscriberGivenName"] +
                        " " +
                        props.recentresidents[key]["SubscriberFamilyName"],
                    email: props.recentresidents[key]["SubscriberEmail"],
                    status: props.recentresidents[key]["Status"],
                    activated:
                        props.recentresidents[key]["StartDateTimeUTC.ISO8601"],
                    expiring:
                        props.recentresidents[key]["EndDateTimeUTC.ISO8601"],
                });
            } else {
                userAttributes.push({
                    id: key,
                    unitID: props.recentresidents[key]["UnitID"],
                    building: props.recentresidents[key]["UnitBuilding"],
                    floor: props.recentresidents[key]["UnitFloor"],
                    name:
                        props.recentresidents[key]["SubscriberGivenName"] +
                        " " +
                        props.recentresidents[key]["SubscriberFamilyName"],
                    email: props.recentresidents[key]["SubscriberEmail"],
                    status: props.recentresidents[key]["Status"],
                    activated:
                        props.recentresidents[key]["StartDateTimeUTC.ISO8601"],
                    expiring: "",
                });
                i = i + 1;
            }
        }
    }
    columns = [
        {
            headerName: "Unit ID",
            field: "unitID",
            minWidth: 120,
            renderCell: renderCellExpand,
        },
        {
            headerName: "Building",
            field: "building",
            minWidth: 120,
            renderCell: renderCellExpand,
        },
        {
            headerName: "Floor",
            field: "floor",
            minWidth: 120,
            renderCell: renderCellExpand,
        },
        {
            headerName: "Name",
            field: "name",
            renderCell: renderCellExpand,
            minWidth: 200,
        },
        {
            headerName: "Email Address",
            field: "email",
            renderCell: renderCellExpand,
            minWidth: 200,
        },
        {
            headerName: "Status",
            field: "status",
            renderCell: renderCellExpand,
            minWidth: 120,
        },
        {
            headerName: "Activated",
            field: "activated",
            minWidth: 200,
            type: "dateTime",
            valueFormatter: (value) =>
                value &&
                new Intl.DateTimeFormat("en-US", {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                }).format(new Date(value)),
            renderCell: renderCellExpand,
        },
        {
            headerName: "Expiring",
            field: "expiring",
            hide: true,
            type: "dateTime",
            valueFormatter: (value) =>
                value &&
                new Intl.DateTimeFormat("en-US", {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                }).format(new Date(value)),
            renderCell: renderCellExpand,
            minWidth: 200,
        },
    ];
    rows = userAttributes;
    return (
        <div style={{ height: 150 }}>
            <DataGrid
                rows={rows}
                columns={columns}
                onRowClick={(id) =>
                    props.modifyResident(props.recentresidents[id.row["id"]])
                }
                getRowHeight={({ id, densityFactor }) => {
                    return 40 * densityFactor;
                }}
                hideFooterPagination={true}
                hideFooter={true}
                sx={{ "--DataGrid-overlayHeight": "300px" }}
            />
        </div>
    );
}
