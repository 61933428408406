import React, { Component } from "react";
import {
    MDBSpinner,
    MDBModal,
    MDBModalBody,
    MDBModalHeader,
    toast,
} from "mdbreact";
import ViewModifyGuestsTable from "../../../components/VenueMgmt/RecentResidents/Datatables/ViewModifyGuestsTable";
import ModifyGuest from "../../../components/VenueMgmt/Forms/ModifyGuest/ModifyGuest";
import axios from "../../../axios";
import _ from "lodash";
import { Search, Grid } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
const initialState = { isLoading: false, results: [], value: "" };
let getResults;
let source;
let source1 = [];
class RecentResidents extends Component {
    constructor(props) {
        super(props);
        this.state = {
            active: true,
            recentResidents: this.props.recentResidents,
            allRecentResidents: this.props.allRecentResidents,
            venueName: this.props.venueName,
            guestData: this.props.guestData,
            loading: this.props.loading,
            errorFound: false,
        };
    }
    toggleModifyResident = () => {
        this.setState({
            modalModifyResident: !this.state.modalModifyResident,
        });
    };
    componentDidMount() {
        this.getVenueDetails();
    }
    getVenueDetails = (index, venueName) => {
        if (venueName === undefined) {
            venueName = this.props.venueName;
        }
        if (index === undefined) {
            index = this.props.venueID;
        }
        let key = [];
        let iteration = 0;
        let lastName = [];
        let firstName = [];
        let unitID = [];
        let keyID = [];
        let email = [];
        let startDate = [];
        let contactpref_email = [];
        let contactpref_sms = [];
        let phoneNumber = [];
        let locale = [];
        let endDate = [];
        let floor = [];
        let building = [];
        let unitID_internal = [];
        source1 = [];
        this.setState({
            recentResidents: undefined,
            allRecentResidents: undefined,
            activeData: undefined,
            expiringData: undefined,
            searchData: undefined,
            value: "",
            guestData: undefined,
        });
        let body = {
            AccessToken: sessionStorage.getItem("AccessToken"),
            VenueID: index,
            UserTimeZone: new Date().getTimezoneOffset(),
        };
        axios
            .post("/venuemgmtconsole/venues/listVenueDetails", body, {
                headers: {},
            })
            .then((response) => {
                this.setState({
                    activeData:
                        response.data[index][
                            "RecentlyStartedSubscriptionsList_7day"
                        ],
                    recentResidents:
                        response.data[index][
                            "RecentlyStartedSubscriptionsList_7day"
                        ],
                    allRecentResidents: response.data[index]["Subscriptions"],
                    guestData: response.data[index]["GuestSubscriptions"],
                });
                this.setState({
                    address1: response.data[index]["AddressLine1"],
                    address2: response.data[index]["AddressLine2"],
                    city: response.data[index]["City"],
                    state: response.data[index]["State"],
                    postalCode: response.data[index]["PostalCode"],
                    venueData: response.data[index],
                    activeData:
                        response.data[index][
                            "RecentlyStartedSubscriptionsList_7day"
                        ],
                    allActiveData: response.data[index]["Subscriptions"],
                    expiringData:
                        response.data[index][
                            "UpcomingSubscriptionEndList_7day_unconfirmed"
                        ],
                    searchData: response.data[index]["UnitsAndSubscriptions"],
                    keypoolID: Object.keys(response.data[index]["Keypools"]),
                    guestData: response.data[index]["GuestSubscriptions"],
                });
                key = Object.keys(response.data[index]["GuestSubscriptions"]);
                key.forEach((val) => {
                    lastName =
                        response.data[this.props.venueID]["GuestSubscriptions"][
                            key[iteration]
                        ]["SubscriberFamilyName"];
                    firstName =
                        response.data[this.props.venueID]["GuestSubscriptions"][
                            key[iteration]
                        ]["SubscriberGivenName"];
                    unitID =
                        response.data[this.props.venueID]["GuestSubscriptions"][
                            key[iteration]
                        ]["UnitID"];
                    startDate =
                        response.data[this.props.venueID]["GuestSubscriptions"][
                            key[iteration]
                        ]["StartDateTimeUTC.ISO8601"];
                    contactpref_email =
                        response.data[this.props.venueID]["GuestSubscriptions"][
                            key[iteration]
                        ]["contactpref_email"];
                    contactpref_sms =
                        response.data[this.props.venueID]["GuestSubscriptions"][
                            key[iteration]
                        ]["contactpref_sms"];
                    phoneNumber =
                        response.data[this.props.venueID]["GuestSubscriptions"][
                            key[iteration]
                        ]["SubscriberPhoneNumber.E164"];
                    locale =
                        response.data[this.props.venueID]["GuestSubscriptions"][
                            key[iteration]
                        ]["SubscriberLocale"];
                    endDate =
                        response.data[this.props.venueID]["GuestSubscriptions"][
                            key[iteration]
                        ]["EndDateTimeUTC.ISO8601"];
                    floor =
                        response.data[this.props.venueID]["GuestSubscriptions"][
                            key[iteration]
                        ]["UnitFloor"];
                    building =
                        response.data[this.props.venueID]["GuestSubscriptions"][
                            key[iteration]
                        ]["UnitBuilding"];
                    email =
                        response.data[this.props.venueID]["GuestSubscriptions"][
                            key[iteration]
                        ]["SubscriberEmail"];
                    unitID_internal =
                        response.data[this.props.venueID]["GuestSubscriptions"][
                            key[iteration]
                        ]["UnitID_internal"];
                    keyID = key[iteration];
                    getResults = () =>
                        _.times(1, () => ({
                            firstName: firstName,
                            lastName: lastName,
                            unitID: unitID,
                            startDate: startDate,
                            contactpref_email: contactpref_email,
                            contactpref_sms: contactpref_sms,
                            phoneNumber: phoneNumber,
                            locale: locale,
                            endDate: endDate,
                            floor: floor,
                            building: building,
                            title: firstName + " " + lastName,
                            description: `Unit: ${unitID} \r\nBuilding: ${building}, Floor: ${floor} \r\n${email}`,
                            keyid: keyID,
                            email: email,
                            unitID_internal: unitID_internal,
                        }));
                    source = _.range(0, 3).reduce((memo1) => {
                        const name = val;
                        // eslint-disable-next-line no-param-reassign
                        memo1 = {
                            name,
                            results: getResults(),
                        };
                        return memo1;
                    }, {});
                    source1.push(source);
                    iteration = iteration + 1;
                });
                this.props.updateName(venueName, index);
            })
            .catch((error) => {
                this.getVenueDetails(index);
                this.setState({ error: true });
            });
    };
    handleResultSelect = (e, { result }) => {
        this.setState({ SubscriberGivenName: result.firstName });
        this.setState({ SubscriberFamilyName: result.lastName });
        this.setState({ UnitID: result.unitID });
        this.setState({ StartDateTime: result.startDate });
        this.setState({ contactpref_email: result.contactpref_email });
        this.setState({ contactpref_sms: result.contactpref_sms });
        this.setState({ SubscriberPhoneNumber: result.phoneNumber });
        this.setState({ SubscriberLocale: result.locale });
        this.setState({ EndDateTime: result.endDate });
        this.setState({ UnitFloor: result.floor });
        this.setState({ UnitBuilding: result.building });
        this.setState({ UnitBuilding: result.building });
        this.setState({ SubscriberEmail: result.email });
        this.setState({ redirect: true });
        this.setState({ value: result.title });
        this.setState({
            modalModifyResident: !this.state.modalModifyResident,
        });
        this.setState({ unitID_internal: result.unitID_internal });
    };
    toggle = () => {
        this.setState({
            modal: !this.state.modal,
        });
    };
    refresh = () => {
        this.setState({ refreshed: true });
    };
    handleSearchChange = (e, { value }) => {
        this.setState({ isLoading: true, value });
        let re = "";
        setTimeout(() => {
            this.setState({ results: "" });
            if (this.state.value.length < 1) return this.setState(initialState);
            re = new RegExp(_.escapeRegExp(this.state.value), "i");
            const isMatch = (result) =>
                re.test(result.title + result.description + result.email);
            this.setState({
                filteredResults: _.reduce(
                    source1,
                    (memo2, data, name) => {
                        let results = _.filter(data.results, isMatch);
                        if (results.length || results[0] !== undefined)
                            memo2[name] = { name, results }; // eslint-disable-line no-param-reassign
                        return memo2;
                    },
                    {}
                ),
            });
            this.setState({
                isLoading: false,
                results: this.state.filteredResults,
            });
        }, 300);
    };
    modifyResident = (key) => {
        this.setState({
            modalModifyResident: !this.state.modalModifyResident,
        });
        this.setState({
            SubscriberGivenName: key.SubscriberGivenName,
            SubscriberFamilyName: key.SubscriberFamilyName,
            SubscriberEmail: key.SubscriberEmail,
            SubscriberPhoneNumber: key["SubscriberPhoneNumber.E164"],
            contactpref_email: key.contactpref_email,
            contactpref_sms: key.contactpref_sms,
            SubscriberLocale: key.SubscriberLocale,
            UnitFloor: key.UnitFloor,
            UnitBuilding: key.UnitBuilding,
            StartDateTime: key["StartDateTimeUTC.ISO8601"],
            EndDateTime: key["EndDateTimeUTC.ISO8601"],
            UnitID: key.UnitID,
            unitID_internal: key.UnitID_internal,
        });
    };
    submitModify = (
        email,
        firstName,
        lastName,
        phoneNumber,
        language,
        contactpref_email,
        contactpref_sms,
        recentResidents,
        dateStart,
        oldDateStart,
        dateEnd,
        oldDateEnd,
        venueID,
        unitID,
        building,
        floor,
        isUpdated,
        isUpdatedStartDate,
        isUpdatedEndDate,
        unitID_internal
    ) => {
        this.setState({ loading: true });
        this.setState({ errorFound: false });
        let bodyDateStart = {
            AccessToken: sessionStorage.getItem("AccessToken"),
            EmailAddress: email,
            VenueID: venueID,
            UnitID_internal: unitID_internal,
            "StartDateTimeUTC.ISO8601": new Date(oldDateStart)
                .toISOString()
                .replace(/:00.\d+Z$/g, "Z"),
            "NewStartDateTimeUTC.ISO8601": new Date(dateStart)
                .toISOString()
                .replace(/:00.\d+Z$/g, "Z"),
            UserTimeZone: new Date().getTimezoneOffset(),
        };
        let bodyDateEnd = {
            AccessToken: sessionStorage.getItem("AccessToken"),
            EmailAddress: email,
            VenueID: venueID,
            UnitID_internal: unitID_internal,
            "StartDateTimeUTC.ISO8601": new Date(oldDateStart).toISOString(),
            "NewEndDateTimeUTC.ISO8601":
                dateEnd !== undefined
                    ? new Date(dateEnd).toISOString().replace(/:00.\d+Z$/g, "Z")
                    : undefined,
            UserTimeZone: new Date().getTimezoneOffset(),
        };
        let bodyUpdatePII = {
            AccessToken: sessionStorage.getItem("AccessToken"),
            EmailAddress: email,
            GivenName: firstName,
            FamilyName: lastName,
            "PhoneNumber.E164": phoneNumber,
            locale: language,
            contactpref_email: true,
            contactpref_sms: contactpref_sms,
            VenueID: venueID,
        };
        Promise.all([
            new Date(dateStart) > new Date() && isUpdatedStartDate === true
                ? axios.post(
                      "/venuemgmtconsole/subscribers/resetSubscriptionStartDate",
                      bodyDateStart,
                      { headers: {} }
                  )
                : console.log(
                      "🚀 ~ file: ViewModifyGuests.js:318 ~ RecentResidents ~ bodyDateStart",
                      bodyDateStart
                  ),
            dateEnd !== undefined && isUpdatedEndDate === true
                ? axios.post(
                      "/venuemgmtconsole/subscribers/resetSubscriptionEndDate",
                      bodyDateEnd,
                      { headers: {} }
                  )
                : console.log(
                      "🚀 ~ file: ViewModifyGuests.js:322 ~ RecentResidents ~ bodyDateEnd",
                      bodyDateEnd
                  ),
            isUpdated === true
                ? axios.post(
                      "/venuemgmtconsole/subscribers/updateSubscriberPII",
                      bodyUpdatePII,
                      { headers: {} }
                  )
                : console.log(
                      "🚀 ~ file: ViewModifyGuests.js:324 ~ RecentResidents ~ bodyUpdatePII",
                      bodyUpdatePII
                  ),
        ])
            .then(async ([res1, res2, res3]) => {
                toast.success("Updated Guest!", {
                    closeButton: false,
                });
                this.toggleModifyResident();
                this.setState({ guestData: undefined });
                this.getVenueDetails(this.props.venueID, this.props.venueName);
                this.setState({ loading: false });
            })
            .catch((error) => {
                toast.error("There was an error!", {
                    closeButton: false,
                });
                this.toggleModifyResident();
                this.getVenueDetails(this.props.venueID, this.props.venueName);
                this.setState({ guestData: recentResidents });
                this.setState({ loading: false });
            });
    };
    deleteResident = (
        email,
        venueID,
        unitID,
        building,
        floor,
        recentResidents,
        oldDateStart,
        unitID_internal
    ) => {
        this.setState({ loadingDelete: true });
        let body = {
            AccessToken: sessionStorage.getItem("AccessToken"),
            EmailAddress: email,
            VenueID: venueID,
            UnitID_internal: unitID_internal,
            "StartDateTimeUTC.ISO8601": oldDateStart,
            "NewEndDateTimeUTC.ISO8601": oldDateStart,
            endImmediately: true,
            UserTimeZone: new Date().getTimezoneOffset(),
        };
        axios
            .post(
                "/venuemgmtconsole/subscribers/resetSubscriptionEndDate",
                body,
                {
                    headers: {},
                }
            )
            .then((response) => {
                toast.success("Deleted Guest!", {
                    closeButton: false,
                });
                this.toggleModifyResident();
                this.setState({ guestData: undefined });
                this.getVenueDetails(this.props.venueID, this.props.venueName);
                this.setState({ loadingDelete: false });
            })
            .catch((error) => {
                toast.error("There was an error!", {
                    closeButton: false,
                });
                this.getVenueDetails(this.props.venueID, this.props.venueName);
                this.setState({ guestData: recentResidents });
                this.setState({ error: true });
                this.setState({ loadingDelete: false });
            });
    };
    render() {
        const { t } = this.props;
        const { isLoading, value, results } = this.state;
        return (
            <>
                <MDBModal
                    isOpen={this.state.modalModifyResident}
                    toggle={this.toggle}
                    size="lg"
                    className="form"
                >
                    <ModifyGuest
                        toggleModifyResident={this.toggleModifyResident}
                        firstName={this.state.SubscriberGivenName}
                        lastName={this.state.SubscriberFamilyName}
                        email={this.state.SubscriberEmail}
                        phoneNumber={this.state.SubscriberPhoneNumber}
                        receivetext={this.state.contactpref_email}
                        receivephone={this.state.contactpref_sms}
                        language={this.state.SubscriberLocale}
                        floor={this.state.UnitFloor}
                        building={this.state.UnitBuilding}
                        startDate={this.state.StartDateTime}
                        endDate={this.state.EndDateTime}
                        unitID={this.state.UnitID}
                        submitModify={this.submitModify}
                        venueID={this.props.venueID}
                        recentResidents={this.state.recentResidents}
                        loading={this.state.loading}
                        submitDelete={this.deleteResident}
                        loadingDelete={this.state.loadingDelete}
                        unitID_internal={this.state.unitID_internal}
                        realpageSyncDate={this.state.realpageSyncDate}
                        subscriberManagedviaSSO={
                            this.state.subscriberManagedviaSSO
                        }
                        StripeClientSecret={this.state.StripeClientSecret}
                    />
                </MDBModal>
                <MDBModalHeader toggle={this.props.toggleViewModifyGuest}>
                    <h4>
                        {this.props.venueType === "HOTSPOT"
                            ? "View/Modify Subscribers"
                            : t("guestnav.viewmodify")}
                    </h4>
                    <p>{t("addnewresident.required")}</p>
                </MDBModalHeader>
                <MDBModalBody>
                    <Grid>
                        <p>{t("quicksearch.title")}</p>
                        <Grid.Column width={16}>
                            <Search
                                category
                                loading={isLoading}
                                onResultSelect={this.handleResultSelect}
                                onSearchChange={_.debounce(
                                    this.handleSearchChange,
                                    500,
                                    {
                                        leading: true,
                                    }
                                )}
                                results={results}
                                placeholder={
                                    this.props.venueType === "HOTSPOT"
                                        ? "Local Policy Name, Unit ID or Email"
                                        : t("quicksearch.label")
                                }
                                value={value}
                            />
                        </Grid.Column>
                    </Grid>
                    {this.state.guestData !== undefined ? (
                        <div
                            className={
                                this.state.active
                                    ? "allrecentresidentsExpanded"
                                    : "allrecentresidents"
                            }
                        >
                            <ViewModifyGuestsTable
                                recentresidents={this.state.guestData}
                                refreshed={this.props.refreshed}
                                modifyResident={this.modifyResident}
                            />
                        </div>
                    ) : (
                        <MDBSpinner small />
                    )}
                </MDBModalBody>
            </>
        );
    }
}
export default withTranslation()(RecentResidents);
