import React, { Component } from "react";

import { MDBBtn, MDBRow, MDBCol, MDBSpinner } from "mdbreact";

import axios from "../../../axios";

import { DateInput } from "semantic-ui-calendar-react";

import TransactionsTable from "../../../components/NetworkMgmt/Transactions/Datatables/TransactionsTable";

class Transactions extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dateStart: undefined,
            dateEnd: undefined,
        };
    }

    handleChange = (event, { name, value }) => {
        if (this.state.hasOwnProperty(name)) {
            this.setState({ [name]: value });
        }
    };

    findTransactions = (event) => {
        event.preventDefault();
        this.setState({ loading: true });

        if (
            this.state.dateStart !== undefined &&
            this.state.dateEnd !== undefined
        ) {
            let body = {
                AccessToken: sessionStorage.getItem("AccessToken"),
                "MinInvoiceDateTimeUTC.ISO8601": new Date(
                    this.state.dateStart
                ).toISOString(),
                "MaxinvoiceDateTimeUTC.ISO8601": new Date(
                    this.state.dateEnd
                ).toISOString(),
            };
            axios
                .post(
                    "/networkoperatorconsole/shoppingcart/listPayments",
                    body,
                    {
                        headers: {},
                    }
                )
                .then(
                    (response) => {
                        this.setState({ transactions: response.data.Payments });
                        this.setState({ loading: false });
                    },
                    (error) => {
                        this.setState({ loading: false });
                    }
                );
        }
    };

    render() {
        let tomorrow = new Date(
            new Date().setDate(new Date().getDate() + 1)
        ).toISOString();
        return (
            <div className="searchForm">
                <h4>Search for Transactions</h4>
                <form
                    className="needs-validation"
                    onSubmit={this.findTransactions}
                    noValidate
                >
                    <MDBRow>
                        <MDBCol col="6">
                            <label
                                htmlFor="defaultFormLoginEmailEx"
                                className="black-text"
                            >
                                Start Date
                            </label>
                            <DateInput
                                dateFormat="MM/DD/YYYY"
                                name="dateStart"
                                placeholder="MM/DD/YYYY"
                                value={this.state.dateStart}
                                iconPosition="left"
                                onChange={this.handleChange}
                                className="dateTimePicker"
                                maxDate={new Date(tomorrow)}
                            />
                        </MDBCol>
                        <MDBCol col="6">
                            <label
                                htmlFor="defaultFormLoginEmailEx"
                                className="black-text"
                            >
                                End Date
                            </label>
                            <DateInput
                                dateFormat="MM/DD/YYYY"
                                name="dateEnd"
                                placeholder="MM/DD/YYYY"
                                value={this.state.dateEnd}
                                iconPosition="left"
                                onChange={this.handleChange}
                                className="dateTimePicker"
                                minDate={new Date(this.state.dateStart)}
                                maxDate={new Date(tomorrow)}
                            />
                        </MDBCol>
                    </MDBRow>
                    <br />
                    {this.state.transactions !== undefined ? (
                        <>
                            {this.state.loading === true ? (
                                <MDBSpinner small />
                            ) : (
                                <TransactionsTable
                                    transactions={this.state.transactions}
                                />
                            )}
                        </>
                    ) : (
                        <TransactionsTable
                            transactions={this.state.transactions}
                        />
                    )}
                    <div className="text-right submitButton">
                        <MDBBtn color="danger" type="submit">
                            Find Transactions
                        </MDBBtn>
                    </div>
                </form>
            </div>
        );
    }
}

export default Transactions;
