import React, { Component } from "react";
import { MDBBtn, MDBModalBody, MDBModalHeader, MDBRow, MDBCol } from "mdbreact";
import axios from "../../../../axios";
import SelectSearch, { fuzzySearch } from "react-select-search";
class BulkDownloadSubscribers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allActiveData: undefined,
        };
    }
    componentDidUpdate(prevProps) {
        if (this.state.venueID !== this.props.venueID) {
            this.getVenueDetails(this.props.venueID);
            this.setState({ venueID: this.props.venueID });
        }
    }
    getVenueDetails = (index) => {
        let body = {
            AccessToken: sessionStorage.getItem("AccessToken"),
            VenueID: index,
            UserTimeZone: new Date().getTimezoneOffset(),
        };
        this.setState({
            unitData: undefined,
            expiringData: undefined,
            searchData: undefined,
            value: "",
            defaultSubscriptionDurationRes: 0,
            loading_units: true,
        });
        axios
            .post("/venuemgmtconsole/venues/listVenueDetails", body, {
                headers: {},
            })
            .then((response) => {
                this.setState({
                    allActiveData: response.data[index]["Subscriptions"],
                    venueName: response.data[index]["VenueName"],
                });
                this.setState({
                    loading_units: false,
                });
            })
            .catch((error) => {
                this.getVenueDetails(index);
                this.setState({
                    error: true,
                    loading_units: false,
                });
            });
    };
    convertToCSV(objArray) {
        var array =
            typeof objArray != "object" ? JSON.parse(objArray) : objArray;
        var str = "";
        for (var i = 0; i < array.length; i++) {
            var line = "";
            for (var index in array[i]) {
                if (line !== "") line += ",";
                line += '"' + array[i][index] + '"';
            }
            str += line + "\r\n";
        }
        return str;
    }
    exportCSVFile(headers, items, fileTitle) {
        if (headers !== items[0]) {
            items.unshift(headers);
        }
        // Convert Object to JSON
        var jsonObject = JSON.stringify(items);
        var csv = this.convertToCSV(jsonObject);
        var exportedFilename = fileTitle + ".csv" || "export.csv";
        var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        if (navigator.msSaveBlob) {
            // IE 10+
            navigator.msSaveBlob(blob, exportedFilename);
        } else {
            var link = document.createElement("a");
            if (link.download !== undefined) {
                // feature detection
                // Browsers that support HTML5 download attribute
                var url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", exportedFilename);
                link.style.visibility = "hidden";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }
    render() {
        if (this.state.allActiveData !== undefined) {
            var headers = {
                unitID: "Unit ID*",
                unitBuilding: "Building*",
                unitFloor: "Floor*",
                SubscriberGivenName: "Given Name*",
                SubscriberFamilyName: "Family Name*",
                SubscriberEmail: "Email*",
                SubscriberLocale: "Locale (en-us)",
                SubscriberPhone: "Phone* (Format: +1xxxyyyzzzz)",
                SubscriberAddress1: "Address 1*",
                SubscriberAddress2: "Address 2",
                SubscriberCity: "City*",
                SubscriberState: "State*",
                SubscriberPostalCode: "Postal Code*",
                SubsriberContactEmail: "Contact by Email* (true/false)",
                SubsriberContactSMS: "Contact by Text* (true/false)",
                StartDateTimeUTC: "Start Date*",
                EndDateTimeUTC: "End Date",
                SubscriberToken: "Subscriber Token",
                externalSubscriberID: "External Subscriber ID",
                externalSubscriptionID: "External Subscription ID",
            };
            var itemsFormatted = [];
            for (const key of Object.keys(this.state.allActiveData)) {
                itemsFormatted.push({
                    unitID: this.state.allActiveData[key]["UnitID"],
                    unitBuilding: this.state.allActiveData[key]["UnitBuilding"],
                    unitFloor: this.state.allActiveData[key]["UnitFloor"],
                    SubscriberGivenName:
                        this.state.allActiveData[key]["SubscriberGivenName"],
                    SubscriberFamilyName:
                        this.state.allActiveData[key]["SubscriberFamilyName"],
                    SubscriberEmail:
                        this.state.allActiveData[key]["SubscriberEmail"],
                    SubscriberLocale:
                        this.state.allActiveData[key]["SubscriberLocale"],
                    SubscriberPhone:
                        this.state.allActiveData[key][
                            "SubscriberPhoneNumber.E164"
                        ],
                    SubscriberAddress1:
                        this.state.allActiveData[key]["AddressLine1"],
                    SubscriberAddress2:
                        this.state.allActiveData[key]["AddressLine2"],
                    SubscriberCity: this.state.allActiveData[key]["City"],
                    SubscriberState: this.state.allActiveData[key]["State"],
                    SubscriberPostalCode:
                        this.state.allActiveData[key]["PostalCode"],
                    SubsriberContactEmail:
                        this.state.allActiveData[key]["contactpref_email"],
                    SubsriberContactSMS:
                        this.state.allActiveData[key]["contactpref_sms"],
                    StartDateTimeUTC:
                        this.state.allActiveData[key][
                            "StartDateTimeUTC.ISO8601"
                        ] !== ""
                            ? new Intl.DateTimeFormat("en-US", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                              }).format(
                                  new Date(
                                      this.state.allActiveData[key][
                                          "StartDateTimeUTC.ISO8601"
                                      ]
                                  )
                              )
                            : "",
                    EndDateTimeUTC:
                        this.state.allActiveData[key][
                            "EndDateTimeUTC.ISO8601"
                        ] !== ""
                            ? new Intl.DateTimeFormat("en-US", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                              }).format(
                                  new Date(
                                      this.state.allActiveData[key][
                                          "EndDateTimeUTC.ISO8601"
                                      ]
                                  )
                              )
                            : "",
                    SubscriberToken:
                        this.state.allActiveData[key]["SubscriberToken"],
                    externalSubscriberID:
                        this.state.allActiveData[key]["externalSubscriberID"],
                    externalSubscriptionID:
                        this.state.allActiveData[key]["externalSubscriptionID"],
                });
            }
            var fileTitle = this.state.venueName; // or 'my-unique-title'
        }
        return (
            <>
                <MDBModalHeader toggle={this.props.toggleDownloadSubscribers}>
                    <h4>Bulk Download Subscribers</h4>
                </MDBModalHeader>
                <MDBModalBody>
                    <div className="venueSelector">
                        <MDBRow>
                            <MDBCol col="6" sm="12" lg="4">
                                <h5>Venue Name:</h5>
                            </MDBCol>
                            <MDBCol col="6" sm="12" lg="8">
                                <SelectSearch
                                    id="selectVenue"
                                    search={true}
                                    filterOptions={fuzzySearch}
                                    options={this.props.optionTemplateVenues}
                                    isOptionSelected={true}
                                    inputId="venueArray"
                                    classNamePrefix="venue-select"
                                    placeholder="Select..."
                                    onChange={
                                        this.props.handleSelectChangeVenue
                                    }
                                    value={
                                        this.props.clearDropDown === true
                                            ? ""
                                            : this.props.venueID
                                    }
                                />
                            </MDBCol>
                        </MDBRow>
                    </div>
                    <div className="text-right submitButton">
                        <MDBBtn
                            color="danger"
                            onClick={() =>
                                this.exportCSVFile(
                                    headers,
                                    itemsFormatted,
                                    fileTitle
                                )
                            }
                        >
                            {this.props.loadingVenueDetails === true ? (
                                <div
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                >
                                    <span className="sr-only">Loading...</span>
                                </div>
                            ) : (
                                "Export Subscribers"
                            )}
                        </MDBBtn>
                        <MDBBtn
                            color="primary"
                            onClick={this.props.toggleDownloadSubscribers}
                        >
                            Close
                        </MDBBtn>
                    </div>
                </MDBModalBody>
            </>
        );
    }
}
export default BulkDownloadSubscribers;
