import React, { Component } from "react";
import {
    MDBModalBody,
    MDBModalHeader,
    MDBRow,
    MDBCol,
    MDBIcon,
    MDBModal,
    toast,
    MDBProgress,
    MDBBtn,
} from "mdbreact";
import AddNewResident from "../../../../components/VenueMgmt/Forms/AddNewResident/AddNewResident";
import ViewModifyResidents from "../../../../components/VenueMgmt/RecentResidents/ViewModifyResidents";
import ResendActivation from "../../../../components/VenueMgmt/ResendActivation/ResendActivation";
import ServicesEndingSoon from "../../../../components/VenueMgmt/UpcomingExpirations/ServicesEndingSoon";
import VenueDropdown from "../../../../components/VenueMgmt/Navigation/VenueDropdown/VenueDropdown";
import axios from "../../../../axios";
import { withTranslation } from "react-i18next";
import CSVReader from "react-csv-reader";
const papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    quotes: true,
    transformHeader: (header) => header.toLowerCase().replace(/\W/g, "_"),
};
class ResidentManagementNavigation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalAddNewResident: false,
            modalViewModifyResident: false,
            modalResendActivation: false,
            modalServicesEndingSoon: false,
            activeData: this.props.activeData,
            allActiveData: [],
            loading: false,
            loading_upload: false,
            uploadSucceeded: undefined,
            lastProcessed: 0,
            paymentFailed: false,
            modalAddPayment: true,
        };
    }
    toggleAddNewResident = () => {
        this.setState({
            modalAddNewResident: !this.state.modalAddNewResident,
        });
    };
    toggleViewModifyResident = () => {
        this.setState({
            modalViewModifyResident: !this.state.modalViewModifyResident,
        });
    };
    toggleResendActivation = () => {
        this.setState({
            modalResendActivation: !this.state.modalResendActivation,
        });
    };
    toggleServicesEndingSoon = () => {
        this.setState({
            modalServicesEndingSoon: !this.state.modalServicesEndingSoon,
        });
    };
    toggleError = () => {
        this.setState({
            modalError: !this.state.modalError,
        });
    };
    submitHandler = (
        dateEnd,
        email,
        firstName,
        lastName,
        phoneNumber,
        language,
        unitID,
        building,
        floor,
        contactpref_email,
        contactpref_sms,
        dateStart,
        unitID_internal,
        servicePlan
    ) => {
        let isoDateString;
        let isoDateStart;
        this.setState({ loading: true });
        if (!isNaN(new Date(dateEnd))) {
            isoDateString = new Date(dateEnd)
                .toISOString()
                .replace(/:00.\d+Z$/g, "Z");
        }
        if (!isNaN(new Date(dateStart))) {
            isoDateStart = new Date(dateStart)
                .toISOString()
                .replace(/:00.\d+Z$/g, "Z");
        }
        let body = {
            AccessToken: sessionStorage.getItem("AccessToken"),
            EmailAddress: email,
            KeyPoolID: this.props.keypoolID[0],
            GivenName: firstName,
            FamilyName: lastName,
            "PhoneNumber.E164": phoneNumber,
            locale: language,
            "EndDateTimeUTC.ISO8601": isoDateString,
            "StartDateTimeUTC.ISO8601": isoDateStart,
            VenueID: this.props.venueID,
            PolicyforSubscriberKey: {
                UnitID_internal: unitID_internal,
            },
            contactpref_email: true,
            contactpref_sms: contactpref_sms,
            UserTimeZone: new Date().getTimezoneOffset(),
            ServicePlanID: servicePlan,
        };
        axios
            .post("/venuemgmtconsole/subscribers/addSubscriber", body, {
                headers: {},
            })
            .then(
                (response) => {
                    toast.success("Added Resident!", {
                        closeButton: false,
                    });
                    this.toggleAddNewResident();
                    this.props.updateName(
                        this.props.venueNameSelected,
                        this.props.venueID
                    );
                    this.setState({ loading: false });
                    if (response.data.paymentFailed) {
                        this.setState({
                            paymentFailed: true,
                        });
                    }
                    sessionStorage.setItem(
                        "unitID_internalSubscriber",
                        unitID_internal
                    );
                    sessionStorage.setItem(
                        "stripeClientSecretSubscriber",
                        response.data.StripeClientSecret
                    );
                },
                (error) => {
                    toast.error(error.response.data.message, {
                        closeButton: false,
                    });
                    this.toggleAddNewResident();
                    this.setState({ loading: false });
                }
            );
    };
    submitHandlerResendActivation = (email) => {
        let body = {
            AccessToken: sessionStorage.getItem("AccessToken"),
            EmailAddress: email,
            VenueID: this.props.venueID,
            UserTimeZone: new Date().getTimezoneOffset(),
        };
        this.setState({ loading: true });
        axios
            .post("/venuemgmtconsole/subscribers/resendCodeforTandC", body, {
                headers: {},
            })
            .then(
                (response) => {
                    toast.success("Resent Confirmation!", {
                        closeButton: false,
                    });
                    this.toggleResendActivation();
                    this.setState({ loading: false });
                },
                (error) => {
                    toast.error("There was an error!", {
                        closeButton: false,
                    });
                    this.toggleResendActivation();
                    this.setState({ loading: false });
                }
            );
    };
    convertToCSV(objArray) {
        var array =
            typeof objArray != "object" ? JSON.parse(objArray) : objArray;
        var str = "";
        for (var i = 0; i < array.length; i++) {
            var line = "";
            for (var index in array[i]) {
                if (line !== "") line += ",";
                line += array[i][index];
            }
            str += line + "\r\n";
        }
        return str;
    }
    exportCSVFile(headers, items, fileTitle) {
        if (headers !== items[0]) {
            items.unshift(headers);
        }
        // Convert Object to JSON
        var jsonObject = JSON.stringify(items);
        var csv = this.convertToCSV(jsonObject);
        var exportedFilename = fileTitle + ".csv" || "export.csv";
        var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        if (navigator.msSaveBlob) {
            // IE 10+
            navigator.msSaveBlob(blob, exportedFilename);
        } else {
            var link = document.createElement("a");
            if (link.download !== undefined) {
                // feature detection
                // Browsers that support HTML5 download attribute
                var url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", exportedFilename);
                link.style.visibility = "hidden";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }
    sleep(ms) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }
    convertCSV = async (data) => {
        this.setState({ loading_upload: true });
        this.setState({ modalError: false });
        this.setState({
            unitUnprocessed: undefined,
            buildingUnprocessed: undefined,
            floorUnprocessed: undefined,
            lastProcessed: undefined,
        });
        let dataImported = [];
        for (let i = 0; i < data.length; i++) {
            dataImported[i] = {
                AccessToken: sessionStorage.getItem("AccessToken"),
                EmailAddress: data[i]["email_"],
                KeyPoolID: this.props.keypoolID[0],
                GivenName: data[i]["given_name_"],
                FamilyName: data[i]["family_name_"],
                "PhoneNumber.E164": data[i]["phone___format___1xxxyyyzzzz_"],
                locale: data[i]["locale__en_us_"],
                "EndDateTimeUTC.ISO8601": new Date(
                    data[i]["end_date"]
                ).toISOString(),
                "StartDateTimeUTC.ISO8601": new Date(
                    data[i]["start_date_"]
                ).toISOString(),
                VenueID: this.props.venueID,
                contactpref_email:
                    data[i]["contact_by_email___true_false_"] === null
                        ? false
                        : data[i]["contact_by_email___true_false_"],
                contactpref_sms:
                    data[i]["contact_by_text___true_false_"] === null
                        ? false
                        : data[i]["contact_by_text___true_false_"],
                PolicyforSubscriberKey: {
                    UnitBuilding: data[i]["building_"],
                    UnitFloor: data[i]["floor_"],
                    UnitID: data[i]["unit_id_"],
                },
                UserTimeZone: new Date().getTimezoneOffset(),
                externalSubscriberID: data[i]["external_subscriber_id"],
                externalSubscriptionID: data[i]["external_subscription_id"],
            };
            let unitID = data[i]["unit_id_"];
            let building = data[i]["building_"];
            let floor = data[i]["floor_"];
            this.setState({ email: data[i]["email"] });
            await axios
                .post(
                    "/venuemgmtconsole/subscribers/addSubscriber",
                    dataImported[i],
                    {
                        headers: {},
                    }
                )
                .then(
                    (response) => {
                        this.props.updateName(
                            this.props.venueNameSelected,
                            this.props.venueID
                        );
                        toast.success(
                            "Unit " +
                                unitID +
                                ", Building " +
                                building +
                                ", Floor " +
                                floor +
                                " processed",
                            {
                                closeButton: false,
                            }
                        );
                    },
                    (error) => {
                        this.setState({
                            unitUnprocessed: unitID,
                            buildingUnprocessed: building,
                            floorUnprocessed: floor,
                            lastProcessed: i + 1,
                        });
                        this.cancelUploadedFile();
                        i = data.length;
                    }
                );
            this.setState({ progress: ((i + 1) / data.length) * 100 });
            this.setState({ modalError: true });
        }
        this.setState({ loading_upload: false });
    };
    cancelUploadedFile() {
        const randomString = Math.random().toString(36);
        this.setState({
            theInputKey: randomString,
        });
    }
    togglePayment = () => {
        this.setState({
            modalAddPayment: !this.state.modalAddPayment,
        });
    };
    render() {
        if (this.state.postMessage) {
            var h = document.getElementById("overlayResidentManagement");
            h.style.display = "block";
            setTimeout(function () {
                h.style.display = "block";
            }, 5000);
            setTimeout(() => {
                h.style.display = "none";
                this.setState({ postMessage: "" });
            }, 6000);
        }
        var headers = {
            unitID: "Unit ID*",
            unitBuilding: "Building*",
            unitFloor: "Floor*",
            SubscriberGivenName: "Given Name*",
            SubscriberFamilyName: "Family Name*",
            SubscriberEmail: "Email*",
            SubscriberLocale: "Locale (en-us)",
            SubscriberPhone: "Phone* (Format: +1xxxyyyzzzz)",
            SubscriberAddress1: "Address 1*",
            SubscriberAddress2: "Address 2",
            SubscriberCity: "City*",
            SubscriberState: "State*",
            SubscriberPostalCode: "Postal Code*",
            SubsriberContactEmail: "Contact by Email* (true/false)",
            SubsriberContactSMS: "Contact by Text* (true/false)",
            StartDateTimeUTC: "Start Date*",
            EndDateTimeUTC: "End Date",
            SubscriberToken: "Subscriber Token",
            externalSubscriberID: "External Subscriber ID",
            externalSubscriptionID: "External Subscription ID",
        };
        var itemsFormatted = [];
        for (const key of Object.keys(this.props.allActiveData)) {
            itemsFormatted.push({
                unitID: this.props.allActiveData[key]["UnitID"],
                unitBuilding: this.props.allActiveData[key]["UnitBuilding"],
                unitFloor: this.props.allActiveData[key]["UnitFloor"],
                SubscriberGivenName:
                    this.props.allActiveData[key]["SubscriberGivenName"],
                SubscriberFamilyName:
                    this.props.allActiveData[key]["SubscriberFamilyName"],
                SubscriberEmail:
                    this.props.allActiveData[key]["SubscriberEmail"],
                SubscriberLocale:
                    this.props.allActiveData[key]["SubscriberLocale"],
                SubscriberPhone:
                    this.props.allActiveData[key]["SubscriberPhoneNumber.E164"],
                SubscriberAddress1:
                    this.props.allActiveData[key]["AddressLine1"],
                SubscriberAddress2:
                    this.props.allActiveData[key]["AddressLine2"],
                SubscriberCity: this.props.allActiveData[key]["City"],
                SubscriberState: this.props.allActiveData[key]["State"],
                SubscriberPostalCode:
                    this.props.allActiveData[key]["PostalCode"],
                SubsriberContactEmail:
                    this.props.allActiveData[key]["contactpref_email"],
                SubsriberContactSMS:
                    this.props.allActiveData[key]["contactpref_sms"],
                StartDateTimeUTC:
                    this.props.allActiveData[key][
                        "StartDateTimeUTC.ISO8601"
                    ] !== ""
                        ? new Intl.DateTimeFormat("en-US", {
                              year: "numeric",
                              month: "2-digit",
                              day: "2-digit",
                          }).format(
                              new Date(
                                  this.props.allActiveData[key][
                                      "StartDateTimeUTC.ISO8601"
                                  ]
                              )
                          )
                        : "",
                EndDateTimeUTC:
                    this.props.allActiveData[key]["EndDateTimeUTC.ISO8601"] !==
                    ""
                        ? new Intl.DateTimeFormat("en-US", {
                              year: "numeric",
                              month: "2-digit",
                              day: "2-digit",
                          }).format(
                              new Date(
                                  this.props.allActiveData[key][
                                      "EndDateTimeUTC.ISO8601"
                                  ]
                              )
                          )
                        : "",
                SubscriberToken:
                    this.props.allActiveData[key]["SubscriberToken"],
                externalSubscriberID:
                    this.props.allActiveData[key]["externalSubscriberID"],
                externalSubscriptionID:
                    this.props.allActiveData[key]["externalSubscriptionID"],
            });
        }
        var fileTitle = this.props.venueNameSelected; // or 'my-unique-title'
        const { t } = this.props;
        return (
            <>
                {this.state.paymentFailed ? (
                    <MDBModal
                        isOpen={this.state.modalAddPayment}
                        toggle={this.togglePayment}
                        size="md"
                        className="form"
                        centered
                    >
                        <MDBModalHeader toggle={this.togglePayment}>
                            Modify Billing Credit Card Info
                        </MDBModalHeader>
                        <MDBModalBody className="error">
                            <h4>
                                The subscriber's credit card info needs to be
                                updated for billing purposes.
                            </h4>
                            <MDBBtn
                                color="danger"
                                className="btn Ripple-parent btn-default centered upgradePlan"
                                href={
                                    "/update/checkout?ui=" +
                                    window.location.pathname.slice(1)
                                }
                            >
                                Modify Billing CC
                            </MDBBtn>
                        </MDBModalBody>
                    </MDBModal>
                ) : (
                    ""
                )}
                {this.state.lastProcessed > 0 ? (
                    <>
                        <MDBModal
                            isOpen={this.state.modalError}
                            toggle={this.toggle}
                            size="md"
                            className="form"
                            centered
                        >
                            <MDBModalHeader toggle={this.toggleError}>
                                <h4>Error Uploading Residents</h4>
                            </MDBModalHeader>
                            <MDBModalBody>
                                {this.state.lastProcessed === 1 ? (
                                    <p>
                                        Line {this.state.lastProcessed} (Unit:{" "}
                                        {this.state.unitUnprocessed}, Building:{" "}
                                        {this.state.buildingUnprocessed}, Floor:{" "}
                                        {this.state.floorUnprocessed}) could not
                                        be processed. Re-upload the CSV after
                                        fixing line 1.
                                    </p>
                                ) : (
                                    <p>
                                        Line {this.state.lastProcessed} (Unit:{" "}
                                        {this.state.unitUnprocessed}, Building:{" "}
                                        {this.state.buildingUnprocessed}, Floor:{" "}
                                        {this.state.floorUnprocessed}) could not
                                        be processed. Re-upload the CSV after
                                        fixing line {this.state.lastProcessed}{" "}
                                        and removing lines 1-
                                        {this.state.lastProcessed - 1}.
                                    </p>
                                )}
                            </MDBModalBody>
                        </MDBModal>
                    </>
                ) : (
                    ""
                )}
                {this.state.loading_upload === true ? (
                    <div
                        className="overlay"
                        id="overlay"
                        style={{ display: "block" }}
                    >
                        <div className="overlayProgressBar">
                            <MDBProgress
                                material
                                value={this.state.progress}
                                height="20px"
                                color="danger"
                                striped
                            />
                        </div>
                    </div>
                ) : (
                    ""
                )}
                {this.props.loading === true ? (
                    <div id="overlay" style={{ display: "block" }}>
                        <div className="overlayMessage">
                            <div
                                className="spinner-border spinner-border"
                                role="status"
                            >
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>
                ) : (
                    ""
                )}
                <MDBModalHeader toggle={this.props.toggle}>
                    <VenueDropdown
                        getVenues={this.props.getVenues}
                        updateName={this.props.updateName}
                    />
                    <h4>
                        {this.props.venueType === "HOTSPOT"
                            ? "Local Policy Management"
                            : t("residentnav.title")}
                    </h4>
                </MDBModalHeader>
                <MDBModalBody>
                    <div className="managementModal">
                        <MDBRow>
                            <MDBCol size="12" md="6" lg="4">
                                <div
                                    className="managementModalButton"
                                    onClick={this.toggleAddNewResident}
                                >
                                    <MDBIcon icon="user-plus" />
                                    <p>
                                        {this.props.venueType === "HOTSPOT"
                                            ? "Add New Local Policy"
                                            : t("residentnav.addnew")}
                                    </p>
                                </div>
                            </MDBCol>
                            <MDBCol size="12" md="6" lg="4">
                                <div
                                    className="managementModalButton"
                                    onClick={this.toggleViewModifyResident}
                                >
                                    <MDBIcon icon="edit" />
                                    <p>
                                        {this.props.venueType === "HOTSPOT"
                                            ? "View/Modify Local Policy"
                                            : t("residentnav.viewmodify")}
                                    </p>
                                </div>
                            </MDBCol>
                            <MDBCol size="12" md="6" lg="4">
                                <div
                                    className="managementModalButton"
                                    onClick={this.toggleResendActivation}
                                >
                                    <MDBIcon icon="envelope-open" />
                                    <p>{t("residentnav.resendactivation")}</p>
                                </div>
                            </MDBCol>
                            <MDBCol size="12" md="6" lg="4">
                                <div
                                    className="managementModalButton"
                                    onClick={this.toggleServicesEndingSoon}
                                >
                                    <MDBIcon icon="stopwatch" />
                                    <p>{t("residentnav.servicesending")}</p>
                                </div>
                            </MDBCol>
                            <MDBCol size="12" md="6" lg="4">
                                <div
                                    className="managementModalButton"
                                    onClick={() =>
                                        this.exportCSVFile(
                                            headers,
                                            itemsFormatted,
                                            fileTitle
                                        )
                                    }
                                >
                                    <MDBIcon icon="cloud-download-alt" />
                                    <p>
                                        {this.props.venueType === "HOTSPOT"
                                            ? "Export Local Policies"
                                            : t("residentnav.export")}
                                    </p>
                                </div>
                            </MDBCol>
                            <MDBCol size="12" md="6" lg="4">
                                <div
                                    className="managementModalButton"
                                    key={this.state.theInputKey || ""}
                                >
                                    <MDBIcon icon="cloud-upload-alt" />
                                    <CSVReader
                                        cssclassName="csv-reader-input"
                                        onFileLoaded={this.convertCSV}
                                        parserOptions={papaparseOptions}
                                        inputId="csvData"
                                        inputStyle={{ color: "transparent" }}
                                        label={
                                            this.props.venueType === "HOTSPOT"
                                                ? "Bulk Upload Local Policies"
                                                : t("residentnav.upload")
                                        }
                                    />
                                </div>
                            </MDBCol>
                        </MDBRow>
                    </div>
                </MDBModalBody>
                <MDBModal
                    isOpen={this.state.modalAddNewResident}
                    toggle={this.toggle}
                    size="lg"
                    className="form"
                    centered
                >
                    <AddNewResident
                        toggleAddNewResident={this.toggleAddNewResident}
                        submitHandler={this.submitHandler}
                        venueID={this.props.venueID}
                        loading={this.state.loading}
                        venueType={this.props.venueType}
                        availablePlans={this.props.availablePlans}
                    />
                </MDBModal>
                <MDBModal
                    isOpen={this.state.modalViewModifyResident}
                    toggle={this.toggle}
                    size="fluid"
                    className="form"
                    centered
                >
                    <ViewModifyResidents
                        toggleViewModifyResident={this.toggleViewModifyResident}
                        recentResidents={this.props.activeData}
                        allRecentResidents={this.props.allActiveData}
                        venueID={this.props.venueID}
                        venueName={this.props.venueNameSelected}
                        updateName={this.props.updateName}
                        loading={this.state.loading}
                        venueType={this.props.venueType}
                        availablePlans={this.props.availablePlans}
                        showUpdate={this.props.showUpdate}
                        cancelInputType={this.props.cancelInputType}
                    />
                </MDBModal>
                <MDBModal
                    isOpen={this.state.modalResendActivation}
                    toggle={this.toggle}
                    size="lg"
                    className="form"
                    centered
                >
                    <ResendActivation
                        toggleResendActivation={this.toggleResendActivation}
                        recentResidents={this.props.activeData}
                        submitHandlerResendActivation={
                            this.submitHandlerResendActivation
                        }
                        loading={this.state.loading}
                    />
                </MDBModal>
                <MDBModal
                    isOpen={this.state.modalServicesEndingSoon}
                    toggle={this.toggle}
                    size="fluid"
                    className="form"
                    centered
                >
                    <ServicesEndingSoon
                        toggleServicesEndingSoon={this.toggleServicesEndingSoon}
                        expirations={this.props.expirations}
                        venueID={this.props.venueID}
                        venueName={this.props.venueNameSelected}
                        updateName={this.props.updateName}
                    />
                </MDBModal>
            </>
        );
    }
}
export default withTranslation()(ResidentManagementNavigation);
