import React, { Component } from "react";

import {
    MDBBtn,
    MDBModalBody,
    MDBModalHeader,
    MDBSelect,
    MDBSelectInput,
    MDBSelectOptions,
    MDBSelectOption,
    MDBRow,
    MDBCol,
} from "mdbreact";

import "react-phone-number-input/style.css";

import { isPossiblePhoneNumber } from "react-phone-number-input";

import { withTranslation } from "react-i18next";

import $ from "jquery";

class AddKey extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: null,
            content: null,
            venueName: this.props.venueName,
            phoneNumber: undefined,
            phone: undefined,
            email: undefined,
            loading_prop_groups: false,
            invalidPhone: null,
            invalidCountry: null,
            invalidState: null,
        };
    }

    componentDidMount() {
        this.getKeyPools();
    }

    validateEmail = (email) => {
        var mailformat = /^[\w._-]+[+]?[\w._-]+@[\w.-]+\.[a-zA-Z]{2,6}$/;
        this.setState({
            invalidPhone: undefined,
            userFound: false,
            userFoundWithPhone: false,
        });

        if (!this.state.email.match(mailformat)) {
            this.setState({
                invalidEmail: "Please provide a valid email address",
            });
            $(".emailInput").addClass("is-invalid");
        } else {
            this.setState({ invalidEmail: undefined });
            $(".emailInput").removeClass("is-invalid");
        }
    };

    selectCountry(val) {
        this.setState({ country: val });
    }

    selectRegion(val) {
        this.setState({ region: val });
    }

    getValueOfSelect = (e) => {
        var index = e.target.selectedIndex;
        var optionElement = e.target.childNodes[index];
        this.setState({
            propertyMUTCGroupSecret: optionElement.getAttribute("value"),
        });
        this.setState({ propertyMUTCGroup: optionElement.getAttribute("id") });
    };

    getValueOfSelectCountry = (value) => {
        this.setState({ country: value[0] });
    };

    getValueOfSelectState = (value) => {
        this.setState({ region: value[0] });
    };

    getValueOfSelectKeyPool = (e) => {
        this.setState({ selectValueKeyPool: e.target.value });
    };

    handleSelectGroup = (value) => {
        this.setState({ selectGroup: value[0] });
    };

    handleSelectKeyPool = (value) => {
        this.setState({ selectKeypool: value[0] });
    };

    handleSelectTimeZone = (value) => {
        this.setState({ selectTimeZone: value[0] });
    };

    getKeyPools = () => {
        var keypoolArray = [];
        let optionTemplateKeyPools = [];
        Object.keys(this.props.keyPoolList).map((oneKey, i) => {
            optionTemplateKeyPools.push(
                <MDBSelectOption value={oneKey}>
                    {this.props.keyPoolList[oneKey]["ssid"]}
                </MDBSelectOption>
            );
            return true;
        });
        keypoolArray.push(
            <MDBSelect
                id="selectKeypool"
                className="form-control"
                getValue={this.handleSelectKeyPool}
            >
                <MDBSelectInput
                    className="selectInput selectKeypool"
                    selected="Choose the KeyPool"
                />
                <MDBSelectOptions search>
                    {optionTemplateKeyPools}
                </MDBSelectOptions>
            </MDBSelect>
        );
        this.setState({
            keypoolForm: keypoolArray,
        });
    };

    submitAddKey = (event) => {
        event.preventDefault();

        $("#selectKeypool").removeClass("is-invalid");
        event.target.className += " was-validated";

        if (this.state.selectKeypool === undefined) {
            this.setState({ invalidKeypool: "Please provide a valid KeyPool" });
            $("#selectKeypool").addClass("is-invalid");
        } else {
            $("#selectKeypool").addClass("is-valid");
        }

        if (this.state.selectKeypool !== undefined) {
            this.props.submitAddKey(
                this.state.selectKeypool,
                this.props.UnitID_internal
            );
        }
    };

    changeHandler = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };

    changeHandlerPhone = (event) => {
        this.setState({ phoneNumber: event });
        var value = event
            ? isPossiblePhoneNumber(event)
                ? undefined
                : "Please provide a valid phone number"
            : "Phone number required";
        this.setState({ invalidPhone: value });
    };
    createMask = () => {
        this.setState({
            phoneNumber: this.state.phoneNumber.replace(/\D/g, ""),
        });
    };

    render() {
        const { t } = this.props;
        return (
            <>
                {this.state.loading_prop_groups === true ? (
                    <div
                        className="overlay"
                        id="overlay"
                        style={{ display: "block" }}
                    >
                        <div className="overlayMessage">
                            <div
                                className="spinner-border spinner-border"
                                role="status"
                            >
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>
                ) : (
                    ""
                )}
                <MDBModalHeader toggle={this.props.toggleAddKey}>
                    <h4>Add New Key</h4>
                    <p>{t("addnewresident.required")}</p>
                </MDBModalHeader>
                <MDBModalBody>
                    <form
                        className="needs-validation"
                        onSubmit={this.submitAddKey}
                        noValidate
                    >
                        <MDBRow>
                            <MDBCol col="6" sm="12" lg="12">
                                <label
                                    htmlFor="defaultFormLoginEmailEx"
                                    className="black-text"
                                >
                                    Venue Name*
                                </label>
                                <input
                                    disabled
                                    name="venueName"
                                    id="venueName"
                                    className="form-control emailInput"
                                    onChange={this.changeHandler}
                                    value={this.state.venueName}
                                />
                                <div className="invalid-feedback">
                                    Please provide a valid name
                                </div>
                            </MDBCol>
                            <MDBCol col="6"></MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol sm="12" lg="6">
                                <label
                                    htmlFor="defaultFormLoginEmailEx"
                                    className="black-text"
                                >
                                    {t("addnewresident.firstname")}
                                </label>
                                <input
                                    disabled
                                    name="firstName"
                                    id="firstName"
                                    className="form-control"
                                    onChange={this.changeHandler}
                                    value={this.props.firstName}
                                />
                                <div className="invalid-feedback">
                                    Please provide a valid first name.
                                </div>
                            </MDBCol>
                            <MDBCol sm="12" lg="6">
                                <label
                                    htmlFor="defaultFormLoginEmailEx"
                                    className="black-text"
                                >
                                    {t("addnewresident.lastname")}
                                </label>
                                <input
                                    disabled
                                    className="form-control"
                                    name="lastName"
                                    id="lastName"
                                    onChange={this.changeHandler}
                                    value={this.props.lastName}
                                />
                                <div className="invalid-feedback">
                                    Please provide a valid last name.
                                </div>
                            </MDBCol>
                        </MDBRow>

                        <MDBRow>
                            <MDBCol col="6" sm="12" lg="6">
                                <label
                                    htmlFor="defaultFormLoginEmailEx"
                                    className="black-text"
                                >
                                    {t("addnewresident.phone")}
                                </label>
                                <input
                                    disabled
                                    className="form-control"
                                    name="phoneNumber"
                                    id="phoneNumber"
                                    onChange={this.changeHandler}
                                    value={this.props.phoneNumber}
                                />
                                {this.state.invalidPhone !== null ? (
                                    <div className="invalid">
                                        {this.state.invalidPhone}
                                    </div>
                                ) : (
                                    ""
                                )}
                            </MDBCol>
                            <MDBCol sm="12" lg="6">
                                <label
                                    htmlFor="defaultFormLoginEmailEx"
                                    className="black-text"
                                >
                                    Email*
                                </label>
                                <input
                                    disabled
                                    name="email"
                                    id="email"
                                    className="form-control emailInput"
                                    onChange={this.changeHandler}
                                    value={this.props.email}
                                />
                                {this.state.invalidEmail !== undefined ? (
                                    <div className="invalid">
                                        {this.state.invalidEmail}
                                    </div>
                                ) : (
                                    ""
                                )}
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol col="6">
                                <label
                                    htmlFor="defaultFormLoginEmailEx"
                                    className="black-text"
                                >
                                    {t("addnewresident.unit")}
                                </label>
                                <input
                                    disabled
                                    id="unitID"
                                    className="form-control"
                                    value={this.props.unitID}
                                />
                                <div className="invalid-feedback">
                                    Please provide a unit number.
                                </div>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol col="6">
                                <label
                                    htmlFor="defaultFormLoginEmailEx"
                                    className="black-text"
                                >
                                    {t("addnewresident.building")}
                                </label>
                                <input
                                    disabled
                                    id="building"
                                    className="form-control"
                                    value={this.props.building}
                                />

                                <div className="invalid-feedback">
                                    Please provide a building.
                                </div>
                            </MDBCol>
                            <MDBCol col="6">
                                <label
                                    htmlFor="defaultFormLoginEmailEx"
                                    className="black-text"
                                >
                                    {t("addnewresident.floor")}
                                </label>
                                <input
                                    disabled
                                    id="floor"
                                    className="form-control"
                                    value={this.props.floor}
                                />

                                <div className="invalid-feedback">
                                    Please provide a floor.
                                </div>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol col="6">
                                <label
                                    htmlFor="defaultFormLoginEmailEx"
                                    className="black-text"
                                >
                                    KeyPool*
                                </label>
                                {this.state.keypoolForm}
                                <div className="invalid-feedback">
                                    Please provide a KeyPool
                                </div>
                            </MDBCol>
                        </MDBRow>
                        <div className="text-right submitButton">
                            <MDBBtn color="danger" type="submit">
                                {this.props.loadingAddKey === true ? (
                                    <div
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                    >
                                        <span className="sr-only">
                                            Loading...
                                        </span>
                                    </div>
                                ) : (
                                    "Add Key"
                                )}
                            </MDBBtn>
                        </div>
                    </form>
                </MDBModalBody>
            </>
        );
    }
}

export default withTranslation()(AddKey);
