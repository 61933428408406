import React, { Component } from "react";

import { MDBModalBody, MDBModalHeader } from "mdbreact";

import { withTranslation } from "react-i18next";

import brandsData from "../../../brands/common/brands.json";

const faq = "";
function highlight_text_nodes($nodes, word) {
    if (!$nodes.length) {
        return;
    }

    var text = "";

    // Concatenate the consecutive nodes to get the actual text
    for (var i = 0; i < $nodes.length; i++) {
        text += $nodes[i].textContent;
    }

    var $fragment = document.createDocumentFragment();

    while (true) {
        // Tweak this if you want to change the highlighting behavior
        var index = text.toLowerCase().indexOf(word.toLowerCase());

        if (index === -1) {
            break;
        }

        // Split the text into [before, match, after]
        var before = text.slice(0, index);
        var match = text.slice(index, index + word.length);
        text = text.slice(index + word.length);

        // Create the <mark>
        var $mark = document.createElement("mark");
        $mark.className = "found";
        $mark.appendChild(document.createTextNode(match));

        // Append it to the fragment
        $fragment.appendChild(document.createTextNode(before));
        $fragment.appendChild($mark);
    }

    // If we have leftover text, just append it to the end
    if (text.length) {
        $fragment.appendChild(document.createTextNode(text));
    }

    // Replace the nodes with the fragment
    $nodes[0].parentNode.insertBefore($fragment, $nodes[0]);

    for (var j = 0; j < $nodes.length; j++) {
        var $node = $nodes[$nodes.length - j - 1];
        $node.parentNode.removeChild($node);
    }
}

/*
 * Highlights all instances of `word` in `$node` and its children
 */
function highlight($node, word) {
    var $children = $node.childNodes;
    var $current_run = [];

    for (var i = 0; i < $children.length; i++) {
        var $child = $children[i];

        if ($child.nodeType === Node.TEXT_NODE) {
            // Keep track of consecutive text nodes
            $current_run.push($child);
        } else {
            // If we hit a regular element, highlight what we have and start over
            highlight_text_nodes($current_run, word);
            $current_run = [];

            // Ignore text inside of our <mark>s
            if (
                $child.nodeType === Node.ELEMENT_NODE &&
                $child.className !== "found"
            ) {
                highlight($child, word);
            }
        }
    }

    // Just in case we have only text nodes as children
    if ($current_run.length) {
        highlight_text_nodes($current_run, word);
    }
}

/*
 * Removes all highlighted <mark>s from the given node
 */
function unhighlight($node) {
    var $marks = [].slice.call($node.querySelectorAll("mark.found"));

    for (var i = 0; i < $marks.length; i++) {
        var $mark = $marks[i];

        // Replace each <mark> with just a text node of its contents
        $mark.parentNode.replaceChild(
            document.createTextNode($mark.childNodes[0].textContent),
            $mark
        );
    }
}

class FAQ extends Component {
    constructor(props) {
        super(props);
        this.state = {
            res: faq,
        };
    }

    componentDidMount() {
        var $p = document.querySelector("p");
        document.getElementById("faq").querySelector("input").onkeyup =
            function () {
                unhighlight($p);

                if (this.value.length) {
                    highlight($p, this.value);
                }
            };
    }

    render() {
        const { t } = this.props;
        let brandName = "";
        let domain = "";
        let phoneNumber = "";
        let clientURL = "";
        brandsData.forEach(({ name, url, phone_number, client_url }) => {
            if (url.includes(window.location.hostname)) {
                brandName = name;
                domain = window.location.hostname;
                phoneNumber = phone_number;
                clientURL = client_url;
            }
        });

        return (
            <>
                <MDBModalHeader toggle={this.props.toggleFAQs}>
                    {t("faq.title")}
                </MDBModalHeader>

                <MDBModalBody className="faqModal" id="faq">
                    <div className="ui icon input">
                        <i aria-hidden="true" className="search icon"></i>
                        <input type="text" id="searcher" />
                    </div>
                    <br />
                    <p>
                        <div className="faq" id="searcher">
                            <h5>Connecting your devices to the Wi-Fi</h5>
                            <p>
                                <strong>
                                    Instructions for connecting common devices
                                    to Wi-Fi
                                </strong>
                            </p>
                            <li>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://support.apple.com/en-us/HT201735"
                                >
                                    Apple Mac
                                </a>
                            </li>
                            <li>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://support.apple.com/en-us/HT202639"
                                >
                                    Apple iPhone, iPad or IPod
                                </a>
                            </li>
                            <li>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://support.google.com/android/answer/9075847?hl=en"
                                >
                                    Android Smartphone
                                </a>
                            </li>
                            <li>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://support.microsoft.com/en-us/help/4027030/windows-10-connect-to-a-wi-fi-network"
                                >
                                    Microsoft Windows 10
                                </a>
                            </li>{" "}
                            <br />
                            <p>
                                <strong>
                                    Connecting common smart home devices
                                </strong>
                            </p>
                            <li>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://support.google.com/googlenest/answer/9223192?hl=en"
                                >
                                    Nest Thermostat
                                </a>
                            </li>
                            <li>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://support.google.com/googlenest/answer/9223711?hl=en"
                                >
                                    Nest Camera and Nest Hello
                                </a>
                            </li>
                            <li>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://support.ring.com/hc/en-us/articles/115001773266-Setting-Up-Your-Ring-Video-Doorbell-in-the-Ring-App"
                                >
                                    Ring Doorbell
                                </a>
                            </li>
                            <li>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://support.ecobee.com/hc/en-us/articles/360029033431-Wi-Fi-FAQ-Setup-Guide-and-Troubleshooting-"
                                >
                                    Ecobee Thermostat
                                </a>
                            </li>{" "}
                            <br />
                            <p>
                                {" "}
                                <strong>Note:</strong>&nbsp;Before connecting
                                smart home devices and gaming consoles, please
                                connect a smartphone / laptop / desktop to Wi-Fi
                                network and accept the terms and conditions for
                                using the Wi-Fi network. Most IoT / smart home
                                devices do not have a built-in web browser, and
                                will not be able to display terms and conditions
                                for Wi-Fi network.{" "}
                            </p>
                            <br />
                            <h5>Frequently Asked Questions</h5>
                            <p>
                                <strong>
                                    I want to change my Wi-Fi password or I have
                                    lost my password. How do I retrieve my
                                    password to connect a new device?
                                </strong>
                            </p>
                            <p>
                                {" "}
                                You can view or reset your Wi-Fi password using
                                the Wi-Fi management console at:{" "}
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={"https://" + domain + "/reset"}
                                >
                                    {"https://" + domain + "/reset"}
                                </a>{" "}
                                Please log in to the Wi-Fi management console
                                using the email address that&nbsp;you provided
                                to your property manager to receive Wi-Fi
                                password. If this is your first
                                time&nbsp;logging into the Wi-Fi management
                                console, you will have to first create an
                                account password. Information on how to do this
                                is included in your welcome email. In case you
                                do not have the welcome email or the account
                                password, please reset your password by clicking
                                on{" "}
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={"https://" + domain + "/reset"}
                                >
                                    "Forgot your password? Click Here"
                                </a>{" "}
                                on the login page. <br />{" "}
                                <strong className="redText">Important:</strong>{" "}
                                The Wi-Fi management console password is
                                different from your Wi-Fi password. Once logged
                                into the Wi-Fi management console, your current
                                Wi-Fi password will be visible. There will also
                                be the option to change your Wi-Fi password.{" "}
                            </p>{" "}
                            <br />
                            <p>
                                <strong>
                                    Why do I have two passwords? Which one
                                    should I be using?
                                </strong>
                            </p>
                            <p>
                                {" "}
                                One password is used to log in to the Wi-Fi
                                management console. This allows you to make
                                changes to your account, including updating your
                                passwords. The other password is used to connect
                                devices to the Wi-Fi network. When in doubt,
                                your Wi-Fi password will include a unique string
                                of numbers at the beginning, indicating your
                                apartment unit number. <br /> Why do we do this?
                                The system is designed to be similar to a home
                                network, while still providing the benefit of
                                property-wide roaming. A typical home network
                                has an administrative account to log in to the
                                router for configuration purposes, as well as
                                the Wi-Fi network password itself. Similarly,{" "}
                                {brandName} has: (1) an account password to
                                login to the Wi-Fi management console, and (2)
                                your personal and secure Wi-Fi network password
                                to connect your devices to the Wi-Fi network.{" "}
                            </p>{" "}
                            <br />
                            <p>
                                <strong>Is the network secure?</strong>
                            </p>
                            <p>
                                {" "}
                                Yes, the network is extremely secure. Each user
                                is given a unique password required to log on to
                                the network. That password tells the network how
                                to route any internet traffic while
                                simultaneously not allowing any one user to see
                                any devices or internet traffic from another
                                user. <br /> For the more technically curious,
                                this is done by providing each user with their
                                own private VLAN, or Virtual Local Area Network.
                                Each password is tied to a unique VLAN, and no
                                two VLANs are allowed to communicate with each
                                other. Every device you add to the network with
                                your password is on your specific VLAN, and your
                                neighbor is on their own and separate VLAN with
                                their unique password. This keeps all of the
                                internet traffic between users separated and
                                unable to communicate with each other. This is
                                why, for example, if you have a castable
                                streaming device like a Google Chromecast you
                                are able to cast to your device, but you can’t
                                see any other castable devices from your
                                neighbors. <br /> In addition, your Wi-Fi
                                password has a unique encryption key. Compared
                                to other networks where all users share their
                                encryption key, {brandName} raises the bar on
                                security with unique encryption keys for every
                                user.{" "}
                            </p>{" "}
                            <br />
                            <p>
                                <strong>
                                    I have a question or issue that is not
                                    listed. Who can help?
                                </strong>
                            </p>
                            <p>
                                {" "}
                                Please call {brandName} at {phoneNumber}{" "}
                                {clientURL !== "" ? (
                                    // console.log(clientURL) : console.log(clientURL)
                                    <span>
                                        or visit us at{" "}
                                        <a
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href={"https://" + clientURL + ""}
                                        >
                                            {clientURL}
                                        </a>
                                    </span>
                                ) : (
                                    ""
                                )}
                            </p>
                        </div>
                    </p>
                </MDBModalBody>
            </>
        );
    }
}

export default withTranslation()(FAQ);
