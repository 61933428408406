import React, { Component } from "react";

import {
    MDBRow,
    MDBCol,
    MDBModal,
    MDBModalHeader,
    MDBModalBody,
} from "mdbreact";

import { withTranslation } from "react-i18next";

import ViewUnitStatus from "../../../components/VenueMgmt/ResidentSummary/ViewUnitStatus";

import SelectSearch, { fuzzySearch } from "react-select-search";

class ResidentSummary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            active: false,
            loading: false,
            viewUnitsFilterSelect: [
                { value: "assigned", name: "Assigned Units" },
                { value: "unassigned", name: "Unassigned Units" },
                { value: "pendingAUP", name: "Pending AUP Acceptance" },
                { value: "all", name: "All Units" },
            ],
        };
    }
    toggleViewUnitStatus = () => {
        this.setState({
            ViewUnitStatus: !this.state.ViewUnitStatus,
        });
    };
    viewAssignedUnits = () => {
        this.setState({
            viewUnitsFilter: "assigned",
        });
        this.toggleViewUnitStatus();
    };
    viewAllUnits = () => {
        this.setState({
            viewUnitsFilter: "",
        });
        this.toggleViewUnitStatus();
    };
    viewPendingUnits = () => {
        this.setState({
            viewUnitsFilter: "pendingAUP",
        });
        this.toggleViewUnitStatus();
    };
    viewUnAssignedUnits = () => {
        this.setState({
            viewUnitsFilter: "unassigned",
        });
        this.toggleViewUnitStatus();
    };

    handleSelectUsageUnits = (e) => {
        this.setState({
            viewUnitsFilter: e,
        });
    };

    render() {
        const { t } = this.props;
        return (
            <div>
                <MDBModal
                    isOpen={this.state.ViewUnitStatus}
                    toggle={this.toggleViewUnitStatus}
                    className="form"
                    size="fluid"
                    centered
                >
                    <MDBModalHeader toggle={this.toggleViewUnitStatus}>
                        <h4>Units</h4>
                    </MDBModalHeader>
                    <MDBModalBody>
                        <form className="needs-validation" noValidate>
                            <MDBRow>
                                <MDBCol col="6">
                                    <SelectSearch
                                        id="unitsFilter"
                                        search={true}
                                        filterOptions={fuzzySearch}
                                        options={
                                            this.state.viewUnitsFilterSelect
                                        }
                                        inputId="unitsFilterArray"
                                        classNamePrefix="unit-select form-control"
                                        onChange={(e) =>
                                            this.handleSelectUsageUnits(e)
                                        }
                                        value={this.state.viewUnitsFilter}
                                    />
                                </MDBCol>
                            </MDBRow>
                        </form>
                        <br />
                        <ViewUnitStatus
                            toggleViewUnitStatus={this.toggleViewUnitStatus}
                            selectedName={this.props.selectedName}
                            coreEquipment={this.props.coreEquipmentState}
                            venueList={this.props.venueList}
                            UnitsBuildingFloorListSusbcribers={
                                this.props.UnitsBuildingFloorListSusbcribers
                            }
                            filter={this.state.viewUnitsFilter}
                            availablePlans={this.props.availablePlans}
                            venueID={this.props.venueID}
                            updateName={this.props.updateName}
                            venueName={this.props.venueName}
                        />
                    </MDBModalBody>
                </MDBModal>
                <div className="summaryCard">
                    <a href="#residentSummary" onClick={this.viewAllUnits}>
                        <h2>{t("servicestatus.title")}</h2>
                    </a>
                    <div className="residentSummary rounded third-step">
                        <MDBRow>
                            <MDBCol md="9">
                                <a
                                    href="#residentSummary"
                                    onClick={this.viewAssignedUnits}
                                >
                                    <p>{t("servicestatus.assignedunits")}</p>
                                </a>
                            </MDBCol>
                            <MDBCol md="3">
                                <a
                                    href="#residentSummary"
                                    onClick={this.viewAssignedUnits}
                                >
                                    {" "}
                                    <p>{this.props.assignedUnits}</p>
                                </a>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md="9">
                                <a
                                    href="#residentSummary"
                                    onClick={this.viewUnAssignedUnits}
                                >
                                    <p>{t("servicestatus.unassignedunits")}</p>
                                </a>
                            </MDBCol>
                            <MDBCol md="3">
                                <a
                                    href="#residentSummary"
                                    onClick={this.viewUnAssignedUnits}
                                >
                                    <p>{this.props.unassignedUnits}</p>
                                </a>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md="9">
                                <a
                                    href="#residentSummary"
                                    onClick={this.viewPendingUnits}
                                >
                                    <p>{t("servicestatus.pending")}</p>
                                </a>
                            </MDBCol>
                            <MDBCol md="3">
                                <a
                                    href="#residentSummary"
                                    onClick={this.viewPendingUnits}
                                >
                                    <p>{this.props.pendingAUP}</p>
                                </a>
                            </MDBCol>
                        </MDBRow>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(ResidentSummary);
