import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import Popper from "@mui/material/Popper";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
// function QuickSearchToolbar() {
//   return (
//     <Box
//       sx={{
//         p: 0.5,
//         pb: 0,
//       }}
//     >
//       <GridToolbarQuickFilter />
//     </Box>
//   );
// }
function isOverflown(element) {
    return (
        element.scrollHeight > element.clientHeight ||
        element.scrollWidth > element.clientWidth
    );
}
const GridCellExpand = React.memo(function GridCellExpand(props) {
    const { width, value } = props;
    const wrapper = React.useRef(null);
    const cellDiv = React.useRef(null);
    const cellValue = React.useRef(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [showFullCell, setShowFullCell] = React.useState(false);
    const [showPopper, setShowPopper] = React.useState(false);
    const handleMouseEnter = () => {
        const isCurrentlyOverflown = isOverflown(cellValue.current);
        setShowPopper(isCurrentlyOverflown);
        setAnchorEl(cellDiv.current);
        setShowFullCell(true);
    };
    const handleMouseLeave = () => {
        setShowFullCell(false);
    };
    React.useEffect(() => {
        if (!showFullCell) {
            return undefined;
        }
        function handleKeyDown(nativeEvent) {
            // IE11, Edge (prior to using Bink?) use 'Esc'
            if (nativeEvent.key === "Escape" || nativeEvent.key === "Esc") {
                setShowFullCell(false);
            }
        }
        document.addEventListener("keydown", handleKeyDown);
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [setShowFullCell, showFullCell]);
    return (
        <Box
            ref={wrapper}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            sx={{
                alignItems: "center",
                lineHeight: "24px",
                width: "100%",
                height: "100%",
                position: "relative",
                display: "flex",
            }}
        >
            <Box
                ref={cellDiv}
                sx={{
                    height: "100%",
                    width,
                    display: "block",
                    position: "absolute",
                    top: 0,
                }}
            />
            <Box
                ref={cellValue}
                sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                }}
            >
                {value}
            </Box>
            {showPopper && (
                <Popper
                    open={showFullCell && anchorEl !== null}
                    anchorEl={anchorEl}
                    style={{ width, marginLeft: -17 }}
                >
                    <Paper
                        elevation={1}
                        style={{ minHeight: wrapper.current.offsetHeight - 3 }}
                    >
                        <Typography variant="body2" style={{ padding: 8 }}>
                            {value}
                        </Typography>
                    </Paper>
                </Popper>
            )}
        </Box>
    );
});
function renderCellExpand(params) {
    return (
        <GridCellExpand
            value={params.formattedValue || ""}
            width={displayTextWidth(
                params.formattedValue,
                ".9rem Proxima Nova Rg"
            )}
        />
    );
}
function displayTextWidth(text, font) {
    let canvas =
        displayTextWidth.canvas ||
        (displayTextWidth.canvas = document.createElement("canvas"));
    let context = canvas.getContext("2d");
    context.font = font;
    let metrics = context.measureText(text);
    return metrics.width + 25;
}
export default function Basic(props) {
    let columns = [];
    let rows = [];
    if (props.searchResults !== undefined) {
        if (Object.keys(props.searchResults).length !== 0) {
            for (const key of Object.keys(props.searchResults)) {
                let activeState;
                if (
                    props.searchResults[key][
                        "SetupSuccessDateTimeUTC.ISO8601"
                    ] === ""
                ) {
                    activeState = "Abandoned";
                } else if (
                    new Date(
                        props.searchResults[key]["EndDateTimeUTC.ISO8601"]
                    ).getTime() < new Date().getTime()
                ) {
                    activeState = "Ended";
                } else {
                    activeState = "Active";
                }
                rows.push({
                    id: key,
                    venueID: props.searchResults[key]["VenueID"],
                    unitID_internal:
                        props.searchResults[key]["UnitID_internal"],
                    subName:
                        props.searchResults[key]["GivenName"] +
                        " " +
                        props.searchResults[key]["FamilyName"],
                    subPhone: props.searchResults[key]["PhoneNumber.E164"],
                    subEmail: props.searchResults[key]["EmailAddress"],
                    subInService:
                        props.searchResults[key]["AccountStatusAtCreation"],
                    venueName: props.searchResults[key]["VenueName"],
                    subStartDate:
                        props.searchResults[key][
                            "StartDateTimeUTC.ISO8601"
                        ].toString(),
                    subEndDate:
                        props.searchResults[key][
                            "EndDateTimeUTC.ISO8601"
                        ].toString(),
                    active: activeState,
                    subToken: props.searchResults[key]["SubscriberToken"],
                    // clickEvent: (new Date(props.searchResults[key]["EndDateTimeUTC.ISO8601"]) > new Date()) || props.searchResults[key]["EndDateTimeUTC.ISO8601"] === "" ?
                    //   () => props.getVenueDetails(key, props.searchResults[key]["UnitID_internal"]) :
                    //   ""
                });
            }
        }
    }
    columns = [
        {
            headerName: "Subscriber Name",
            field: "subName",
            renderCell: renderCellExpand,
            minWidth: 180,
        },
        {
            headerName: "Phone",
            field: "subPhone",
            renderCell: renderCellExpand,
            minWidth: 140,
        },
        {
            headerName: "Email",
            field: "subEmail",
            renderCell: renderCellExpand,
            minWidth: 180,
        },
        {
            headerName: "Venue Name",
            field: "venueName",
            renderCell: renderCellExpand,
            minWidth: 180,
        },
        {
            headerName: "Start Date",
            field: "subStartDate",
            type: "dateTime",
            valueFormatter: (value) =>
                value &&
                new Intl.DateTimeFormat("en-US", {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                }).format(new Date(value)),
            renderCell: renderCellExpand,
            minWidth: 180,
        },
        {
            headerName: "End Date",
            field: "subEndDate",
            type: "dateTime",
            valueFormatter: (value) =>
                value &&
                new Intl.DateTimeFormat("en-US", {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                }).format(new Date(value)),
            renderCell: renderCellExpand,
            minWidth: 180,
        },
        {
            headerName: "Subscription Status",
            field: "active",
            renderCell: (params) => (
                <div>
                    <div className="subStatus"></div>
                    {params.row.active}
                </div>
            ),
            minWidth: 180,
        },
    ];
    // rows = userAttributes;
    return (
        <div style={{ height: 300, width: "100%" }}>
            <DataGrid
                rows={rows}
                columns={columns}
                columnVisibilityModel={{
                    // Hide columns status and traderName, the other columns will remain visible
                    unitID_internal: false,
                }}
                // onRowClick={(id) => console.log(id["row"]["unitID_internal"])}
                onRowClick={(id) =>
                    props.getVenueUnitDetails(
                        id["id"],
                        id["row"]["venueID"],
                        id["row"]["unitID_internal"],
                        id["row"]["active"],
                        id["row"]["subToken"],
                    )
                }
                getRowHeight={({ id, densityFactor }) => {
                    return 40 * densityFactor;
                }}
                // slots={{ toolbar: QuickSearchToolbar }}
                // hideFooterPagination={true}
                // hideFooter={true}
                // onRowSelectionModelChange={(ids) => {
                //   const selectedIDs = new Set(ids);
                //   const selectedRowData = rows.filter((row) =>
                //     selectedIDs.has(row.id.toString())
                //   );
                //   props.getSelectedUnits(selectedRowData);
                // }
                // }
                sx={{ "--DataGrid-overlayHeight": "300px" }}
                className="subscribersTable"
                onSortModelChange={props.findSubscribers}
                initialState={{
                    sorting: {
                        sortModel: [{ field: "active", sort: "asc" }],
                    },
                }}
                getRowClassName={(id) =>
                    id["row"]["active"] === "Abandoned" ||
                    id["row"]["active"] === "Ended"
                        ? "inactiveSubscription"
                        : "activeSubscription"
                }
            />
        </div>
    );
}
