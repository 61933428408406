import React, { Component } from "react";

import { MDBRow, MDBCol, MDBModal } from "mdbreact";

import { withTranslation } from "react-i18next";

import ViewVenues from "../../../components/NetworkMgmt/Venues/ViewVenues";

class ResidentSummary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            active: false,
            loading: false,
        };
    }

    toggleViewVenues = () => {
        this.setState({
            modalViewVenues: !this.state.modalViewVenues,
        });
    };

    toggleViewVenuePropMgmtGroups = () => {
        this.setState({
            modalViewVenuePropMgmtGroups:
                !this.state.modalViewVenuePropMgmtGroups,
        });
    };

    render() {
        return (
            <div>
                <MDBModal
                    isOpen={this.state.modalViewVenues}
                    toggle={this.toggleViewVenues}
                    className="form"
                    size="fluid"
                    centered
                >
                    <ViewVenues
                        venueList={this.props.venueList["VenueList"]}
                        toggleViewModifyVenues={this.toggleViewVenues}
                    />
                </MDBModal>

                <div className="summaryCard">
                    <a href="#link" onClick={this.viewAllUnits}>
                        <h2>Subscribers</h2>
                    </a>
                    <div className="support rounded">
                        <MDBRow>
                            <MDBCol md="9">
                                <a href="#link">
                                    <p>Subscribers</p>
                                </a>
                            </MDBCol>
                            <MDBCol md="3">
                                <a href="#link">
                                    {" "}
                                    <p>
                                        {
                                            this.props.venueList[
                                                "PolicyCount_GlobalWirelessPlusLocalWiredAccess"
                                            ]
                                        }
                                    </p>
                                </a>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md="9">
                                <p>Devices</p>
                            </MDBCol>
                            <MDBCol md="3">
                                <p>
                                    {this.props.venueList["OnlineDeviceCount"]}
                                </p>
                            </MDBCol>
                        </MDBRow>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(ResidentSummary);
