import React from "react";

import { MDBDataTableV5 } from "mdbreact";

import { useTranslation } from "react-i18next";

import getSymbolFromCurrency from "currency-symbol-map";

export default function Basic(props) {
    const { t } = useTranslation();
    let userAttributes;
    let i = 0;

    let datatable;
    [datatable] = React.useState("");

    if (props.currencies !== undefined) {
        userAttributes = [];
        let currencyType;
        for (const key of Object.keys(props.currencies)) {
            currencyType = props.currencies[key];
            userAttributes.push({
                currency: props.currencies[key],
                billingCyclePrice:
                    getSymbolFromCurrency(props.currencies[key]) +
                    (
                        props.allServicePlans[props.index][
                            "BillingCyclePrice." + currencyType
                        ] / 100
                    ).toFixed(2),
            });
            i = i + 1;
        }
    }

    datatable = {
        columns: [
            {
                label: "Currency",
                field: "currency",
            },
            {
                label: "Billing Cycle Price",
                field: "billingCyclePrice",
            },
        ],
        rows: userAttributes,
    };
    return (
        <MDBDataTableV5
            responsive
            hover
            data={datatable}
            searchBottom={false}
            paging={false}
            className="modifyStaff"
            info={false}
            noRecordsFoundLabel={t("recentservice.nomatches")}
        />
    );
}
