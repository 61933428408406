import axios from "axios";

const host = window.location.host;
let instance;
switch (host) {
  case "dish.roamingiqtest.com":
  case "dishfiberlabtesting.roamingiqtest.com": {
    instance = axios.create({
      baseURL: "https://api.wifikey.link/v1",
    });
    break;
  }
  case "dish.wifikey.link": {
    instance = axios.create({
      baseURL: "https://api.wifikey.link/dish",
    });
    break;
  }
  case "www-test.mydishfiber.com": {
    instance = axios.create({
      baseURL: "https://api-dishfiber.roamingiqtest.com/v1",
    });
    break;
  }
  case "blueport.roamingiqtest.com": {
    instance = axios.create({
      baseURL: "https://api.roamingiqtest.com/v1",
    });
    break;
  }
  case "blueportiq.roamingiqtest.com": {
    instance = axios.create({
      baseURL: "https://api.roamingiqtest.com/v1",
    });
    break;
  }
  case "roamingiq.roamingiqtest.com": {
    instance = axios.create({
      baseURL: "https://api.roamingiqtest.com/v1",
    });
    break;
  }
  case "xperity.wifikey.link": {
    instance = axios.create({
      baseURL: "https://apixperity.us-east-1.wifikey.link/xperity",
    });
    break;
  }
  case "localhost:3003": {
    instance = axios.create({
      baseURL: "https://api.wifikey.link/v1qa",
    });
    break;
  }
  // case "localhost:3003":
  case "xperity.wifikey.link":
  case "sanket3xperity.wifikey.link":
  case "sanket5xperity.wifikey.link": {
    instance = axios.create({
      baseURL: "https://apixperity.wifikey.link/xperity",
    });
    break;
  }
  case "xperity.roamingiqtest.com":
  case "xperity2.roamingiqtest.com":
  case "xperity3xperity.roamingiqtest.com": {
    instance = axios.create({
      baseURL: "https://apixperity.roamingiqtest.com/xperityqa",
    });
    break;
  }
  case "devgj01.wifikey.link": {
   instance = axios.create({
    baseURL: "https://api.wifikey.link/devgj",
  });
  break;
  }
  case "devdd01.wifikey.link": {
   instance = axios.create({
    baseURL: "https://api.wifikey.link/devdd",
  });
  break;
  }
  case "devsr01.wifikey.link": {
    instance = axios.create({
      baseURL: "https://api.wifikey.link/devsr01",
    });
    break;
  }
  case "devsr02.wifikey.link": {
    instance = axios.create({
      baseURL: "https://api.wifikey.link/devsr02",
    });
    break;
  }
   case "qaapijc01.wifikey.link": {
   instance = axios.create({
     baseURL: "https://api.wifikey.link/qaapijc",
   });
   break;
   }
   case "qauijc01.wifikey.link": {
   instance = axios.create({
     baseURL: "https://api.wifikey.link/qauijc",
   });
   break;
   }
   case "qars01.wifikey.link": {
   instance = axios.create({
     baseURL: "https://api.wifikey.link/qars",
   });
   break;
   }
  default: {
    instance = axios.create({
      baseURL: "https://api.roamingiqtest.com/v1",
    });
    break;
  }
}
sessionStorage.setItem("host", host);
// baseURL: 'https://api.roamingiqtest.com/v1qa'

instance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        const originalRequest = error.config;
        const refreshToken = sessionStorage.getItem("AccessToken");
        if (
            error.response &&
            error.response.status === 401 &&
            error.config &&
            !error.config.__isRetryRequest &&
            refreshToken
        ) {
            originalRequest._retry = true;
            instance
                .post("/subscriberconsole/refreshAccessToken", {
                    AccessToken: sessionStorage.getItem("AccessToken"),
                    RefreshToken: sessionStorage.getItem("RefreshToken"),
                })
                .then((tokenRefreshResponse) => {
                    //console.log("Refreshing");
                    //console.log(tokenRefreshResponse);
                    sessionStorage.setItem(
                        "AccessToken",
                        tokenRefreshResponse.data.AuthenticationResult
                            .AuthenticationResult.AccessToken
                    );
                    originalRequest.headers["Authorization"] =
                        tokenRefreshResponse.data.AuthenticationResult.AuthenticationResult.AccessToken;
                    return Promise.resolve();
                });
        }
        return Promise.reject(error);
    }
);

export default instance;
