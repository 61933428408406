import React, { Component } from "react";

import {
    MDBBtn,
    MDBModalBody,
    MDBModalHeader,
    MDBRow,
    MDBCol,
    MDBInput,
    MDBTooltip,
    MDBIcon,
} from "mdbreact";

import "react-phone-number-input/style.css";

import { withTranslation } from "react-i18next";

class AddKeypool extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: null,
            content: null,
            venueName: this.props.venueName,
            phoneNumber: undefined,
            phone: undefined,
            email: undefined,
            loading_prop_groups: false,
            invalidPhone: null,
            invalidCountry: null,
            invalidState: null,
            displayOrder: undefined,
        };
    }

    submitAddKeypool = (event) => {
        event.preventDefault();

        if (this.state.ssid !== undefined) {
            this.props.submitAddKeypool(
                this.state.ssid,
                this.props.venueID,
                this.state.setKeypoolRole,
                this.state.displayOrder,
                this.state.setKeypoolRole
            );
        }
    };

    changeHandler = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };

    changeHandlerRadio = (nr) => () => {
        this.setState({
            setKeypoolRole: nr,
        });
    };

    render() {
        const { t } = this.props;
        return (
            <>
                <MDBModalHeader toggle={this.props.toggleAddKeypool}>
                    <h4>Add New Keypool</h4>
                    <p>{t("addnewresident.required")}</p>
                </MDBModalHeader>
                <MDBModalBody>
                    <form
                        className="needs-validation"
                        onSubmit={this.submitAddKeypool}
                        noValidate
                    >
                        {this.props.globalKey !== true ? (
                            <MDBRow>
                                <MDBCol col="6" sm="12" lg="12">
                                    <label
                                        htmlFor="defaultFormLoginEmailEx"
                                        className="black-text"
                                    >
                                        Venue Name*
                                    </label>
                                    <input
                                        disabled
                                        name="venueName"
                                        id="venueName"
                                        className="form-control emailInput"
                                        onChange={this.changeHandler}
                                        value={this.props.venueName}
                                    />
                                    <div className="invalid-feedback">
                                        Please provide a valid name
                                    </div>
                                </MDBCol>
                                <MDBCol col="6"></MDBCol>
                            </MDBRow>
                        ) : (
                            ""
                        )}
                        <MDBRow>
                            <MDBCol sm="12" lg="12">
                                <label
                                    htmlFor="defaultFormLoginEmailEx"
                                    className="black-text"
                                >
                                    SSID
                                </label>
                                <input
                                    required
                                    name="ssid"
                                    id="ssid"
                                    className="form-control"
                                    onChange={this.changeHandler}
                                    value={this.state.ssid}
                                />
                                <div className="invalid-feedback">
                                    Please provide a valid SSID name.
                                </div>
                            </MDBCol>
                        </MDBRow>
                        <br />
                        <p className="mb-0">Keypool Type:</p>
                        <MDBInput
                            label="Global"
                            type="radio"
                            id="setKeypoolRoleGlobal"
                            name="Global"
                            onClick={this.changeHandlerRadio("global")}
                            checked={
                                this.state.setKeypoolRole === "global"
                                    ? true
                                    : false
                            }
                        >
                            <MDBTooltip
                                material
                                placement="left"
                                className="rightTooltip"
                                style={{ display: "block" }}
                            >
                                <MDBIcon far icon="question-circle" />
                                <span>
                                    This keypool will be available at all venues
                                </span>
                            </MDBTooltip>
                        </MDBInput>
                        <MDBInput
                            label="Local"
                            type="radio"
                            id="setKeypoolRoleLocal"
                            name="Local"
                            onClick={this.changeHandlerRadio("local")}
                            checked={
                                this.state.setKeypoolRole === "local"
                                    ? true
                                    : false
                            }
                        >
                            <MDBTooltip
                                material
                                placement="left"
                                className="rightTooltip"
                                style={{ display: "block" }}
                            >
                                <MDBIcon far icon="question-circle" />
                                <span>
                                    This keypool will only be available at the
                                    selected venue
                                </span>
                            </MDBTooltip>
                        </MDBInput>
                        <br />
                        {this.state.setKeypoolRole === "local" &&
                        this.props.globalKey === true ? (
                            <MDBRow>
                                <MDBCol col="6" sm="12" lg="12">
                                    <label
                                        htmlFor="defaultFormLoginEmailEx"
                                        className="black-text"
                                    >
                                        Venue for Local Keypool
                                    </label>
                                    {this.props.attributeForm}
                                    <div className="invalid-feedback">
                                        Please select a venue.
                                    </div>
                                </MDBCol>
                                <MDBCol col="6"></MDBCol>
                            </MDBRow>
                        ) : (
                            ""
                        )}
                        {/* <MDBRow>
                            <MDBCol col="6" sm="12" lg="12">
                                <label
                                    htmlFor="defaultFormLoginEmailEx"
                                    className="black-text"
                                >
                                    Display Order
                                </label>
                                <input
                                    name="displayOrder"
                                    id="displayOrder"
                                    className="form-control emailInput"
                                    onChange={this.changeHandler}
                                    value={this.state.displayOrder}
                                />
                            </MDBCol>
                            <MDBCol col="6"></MDBCol>
                        </MDBRow> */}
                        <div className="text-right submitButton">
                            <MDBBtn color="danger" type="submit">
                                {this.props.add_keypool === true ? (
                                    <div
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                    >
                                        <span className="sr-only">
                                            Loading...
                                        </span>
                                    </div>
                                ) : (
                                    "Add Keypool"
                                )}
                            </MDBBtn>
                        </div>
                    </form>
                </MDBModalBody>
            </>
        );
    }
}

export default withTranslation()(AddKeypool);
