import * as React from "react";
import { DataGrid, GridToolbarQuickFilter } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import Typography from "@material-ui/core/Typography";
function QuickSearchToolbar() {
    return (
        <Box
            sx={{
                p: 0.5,
                pb: 0,
            }}
        >
            <GridToolbarQuickFilter />
        </Box>
    );
}
export default function Basic(props) {
    let userAttributes;
    let i = 0;
    let columns = [];
    let rows = [];
    if (props.unitsList !== undefined) {
        userAttributes = [];
        for (const key of Object.keys(props.unitsList)) {
            let purpose;
            switch (props.unitsList[key]["Purpose"]) {
                case "wiredunit":
                    purpose = "Residential Unit";
                    break;
                case "localwirelessonly":
                    purpose = "Guest Subscription";
                    break;
                case "globalroaming":
                    purpose = "Roaming Device";
                    break;
                case "globalsubscribers":
                    purpose = "Global Subscriber";
                    break;
                default:
                    purpose = "Residential Unit";
                    break;
            }
            let Address2 =
                props.unitsList[key]["address2"] !== ""
                    ? props.unitsList[key]["address2"] + "\n\r"
                    : "";
            userAttributes.push({
                id: key,
                purpose: purpose,
                unitID: props.unitsList[key]["UnitID"],
                building: props.unitsList[key]["Building"],
                floor: props.unitsList[key]["Floor"],
                vlan: props.unitsList[key]["vlan"],
                address: props.unitsList[key]["address1"],
                address2: props.unitsList[key]["address2"],
                city: props.unitsList[key]["city"],
                state: props.unitsList[key]["state"],
                postalcode: props.unitsList[key]["postalcode"],
                smsCode: props.unitsList[key]["smsCode"],
                hasActiveSubscription:
                    props.unitsList[key]["hasActiveSubscription"] === true
                        ? "Yes"
                        : "No",
                unitID_internal: props.unitsList[key]["unitID_internal"],
            });
            i = i + 1;
        }
    }

    columns = [
        {
            headerName: "Purpose",
            field: "purpose",
            minWidth: 120,
        },
        {
            headerName: "Unit ID",
            field: "unitID",
            minWidth: 120,
        },
        {
            headerName: "Building",
            field: "building",
            minWidth: 120,
        },
        {
            headerName: "Floor",
            field: "floor",
            minWidth: 120,
        },
        {
            headerName: "VLAN",
            field: "vlan",
            minWidth: 120,
        },
        {
            headerName: "Address",
            field: "address",
            renderCell: (params) => (
                // console.log(params)
                <div>
                    <div>{params.row.address}</div>
                    <div>{params.row.address2}</div>
                    <div>
                        {params.row.city}, {params.row.state}{" "}
                        {params.row.postalcode}
                    </div>
                </div>
            ),
            valueGetter: (value, row) => {
                return `
           ${row.address || ""} 
           ${row.address2 || ""} 
           ${row.city || ""} 
           ${row.state || ""} 
           ${row.postalcode || ""}`;
            },
            minWidth: 180,
        },
        {
            headerName: "SMS Code",
            field: "smsCode",
            minWidth: 140,
        },
        {
            headerName: "Active\nSubscription?",
            field: "hasActiveSubscription",
            minWidth: 200,
        },
        {
            headerName: "Unit ID Internal",
            field: "unitID_internal",
            hide: true,
        },
    ];
    rows = userAttributes;
    return (
        <div style={{ height: 400, width: "100%" }}>
            <DataGrid
                rows={rows}
                columns={columns}
                columnVisibilityModel={{
                    // Hide columns status and traderName, the other columns will remain visible
                    unitID_internal: false,
                }}
                checkboxSelection
                disableRowSelectionOnClick={true}
                onRowClick={(rowData) => props.getSubscriptionList(rowData)}
                getRowHeight={({ id, densityFactor }) => {
                    return 60 * densityFactor;
                }}
                slots={{ toolbar: QuickSearchToolbar }}
                hideFooterPagination={false}
                hideFooter={false}
                onRowSelectionModelChange={(ids) => {
                    const selectedIDs = new Set(ids);
                    const selectedRowData = rows.filter((row) =>
                        selectedIDs.has(row.id.toString())
                    );
                    props.getSelectedUnits(selectedRowData);
                }}
            />
        </div>
    );
}
