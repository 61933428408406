import React, { Component } from "react";

import {
    MDBBtn,
    MDBModalBody,
    MDBModalHeader,
    MDBRow,
    MDBCol,
    MDBTabPane,
    MDBTabContent,
    MDBNav,
    MDBNavItem,
    MDBNavLink,
    MDBIcon,
} from "mdbreact";

import reactCSS from "reactcss";

import { ChromePicker } from "react-color";

import { withTranslation } from "react-i18next";

import SelectSearch, { fuzzySearch } from "react-select-search";

import parse from "html-react-parser";

class AddNewVenueEquipment extends Component {
    constructor(props) {
        super(props);

        this.state = {
            Email: "",
            sms: false,
            voice: false,
            invalidPhone: null,
            loading: false,
            userFound: false,
            activeItem: "1",
            languageOptions: [
                { value: "en-us", name: "English" },
                { value: "es-xl", name: "Spanish" },
            ],
            emailTypeOptions: [
                { value: "WelcomeEmail", name: "Welcome Email" },
                { value: "SubscriberEvents", name: "Subscriber Events" },
            ],
            emailUserOptions: [
                { value: "NewSubscriber", name: "New Subscriber" },
                { value: "KnownSubscriber", name: "Known Subscriber" },
                { value: "GuestAccess", name: "Guest Access" },
                { value: "NewAdministrator", name: "New Administrator" },
                { value: "KnownAdministrator", name: "Known Administrator" },
            ],
            emailUserEventsOptions: [
                {
                    value: "PasswordResetInitiated",
                    name: "Password Reset Initiated",
                },
                {
                    value: "PasswordResetComplete",
                    name: "Password Reset Complete",
                },
                // { value: 'PIIDataUpdated', name: 'PII Data Updated' },
                { value: "PaymentSuccess", name: "Payment Success" },
                { value: "PaymentFailed", name: "Payment Failed" },
                {
                    value: "PaymentMethodUpdated",
                    name: "Payment Method Updated",
                },
                {
                    value: "SubscriptionStartDateAdjustment",
                    name: "Subscription Start Date Adjustment",
                },
                {
                    value: "SubscriptionEndDateAdjustment",
                    name: "Subscription End Date Adjustment",
                },
                { value: "SubscriptionEnded", name: "Subscription Ended" },
                {
                    value: "SubscriptionPlanUpdated",
                    name: "Subscription Plan Updated",
                },
                {
                    value: "SubscriptionPriceUpdated",
                    name: "Subscription Price Updated",
                },
                {
                    value: "SubscriptionNextInvoiceAdjustment",
                    name: "Subscription Next Invoice Adjustment",
                },
                { value: "ChargeRefunded", name: "Charge Refunded" },
                {
                    value: "MessageFromAdministrator",
                    name: "Message From Administrator",
                },
            ],
            displayColorPicker: false,
            disabled:
                sessionStorage.getItem("customerSupport") > 0 ? true : false,
            // emailSectionOptions: ([
            // 	{ value: 'Subject', name: 'Subject' },
            // 	{ value: 'preheader', name: 'Pre-Header' },
            // 	{ value: 'title', name: 'Title' },
            // 	{ value: 'serviceinfo', name: 'Service Info' },
            // 	{ value: 'header', name: 'Header' },
            // 	{ value: 'message', name: 'Message' },
            // 	{ value: 'actionbutton', name: 'Action Button' },
            // 	{ value: 'additional_instructions', name: 'Additional Instructions' }
            // ]),
            // emailServiceInfoOptions: ([
            // 	{ value: 'template', name: 'Template' },
            // 	{ value: 'networkname', name: 'Network Name' },
            // 	{ value: 'wifipassword', name: 'Wifi Password' },
            // 	{ value: 'subscriptionstart', name: 'Subscription Start' },
            // 	{ value: 'subscriptionend', name: 'Subscription End' }
            // ])
        };
        this.handleEditorChangeMainTemplate =
            this.handleEditorChangeMainTemplate.bind(this);
        this.handleEditorChangePreheader =
            this.handleEditorChangePreheader.bind(this);
        this.handleEditorChangeHeader =
            this.handleEditorChangeHeader.bind(this);
        this.handleEditorChangeTitle = this.handleEditorChangeTitle.bind(this);
        this.handleEditorChangeActionButton =
            this.handleEditorChangeActionButton.bind(this);
        this.handleEditorChangeAdditionalInfo =
            this.handleEditorChangeAdditionalInfo.bind(this);
        this.handleEditorChange = this.handleEditorChange.bind(this);
        this.handleEditorChangeTemplate =
            this.handleEditorChangeTemplate.bind(this);
        this.handleEditorChangeNetworkName =
            this.handleEditorChangeNetworkName.bind(this);
        this.handleEditorChangeWifiPassword =
            this.handleEditorChangeWifiPassword.bind(this);
        this.handleEditorChangeSubscriptionStart =
            this.handleEditorChangeSubscriptionStart.bind(this);
        this.handleEditorChangeSubscriptionEnd =
            this.handleEditorChangeSubscriptionEnd.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.logoURL !== this.props.logoURL) {
            this.setState({ logoURL: this.props.logoURL });
        }
        if (prevProps.dynamicVariables !== this.props.dynamicVariables) {
            this.setState({ dynamicVariables: this.props.dynamicVariables });
        }
        if (prevProps.brandColor !== this.props.brandColor) {
            this.setState({ brandColor: this.props.brandColor });
        }
    }

    submitModifyEmail = (event) => {
        event.preventDefault();
        event.target.className += " was-validated";

        if (
            this.state.language !== undefined &&
            this.state.type !== undefined &&
            this.state.emailSubject !== undefined &&
            this.state.emailMessage !== undefined
        ) {
            this.props.submitModifyEmail(
                this.state.language,
                this.state.type,
                this.state.emailUserType,
                this.state.emailSubject,
                this.state.emailMessage,
                this.state.logoURL,
                this.state.dynamicVariables,
                this.state.brandColor,
                this.state.emailMainTemplate,
                this.state.emailPreheader,
                this.state.emailTitle,
                this.state.emailHeader,
                this.state.emailActionButton,
                this.state.emailAdditionalInfo,
                this.state.emailTemplate,
                this.state.emailNetworkName,
                this.state.emailWifiPassword,
                this.state.emailSubscriptionStart,
                this.state.emailSubscriptionEnd
            );
        }
    };

    changeHandlerLanguage = (value) => {
        this.setState({
            language: value,
        });
        this.handleEditorChange(
            this.props.allEmailData[value === undefined ? "en-us" : value][
                this.state.type + "Message"
            ]
        );
    };

    changeHandlerEmailType = (value) => {
        this.setState({
            type: value,
            emailMainTemplate:
                this.props.allEmailData[
                    this.state.language === undefined
                        ? "en-us"
                        : this.state.language
                ][value]["Template"],
        });
        this.handleEditorChange(
            this.props.allEmailData[
                this.state.language === undefined
                    ? "en-us"
                    : this.state.language
            ][value + "Message"]
        );
    };

    changeHandlerUserType = (value) => {
        let previewMessage = undefined;
        this.setState({
            previewMessage:
                this.props.allEmailData[
                    this.state.language === undefined
                        ? "en-us"
                        : this.state.language
                ][this.state.type][value]["Template"],
            emailUserType: value,
            emailSubject:
                this.props.allEmailData[
                    this.state.language === undefined
                        ? "en-us"
                        : this.state.language
                ][this.state.type][value]["Subject"],
            emailPreheader:
                this.props.allEmailData[
                    this.state.language === undefined
                        ? "en-us"
                        : this.state.language
                ][this.state.type][value]["preheader"],
            emailTitle:
                this.props.allEmailData[
                    this.state.language === undefined
                        ? "en-us"
                        : this.state.language
                ][this.state.type][value]["title"],
            emailHeader:
                this.props.allEmailData[
                    this.state.language === undefined
                        ? "en-us"
                        : this.state.language
                ][this.state.type][value]["header"],
            emailMessage:
                this.props.allEmailData[
                    this.state.language === undefined
                        ? "en-us"
                        : this.state.language
                ][this.state.type][value]["message"],
            emailActionButton:
                this.props.allEmailData[
                    this.state.language === undefined
                        ? "en-us"
                        : this.state.language
                ][this.state.type][value]["actionbutton"],
            emailAdditionalInfo:
                this.props.allEmailData[
                    this.state.language === undefined
                        ? "en-us"
                        : this.state.language
                ][this.state.type][value]["additional_instructions"],
            emailTemplate:
                this.props.allEmailData[
                    this.state.language === undefined
                        ? "en-us"
                        : this.state.language
                ][this.state.type][value]["serviceinfo"] !== undefined
                    ? this.props.allEmailData[
                          this.state.language === undefined
                              ? "en-us"
                              : this.state.language
                      ][this.state.type][value]["serviceinfo"]["template"]
                    : undefined,
            emailNetworkName:
                this.props.allEmailData[
                    this.state.language === undefined
                        ? "en-us"
                        : this.state.language
                ][this.state.type][value]["serviceinfo"] !== undefined
                    ? this.props.allEmailData[
                          this.state.language === undefined
                              ? "en-us"
                              : this.state.language
                      ][this.state.type][value]["serviceinfo"]["networkname"]
                    : undefined,
            emailWifiPassword:
                this.props.allEmailData[
                    this.state.language === undefined
                        ? "en-us"
                        : this.state.language
                ][this.state.type][value]["serviceinfo"] !== undefined
                    ? this.props.allEmailData[
                          this.state.language === undefined
                              ? "en-us"
                              : this.state.language
                      ][this.state.type][value]["serviceinfo"]["wifipassword"]
                    : undefined,
            emailSubscriptionStart:
                this.props.allEmailData[
                    this.state.language === undefined
                        ? "en-us"
                        : this.state.language
                ][this.state.type][value]["serviceinfo"] !== undefined
                    ? this.props.allEmailData[
                          this.state.language === undefined
                              ? "en-us"
                              : this.state.language
                      ][this.state.type][value]["serviceinfo"][
                          "subscriptionstart"
                      ]
                    : undefined,
            emailSubscriptionEnd:
                this.props.allEmailData[
                    this.state.language === undefined
                        ? "en-us"
                        : this.state.language
                ][this.state.type][value]["serviceinfo"] !== undefined
                    ? this.props.allEmailData[
                          this.state.language === undefined
                              ? "en-us"
                              : this.state.language
                      ][this.state.type][value]["serviceinfo"][
                          "subscriptionend"
                      ]
                    : undefined,
            activeItem: "1",
        });
        previewMessage = this.props.allEmailData[
            this.state.language === undefined ? "en-us" : this.state.language
        ][this.state.type]["Template"].replace(
            "{preheader}",
            this.props.allEmailData[
                this.state.language === undefined
                    ? "en-us"
                    : this.state.language
            ][this.state.type][value]["preheader"]
        );
        previewMessage = previewMessage.replace(
            "{serviceinfo}",
            this.props.allEmailData[
                this.state.language === undefined
                    ? "en-us"
                    : this.state.language
            ][this.state.type][value]["serviceinfo"] !== undefined
                ? this.props.allEmailData[
                      this.state.language === undefined
                          ? "en-us"
                          : this.state.language
                  ][this.state.type][value]["serviceinfo"]["template"]
                : ""
        );
        previewMessage = previewMessage.replace(
            "{title}",
            this.props.allEmailData[
                this.state.language === undefined
                    ? "en-us"
                    : this.state.language
            ][this.state.type][value]["title"]
        );
        previewMessage = previewMessage.replace(
            "{networkname}",
            this.props.allEmailData[
                this.state.language === undefined
                    ? "en-us"
                    : this.state.language
            ][this.state.type][value]["serviceinfo"] !== undefined
                ? this.props.allEmailData[
                      this.state.language === undefined
                          ? "en-us"
                          : this.state.language
                  ][this.state.type][value]["serviceinfo"]["networkname"]
                : undefined
        );
        previewMessage = previewMessage.replace(
            "{subscriptionstart}",
            this.props.allEmailData[
                this.state.language === undefined
                    ? "en-us"
                    : this.state.language
            ][this.state.type][value]["serviceinfo"] !== undefined
                ? this.props.allEmailData[
                      this.state.language === undefined
                          ? "en-us"
                          : this.state.language
                  ][this.state.type][value]["serviceinfo"]["subscriptionstart"]
                : undefined
        );
        previewMessage = previewMessage.replace(
            "{subscriptionend}",
            this.props.allEmailData[
                this.state.language === undefined
                    ? "en-us"
                    : this.state.language
            ][this.state.type][value]["serviceinfo"] !== undefined
                ? this.props.allEmailData[
                      this.state.language === undefined
                          ? "en-us"
                          : this.state.language
                  ][this.state.type][value]["serviceinfo"]["subscriptionend"]
                : undefined
        );
        previewMessage = previewMessage.replace(
            "{actionbutton}",
            this.props.allEmailData[
                this.state.language === undefined
                    ? "en-us"
                    : this.state.language
            ][this.state.type][value]["actionbutton"]
        );
        previewMessage = previewMessage.replace(
            "{header}",
            this.props.allEmailData[
                this.state.language === undefined
                    ? "en-us"
                    : this.state.language
            ][this.state.type][value]["header"]
        );
        previewMessage = previewMessage.replace(
            "{message}",
            this.props.allEmailData[
                this.state.language === undefined
                    ? "en-us"
                    : this.state.language
            ][this.state.type][value]["message"]
        );
        previewMessage = previewMessage.replace(
            "{additional_instructions}",
            this.props.allEmailData[
                this.state.language === undefined
                    ? "en-us"
                    : this.state.language
            ][this.state.type][value]["additional_instructions"]
        );
        previewMessage = previewMessage.replace(
            "{operatorlogourl}",
            this.state.logoURL
        );
        this.setState({
            previewMessage: previewMessage,
        });
    };

    changeHandler = (event) => {
        this.setState({ [event.target.name]: event.target.value });
        if (event.target.name === "emailMainTemplate") {
            let previewMessage = event.target.value;
            previewMessage = previewMessage.replace(
                "{preheader}",
                this.state.emailPreheader
            );
            previewMessage = previewMessage.replace(
                "{serviceinfo}",
                this.state.emailTemplate
            );
            previewMessage = previewMessage.replace(
                "{title}",
                this.state.emailTitle
            );
            previewMessage = previewMessage.replace(
                "{networkname}",
                this.state.emailNetworkName
            );
            previewMessage = previewMessage.replace(
                "{subscriptionstart}",
                this.state.emailSubscriptionStart
            );
            previewMessage = previewMessage.replace(
                "{subscriptionend}",
                this.state.emailSubscriptionEnd
            );
            previewMessage = previewMessage.replace(
                "{actionbutton}",
                this.state.emailActionButton
            );
            previewMessage = previewMessage.replace(
                "{header}",
                this.state.emailHeader
            );
            previewMessage = previewMessage.replace(
                "{message}",
                this.state.emailMessage
            );
            previewMessage = previewMessage.replace(
                "{additional_instructions}",
                this.state.emailAdditionalInfo
            );
            previewMessage = previewMessage.replace(
                "{operatorlogourl}",
                this.state.logoURL
            );
            this.setState({
                previewMessage: previewMessage,
            });
        } else {
            let previewMessage = this.state.previewMessage;
            previewMessage = previewMessage.replace(
                this.state[event.target.name],
                event.target.value
            );
            this.setState({
                previewMessage: previewMessage,
            });
        }
    };

    handleEditorChangeMainTemplate(emailMainTemplate, editor) {
        this.setState({ emailMainTemplate });
    }

    handleEditorChangePreheader(emailPreheader, editor) {
        this.setState({ emailPreheader });
    }

    handleEditorChange(emailMessage, editor) {
        this.setState({ emailMessage });
    }

    handleEditorChangeHeader(emailHeader, editor) {
        this.setState({ emailHeader });
    }

    handleEditorChangeTitle(emailTitle, editor) {
        this.setState({ emailTitle });
    }

    handleEditorChangeActionButton(emailActionButton, editor) {
        this.setState({ emailActionButton });
    }

    handleEditorChangeAdditionalInfo(emailAdditionalInfo, editor) {
        this.setState({ emailAdditionalInfo });
    }

    handleEditorChangeTemplate(emailTemplate, editor) {
        this.setState({ emailTemplate });
    }

    handleEditorChangeNetworkName(emailNetworkName, editor) {
        this.setState({ emailNetworkName });
    }

    handleEditorChangeWifiPassword(emailWifiPassword, editor) {
        this.setState({ emailWifiPassword });
    }

    handleEditorChangeSubscriptionStart(emailSubscriptionStart, editor) {
        this.setState({ emailSubscriptionStart });
    }

    handleEditorChangeSubscriptionEnd(emailSubscriptionEnd, editor) {
        this.setState({ emailSubscriptionEnd });
    }

    handleChangeComplete = (color) => {
        this.setState({ brandColor: color.hex });
    };

    handleClick = () => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker });
    };

    handleClose = () => {
        this.setState({ displayColorPicker: false });
    };

    handleChange = (color) => {
        // if(this.state.previewMessage !== undefined){
        // 	console.log(this.state.brandColor.toUpperCase())
        // 	let previewMessage = this.state.previewMessage;
        // 	previewMessage = previewMessage.replace(this.state.brandColor.toUpperCase(), color.hex)
        // 	this.setState({
        // 		previewMessage: previewMessage
        // 	})
        // }

        this.setState({ brandColor: color.hex });
        // console.log(this.state.brandColor)
    };

    toggleTab = (tab) => () => {
        if (this.state.activeItem !== tab) {
            this.setState({
                activeItem: tab,
            });
        }
    };

    render() {
        let previewMessage;
        previewMessage =
            this.state.previewMessage !== undefined
                ? parse(this.state.previewMessage)
                : "";
        const { t } = this.props;
        const styles = reactCSS({
            default: {
                color: {
                    width: "36px",
                    height: "14px",
                    borderRadius: "2px",
                    background: `${this.state.brandColor}`,
                },
                swatch: {
                    padding: "5px",
                    background: `${this.state.brandColor}`,
                    borderRadius: "1px",
                    boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
                    display: "inline-block",
                    cursor: "pointer",
                },
                popover: {
                    position: "absolute",
                    zIndex: "2",
                },
                cover: {
                    position: "fixed",
                    top: "0px",
                    right: "0px",
                    bottom: "0px",
                    left: "0px",
                },
            },
        });
        return (
            <>
                <MDBModalHeader toggle={this.props.toggleEmailInfo}>
                    <h4>Modify Email</h4>
                    <p>{t("addnewresident.required")}</p>
                </MDBModalHeader>
                <MDBModalBody>
                    <form
                        className="needs-validation"
                        onSubmit={this.submitModifyEmail}
                        noValidate
                    >
                        <MDBRow>
                            <MDBCol sm="12" lg="12">
                                <label
                                    htmlFor="defaultFormLoginEmailEx"
                                    className="black-text"
                                >
                                    Dynamic Variables
                                </label>
                                <div className="disabledArea">
                                    {this.state.dynamicVariables}
                                </div>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol sm="12" lg="12">
                                <label
                                    htmlFor="defaultFormLoginEmailEx"
                                    className="black-text"
                                >
                                    Email Logo
                                </label>
                                <input
                                    name="logoURL"
                                    id="logoURL"
                                    className="form-control"
                                    onChange={this.changeHandler}
                                    value={this.state.logoURL}
                                    disabled={this.state.disabled}
                                />
                                <div className="invalid-feedback">
                                    Please provide a valid link.
                                </div>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol>
                                <label
                                    htmlFor="defaultFormLoginEmailEx"
                                    className="black-text"
                                >
                                    Brand Color
                                </label>
                                <br />
                                <div
                                    style={styles.swatch}
                                    onClick={this.handleClick}
                                >
                                    <div
                                        style={{
                                            width: "36px",
                                            height: "14px",
                                            borderRadius: "2px",
                                            backgroundColor: `${this.state.brandColor}`,
                                        }}
                                    />
                                </div>
                                {this.state.displayColorPicker ? (
                                    <div style={styles.popover}>
                                        <div
                                            style={styles.cover}
                                            onClick={this.handleClose}
                                        />
                                        <ChromePicker
                                            color={this.state.brandColor}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                ) : null}
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol col="6" sm="12" lg="12">
                                <div>
                                    <label
                                        htmlFor="defaultFormLoginEmailEx"
                                        className="black-text"
                                    >
                                        {t("addnewresident.language")}
                                    </label>
                                    <SelectSearch
                                        id="selectLanguage"
                                        search={true}
                                        filterOptions={fuzzySearch}
                                        options={this.state.languageOptions}
                                        isOptionSelected={true}
                                        classNamePrefix="language-select"
                                        onChange={this.changeHandlerLanguage}
                                        value={this.state.language}
                                        placeholder={
                                            this.state.language !== undefined
                                                ? this.state.language ===
                                                  "en-us"
                                                    ? "English"
                                                    : "Spanish"
                                                : "Select..."
                                        }
                                    />
                                    <div className="invalid-feedback">
                                        Please provide a valid language.
                                    </div>
                                </div>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol col="6" sm="12" lg="12">
                                <div>
                                    <label
                                        htmlFor="defaultFormLoginEmailEx"
                                        className="black-text"
                                    >
                                        Email Type
                                    </label>
                                    <SelectSearch
                                        id="selectEmailType"
                                        search={true}
                                        filterOptions={fuzzySearch}
                                        options={this.state.emailTypeOptions}
                                        isOptionSelected={true}
                                        classNamePrefix="emailType-select"
                                        onChange={this.changeHandlerEmailType}
                                        value={this.state.type}
                                        placeholder={"Select an Email Type"}
                                    />

                                    <div className="invalid-feedback">
                                        Please provide a valid type.
                                    </div>
                                </div>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol col="6" sm="12" lg="12">
                                <div>
                                    <label
                                        htmlFor="defaultFormLoginEmailEx"
                                        className="black-text"
                                    >
                                        {this.state.type === "WelcomeEmail"
                                            ? "User Type"
                                            : "Action Type"}
                                    </label>
                                    <SelectSearch
                                        id="selectUserType"
                                        search={true}
                                        filterOptions={fuzzySearch}
                                        options={
                                            this.state.type === "WelcomeEmail"
                                                ? this.state.emailUserOptions
                                                : this.state
                                                      .emailUserEventsOptions
                                        }
                                        isOptionSelected={true}
                                        classNamePrefix="emailType-select"
                                        onChange={this.changeHandlerUserType}
                                        value={this.state.userType}
                                        placeholder={
                                            this.state.type === "WelcomeEmail"
                                                ? "Select a User Type"
                                                : "Select an Action Type"
                                        }
                                    />

                                    <div className="invalid-feedback">
                                        Please provide a valid type.
                                    </div>
                                </div>
                            </MDBCol>
                        </MDBRow>
                        {/* <MDBRow>
							<MDBCol col="6" sm="12" lg="12">
								<div>
									<label htmlFor="defaultFormLoginEmailEx" className="black-text">
										Email Section
									</label>
									<SelectSearch 
								    	id="selectEmailSection" 
								    	search={true}
                         				filterOptions={ fuzzySearch }
								    	options={this.state.emailSectionOptions}
								    	isOptionSelected={true}
								    	classNamePrefix="emailType-select"
								    	onChange={this.changeHandlerEmailSection}
								    	value={this.state.emailSection}
								    	placeholder={"Select an Email Section"}
								    />
									
									<div className="invalid-feedback">Please provide a valid section.</div>
								</div>
							</MDBCol>
						</MDBRow> */}
                        {/* <MDBRow>
									<MDBCol col="6" sm="12" lg="12">
										<div>
											<label htmlFor="defaultFormLoginEmailEx" className="black-text">
												Service Info
											</label>
											<SelectSearch
												id="selectEmaiServiceInfo"
												search={true}
												filterOptions={fuzzySearch}
												options={this.state.emailServiceInfoOptions}
												isOptionSelected={true}
												classNamePrefix="emailType-select"
												onChange={this.changeHandlerEmailServiceInfo}
												value={this.state.emailServiceInfo}
												placeholder={"Select a Service Info Type"}
											/>

											<div className="invalid-feedback">Please provide a valid section.</div>
										</div>
									</MDBCol>
								</MDBRow> */}
                        <br />

                        <MDBNav className="nav-tabs">
                            <MDBNavItem>
                                <MDBNavLink
                                    link
                                    to="#"
                                    active={this.state.activeItem === "1"}
                                    onClick={this.toggleTab("1")}
                                    role="tab"
                                >
                                    <MDBIcon icon="envelope" /> Preview
                                </MDBNavLink>
                            </MDBNavItem>
                            <MDBNavItem>
                                <MDBNavLink
                                    link
                                    to="#"
                                    active={this.state.activeItem === "4"}
                                    onClick={this.toggleTab("4")}
                                    role="tab"
                                >
                                    <MDBIcon icon="file-invoice" /> Branding
                                    Template
                                </MDBNavLink>
                            </MDBNavItem>
                            <MDBNavItem>
                                <MDBNavLink
                                    link
                                    to="#"
                                    active={this.state.activeItem === "2"}
                                    onClick={this.toggleTab("2")}
                                    role="tab"
                                >
                                    <MDBIcon icon="envelope" /> Components
                                </MDBNavLink>
                            </MDBNavItem>
                            {/* <MDBNavItem>
								<MDBNavLink
									link
									to="#"
									active={this.state.activeItem === "3"}
									onClick={this.toggleTab("3")}
									role="tab"
									disabled={this.state.emailTemplate !== undefined ? false : true}
								>
									<MDBIcon icon="file" /> Service Info Templates
								</MDBNavLink>
							</MDBNavItem> */}
                        </MDBNav>

                        <MDBTabContent
                            className=""
                            activeItem={this.state.activeItem}
                        >
                            <MDBTabPane tabId="1" role="tabpanel">
                                {previewMessage}
                            </MDBTabPane>
                            <MDBTabPane tabId="4" role="tabpanel">
                                <MDBRow>
                                    <MDBCol sm="12" lg="12">
                                        <label
                                            htmlFor="defaultFormLoginEmailEx"
                                            className="black-text"
                                        >
                                            Main Template
                                        </label>
                                        <textarea
                                            className="form-control"
                                            id="emailMainTemplate"
                                            name="emailMainTemplate"
                                            rows="10"
                                            onChange={this.changeHandler}
                                            value={this.state.emailMainTemplate}
                                            disabled={this.state.disabled}
                                        />
                                        {/* <Editor
											apiKey="gw4td27mfhpoc1zr4q0yxusw050fnnxz00us4j5645mz285d"
											value={this.state.emailMainTemplate}
											outputFormat="html"
											init={{
												entity_encoding: "raw",
												height: 300,
												menubar: true,
												plugins: [
													"advlist autolink lists link image charmap print preview anchor",
													"searchreplace visualblocks code fullscreen",
													"insertdatetime media table paste code help wordcount",
													"fullpage"
												],
												toolbar:
													"undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help | code | image |inserttable | cell row column | tableprops deletetable ",
											}}
											onEditorChange={this.handleEditorChangeMainTemplate}
											entity_encoding="raw"
										/> */}
                                        <div className="invalid-feedback">
                                            Please provide a valid subject.
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                            </MDBTabPane>
                            <MDBTabPane tabId="2" role="tabpanel">
                                <MDBRow>
                                    <MDBCol sm="12" lg="12">
                                        <label
                                            htmlFor="defaultFormLoginEmailEx"
                                            className="black-text"
                                        >
                                            Email Subject
                                        </label>
                                        <input
                                            name="emailSubject"
                                            id="emailSubject"
                                            className="form-control"
                                            onChange={this.changeHandler}
                                            value={this.state.emailSubject}
                                            disabled={this.state.disabled}
                                        />
                                        <div className="invalid-feedback">
                                            Please provide a valid subject.
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol sm="12" lg="12">
                                        <label
                                            htmlFor="defaultFormLoginEmailEx"
                                            className="black-text"
                                        >
                                            Preheader
                                        </label>
                                        <textarea
                                            className="form-control"
                                            id="emailPreheader"
                                            name="emailPreheader"
                                            rows="10"
                                            onChange={this.changeHandler}
                                            value={this.state.emailPreheader}
                                            disabled={this.state.disabled}
                                        />
                                        {/* <Editor
											apiKey="gw4td27mfhpoc1zr4q0yxusw050fnnxz00us4j5645mz285d"
											value={this.state.emailPreheader}
											outputFormat="html"
											init={{
												forced_root_block : false,
												entity_encoding: "raw",
												height: 300,
												menubar: true,
												plugins: [
													"advlist autolink lists link image charmap print preview anchor",
													"searchreplace visualblocks code fullscreen",
													"insertdatetime media table paste help wordcount"
												],
												toolbar:
													"undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help | code | image |inserttable | cell row column | tableprops deletetable ",
											}}
											onEditorChange={this.handleEditorChangePreheader}
											entity_encoding="raw"
										/> */}
                                        <div className="invalid-feedback">
                                            Please provide a valid subject.
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol sm="12" lg="12">
                                        <label
                                            htmlFor="defaultFormLoginEmailEx"
                                            className="black-text"
                                        >
                                            Title
                                        </label>
                                        <textarea
                                            className="form-control"
                                            id="emailTitle"
                                            name="emailTitle"
                                            rows="10"
                                            onChange={this.changeHandler}
                                            value={this.state.emailTitle}
                                            disabled={this.state.disabled}
                                        />
                                        {/* <Editor
											apiKey="gw4td27mfhpoc1zr4q0yxusw050fnnxz00us4j5645mz285d"
											value={this.state.emailTitle}
											outputFormat="html"
											init={{
												forced_root_block : false,
												entity_encoding: "raw",
												height: 300,
												menubar: true,
												plugins: [
													"advlist autolink lists link image charmap print preview anchor",
													"searchreplace visualblocks code fullscreen",
													"insertdatetime media table paste help wordcount"
												],
												toolbar:
													"undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help | code | image |inserttable | cell row column | tableprops deletetable ",
											}}
											onEditorChange={this.handleEditorChangeTitle}
											entity_encoding="raw"
										/> */}
                                        <div className="invalid-feedback">
                                            Please provide a valid subject.
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol sm="12" lg="12">
                                        <label
                                            htmlFor="defaultFormLoginEmailEx"
                                            className="black-text"
                                        >
                                            Header
                                        </label>
                                        <textarea
                                            className="form-control"
                                            id="emailHeader"
                                            name="emailHeader"
                                            rows="10"
                                            onChange={this.changeHandler}
                                            value={this.state.emailHeader}
                                            disabled={this.state.disabled}
                                        />
                                        {/* <Editor
											apiKey="gw4td27mfhpoc1zr4q0yxusw050fnnxz00us4j5645mz285d"
											value={this.state.emailHeader}
											outputFormat="html"
											init={{
												forced_root_block : false,
												entity_encoding: "raw",
												height: 300,
												menubar: true,
												plugins: [
													"advlist autolink lists link image charmap print preview anchor",
													"searchreplace visualblocks code fullscreen",
													"insertdatetime media table paste help wordcount"
												],
												toolbar:
													"undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help | code | image |inserttable | cell row column | tableprops deletetable ",
											}}
											onEditorChange={this.handleEditorChangeHeader}
											entity_encoding="raw"
										/> */}
                                        <div className="invalid-feedback">
                                            Please provide a valid subject.
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol sm="12" lg="12">
                                        <label htmlFor="exampleFormControlTextarea1">
                                            Message
                                        </label>
                                        <textarea
                                            className="form-control"
                                            id="emailMessage"
                                            name="emailMessage"
                                            rows="10"
                                            onChange={this.changeHandler}
                                            value={this.state.emailMessage}
                                            disabled={this.state.disabled}
                                        />
                                        {/* <Editor
											apiKey="gw4td27mfhpoc1zr4q0yxusw050fnnxz00us4j5645mz285d"
											value={this.state.emailMessage}
											outputFormat="html"
											init={{
												forced_root_block : false,
												entity_encoding: "raw",
												height: 300,
												menubar: true,
												plugins: [
													"advlist autolink lists link image charmap print preview anchor",
													"searchreplace visualblocks code fullscreen",
													"insertdatetime media table paste help wordcount"
												],
												toolbar:
													"undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help | code | image |inserttable | cell row column | tableprops deletetable ",
											}}
											onEditorChange={this.handleEditorChange}
											entity_encoding="raw"
										/> */}
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol sm="12" lg="12">
                                        <label
                                            htmlFor="defaultFormLoginEmailEx"
                                            className="black-text"
                                        >
                                            Action Button
                                        </label>
                                        <textarea
                                            className="form-control"
                                            id="emailActionButton"
                                            name="emailActionButton"
                                            rows="10"
                                            onChange={this.changeHandler}
                                            value={this.state.emailActionButton}
                                            disabled={this.state.disabled}
                                        />
                                        {/* <Editor
											apiKey="gw4td27mfhpoc1zr4q0yxusw050fnnxz00us4j5645mz285d"
											value={this.state.emailActionButton}
											outputFormat="html"
											init={{
												forced_root_block : false,
												entity_encoding: "raw",
												height: 300,
												menubar: true,
												plugins: [
													"advlist autolink lists link image charmap print preview anchor",
													"searchreplace visualblocks code fullscreen",
													"insertdatetime media table paste help wordcount"
												],
												toolbar:
													"undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help | code | image |inserttable | cell row column | tableprops deletetable ",
											}}
											onEditorChange={this.handleEditorChangeActionButton}
											entity_encoding="raw"
										/> */}
                                        <div className="invalid-feedback">
                                            Please provide a valid subject.
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol sm="12" lg="12">
                                        <label
                                            htmlFor="defaultFormLoginEmailEx"
                                            className="black-text"
                                        >
                                            Additional Instructions
                                        </label>
                                        <textarea
                                            className="form-control"
                                            id="emailAdditionalInfo"
                                            name="emailAdditionalInfo"
                                            rows="10"
                                            onChange={this.changeHandler}
                                            value={
                                                this.state.emailAdditionalInfo
                                            }
                                            disabled={this.state.disabled}
                                        />
                                        {/* <Editor
											apiKey="gw4td27mfhpoc1zr4q0yxusw050fnnxz00us4j5645mz285d"
											value={this.state.emailAdditionalInfo}
											outputFormat="html"
											init={{
												forced_root_block : false,
												entity_encoding: "raw",
												height: 300,
												menubar: true,
												plugins: [
													"advlist autolink lists link image charmap print preview anchor",
													"searchreplace visualblocks code fullscreen",
													"insertdatetime media table paste help wordcount"
												],
												toolbar:
													"undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help | code | image |inserttable | cell row column | tableprops deletetable ",
											}}
											onEditorChange={this.handleEditorChangeAdditionalInfo}
											entity_encoding="raw"
										/> */}
                                        <div className="invalid-feedback">
                                            Please provide a valid subject.
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol sm="12" lg="12">
                                        <label
                                            htmlFor="defaultFormLoginEmailEx"
                                            className="black-text"
                                        >
                                            Template
                                        </label>
                                        <textarea
                                            className="form-control"
                                            id="emailTemplate"
                                            name="emailTemplate"
                                            rows="10"
                                            onChange={this.changeHandler}
                                            value={this.state.emailTemplate}
                                            disabled={this.state.disabled}
                                        />
                                        {/* <Editor
											apiKey="gw4td27mfhpoc1zr4q0yxusw050fnnxz00us4j5645mz285d"
											value={this.state.emailTemplate}
											init={{
												forced_root_block : false,
												entity_encoding: "raw",
												height: 300,
												menubar: true,
												plugins: [
													"advlist autolink lists link image charmap print preview anchor",
													"searchreplace visualblocks code fullscreen",
													"insertdatetime media table paste help wordcount"
												],
												toolbar:
													"undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help | code | image |inserttable | cell row column | tableprops deletetable ",
											}}
											onEditorChange={this.handleEditorChangeTemplate}
										/> */}
                                        <div className="invalid-feedback">
                                            Please provide a valid subject.
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol sm="12" lg="12">
                                        <label
                                            htmlFor="defaultFormLoginEmailEx"
                                            className="black-text"
                                        >
                                            Network Name
                                        </label>
                                        <textarea
                                            className="form-control"
                                            id="emailNetworkName"
                                            name="emailNetworkName"
                                            rows="10"
                                            onChange={this.changeHandler}
                                            value={this.state.emailNetworkName}
                                            disabled={this.state.disabled}
                                        />
                                        {/* <Editor
											apiKey="gw4td27mfhpoc1zr4q0yxusw050fnnxz00us4j5645mz285d"
											value={this.state.emailNetworkName}
											outputFormat="html"
											init={{
												forced_root_block : false,
												entity_encoding: "raw",
												height: 300,
												menubar: true,
												plugins: [
													"advlist autolink lists link image charmap print preview anchor",
													"searchreplace visualblocks code fullscreen",
													"insertdatetime media table paste help wordcount"
												],
												toolbar:
													"undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help | code | image |inserttable | cell row column | tableprops deletetable ",
											}}
											onEditorChange={this.handleEditorChangeNetworkName}
											entity_encoding="raw"
										/> */}
                                        <div className="invalid-feedback">
                                            Please provide a valid subject.
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol sm="12" lg="12">
                                        <label
                                            htmlFor="defaultFormLoginEmailEx"
                                            className="black-text"
                                        >
                                            Wifi Password
                                        </label>
                                        <textarea
                                            className="form-control"
                                            id="emailWifiPassword"
                                            name="emailWifiPassword"
                                            rows="10"
                                            onChange={this.changeHandler}
                                            value={this.state.emailWifiPassword}
                                            disabled={this.state.disabled}
                                        />
                                        {/* <Editor
											apiKey="gw4td27mfhpoc1zr4q0yxusw050fnnxz00us4j5645mz285d"
											value={this.state.emailWifiPassword}
											outputFormat="text"
											init={{
												forced_root_block : false,
												entity_encoding: "raw",
												height: 300,
												menubar: true,
												plugins: [
													"advlist autolink lists link image charmap print preview anchor",
													"searchreplace visualblocks code fullscreen",
													"insertdatetime media table paste help wordcount"
												],
												toolbar:
													"undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help | code | image |inserttable | cell row column | tableprops deletetable ",
											}}
											onEditorChange={this.handleEditorChangeWifiPassword}
											entity_encoding="raw"
										/> */}
                                        <div className="invalid-feedback">
                                            Please provide a valid subject.
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol sm="12" lg="12">
                                        <label
                                            htmlFor="defaultFormLoginEmailEx"
                                            className="black-text"
                                        >
                                            Subscription Start
                                        </label>
                                        <textarea
                                            className="form-control"
                                            id="emailSubscriptionStart"
                                            name="emailSubscriptionStart"
                                            rows="10"
                                            onChange={this.changeHandler}
                                            value={
                                                this.state
                                                    .emailSubscriptionStart
                                            }
                                            disabled={this.state.disabled}
                                        />
                                        {/* <Editor
											apiKey="gw4td27mfhpoc1zr4q0yxusw050fnnxz00us4j5645mz285d"
											value={this.state.emailSubscriptionStart}
											outputFormat="html"
											init={{
												forced_root_block : false,
												entity_encoding: "raw",
												height: 300,
												menubar: true,
												plugins: [
													"advlist autolink lists link image charmap print preview anchor",
													"searchreplace visualblocks code fullscreen",
													"insertdatetime media table paste help wordcount"
												],
												toolbar:
													"undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help | code | image |inserttable | cell row column | tableprops deletetable ",
											}}
											onEditorChange={this.handleEditorChangeSubscriptionStart}
											entity_encoding="raw"
										/> */}
                                        <div className="invalid-feedback">
                                            Please provide a valid subject.
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol sm="12" lg="12">
                                        <label
                                            htmlFor="defaultFormLoginEmailEx"
                                            className="black-text"
                                        >
                                            Subscription End
                                        </label>
                                        <textarea
                                            className="form-control"
                                            id="emailSubscriptionEnd"
                                            name="emailSubscriptionEnd"
                                            rows="10"
                                            onChange={this.changeHandler}
                                            value={
                                                this.state.emailSubscriptionEnd
                                            }
                                            disabled={this.state.disabled}
                                        />
                                        {/* <Editor
											apiKey="gw4td27mfhpoc1zr4q0yxusw050fnnxz00us4j5645mz285d"
											value={this.state.emailSubscriptionEnd}
											outputFormat="html"
											init={{
												forced_root_block : false,
												entity_encoding: "raw",
												height: 300,
												menubar: true,
												plugins: [
													"advlist autolink lists link image charmap print preview anchor",
													"searchreplace visualblocks code fullscreen",
													"insertdatetime media table paste help wordcount"
												],
												toolbar:
													"undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help | code | image |inserttable | cell row column | tableprops deletetable ",
											}}
											onEditorChange={this.handleEditorChangeSubscriptionEnd}
											entity_encoding="raw"
										/> */}
                                        <div className="invalid-feedback">
                                            Please provide a valid subject.
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                            </MDBTabPane>
                            <MDBTabPane tabId="3" role="tabpanel"></MDBTabPane>
                        </MDBTabContent>
                        <div className="text-right submitButton">
                            <MDBBtn
                                color="danger"
                                type="submit"
                                disabled={this.state.disabled}
                            >
                                {this.props.modify_email === true ? (
                                    <div
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                    >
                                        <span className="sr-only">
                                            Loading...
                                        </span>
                                    </div>
                                ) : (
                                    "Save Changes"
                                )}
                            </MDBBtn>
                        </div>
                    </form>
                </MDBModalBody>
            </>
        );
    }
}

export default withTranslation()(AddNewVenueEquipment);
