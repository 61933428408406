import React, { Component } from "react";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import "./brands/common/css/common.scss";
import { Route, BrowserRouter, Redirect, Switch } from "react-router-dom";
import Login from "./components/Login/Login";
import Logout from "./components/Login/Logout";
import Dashboard from "./components/VenueMgmt/Dashboard/Dashboard";
import SubscriberDashboard from "./components/SubscriberMgmt/Dashboard/Dashboard";
import NetworkDashboard from "./components/NetworkMgmt/Dashboard/Dashboard";
import FindDeals from "./components/CreateAccount/FindDeals";
import SelectPlan from "./components/CreateAccount/SelectPlan";
import SelectPlanFacts from "./components/CreateAccount/SelectPlanFacts";
import CreateAccountP1 from "./components/CreateAccount/CreateAccountP1";
import CreateAccountP2 from "./components/CreateAccount/CreateAccountP2";
import CheckOut from "./components/CreateAccount/CheckOut";
import UpdateCheckOut from "./components/UpdateAccount/CheckOut";
import UpgradeCheckout from "./components/UpdatePaymentMethod/CheckOut";
import PaymentSuccess from "./components/CreateAccount/PaymentSuccess";
import Verification from "./components/CreateAccount/Verification";
// import Get5G from "./components/5g/5g";
// import Confirmation5g from "./components/5g/5gConfirmation";
import IdleTimer from "react-idle-timer";
import ReactGA from "react-ga4";
import axios from "./axios";
import { Helmet } from "react-helmet";
class App extends Component {
    constructor(props) {
        super(props);
        this.idleTimer = null;
        this.handleOnIdle = this.handleOnIdle.bind(this);
        this.handleOnAction = this.handleOnAction.bind(this);
        this.handleOnActive = this.handleOnActive.bind(this);
        this.getDetails = this.getDetails(this);
        this.state = {
            brand: undefined,
        };
    }
    getDetails = () => {
        let body;
        let theme = "ThemeLight";
        axios
            .post(
                "/networkoperatorconsole/equipment/listOperatorDetails",
                body,
                {
                    headers: {},
                }
            )
            .then((response) => {
                this.setState({
                    brand: response.data.DisplayConfiguration["Behavior"][
                        "S3Directory"
                    ],
                    customBrand:
                        response.data.DisplayConfiguration["Behavior"][
                            "S3DirectoryCustomComponents"
                        ],
                    operatorName: response.data.OperatorName,
                    logoLogin:
                        response.data.DisplayConfiguration["ThemeLight"][
                            "LogoFileNameDesktopLogin"
                        ],
                    logoLoginMobile:
                        response.data.DisplayConfiguration["ThemeLight"][
                            "LogoFileNameMobileLogin"
                        ],
                    // FlagSignUp = response.data.DisplayConfiguration["Behavior"]["ShowFooterOnLoginPage"];
                    flagFooter:
                        response.data.DisplayConfiguration["Behavior"][
                            "ShowFooterOnLoginPage"
                        ],
                    pageTitle:
                        response.data.DisplayConfiguration["MetaData"][
                            "TitleTextContent"
                        ],
                    pageDescription:
                        response.data.DisplayConfiguration["MetaData"][
                            "DescriptionContent"
                        ],
                    favicon:
                        response.data.DisplayConfiguration["MetaData"][
                            "FavIconHRef"
                        ],
                    flagLoginSignup:
                        response.data.DisplayConfiguration["ShoppingCart"][
                            "AllowSelfServicePlanUpdate"
                        ],
                    // flagLoginSignup: true,
                });
                sessionStorage.setItem(
                    "background-color",
                    response.data.DisplayConfiguration[theme][
                        "BackgroundColorHex"
                    ]
                );
                sessionStorage.setItem(
                    "modal-background-color",
                    response.data.DisplayConfiguration[theme]["ModalColorHex"]
                );
                sessionStorage.setItem(
                    "primary-text-color",
                    response.data.DisplayConfiguration[theme][
                        "PrimaryTextColorHex"
                    ]
                );
                sessionStorage.setItem(
                    "base-color",
                    response.data.DisplayConfiguration[theme]["PrimaryColorHex"]
                );
                sessionStorage.setItem(
                    "secondary-color",
                    response.data.DisplayConfiguration[theme][
                        "SecondaryColorHex"
                    ]
                );
                sessionStorage.setItem(
                    "secondary-text-color",
                    response.data.DisplayConfiguration[theme][
                        "SecondaryTextColorHex"
                    ]
                );
                sessionStorage.setItem(
                    "border-color",
                    response.data.DisplayConfiguration[theme]["BorderColorHex"]
                );
                sessionStorage.setItem(
                    "base-color-rgb",
                    response.data.DisplayConfiguration[theme]["PrimaryColorHex"]
                );
                sessionStorage.setItem(
                    "link-color",
                    response.data.DisplayConfiguration[theme]["LinkColorHex"]
                );
                sessionStorage.setItem(
                    "button-color",
                    response.data.DisplayConfiguration[theme]["PrimaryColorHex"]
                );
                sessionStorage.setItem(
                    "button-text-color",
                    response.data.DisplayConfiguration[theme][
                        "SecondaryTextColorHex"
                    ]
                );
                sessionStorage.setItem(
                    "input-color",
                    response.data.DisplayConfiguration[theme][
                        "InputBoxColorHex"
                    ]
                );
                sessionStorage.setItem(
                    "input-focus-color",
                    response.data.DisplayConfiguration[theme][
                        "InputBoxColorHex"
                    ]
                );
                sessionStorage.setItem(
                    "banner-color-1",
                    response.data.DisplayConfiguration["ThemeLight"][
                        "BannerColor1Hex"
                    ]
                );
                sessionStorage.setItem(
                    "banner-color-2",
                    response.data.DisplayConfiguration["ThemeLight"][
                        "BannerColor2Hex"
                    ]
                );
                sessionStorage.setItem(
                    "clientPhoneNumber",
                    response.data.PhoneNumber
                );
                sessionStorage.setItem(
                    "clientName",
                    response.data.OperatorName
                );
                sessionStorage.setItem(
                    "supportEmail",
                    response.data.DisplayConfiguration.Support[
                        "SupportEmailAddress"
                    ]
                );
                sessionStorage.setItem(
                    "supportPhone",
                    response.data.DisplayConfiguration.Support[
                        "SupportPhoneNumber.E164"
                    ]
                );
                sessionStorage.setItem(
                    "brandDirectory",
                    response.data.DisplayConfiguration["Behavior"][
                        "S3Directory"
                    ]
                );
                sessionStorage.setItem(
                    "customBrand",
                    response.data.DisplayConfiguration["Behavior"][
                        "S3DirectoryCustomComponents"
                    ]
                );
                localStorage.setItem(
                    "customBrand",
                    response.data.DisplayConfiguration["Behavior"][
                        "S3DirectoryCustomComponents"
                    ]
                );
                sessionStorage.setItem(
                    "PrivacyPolicyURL",
                    response.data.DisplayConfiguration["Behavior"][
                        "PrivacyPolicyURL"
                    ]
                );
                sessionStorage.setItem(
                    "shoppingCart",
                    response.data.ShoppingCart.enableShoppingCart
                );
                // const root = document.documentElement;
                // root?.style.setProperty(
                //   "--login-background-color",
                //   response.data.DisplayConfiguration["ThemeLight"][
                //     "LoginBackgroundColorHex"
                //   ]
                // );
                // root?.style.setProperty(
                //   "--primary-text-color",
                //   response.data.DisplayConfiguration.PrimaryTextColorHex
                // );
                // root?.style.setProperty(
                //   "--primary-text-color",
                //   response.data.DisplayConfiguration["ThemeLight"][
                //     "PrimaryTextColorHex"
                //   ]
                // );
                // root?.style.setProperty(
                //   "--primary-color",
                //   response.data.DisplayConfiguration["ThemeLight"]["PrimaryColorHex"]
                // );
                // root?.style.setProperty(
                //   "--secondary-color",
                //   response.data.DisplayConfiguration["ThemeLight"]["SecondaryColorHex"]
                // );
                // root?.style.setProperty(
                //   "--secondary-text-color",
                //   response.data.DisplayConfiguration["ThemeLight"][
                //     "SecondaryTextColorHex"
                //   ]
                // );
                // root?.style.setProperty(
                //   "--border-color",
                //   response.data.DisplayConfiguration["ThemeLight"]["BorderColorHex"]
                // );
                // root?.style.setProperty(
                //   "--primary-color-rgb",
                //   response.data.DisplayConfiguration["ThemeLight"]["PrimaryColorHex"] +
                //     "4D"
                // );
                // root?.style.setProperty(
                //   "--link-color",
                //   response.data.DisplayConfiguration["ThemeLight"]["LinkColorHex"]
                // );
                // root?.style.setProperty(
                //   "--button-color",
                //   response.data.DisplayConfiguration["ThemeLight"]["PrimaryColorHex"]
                // );
                // root?.style.setProperty("--button-text-color", "#FFF");
                // root?.style.setProperty(
                //   "--input-color",
                //   response.data.DisplayConfiguration["ThemeLight"]["InputBoxColorHex"]
                // );
                // root?.style.setProperty(
                //   "--input-focus-color",
                //   response.data.DisplayConfiguration["ThemeLight"]["BannerColor2Hex"]
                // );
                // root?.style.setProperty(
                //   "--login-text-color",
                //   response.data.DisplayConfiguration["ThemeLight"]["LoginTextColorHex"]
                // );
                // root?.style.setProperty(
                //   "--background-color",
                //   response.data.DisplayConfiguration["ThemeLight"]["BackgroundColorHex"]
                // );
                // root?.style.setProperty(
                //   "--modal-background-color",
                //   response.data.DisplayConfiguration["ThemeLight"]["ModalColorHex"]
                // );
                // root?.style.setProperty(
                //   "--banner-color-1",
                //   response.data.DisplayConfiguration["ThemeLight"]["BannerColor1Hex"]
                // );
                // root?.style.setProperty(
                //   "--banner-color-2",
                //   response.data.DisplayConfiguration["ThemeLight"]["BannerColor2Hex"]
                // );
                var favicon = document.createElement("link");
                var title = document.createElement("title");
                var description = document.createElement("meta");
                let googleanalytics_id;
                favicon.id = "myFavicon";
                favicon.rel = "icon";
                favicon.type = "image/x-icon";
                favicon.href =
                    window.location.protocol +
                    "//" +
                    window.location.host +
                    "/" +
                    response.data.DisplayConfiguration["Behavior"][
                        "S3Directory"
                    ] +
                    "/favicon.png";
                document.title = "Your desired title";
                document.title =
                    response.data.DisplayConfiguration["MetaData"][
                        "TitleTextContent"
                    ];
                description.name = "description";
                description.content =
                    response.data.DisplayConfiguration["MetaData"][
                        "DescriptionContent"
                    ];
                googleanalytics_id =
                    response.data.DisplayConfiguration["Behavior"][
                        "GoogleAnalyticsID"
                    ];
                document.head.removeChild(favicon);
                document.head.removeChild(title);
                document.head.removeChild(description);
                document.head.appendChild(favicon);
                document.head.appendChild(title);
                document.head.appendChild(description);
                sessionStorage.setItem(
                    "logo",
                    response.data.DisplayConfiguration["ThemeLight"][
                        "LogoFileNameDesktopLogin"
                    ]
                );
                if (googleanalytics_id !== undefined) {
                    ReactGA.initialize(googleanalytics_id);
                }
                // sessionStorage.setItem('brand', response.data.DisplayConfiguration["Behavior"]["S3Directory"])
                // this.toggleResetPassword();
                // toast.success("Password reset initiated, please check your email for instructions!", {
                //   closeButton: false,
                // });
            })
            .catch((error) => {
                // toast.success("An error occurred", {
                //   closeButton: false,
                // });
            });
    };
    handleOnAction(event) {
        // console.log("🚀 ~ file: App.js:53 ~ App ~ handleOnAction ~ event", event)
    }
    handleOnActive(event) {
        // console.log("🚀 ~ file: App.js:57 ~ App ~ handleOnActive ~ event", event)
    }
    handleOnIdle(event) {
        // console.log("🚀 ~ file: App.js:61 ~ App ~ handleOnIdle ~ event", event)
        if (
            sessionStorage.getItem("fromAuthToken") &&
            sessionStorage.getItem("AccessToken") !== null
        ) {
            sessionStorage.removeItem("AccessToken");
            sessionStorage.removeItem("isAdmin");
            sessionStorage.removeItem("adminOf");
            sessionStorage.removeItem("venueName");
            sessionStorage.removeItem("venueID");
            sessionStorage.removeItem("firstName");
            sessionStorage.removeItem("email");
            sessionStorage.removeItem("rememberMe");
            sessionStorage.removeItem("phoneNumber");
            sessionStorage.removeItem("lastName");
            sessionStorage.removeItem("domain");
            sessionStorage.removeItem("i18next");
            sessionStorage.removeItem("RefreshToken");
            sessionStorage.removeItem("venueAdmin");
            sessionStorage.removeItem("networkAdmin");
            sessionStorage.removeItem("leasingAgent");
            sessionStorage.removeItem("loginPage");
            window.location.href = "/logout";
        } else {
            if (
                !sessionStorage.getItem("rememberMe") &&
                sessionStorage.getItem("loginPage") !== null
            ) {
                sessionStorage.removeItem("AccessToken");
                sessionStorage.removeItem("isAdmin");
                sessionStorage.removeItem("adminOf");
                sessionStorage.removeItem("venueName");
                sessionStorage.removeItem("venueID");
                sessionStorage.removeItem("firstName");
                sessionStorage.removeItem("email");
                sessionStorage.removeItem("rememberMe");
                sessionStorage.removeItem("phoneNumber");
                sessionStorage.removeItem("lastName");
                sessionStorage.removeItem("domain");
                sessionStorage.removeItem("i18next");
                sessionStorage.removeItem("RefreshToken");
                sessionStorage.removeItem("venueAdmin");
                sessionStorage.removeItem("networkAdmin");
                sessionStorage.removeItem("leasingAgent");
                window.location.href = sessionStorage.getItem("loginPage");
                sessionStorage.removeItem("loginPage");
            }
        }
    }
    myFunction() {}
    render() {
        const isLogged = !!sessionStorage.getItem("AccessToken");
        let loginURL;
        sessionStorage.getItem("loginPage") === "/signin" ||
        window.location.pathname === "/signin"
            ? (loginURL = "/signin")
            : (loginURL = "/");
        return (
            <>
                <Helmet>
                    <meta
                        name="description"
                        content={this.state.pageDescription}
                    />
                    <title>{this.state.pageTitle}</title>
                    <link rel="icon" href={this.state.favicon} />
                </Helmet>
                <BrowserRouter>
                    {this.myFunction()}
                    <IdleTimer
                        ref={(ref) => {
                            this.idleTimer = ref;
                        }}
                        timeout={1000 * 60 * 10}
                        onActive={this.handleOnActive}
                        onIdle={this.handleOnIdle}
                        onAction={this.handleOnAction}
                        debounce={250}
                    />
                    <Switch>
                        {this.state.brand !== undefined &&
                        this.state.operatorName !== undefined &&
                        this.state.customBrand !== undefined ? (
                            window.location.hostname ===
                                "wifi.hyperleapnetwork.com" ||
                            window.location.hostname ===
                                "devdd01.wifikey.link" ? (
                                <Route
                                    exact
                                    path={loginURL}
                                    render={(props) => (
                                        <FindDeals
                                            brand={this.state.brand}
                                            customBrand={this.state.customBrand}
                                            operatorName={
                                                this.state.operatorName
                                            }
                                            logoLogin={this.state.logoLogin}
                                            logoMobile={
                                                this.state.logoLoginMobile
                                            }
                                            flagFooter={this.state.flagFooter}
                                            flagLoginSignup={
                                                this.state.flagLoginSignup
                                            }
                                        />
                                    )}
                                />
                            ) : (
                                <Route
                                    exact
                                    path={loginURL}
                                    render={(props) => (
                                        <Login
                                            brand={this.state.brand}
                                            customBrand={this.state.customBrand}
                                            operatorName={
                                                this.state.operatorName
                                            }
                                            logoLogin={this.state.logoLogin}
                                            logoMobile={
                                                this.state.logoLoginMobile
                                            }
                                            flagFooter={this.state.flagFooter}
                                            flagLoginSignup={
                                                this.state.flagLoginSignup
                                            }
                                        />
                                    )}
                                />
                            )
                        ) : (
                            ""
                        )}
                        {/* {this.state.brand !== undefined &&
            this.state.operatorName !== undefined ? (
              <Route
                exact
                path={loginURL}
                render={(props) => (
                  <Login
                    brand={this.state.brand}
                    customBrand={this.state.customBrand}
                    operatorName={this.state.operatorName}
                    logoLogin={this.state.logoLogin}
                    logoMobile={this.state.logoLoginMobile}
                    flagFooter={this.state.flagFooter}
                    flagLoginSignup={this.state.flagLoginSignup}
                  />
                )}
              />
            ) : (
              ""
            )} */}
                        {!isLogged &&
                        window.location.pathname === "/dashboard" ? (
                            <Redirect to={loginURL} />
                        ) : (
                            <Route
                                exact
                                path="/dashboard"
                                component={Dashboard}
                            />
                        )}
                        {!isLogged &&
                        window.location.pathname === "/myaccount" ? (
                            <Redirect to={loginURL} />
                        ) : (
                            <Route
                                exact
                                path="/myaccount"
                                component={SubscriberDashboard}
                            />
                        )}
                        {!isLogged &&
                        window.location.pathname === "/networkdashboard" ? (
                            <Redirect to={loginURL} />
                        ) : (
                            <Route
                                exact
                                path="/networkdashboard"
                                component={NetworkDashboard}
                            />
                        )}
                        {this.state.brand !== undefined ? (
                            <Route
                                exact
                                path="/login"
                                render={(props) => (
                                    <Login
                                        brand={this.state.brand}
                                        customBrand={this.state.customBrand}
                                        operatorName={this.state.operatorName}
                                        logoLogin={this.state.logoLogin}
                                        logoMobile={this.state.logoLoginMobile}
                                        flagFooter={this.state.flagFooter}
                                        flagLoginSignup={
                                            this.state.flagLoginSignup
                                        }
                                    />
                                )}
                            />
                        ) : (
                            ""
                        )}
                        {this.state.brand !== undefined ? (
                            <Route
                                exact
                                path="/terms"
                                render={(props) => (
                                    <Login
                                        brand={this.state.brand}
                                        customBrand={this.state.customBrand}
                                        operatorName={this.state.operatorName}
                                        logoLogin={this.state.logoLogin}
                                        logoMobile={this.state.logoLoginMobile}
                                        flagFooter={this.state.flagFooter}
                                        flagLoginSignup={
                                            this.state.flagLoginSignup
                                        }
                                    />
                                )}
                            />
                        ) : (
                            ""
                        )}
                        <Route exact path="/reset" component={Login} />
                        {this.state.brand !== undefined ? (
                            <Route
                                exact
                                path="/reset_confirm"
                                render={(props) => (
                                    <Login
                                        brand={this.state.brand}
                                        customBrand={this.state.customBrand}
                                        operatorName={this.state.operatorName}
                                        logoLogin={this.state.logoLogin}
                                        logoMobile={this.state.logoLoginMobile}
                                        flagFooter={this.state.flagFooter}
                                        flagLoginSignup={
                                            this.state.flagLoginSignup
                                        }
                                    />
                                )}
                            />
                        ) : (
                            ""
                        )}
                        {this.state.brand !== undefined ? (
                            <Route
                                exact
                                path="/help"
                                render={(props) => (
                                    <Login
                                        brand={this.state.brand}
                                        customBrand={this.state.customBrand}
                                        operatorName={this.state.operatorName}
                                        logoLogin={this.state.logoLogin}
                                        logoMobile={this.state.logoLoginMobile}
                                        flagFooter={this.state.flagFooter}
                                        flagLoginSignup={
                                            this.state.flagLoginSignup
                                        }
                                    />
                                )}
                            />
                        ) : (
                            ""
                        )}
                        <Route exact path="/logout" component={Logout} />
                        {this.state.brand !== undefined ? (
                            <Route
                                exact
                                path="/finddeals"
                                render={(props) => (
                                    <FindDeals
                                        brand={this.state.brand}
                                        customBrand={this.state.customBrand}
                                        operatorName={this.state.operatorName}
                                        logoLogin={this.state.logoLogin}
                                        logoMobile={this.state.logoLoginMobile}
                                        flagFooter={this.state.flagFooter}
                                    />
                                )}
                            />
                        ) : (
                            ""
                        )}
                        {this.state.brand !== undefined ? (
                            <Route
                                exact
                                path="/selectplan"
                                render={(props) => (
                                    <SelectPlan
                                        brand={this.state.brand}
                                        customBrand={this.state.customBrand}
                                        operatorName={this.state.operatorName}
                                        logoLogin={this.state.logoLogin}
                                        logoLoginMobile={
                                            this.state.logoLoginMobile
                                        }
                                        flagFooter={this.state.flagFooter}
                                    />
                                )}
                            />
                        ) : (
                            ""
                        )}
                        {this.state.brand !== undefined ? (
                            <Route
                                exact
                                path="/selectplanfacts"
                                render={(props) => (
                                    <SelectPlanFacts
                                        brand={this.state.brand}
                                        customBrand={this.state.customBrand}
                                        operatorName={this.state.operatorName}
                                        logoLogin={this.state.logoLogin}
                                        logoLoginMobile={
                                            this.state.logoLoginMobile
                                        }
                                        flagFooter={this.state.flagFooter}
                                    />
                                )}
                            />
                        ) : (
                            ""
                        )}
                        {this.state.brand !== undefined ? (
                            <Route
                                exact
                                path="/createaccount1"
                                render={(props) => (
                                    <CreateAccountP1
                                        brand={this.state.brand}
                                        logoLogin={this.state.logoLogin}
                                        logoLoginMobile={
                                            this.state.logoLoginMobile
                                        }
                                        flagFooter={this.state.flagFooter}
                                        operatorName={this.state.operatorName}
                                    />
                                )}
                            />
                        ) : (
                            ""
                        )}
                        {this.state.brand !== undefined ? (
                            <Route
                                exact
                                path="/verification"
                                render={(props) => (
                                    <Verification
                                        brand={this.state.brand}
                                        customBrand={this.state.customBrand}
                                        operatorName={this.state.operatorName}
                                        logoLogin={this.state.logoLogin}
                                        logoLoginMobile={
                                            this.state.logoLoginMobile
                                        }
                                        flagFooter={this.state.flagFooter}
                                    />
                                )}
                            />
                        ) : (
                            ""
                        )}
                        {this.state.brand !== undefined ? (
                            <Route
                                exact
                                path="/createaccount2"
                                render={(props) => (
                                    <CreateAccountP2
                                        brand={this.state.brand}
                                        customBrand={this.state.customBrand}
                                        operatorName={this.state.operatorName}
                                        logoLogin={this.state.logoLogin}
                                        logoLoginMobile={
                                            this.state.logoLoginMobile
                                        }
                                        flagFooter={this.state.flagFooter}
                                    />
                                )}
                            />
                        ) : (
                            ""
                        )}
                        {this.state.brand !== undefined ? (
                            <Route
                                exact
                                path="/checkout"
                                render={(props) => (
                                    <CheckOut
                                        brand={this.state.brand}
                                        customBrand={this.state.customBrand}
                                        operatorName={this.state.operatorName}
                                        logoLogin={this.state.logoLogin}
                                        logoLoginMobile={
                                            this.state.logoLoginMobile
                                        }
                                        flagFooter={this.state.flagFooter}
                                    />
                                )}
                            />
                        ) : (
                            ""
                        )}
                        {this.state.brand !== undefined ? (
                            <Route
                                exact
                                path="/paymentsuccess"
                                render={(props) => (
                                    <PaymentSuccess
                                        brand={this.state.brand}
                                        customBrand={this.state.customBrand}
                                        operatorName={this.state.operatorName}
                                        logoLogin={this.state.logoLogin}
                                        logoLoginMobile={
                                            this.state.logoLoginMobile
                                        }
                                        flagFooter={this.state.flagFooter}
                                    />
                                )}
                            />
                        ) : (
                            ""
                        )}
                        <Route
                            exact
                            path="/myaccount/upgrade"
                            component={SubscriberDashboard}
                        />
                        <Route
                            exact
                            path="/myaccount/upgrade/review"
                            component={SubscriberDashboard}
                        />
                        {this.state.brand !== undefined ? (
                            <Route
                                exact
                                path="/myaccount/upgrade/checkout"
                                component={SubscriberDashboard}
                                render={(props) => (
                                    <UpgradeCheckout
                                        brand={this.state.brand}
                                        customBrand={this.state.customBrand}
                                        operatorName={this.state.operatorName}
                                        logoLogin={this.state.logoLogin}
                                        logoLoginMobile={
                                            this.state.logoLoginMobile
                                        }
                                        flagFooter={this.state.flagFooter}
                                    />
                                )}
                            />
                        ) : (
                            ""
                        )}
                        {this.state.brand !== undefined ? (
                            <Route
                                exact
                                path="/update/checkout"
                                render={(props) => (
                                    <UpdateCheckOut
                                        brand={this.state.brand}
                                        customBrand={this.state.customBrand}
                                        operatorName={this.state.operatorName}
                                        logoLogin={this.state.logoLogin}
                                        logoLoginMobile={
                                            this.state.logoLoginMobile
                                        }
                                        flagFooter={this.state.flagFooter}
                                    />
                                )}
                            />
                        ) : (
                            ""
                        )}
                        <Route
                            exact
                            path="/myaccount/upgrade/paymentsuccess"
                            component={SubscriberDashboard}
                        />
                        <Route
                            exact
                            path="/myaccount/cancel"
                            component={SubscriberDashboard}
                        />
                        {/* <Route 
             exact 
             path="/5g"
             render={(props) => (
              <Get5G
                brand={this.state.brand}
                customBrand={this.state.customBrand}
                operatorName={this.state.operatorName}
                logoLogin={this.state.logoLogin}
                logoMobile={this.state.logoLoginMobile}
                flagFooter={this.state.flagFooter}
              />
             )}
            />
            <Route 
             exact 
             path="/5gconfirmation" 
             render={(props) => (
              <Confirmation5g
               brand={this.state.brand}
               customBrand={this.state.customBrand}
               operatorName={this.state.operatorName}
               logoLogin={this.state.logoLogin}
               logoMobile={this.state.logoLoginMobile}
               flagFooter={this.state.flagFooter}
              />
             )}
            /> */}
                    </Switch>
                </BrowserRouter>
            </>
        );
    }
}
export default App;
