import React, { Component } from "react";

import {
    MDBBtn,
    MDBModal,
    MDBModalBody,
    MDBModalHeader,
    MDBModalFooter,
    MDBSelect,
    MDBSelectInput,
    MDBSelectOptions,
    MDBSelectOption,
    MDBRow,
    MDBCol,
    toast,
} from "mdbreact";

import axios from "../../../../axios";

import { DateTimeInput } from "semantic-ui-calendar-react";

import "react-phone-number-input/style.css";

import PhoneInput, {
    isPossiblePhoneNumber,
    isValidPhoneNumber,
} from "react-phone-number-input";

import $ from "jquery";

import { withTranslation } from "react-i18next";

import SelectSearch, { fuzzySearch } from "react-select-search";

class AddNewResident extends Component {
    constructor(props) {
        super(props);
        this.handleDayChange = this.handleDayChange.bind(this);
        this.handleDayChangeEnd = this.handleDayChangeEnd.bind(this);
        this.state = {
            Email: "",
            firstName: this.props.firstName,
            lastName: this.props.lastName,
            email: this.props.email,
            phoneNumber: this.props.phoneNumber,
            selectedLanguage: this.props.language,
            floor: this.props.floor,
            building: this.props.building,
            receivetext: this.props.receivetext,
            receivephone: this.props.receivephone,
            dateStart:
                this.props.startDate !== ""
                    ? new Intl.DateTimeFormat("en-US", {
                          year: "numeric",
                          month: "2-digit",
                          day: "2-digit",
                          hour: "numeric",
                          minute: "numeric",
                      }).format(new Date(this.props.startDate))
                    : "",
            oldDateStart: this.props.startDate,
            dateEnd:
                this.props.endDate !== ""
                    ? new Intl.DateTimeFormat("en-US", {
                          year: "numeric",
                          month: "2-digit",
                          day: "2-digit",
                          hour: "numeric",
                          minute: "numeric",
                      }).format(new Date(this.props.endDate))
                    : "",
            oldDateEnd: this.props.endDate,
            unitID: this.props.unitID,
            guestData: this.props.recentresidents,
            venueID: this.props.venueID,
            loading: false,
            invalidDateEnd: null,
            loadingResend: false,
            languageOptions: [
                { value: "en-us", name: "English" },
                { value: "es-xl", name: "Spanish" },
            ],
            unitID_internal: this.props.unitID_internal,
        };
    }

    componentDidMount() {
        this.getVenueDetails(this.props.venueID);
    }

    validateEmail = (email) => {
        this.setState({ loading: true });
        let body = {
            AccessToken: sessionStorage.getItem("AccessToken"),
            EmailAddress: email,
            VenueID: this.props.venueID,
        };
        axios
            .post("/venuemgmtconsole/subscribers/validateEmailAddress", body, {
                headers: {},
            })
            .then((response) => {
                this.setState({
                    firstName: response.data.GivenName,
                    lastName: response.data.FamilyName,
                    phoneNumber: response.data["PhoneNumber.E164"],
                    selectedLanguage: response.data.locale,
                    invalidPhone: undefined,
                });
                this.setState({ loading: false });
            })
            .catch((error) => {
                this.setState({ error: true });
                this.setState({ loading: false });
            });
    };

    getVenueDetails = (index) => {
        let body = {
            AccessToken: sessionStorage.getItem("AccessToken"),
            VenueID: index,
            UserTimeZone: new Date().getTimezoneOffset(),
        };
        this.setState({
            unitData: undefined,
            expiringData: undefined,
            searchData: undefined,
            value: "",
            duration: undefined,
        });
        axios
            .post("/venuemgmtconsole/venues/listVenueDetails", body, {
                headers: {},
            })
            .then((response) => {
                this.setState({
                    unitData:
                        response.data[index][
                            "Units-Building-Floor-List-GuestNetworks"
                        ],
                    timezone: Math.abs(response.data[index]["TimeZone"]), //+4
                    duration:
                        response.data[index][
                            "DefaultSubscriptionDurationHours-Guests"
                        ],
                });
                var unitArray = [];
                let optionTemplateUnits = [];
                Object.keys(
                    response.data[index][
                        "Units-Building-Floor-List-GuestNetworks"
                    ]
                ).map((oneKey, i) => {
                    optionTemplateUnits.push(
                        this.state.selectedUnit === oneKey ? (
                            <MDBSelectOption selected value={oneKey}>
                                {oneKey}
                            </MDBSelectOption>
                        ) : (
                            <MDBSelectOption value={oneKey}>
                                {oneKey}
                            </MDBSelectOption>
                        )
                    );
                    return true;
                });
                unitArray.push(
                    <MDBSelect
                        id="selectUnit"
                        getValue={this.resetBuildingForm}
                        className="form-control"
                    >
                        <MDBSelectInput
                            className="selectInput selectUnit"
                            selected="Choose your unit"
                        />
                        <MDBSelectOptions search>
                            {optionTemplateUnits}
                        </MDBSelectOptions>
                    </MDBSelect>
                );
                this.setState({
                    unitForm: unitArray,
                });
            })
            .catch((error) => {
                this.getVenueDetails(index);
                this.setState({ error: true });
            });
    };

    resetBuildingForm = (value) => {
        this.setState({
            buildingForm: (
                <MDBSelect
                    id="selectBuilding"
                    getValue={this.handleSelectChangeBuilding}
                    className="form-control"
                >
                    <MDBSelectInput
                        className="selectInput selectBuilding"
                        selected="Choose your building"
                    />
                </MDBSelect>
            ),
        });
        this.setState({
            floorForm: (
                <MDBSelect
                    id="selectFloor"
                    getValue={this.handleSelectChangeFloor}
                    className="form-control"
                >
                    <MDBSelectInput
                        className="selectInput selectFloor"
                        selected="Choose your floor"
                    />
                </MDBSelect>
            ),
        });
        setTimeout(() => {
            this.handleSelectChangeUnit(value);
        }, 1);
    };

    handleSelectChangeUnit = (value) => {
        this.setState({ selectedUnit: value[0] });

        let buildingArray = [];
        let optionTemplateBuilding = [];
        Object.keys(this.state.unitData[value[0]]).map((oneKey, i) => {
            optionTemplateBuilding.push(
                this.state.selectedBuilding === oneKey ? (
                    <MDBSelectOption selected value={oneKey}>
                        {oneKey}
                    </MDBSelectOption>
                ) : (
                    <MDBSelectOption value={oneKey}>{oneKey}</MDBSelectOption>
                )
            );
            return true;
        });

        buildingArray.push(
            <MDBSelect
                id="selectBuilding"
                getValue={this.handleSelectChangeBuilding}
                className="form-control"
                selected="Choose option"
            >
                <MDBSelectInput
                    className="selectInput selectBuilding"
                    selected="Choose your building"
                />
                <MDBSelectOptions search>
                    {optionTemplateBuilding}
                </MDBSelectOptions>
            </MDBSelect>
        );
        this.setState({
            buildingForm: buildingArray,
        });
    };

    resetFloorForm = (value) => {
        setTimeout(() => {
            this.handleSelectChangeBuilding(value);
        }, 1);
    };

    handleSelectChangeBuilding = (value) => {
        this.setState({ selectedBuilding: value[0] });

        let floorArray = [];
        let optionTemplateFloor = [];
        Object.keys(this.state.unitData[this.state.selectedUnit][value[0]]).map(
            (oneKey, i) => {
                optionTemplateFloor.push(
                    this.state.selectedFloor === oneKey ? (
                        <MDBSelectOption selected value={oneKey}>
                            {oneKey}
                        </MDBSelectOption>
                    ) : (
                        <MDBSelectOption value={oneKey}>
                            {oneKey}
                        </MDBSelectOption>
                    )
                );
                return true;
            }
        );

        floorArray.push(
            <MDBSelect
                id="selectFloor"
                getValue={this.handleSelectChangeFloor}
                className="form-control"
                selected="Choose option"
            >
                <MDBSelectInput
                    className="selectInput selectFloor"
                    selected="Choose your floor"
                />
                <MDBSelectOptions search>
                    {optionTemplateFloor}
                </MDBSelectOptions>
            </MDBSelect>
        );
        this.setState({
            floorForm: floorArray,
        });
    };

    handleSelectChangeFloor = (value) => {
        this.setState({ selectedFloor: value[0] });
    };

    handleSelectChangeLanguage = (value) => {
        this.setState({ selectedLanguage: value });
    };

    submitHandler = (event) => {
        let isUpdated;
        let isUpdatedStartDate;
        let isUpdatedEndDate;
        event.preventDefault();
        event.target.className += " was-validated";
        $("#selectUnit").removeClass("is-invalid");
        $("#selectBuilding").removeClass("is-invalid");
        $("#selectFloor").removeClass("is-invalid");
        $("#selectLanguage").removeClass("is-invalid");
        if (this.state.selectedLanguage === undefined) {
            this.setState({
                invalidLanguage: "Please provide a valid language",
            });
            $("#selectLanguage").addClass("is-invalid");
        } else {
            $("#selectLanguage").addClass("is-valid");
        }
        if (this.state.selectedUnit === undefined) {
            this.setState({ invalidUnit: "Please provide a valid unit" });
            $("#selectUnit").addClass("is-invalid");
        } else {
            $("#selectUnit").addClass("is-valid");
        }
        if (this.state.selectedBuilding === undefined) {
            this.setState({ invalidBuilding: "Please provide a valid unit" });
            $("#selectBuilding").addClass("is-invalid");
        } else {
            $("#selectBuilding").addClass("is-valid");
        }
        if (this.state.selectedFloor === undefined) {
            this.setState({ invalidFloor: "Please provide a valid floor" });
            $("#selectFloor").addClass("is-invalid");
        } else {
            $("#selectFloor").addClass("is-valid");
        }
        if (
            this.state.invalidPhone === null ||
            this.state.invalidPhone !== undefined
        ) {
            this.setState({
                invalidPhone: "Please provide a valid phone number",
            });
            $(".PhoneInput").addClass("is-invalid");
        } else {
            $(".PhoneInput").addClass("is-valid");
        }

        if (
            this.state.firstName !== this.props.firstName ||
            this.state.lastName !== this.props.lastName ||
            this.state.phoneNumber !== this.props.phoneNumber ||
            this.state.selectedLanguage !== this.props.language
        ) {
            isUpdated = true;
        }

        if (
            new Intl.DateTimeFormat("en-US", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "numeric",
                minute: "numeric",
            }).format(new Date(this.state.oldDateStart)) !==
            this.state.dateStart
        ) {
            isUpdatedStartDate = true;
        }
        if (
            this.state.oldDateEnd !== undefined &&
            new Intl.DateTimeFormat("en-US", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "numeric",
                minute: "numeric",
            }).format(new Date(this.state.oldDateEnd)) !== this.state.dateEnd
        ) {
            isUpdatedEndDate = true;
        }

        if (
            this.state.invalidPhone === undefined &&
            this.state.firstName !== "" &&
            this.state.lastName !== "" &&
            this.state.selectedLanguage !== undefined &&
            this.state.invalidDate === undefined &&
            this.state.invalidDateEnd === null
        ) {
            this.props.submitModify(
                this.state.email,
                this.state.firstName,
                this.state.lastName,
                this.state.phoneNumber,
                this.state.selectedLanguage,
                this.state.receivetext,
                this.state.receivephone,
                this.state.recentResidents,
                this.state.dateStart,
                this.state.oldDateStart,
                this.state.dateEnd,
                this.state.oldDateEnd,
                this.state.venueID,
                this.state.unitID,
                this.state.building,
                this.state.floor,
                undefined,
                isUpdated,
                false,
                isUpdatedStartDate,
                isUpdatedEndDate,
                false,
                this.state.unitID_internal,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                true
            );
        }
    };
    changeHandler = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };
    changeHandlerPhone = (event) => {
        this.setState({ phoneNumber: event });
        var value = event
            ? isPossiblePhoneNumber(event)
                ? undefined
                : "Please provide a valid phone number"
            : "Phone number required";
        this.setState({ invalidPhone: value });
    };
    toggleCheckboxValueSMS = () => {
        this.setState({ receivetext: !this.state.receivetext });
    };
    toggleCheckboxValueVoice = () => {
        this.setState({ receivephone: !this.state.receivephone });
    };
    createMask = () => {
        this.setState({
            phoneNumber: this.state.phoneNumber.replace(/\D/g, ""),
        });
    };

    setEndDate = (dateStart) => {
        if (dateStart !== null) {
            var endDateDay = new Date(dateStart.valueOf()).getDate() + 1;
            var endDate = new Date(dateStart).setDate(endDateDay);
            if (isNaN(endDate) === true) {
                this.setState({
                    invalidDate:
                        "Please provide a valid date in this format MM/DD/YYYY H:MM AM/PM",
                });
            } else {
                var endDateISO = new Date(endDate)
                    .toISOString()
                    .replace(/:00.000Z/, "");
                if (dateStart !== null && this.state.dateEnd === null) {
                    this.setState({
                        dateEnd: Intl.DateTimeFormat("en-US", {
                            month: "2-digit",
                            day: "2-digit",
                            year: "numeric",
                            hour: "numeric",
                            minute: "numeric",
                        }).format(new Date(endDateISO)),
                    });
                }
            }
        }
    };
    validateEndDate = (dateEnd) => {
        if (dateEnd !== null) {
            var endDateDay = new Date(dateEnd.valueOf()).getDate() + 1;
            var endDate = new Date(dateEnd).setDate(endDateDay);
            if (isNaN(endDate) === true) {
                this.setState({
                    invalidDateEnd:
                        "Please provide a valid date in this format MM/DD/YYYY H:MM AM/PM",
                });
            } else {
                var endDateISO = new Date(endDate)
                    .toISOString()
                    .replace(/:00.000Z/, "");
                if (dateEnd !== null && this.state.dateEnd === null) {
                    this.setState({ dateEnd: endDateISO });
                }
            }
        }
    };
    handleDayChange(dateStart, modifiers, dayPickerInput) {
        const input = dayPickerInput.getInput();
        this.setState({
            dateStart,
            isEmpty: !input.value.trim(),
            isDisabled: modifiers.disabled === true,
        });
    }

    handleDayChangeEnd = (event, { name, value }) => {
        if (this.state.hasOwnProperty(name)) {
            this.setState({ [name]: value });
        }
        var isISO = value;
        let maxDateDay;
        let maxDate;
        if (this.state.duration === 0) {
            maxDateDay = new Date(this.state.dateStart.valueOf());
            maxDate = new Date(this.state.dateStart).setDate(maxDateDay);
        } else {
            maxDateDay =
                new Date(this.state.dateStart.valueOf()).getHours() +
                this.state.duration;
            maxDate = new Date(this.state.dateStart).setHours(maxDateDay);
        }
        if (this.state.duration !== 0) {
            if (new Date(isISO) > new Date(maxDate)) {
                this.setState({
                    invalidDateEnd:
                        "Date cannot exceed " +
                        this.state.duration +
                        " hours for a Guest",
                });
            } else {
                if (isNaN(new Date(isISO)) === false) {
                    this.setState({ invalidDateEnd: null });
                } else {
                    this.setState({
                        invalidDateEnd:
                            "Please provide a valid date in this format MM/DD/YYYY H:MM AM/PM",
                    });
                }
            }
        }
    };

    submitHandlerResendActivation = (email) => {
        let body = {
            AccessToken: sessionStorage.getItem("AccessToken"),
            EmailAddress: this.state.email,
            VenueID: this.props.venueID,
            UserTimeZone: new Date().getTimezoneOffset(),
        };
        this.setState({ loadingResend: true });
        axios
            .post("/venuemgmtconsole/subscribers/resendCodeforTandC", body, {
                headers: {},
            })
            .then(
                (response) => {
                    toast.success("Resent Confirmation!", {
                        closeButton: false,
                    });
                    this.setState({ loadingResend: false });
                },
                (error) => {
                    toast.error("There was an error", {
                        closeButton: false,
                    });
                    this.setState({ loadingResend: false });
                }
            );
    };
    handleChange = (event, { name, value }) => {
        if (this.state.hasOwnProperty(name)) {
            this.setState({ [name]: value });
        }
    };
    toggleConfirmDeletion = (key) => {
        this.setState({
            toggleConfirm: true,
            modal: !this.state.modal,
        });
    };

    submitDelete = (event) => {
        this.props.submitDelete(
            this.state.email,
            this.state.venueID,
            this.state.unitID,
            this.state.building,
            this.state.floor,
            this.state.recentResidents,
            this.state.oldDateStart,
            this.state.unitID_internal,
            undefined,
            undefined,
            true
        );
    };
    toggle = () => {
        this.setState({
            modal: !this.state.modal,
            modalMessage: !this.state.modalMessage,
        });
    };
    render() {
        const { t } = this.props;
        const FORMAT = "MM/dd/yyyy";
        let maxDateDay;
        let maxDate;
        if (this.state.duration === 0) {
            maxDateDay = new Date(this.state.dateStart.valueOf()).getDate();
            maxDate = new Date(this.state.dateStart).setDate(maxDateDay);
        } else {
            maxDateDay =
                new Date(this.state.dateStart.valueOf()).getDate() +
                this.state.duration / 24;
            maxDate = new Date(this.state.dateStart).setDate(maxDateDay);
        }
        return (
            <>
                {this.state.toggleConfirm ? (
                    <MDBModal isOpen={this.state.modal} toggle={this.toggle}>
                        <MDBModalHeader toggle={this.toggle}>
                            Confirm Deletion
                        </MDBModalHeader>
                        <MDBModalBody>
                            <p>
                                Name:{" "}
                                {this.state.firstName +
                                    " " +
                                    this.state.lastName}
                            </p>
                            <p>Email: {this.state.email}</p>
                            <p>Unit: {this.state.unitID}</p>
                            <p>Building: {this.state.building}</p>
                            <p>Floor: {this.state.floor}</p>
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn color="primary" onClick={this.submitDelete}>
                                Confirm
                            </MDBBtn>
                            <MDBBtn color="primary" onClick={this.toggle}>
                                Close
                            </MDBBtn>
                        </MDBModalFooter>
                    </MDBModal>
                ) : null}
                <MDBModalHeader toggle={this.props.toggleModifyResident}>
                    <h4>
                        {this.props.venueType === "HOTSPOT"
                            ? "View/Modify Local Policy"
                            : t("modifyguest.title")}
                    </h4>
                    <p>* = required</p>
                </MDBModalHeader>
                <MDBModalBody>
                    <form
                        className="needs-validation"
                        onSubmit={this.submitHandler}
                        noValidate
                    >
                        <MDBRow>
                            <MDBCol col="6" sm="12" lg="6">
                                <label
                                    htmlFor="defaultFormLoginEmailEx"
                                    className="black-text"
                                >
                                    {t("addnewresident.email")}
                                </label>
                                <input
                                    disabled
                                    id="email"
                                    className="form-control"
                                    onBlur={(event) =>
                                        this.validateEmail(this.state.email)
                                    }
                                    onChange={(event) =>
                                        this.setState({
                                            email: event.target.value,
                                        })
                                    }
                                    value={this.state.email}
                                />
                                <div className="invalid-feedback">
                                    Please provide a valid email.
                                </div>
                            </MDBCol>
                            <MDBCol col="6"></MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol sm="12" lg="6">
                                <label
                                    htmlFor="defaultFormLoginEmailEx"
                                    className="black-text"
                                >
                                    {t("addnewresident.firstname")}
                                </label>
                                <input
                                    required
                                    name="firstName"
                                    id="firstName"
                                    className={
                                        this.state.loading === true
                                            ? "form-control loading"
                                            : "form-control"
                                    }
                                    onChange={this.changeHandler}
                                    value={this.state.firstName}
                                />
                                <div className="invalid-feedback">
                                    Please provide a valid first name.
                                </div>
                            </MDBCol>
                            <MDBCol sm="12" lg="6">
                                <label
                                    htmlFor="defaultFormLoginEmailEx"
                                    className="black-text"
                                >
                                    {t("addnewresident.lastname")}
                                </label>
                                <input
                                    required
                                    className={
                                        this.state.loading === true
                                            ? "form-control loading"
                                            : "form-control"
                                    }
                                    name="lastName"
                                    id="lastName"
                                    onChange={this.changeHandler}
                                    value={this.state.lastName}
                                />
                                <div className="invalid-feedback">
                                    Please provide a valid last name.
                                </div>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol col="6" sm="12" lg="6">
                                <label
                                    htmlFor="defaultFormLoginEmailEx"
                                    className="black-text"
                                >
                                    {t("addnewguest.phone")}
                                </label>
                                <PhoneInput
                                    placeholder={t("addnewresident.enterphone")}
                                    value={this.state.phoneNumber}
                                    format={FORMAT}
                                    onChange={this.changeHandlerPhone}
                                    name="phoneNumber"
                                    id="phoneNumber"
                                    className={
                                        this.state.loading === true
                                            ? "form-control loading"
                                            : "form-control"
                                    }
                                    defaultCountry="US"
                                    required
                                    error={
                                        this.state.phoneNumber
                                            ? isValidPhoneNumber(
                                                  this.state.phoneNumber
                                              )
                                                ? undefined
                                                : "Invalid phone number"
                                            : "Phone number required"
                                    }
                                />
                                {this.state.invalidPhone !== null ? (
                                    <div className="invalid">
                                        {this.state.invalidPhone}
                                    </div>
                                ) : (
                                    ""
                                )}
                            </MDBCol>
                            <MDBCol col="6">
                                <div>
                                    <label
                                        htmlFor="defaultFormLoginEmailEx"
                                        className="black-text"
                                    >
                                        {t("addnewresident.language")}
                                    </label>
                                    <SelectSearch
                                        id="selectLanguage"
                                        search={true}
                                        filterOptions={fuzzySearch}
                                        options={this.state.languageOptions}
                                        isOptionSelected={true}
                                        classNamePrefix="language-select"
                                        onChange={
                                            this.handleSelectChangeLanguage
                                        }
                                        value={this.state.selectedLanguage}
                                        placeholder={
                                            this.state.selectedLanguage !==
                                            undefined
                                                ? this.state
                                                      .selectedLanguage ===
                                                  "en-us"
                                                    ? "English"
                                                    : "Spanish"
                                                : "Select..."
                                        }
                                    />
                                    <div className="invalid-feedback">
                                        Please provide a valid language.
                                    </div>
                                </div>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol col="6">
                                <label
                                    htmlFor="defaultFormLoginEmailEx"
                                    className="black-text"
                                >
                                    {t("addnewguest.unit")}
                                </label>
                                <input
                                    disabled
                                    id="unitID"
                                    className="form-control"
                                    value={this.state.unitID}
                                />
                                <div className="invalid-feedback">
                                    Please provide a unit number.
                                </div>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol col="6">
                                <label
                                    htmlFor="defaultFormLoginEmailEx"
                                    className="black-text"
                                >
                                    {t("addnewresident.building")}
                                </label>
                                <input
                                    disabled
                                    id="building"
                                    className="form-control"
                                    value={this.state.building}
                                />
                                <div className="invalid-feedback">
                                    Please provide a building.
                                </div>
                            </MDBCol>
                            <MDBCol col="6">
                                <label
                                    htmlFor="defaultFormLoginEmailEx"
                                    className="black-text"
                                >
                                    {t("addnewresident.floor")}
                                </label>
                                <input
                                    disabled
                                    id="floor"
                                    className="form-control"
                                    value={this.state.floor}
                                />
                                <div className="invalid-feedback">
                                    Please provide a floor.
                                </div>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol sm="12" lg="6">
                                <label
                                    htmlFor="defaultFormLoginEmailEx"
                                    className="black-text"
                                >
                                    {t("addnewresident.servicestart")}
                                </label>
                                <br />
                                <DateTimeInput
                                    dateTimeFormat="MM/DD/YYYY, h:mm A"
                                    timeFormat="ampm"
                                    name="dateStart"
                                    placeholder="MM/DD/YYYY"
                                    value={this.state.dateStart}
                                    iconPosition="left"
                                    onChange={this.handleChange}
                                    onBlur={() =>
                                        this.setEndDate(this.state.dateStart)
                                    }
                                    className="dateTimePicker"
                                    disableMinute="true"
                                    minDate={new Date()}
                                    disabled={
                                        new Date(this.state.dateStart) <
                                        new Date()
                                            ? true
                                            : false
                                    }
                                />
                                <label
                                    for="serviceStart"
                                    className="black-text"
                                    style={{
                                        fontSize: "11px",
                                        position: "relative",
                                        top: "-5px",
                                        left: "0px",
                                    }}
                                >
                                    {this.props.venueType === "HOTSPOT"
                                        ? "Subscriber can use their Wi-Fi password as of this date"
                                        : t("addnewguest.servicestartlabel")}
                                </label>
                            </MDBCol>
                            <MDBCol sm="12" lg="6">
                                <label
                                    htmlFor="defaultFormLoginEmailEx"
                                    className="black-text"
                                >
                                    {t("addnewresident.serviceend")}
                                </label>
                                <DateTimeInput
                                    dateTimeFormat="MM/DD/YYYY, h:mm A"
                                    timeFormat="ampm"
                                    name="dateEnd"
                                    placeholder="Date Time"
                                    value={
                                        isNaN(new Date(this.state.dateEnd)) ===
                                            true &&
                                        this.state.invalidDateEnd === null &&
                                        this.state.dateEnd !== ""
                                            ? Intl.DateTimeFormat("en-US", {
                                                  month: "2-digit",
                                                  day: "2-digit",
                                                  year: "numeric",
                                                  hour: "numeric",
                                                  minute: "numeric",
                                              }).format(
                                                  new Date(this.state.dateEnd)
                                              )
                                            : this.state.dateEnd
                                    }
                                    iconPosition="left"
                                    onChange={this.handleDayChangeEnd}
                                    className="dateTimePicker"
                                    disableMinute="true"
                                    maxDate={
                                        this.state.duration === 0
                                            ? "0"
                                            : new Date(maxDate)
                                    }
                                    minDate={new Date(this.state.dateStart)}
                                />
                                <label
                                    className="black-text"
                                    style={{
                                        fontSize: "11px",
                                        position: "relative",
                                        top: "-5px",
                                        left: "0px",
                                    }}
                                >
                                    {t("addnewguest.serviceendlabel")}
                                </label>
                                {this.state.invalidDateEnd !== null ? (
                                    <div className="invalid">
                                        {this.state.invalidDateEnd}
                                    </div>
                                ) : (
                                    ""
                                )}
                            </MDBCol>
                        </MDBRow>
                        <div className="text-right submitButton">
                            <MDBBtn
                                color="danger"
                                onClick={this.toggleConfirmDeletion}
                            >
                                {this.props.loadingDelete === true ? (
                                    <div
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                    >
                                        <span className="sr-only">
                                            Loading...
                                        </span>
                                    </div>
                                ) : (
                                    "Delete Guest"
                                )}
                            </MDBBtn>
                            <MDBBtn
                                color="danger"
                                onClick={this.submitHandlerResendActivation}
                            >
                                {this.state.loadingResend === true ? (
                                    <div
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                    >
                                        <span className="sr-only">
                                            Loading...
                                        </span>
                                    </div>
                                ) : (
                                    t("residentnav.resendactivation")
                                )}
                            </MDBBtn>
                            <MDBBtn color="danger" type="submit">
                                {this.props.loading === true ? (
                                    <div
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                    >
                                        <span className="sr-only">
                                            Loading...
                                        </span>
                                    </div>
                                ) : this.props.venueType === "HOTSPOT" ? (
                                    "Save Changes"
                                ) : (
                                    t("modifyguest.modifyguest")
                                )}
                            </MDBBtn>
                        </div>
                    </form>
                </MDBModalBody>
            </>
        );
    }
}

export default withTranslation()(AddNewResident);
