import React, { Component } from "react";

import {
    MDBBtn,
    MDBModalBody,
    MDBModalHeader,
    MDBSelect,
    MDBSelectInput,
    MDBSelectOptions,
    MDBRow,
    MDBCol,
    MDBSpinner,
} from "mdbreact";

import axios from "../../../../axios";

import { withTranslation } from "react-i18next";

import $ from "jquery";

import SelectSearch, { fuzzySearch } from "react-select-search";

import CurrencyDatatables from "../../../../components/NetworkMgmt/Forms/AddServicePlanToVenue/Datatables/AddServicePlanToVenueCurrencyDatatable";

class AddVenue extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: null,
            content: null,
            unitName: undefined,
            unitBuilding: undefined,
            unitFloor: undefined,
            address1: undefined,
            address2: undefined,
            city: undefined,
            state: undefined,
            postalcode: undefined,
            country: undefined,
            region: undefined,
            value: "en",
            invalidCountry: null,
            invalidState: null,
        };
    }

    componentDidMount() {
        this.getServicePlans();
    }

    getServicePlans = () => {
        this.setState({
            loading_prop_groups: true,
            allServicePlans: undefined,
        });
        const data = {
            AccessToken: sessionStorage.getItem("AccessToken"),
        };
        axios
            .post(
                "/networkoperatorconsole/equipment/listOperatorDetails",
                data,
                {
                    headers: {},
                }
            )
            .then((response) => {
                var servicePlanArray = [];
                let optionTemplateServicePlans = [];
                Object.keys(response.data.ServicePlans).map((oneKey, i) => {
                    optionTemplateServicePlans.push({
                        value: response.data.ServicePlans[oneKey][
                            "ServicePlanID"
                        ],
                        name: response.data.ServicePlans[oneKey][
                            "ServicePlanName"
                        ],
                    });
                    return true;
                });
                servicePlanArray.push(
                    <MDBSelect
                        id="selectServicePlan"
                        className="form-control"
                        getValue={this.handleSelectServicePlan}
                    >
                        <MDBSelectInput
                            className="selectInput selectServicePlan"
                            selected="Choose the Service Plan to Add"
                        />
                        <MDBSelectOptions search>
                            {optionTemplateServicePlans}
                        </MDBSelectOptions>
                    </MDBSelect>
                );
                this.setState({
                    optionTemplateServicePlans: optionTemplateServicePlans,
                    allServicePlans: response.data.ServicePlans,
                    loading_prop_serviceplans: false,
                });
            })
            .catch((error) => {
                this.setState({ loading_prop_serviceplans: false });
            });
    };

    handleSelectServicePlan = (value, searchId) => {
        this.getServicePlans();
        this.setState({
            index: searchId["index"],
            servicePlanID:
                this.state.allServicePlans[searchId["index"]]["ServicePlanID"],
            ServicePlanName:
                this.state.allServicePlans[searchId["index"]][
                    "ServicePlanName"
                ],
            DownloadKbps:
                this.state.allServicePlans[searchId["index"]]["DownloadKbps"],
            UploadKbps:
                this.state.allServicePlans[searchId["index"]]["UploadKbps"],
            ConcurrentDevices:
                this.state.allServicePlans[searchId["index"]][
                    "ConcurrentDevices"
                ],
            GloballyAvailablePlan:
                this.state.allServicePlans[searchId["index"]][
                    "GloballyAvailablePlan"
                ],
            ServicePlanDescription:
                this.state.allServicePlans[searchId["index"]][
                    "ServicePlanDescription"
                ],
            externalServicePlanID:
                this.state.allServicePlans[searchId["index"]][
                    "externalServicePlanID"
                ],
            SMSCode: this.state.allServicePlans[searchId["index"]]["SMSCode"],
            TrialCycles:
                this.state.allServicePlans[searchId["index"]]["TrialCycles"],
            BillingCycleHours:
                this.state.allServicePlans[searchId["index"]][
                    "BillingCycleHours"
                ],
            Currencies:
                this.state.allServicePlans[searchId["index"]]["Currencies"],
            BillingCyclePrice:
                this.state.allServicePlans[searchId["index"]][
                    "BillingCyclePrice"
                ],
            BillingCycleRecurrence:
                this.state.allServicePlans[searchId["index"]][
                    "BillingCycleRecurrence"
                ],
            MaximumTrialCyclesPerSubscriberPerVenue:
                this.state.allServicePlans[searchId["index"]][
                    "MaximumTrialCyclesPerSubscriberPerVenue"
                ],
            MaximumFreeEnrollmentsPerSubscriberPerVenue:
                this.state.allServicePlans[searchId["index"]][
                    "MaximumFreeEnrollmentsPerSubscriberPerVenue"
                ],
        });
    };

    getValueOfSelect = (e) => {
        var index = e.target.selectedIndex;
        var optionElement = e.target.childNodes[index];
        this.setState({
            propertyMUTCGroupSecret: optionElement.getAttribute("value"),
        });
        this.setState({ propertyMUTCGroup: optionElement.getAttribute("id") });
    };

    submitHandler = (event) => {
        event.preventDefault();

        $("#selectServicePlan").removeClass("is-invalid");

        event.target.className += " was-validated";
        if (this.state.servicePlanID === undefined) {
            this.setState({
                invalidServicePlan: "Please provide a valid service plan",
            });
            $("#selectServicePlan").addClass("is-invalid");
        } else {
            this.setState({ invalidServicePlan: null });
            $("#selectServicePlan").addClass("is-valid");
        }

        if (this.state.servicePlanID !== undefined) {
            this.props.submitAddServicePlan(this.state.servicePlanID);
        }
    };

    changeHandler = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };

    render() {
        const { t } = this.props;
        return (
            <>
                <MDBModalHeader toggle={this.props.toggleAddServicePlan}>
                    <h4>Add Service Plan</h4>
                    <p>{t("addnewresident.required")}</p>
                </MDBModalHeader>
                <MDBModalBody>
                    <form
                        className="needs-validation"
                        onSubmit={this.submitHandler}
                        noValidate
                    >
                        <MDBRow>
                            <MDBCol col="6" sm="12" lg="12">
                                <label
                                    htmlFor="defaultFormLoginEmailEx"
                                    className="black-text"
                                >
                                    <h5>Venue Name: {this.props.venueName}</h5>
                                </label>
                            </MDBCol>
                            <MDBCol col="6"></MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol col="6">
                                <label
                                    htmlFor="defaultFormLoginEmailEx"
                                    className="black-text"
                                >
                                    Service Plan to Add*
                                </label>
                                <SelectSearch
                                    id="selectGroup"
                                    search={true}
                                    filterOptions={fuzzySearch}
                                    options={
                                        this.state.optionTemplateServicePlans
                                    }
                                    inputId="groupArray"
                                    classNamePrefix="group-select"
                                    onChange={this.handleSelectServicePlan}
                                    value={this.state.servicePlanID}
                                    placeholder="Select..."
                                />
                                <div className="invalid-feedback">
                                    Please provide a Service Plan
                                </div>
                            </MDBCol>
                        </MDBRow>
                        {this.state.ServicePlanName !== undefined ? (
                            <>
                                <MDBRow>
                                    <MDBCol className="mt-3" sm="12" lg="6">
                                        <p>
                                            <strong>Service Plan Name</strong>
                                        </p>
                                        <p className="breakText">
                                            {this.state.ServicePlanName}
                                        </p>
                                    </MDBCol>
                                    <MDBCol className="mt-3" sm="12" lg="6">
                                        <p>
                                            <strong>Service Plan ID</strong>
                                        </p>
                                        <p className="breakText">
                                            {this.state.servicePlanID}
                                        </p>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol className="mt-3" sm="12" lg="6">
                                        <p>
                                            <strong>Description</strong>
                                        </p>
                                        <p className="breakText">
                                            {this.state.ServicePlanDescription}
                                        </p>
                                    </MDBCol>
                                    <MDBCol className="mt-3" sm="12" lg="6">
                                        <p>
                                            <strong>
                                                External Service Plan ID
                                            </strong>
                                        </p>
                                        <p className="breakText">
                                            {this.state.externalServicePlanID}
                                        </p>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol className="mt-3" sm="12" lg="6">
                                        <p>
                                            <strong>Download KBPS</strong>
                                        </p>
                                        <p className="breakText">
                                            {this.state.DownloadKbps}
                                        </p>
                                    </MDBCol>
                                    <MDBCol className="mt-3" sm="12" lg="6">
                                        <p>
                                            <strong>Upload KBPS</strong>
                                        </p>
                                        <p className="breakText">
                                            {this.state.UploadKbps}
                                        </p>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol className="mt-3" sm="12" lg="6">
                                        <p>
                                            <strong>Concurrent Devices</strong>
                                        </p>
                                        <p className="breakText">
                                            {this.state.ConcurrentDevices}
                                        </p>
                                    </MDBCol>
                                    <MDBCol className="mt-3" sm="12" lg="6">
                                        <p>
                                            <strong>Globally Available</strong>
                                        </p>
                                        <p className="breakText">
                                            {this.state.GloballyAvailablePlan}
                                        </p>
                                    </MDBCol>
                                </MDBRow>

                                <MDBRow>
                                    <MDBCol className="mt-3" sm="12" lg="6">
                                        <p>
                                            <strong>SMS Code</strong>
                                        </p>
                                        <p className="breakText">
                                            {this.state.SMSCode}
                                        </p>
                                    </MDBCol>
                                    <MDBCol className="mt-3" sm="12" lg="6">
                                        <p>
                                            <strong>Trial Cycles</strong>
                                        </p>
                                        <p className="breakText">
                                            {this.state.TrialCycles}
                                        </p>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol className="mt-3" sm="12" lg="6">
                                        <p>
                                            <strong>
                                                Billing Cycle Recurrence
                                            </strong>
                                        </p>
                                        <p className="breakText">
                                            {this.state.BillingCycleRecurrence}
                                        </p>
                                    </MDBCol>
                                    <MDBCol
                                        className="mt-3"
                                        sm="12"
                                        lg="6"
                                    ></MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol className="mt-3" sm="12" lg="6">
                                        <p>
                                            <strong>
                                                Maximum Trial Cycles Per
                                                Subscriber - Per Venue
                                            </strong>
                                        </p>
                                        <p className="breakText">
                                            {
                                                this.state
                                                    .MaximumTrialCyclesPerSubscriberPerVenue
                                            }
                                        </p>
                                    </MDBCol>
                                    <MDBCol className="mt-3" sm="12" lg="6">
                                        <p>
                                            <strong>
                                                Maximum Free Enrollments Per
                                                Subscriber - Per Venue
                                            </strong>
                                        </p>
                                        <p className="breakText">
                                            {
                                                this.state
                                                    .MaximumFreeEnrollmentsPerSubscriberPerVenue
                                            }
                                        </p>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol className="mt-3" sm="12" lg="6">
                                        <p>
                                            <strong>
                                                Maximum Trial Cycles Per
                                                Subscriber - Per Venue
                                            </strong>
                                        </p>
                                        <p className="breakText">
                                            {
                                                this.state
                                                    .MaximumTrialCyclesPerSubscriberPerVenue
                                            }
                                        </p>
                                    </MDBCol>
                                    <MDBCol className="mt-3" sm="12" lg="6">
                                        <p>
                                            <strong>
                                                Maximum Free Enrollments Per
                                                Subscriber - Per Venue
                                            </strong>
                                        </p>
                                        <p className="breakText">
                                            {
                                                this.state
                                                    .MaximumFreeEnrollmentsPerSubscriberPerVenue
                                            }
                                        </p>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol className="mt-3" sm="12" lg="12">
                                        <p>
                                            <strong>
                                                Maximum Trial Cycles Per
                                                Subscriber - Per Venue
                                            </strong>
                                        </p>
                                        {this.state.allServicePlans !==
                                        undefined ? (
                                            <>
                                                <CurrencyDatatables
                                                    allServicePlans={
                                                        this.state
                                                            .allServicePlans
                                                    }
                                                    currencies={
                                                        this.state.Currencies
                                                    }
                                                    index={this.state.index}
                                                />
                                            </>
                                        ) : (
                                            <MDBSpinner small />
                                        )}
                                    </MDBCol>
                                </MDBRow>
                            </>
                        ) : (
                            ""
                        )}
                        <div className="text-right submitButton">
                            <MDBBtn color="danger" type="submit">
                                {this.props.add_serviceplan === true ? (
                                    <div
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                    >
                                        <span className="sr-only">
                                            Loading...
                                        </span>
                                    </div>
                                ) : (
                                    "Add Service Plan"
                                )}
                            </MDBBtn>
                        </div>
                    </form>
                </MDBModalBody>
            </>
        );
    }
}

export default withTranslation()(AddVenue);
