import React, { Component } from "react";

import {
    MDBSelect,
    MDBSelectInput,
    MDBSelectOptions,
    MDBSelectOption,
} from "mdbreact";

import axios from "../../../../axios";

class VenueDropdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            attributeForm: [],
        };
    }
    componentDidMount() {
        this._isMounted = true;
        this.getVenues();
    }

    getVenues = (selectedID) => {
        this.setState({
            attributeForm: [],
        });
        let body = { AccessToken: sessionStorage.getItem("AccessToken") };

        axios
            .post("/venuemgmtconsole/venues/listVenues", body, {
                headers: {},
            })
            .then((response) => {
                var formArray = [];
                let optionTemplateKeyPools = [];
                Object.keys(response.data.VenueList).map((oneKey, i) => {
                    if (sessionStorage.getItem("venueID") === oneKey) {
                        optionTemplateKeyPools.push(
                            <MDBSelectOption selected value={oneKey}>
                                {response.data.VenueList[oneKey]["VenueName"]}
                            </MDBSelectOption>
                        );
                    } else if (selectedID === oneKey) {
                        optionTemplateKeyPools.push(
                            <MDBSelectOption selected value={oneKey}>
                                {response.data.VenueList[oneKey]["VenueName"]}
                            </MDBSelectOption>
                        );
                    } else {
                        optionTemplateKeyPools.push(
                            <MDBSelectOption value={oneKey}>
                                {response.data.VenueList[oneKey]["VenueName"]}
                            </MDBSelectOption>
                        );
                    }
                    return true;
                });
                formArray.push(
                    <MDBSelect
                        id="selectVenueResidentModal"
                        getValue={this.handleSelectChange}
                    >
                        <MDBSelectInput
                            className="selectInput venueSelectedResidentModal"
                            selected="Choose your venue"
                        />
                        <MDBSelectOptions search>
                            {optionTemplateKeyPools}
                        </MDBSelectOptions>
                    </MDBSelect>
                );
                this.setState({
                    attributeForm: formArray,
                });
            })
            .catch((error) => {
                this.setState({ error: true });
            });
    };

    handleSelectChange = (value) => {
        var venue = document.getElementsByClassName(
            "venueSelectedResidentModal"
        )[0].value;
        var lDiv = document.createElement("div");

        document.body.appendChild(lDiv);

        lDiv.style.fontSize = "" + 20 + "px";
        lDiv.style.position = "absolute";
        lDiv.style.left = -1000;
        lDiv.style.top = -1000;

        lDiv.innerHTML = venue;

        var lResult = {
            width: lDiv.clientWidth,
            height: lDiv.clientHeight,
        };

        document.body.removeChild(lDiv);
        lDiv = null;
        document.getElementById("selectVenueResidentModal").style.width =
            lResult["width"] + 30 + "px";
        sessionStorage.setItem("venueName", venue);
        sessionStorage.setItem("venueID", value[0]);
        this.props.getVenues(value[0]);
        this.setState({ selectedID: value[0] });
    };

    render() {
        return <>{this.state.attributeForm}</>;
    }
}

export default VenueDropdown;
