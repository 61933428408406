import React, { Component } from "react";
import {
    MDBBtn,
    MDBModalBody,
    MDBModalHeader,
    MDBSelect,
    MDBSelectInput,
    MDBSelectOptions,
    MDBSelectOption,
    MDBRow,
    MDBCol,
} from "mdbreact";
import { withTranslation } from "react-i18next";
import $ from "jquery";
import {
    CountryDropdown,
    RegionDropdown,
} from "vault-react-country-region-selector";
import SelectSearch, { fuzzySearch } from "react-select-search";
class AddVenue extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: null,
            content: null,
            unitName: undefined,
            unitBuilding: undefined,
            unitFloor: undefined,
            value: "en",
            invalidCountry: null,
            invalidState: null,
            invalidVlan: undefined,
            vlan: undefined,
            isLoaded: false,
            hasUpdated: this.props.hasUpdated,
            invalidPlan: undefined,
            address1: this.props.address1,
            address2: this.props.address2,
            city: this.props.city,
            state: this.props.region,
            postalcode: this.props.postalcode,
            country: this.props.country,
            region: this.props.region,
        };
    }
    componentDidUpdate(prevProps) {
        if (prevProps.hasUpdated !== this.props.hasUpdated) {
            this.setState({
                address1: this.props.address1,
                address2: this.props.address2,
                city: this.props.city,
                state: this.props.region,
                postalcode: this.props.postalcode,
                country: this.props.country,
                region: this.props.region,
                optionTemplatePlans: this.props.optionTemplatePlans,
                availableServicePlans: this.props.availableServicePlans,
            });
        }
    }
    selectCountry(val) {
        this.setState({ country: val });
    }
    selectRegion(val) {
        this.setState({ region: val });
    }
    getValueOfSelect = (e) => {
        var index = e.target.selectedIndex;
        var optionElement = e.target.childNodes[index];
        this.setState({
            propertyMUTCGroupSecret: optionElement.getAttribute("value"),
        });
        this.setState({ propertyMUTCGroup: optionElement.getAttribute("id") });
    };
    getValueOfSelectCountry = (value) => {
        this.setState({ country: value[0] });
    };
    getValueOfSelectState = (value) => {
        this.setState({ region: value[0] });
    };
    submitHandler = (event) => {
        event.preventDefault();
        $("#selectCountry").removeClass("is-invalid");
        $("#selectState").removeClass("is-invalid");
        $("#selectPurpose").removeClass("is-invalid");
        $("#vlan").removeClass("is-invalid");
        event.target.className += " was-validated";
        if (this.state.country === undefined) {
            this.setState({ invalidCountry: "Please provide a valid country" });
            $("#selectCountry").addClass("is-invalid");
        } else {
            this.setState({ invalidCountry: null });
            $("#selectCountry").addClass("is-valid");
        }
        if (this.state.region === undefined) {
            this.setState({
                invalidState: "Please provide a valid state/region",
            });
            $("#selectState").addClass("is-invalid");
        } else {
            this.setState({ invalidState: null });
            $("#selectState").addClass("is-valid");
        }
        if (this.state.selectedPurpose === undefined) {
            this.setState({ invalidLanguage: "Please provide a purpose" });
            $("#selectPurpose").addClass("is-invalid");
        } else {
            $("#selectPurpose").addClass("is-valid");
        }
        if (
            this.state.vlan !== undefined &&
            (this.state.vlan < 1 || this.state.vlan > 4095)
        ) {
            this.setState({
                invalidVlan: "Please provide a valid VLAN (1 - 4095)",
            });
            $("#vlan").addClass("is-invalid");
            $("#vlan").removeClass("is-valid");
        } else {
            $("#vlan").addClass("is-valid");
        }
        if (
            this.state.selectedPurpose === "globalroaming" &&
            this.state.selectedPlan === undefined
        ) {
            this.setState({ invalidPlan: "Please provide a valid plan" });
            $("#selectPlan").addClass("is-invalid");
        } else {
            $("#selectPlan").addClass("is-valid");
        }
        if (
            this.state.unitName !== undefined &&
            this.state.unitBuilding !== undefined &&
            this.state.unitFloor !== undefined &&
            this.state.address1 !== undefined &&
            this.state.country !== undefined &&
            this.state.city !== undefined &&
            this.state.region !== undefined &&
            this.state.postalcode !== undefined &&
            this.state.selectedPurpose !== undefined &&
            this.state.invalidPlan === undefined &&
            $(".invalid-feedback").is(":visible") !== true
        ) {
            this.props.submitAddUnit(
                this.props.venueID,
                this.state.unitName,
                this.state.unitBuilding,
                this.state.unitFloor,
                this.state.address1,
                this.state.address2,
                this.state.country,
                this.state.city,
                this.state.region,
                this.state.postalcode,
                this.state.selectedPurpose,
                this.state.vlan,
                this.state.selectedPlan,
                this.state.smsCode
            );
        }
    };
    changeHandler = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };
    handleSelectChangePurpose = (value) => {
        this.setState({
            selectedPurpose: value[0],
            selectedPlan: undefined,
        });
    };
    handleSelectPlan = (value) => {
        this.setState({ selectedPlan: value });
    };
    render() {
        const { country, region } = this.state;
        const { t } = this.props;
        return (
            <>
                <MDBModalHeader toggle={this.props.toggleAddUnit}>
                    <h4>Add Unit</h4>
                    <p>{t("addnewresident.required")}</p>
                </MDBModalHeader>
                <MDBModalBody>
                    <form
                        className="needs-validation"
                        onSubmit={this.submitHandler}
                        noValidate
                    >
                        <MDBRow>
                            <MDBCol col="6" sm="12" lg="12">
                                <label
                                    htmlFor="defaultFormLoginEmailEx"
                                    className="black-text"
                                >
                                    Venue Name
                                </label>
                                <SelectSearch
                                    id="selectVenue"
                                    search={true}
                                    filterOptions={fuzzySearch}
                                    options={this.props.optionTemplateVenues}
                                    isOptionSelected={true}
                                    inputId="venueArray"
                                    classNamePrefix="venue-select"
                                    placeholder="Select..."
                                    onChange={this.props.handleSelectVenue}
                                    value={
                                        this.props.clearDropDown === true
                                            ? ""
                                            : this.props.venueID
                                    }
                                />
                                <div className="invalid-feedback">
                                    Please select a venue.
                                </div>
                            </MDBCol>
                            <MDBCol col="6"></MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol sm="12" lg="12">
                                <label
                                    htmlFor="defaultFormLoginEmailEx"
                                    className="black-text"
                                >
                                    Unit Name*
                                </label>
                                <input
                                    required
                                    name="unitName"
                                    id="unitName"
                                    className="form-control"
                                    onChange={this.changeHandler}
                                    value={this.state.unitName}
                                />
                                <div className="invalid-feedback">
                                    Please provide a valid unit name
                                </div>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol sm="12" lg="6">
                                <label
                                    htmlFor="defaultFormLoginEmailEx"
                                    className="black-text"
                                >
                                    Building*
                                </label>
                                <input
                                    required
                                    name="unitBuilding"
                                    id="unitBuilding"
                                    className="form-control"
                                    onChange={this.changeHandler}
                                    value={this.state.unitBuilding}
                                />
                                <div className="invalid-feedback">
                                    Please provide a valid building
                                </div>
                            </MDBCol>
                            <MDBCol sm="12" lg="6">
                                <label
                                    htmlFor="defaultFormLoginEmailEx"
                                    className="black-text"
                                >
                                    Floor*
                                </label>
                                <input
                                    required
                                    className="form-control"
                                    name="unitFloor"
                                    id="unitFloor"
                                    onChange={this.changeHandler}
                                    value={this.state.unitFloor}
                                />
                                <div className="invalid-feedback">
                                    Please provide a valid floor
                                </div>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol sm="12" lg="6">
                                <label
                                    htmlFor="defaultFormLoginEmailEx"
                                    className="black-text"
                                >
                                    Address Line 1*
                                </label>
                                <input
                                    required
                                    name="address1"
                                    id="address1"
                                    className="form-control"
                                    onChange={this.changeHandler}
                                    value={this.state.address1}
                                />
                                <div className="invalid-feedback">
                                    Please provide a valid address
                                </div>
                            </MDBCol>
                            <MDBCol sm="12" lg="6">
                                <label
                                    htmlFor="defaultFormLoginEmailEx"
                                    className="black-text"
                                >
                                    Address Line 2
                                </label>
                                <input
                                    className="form-control"
                                    name="address2"
                                    id="address2"
                                    onChange={this.changeHandler}
                                    value={this.state.address2}
                                />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol sm="12" lg="12">
                                <label
                                    htmlFor="defaultFormLoginEmailEx"
                                    className="black-text"
                                >
                                    Country*
                                </label>
                                <div className="select-box">
                                    <CountryDropdown
                                        value={this.state.country}
                                        onChange={(val) =>
                                            this.selectCountry(val)
                                        }
                                        priorityOptions={["CAN", "USA", "GBR"]}
                                        valueType="short"
                                        countryvaluetype="short"
                                        className="form-control"
                                        id="selectCountry"
                                    />
                                </div>
                                {this.state.invalidCountry !== null ? (
                                    <div className="invalid">
                                        {this.state.invalidCountry}
                                    </div>
                                ) : (
                                    ""
                                )}
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol sm="12" lg="4">
                                <label
                                    htmlFor="defaultFormLoginEmailEx"
                                    className="black-text"
                                >
                                    City*
                                </label>
                                <input
                                    required
                                    name="city"
                                    id="city"
                                    className="form-control"
                                    onChange={this.changeHandler}
                                    value={this.state.city}
                                />
                                <div className="invalid-feedback">
                                    Please provide a valid city
                                </div>
                            </MDBCol>
                            <MDBCol sm="12" lg="4">
                                <label
                                    htmlFor="defaultFormLoginEmailEx"
                                    className="black-text"
                                >
                                    State*
                                </label>
                                <div className="select-box">
                                    <RegionDropdown
                                        country={this.state.country}
                                        value={this.state.region}
                                        onChange={(val) =>
                                            this.selectRegion(val)
                                        }
                                        valueType="short"
                                        className="form-control"
                                        id="selectState"
                                    />
                                </div>
                                {this.state.invalidState !== null ? (
                                    <div className="invalid">
                                        {this.state.invalidState}
                                    </div>
                                ) : (
                                    ""
                                )}
                            </MDBCol>
                            <MDBCol sm="12" lg="4">
                                <label
                                    htmlFor="defaultFormLoginEmailEx"
                                    className="black-text"
                                >
                                    Zip Code*
                                </label>
                                <input
                                    required
                                    name="postalcode"
                                    id="postalcode"
                                    className="form-control"
                                    onChange={this.changeHandler}
                                    value={this.state.postalcode}
                                />
                                <div className="invalid-feedback">
                                    Please provide a valid zipcode
                                </div>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol sm="12" lg="6">
                                <label
                                    htmlFor="defaultFormLoginEmailEx"
                                    className="black-text"
                                >
                                    VLAN
                                </label>
                                <input
                                    name="vlan"
                                    id="vlan"
                                    className="form-control"
                                    onChange={this.changeHandler}
                                    value={this.state.vlan}
                                />
                                <div className="invalid-feedback">
                                    Please provide a valid vlan (1 - 4095)
                                </div>
                            </MDBCol>
                            <MDBCol sm="12" lg="6">
                                <label
                                    htmlFor="defaultFormLoginEmailEx"
                                    className="black-text"
                                >
                                    Purpose*
                                </label>
                                <MDBSelect
                                    id="selectPurpose"
                                    getValue={this.handleSelectChangePurpose}
                                    className="form-control"
                                >
                                    <MDBSelectInput
                                        className="selectInput selectPurpose"
                                        selected="Select"
                                    />
                                    <MDBSelectOptions search>
                                        <MDBSelectOption value="wiredunit">
                                            Residential Unit
                                        </MDBSelectOption>
                                        <MDBSelectOption value="localwirelessonly">
                                            Guest Subscription
                                        </MDBSelectOption>
                                        <MDBSelectOption value="globalroaming">
                                            Roaming Device
                                        </MDBSelectOption>
                                    </MDBSelectOptions>
                                </MDBSelect>
                                <div className="invalid-feedback">
                                    Please provide a purpose.
                                </div>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol sm="12" lg="6">
                                <label
                                    htmlFor="defaultFormLoginEmailEx"
                                    className="black-text"
                                >
                                    SMS Code
                                </label>
                                <input
                                    name="smsCode"
                                    id="smsCode"
                                    className="form-control"
                                    value={this.state.smsCode}
                                    onChange={this.changeHandler}
                                    maxlength="6"
                                    pattern="[a-zA-Z0-9]*"
                                />
                                <div
                                    className="invalid-feedback"
                                    id="smsCodeFeedback"
                                >
                                    Please provide a valid value (alphanumeric
                                    characters only).
                                </div>
                            </MDBCol>
                            <MDBCol sm="12" lg="6"></MDBCol>
                        </MDBRow>
                        {this.state.selectedPurpose === "globalroaming" &&
                        this.props.optionTemplateServicePlans !== undefined ? (
                            <>
                                <MDBRow>
                                    <MDBCol sm="12" lg="12">
                                        <label
                                            htmlFor="defaultFormLoginEmailEx"
                                            className="black-text"
                                        >
                                            Service Plan
                                        </label>
                                        <SelectSearch
                                            ref={this.servicePlanSelect}
                                            id="selectServicePlan"
                                            search={true}
                                            filterOptions={fuzzySearch}
                                            options={
                                                this.props
                                                    .optionTemplateServicePlans
                                            }
                                            onChange={this.handleSelectPlan}
                                            isOptionSelected={true}
                                            inputId="floorArray"
                                            classNamePrefix="floo-select"
                                            placeholder="Select..."
                                            emptyMessage="Select..."
                                            value={
                                                this.state.selectedServicePlan
                                            }
                                        />
                                        <div className="invalid-feedback">
                                            Please provide a valid plan
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                            </>
                        ) : (
                            ""
                        )}
                        <div className="text-right submitButton">
                            <MDBBtn color="danger" type="submit">
                                {this.props.loadingUnit === true ? (
                                    <div
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                    >
                                        <span className="sr-only">
                                            Loading...
                                        </span>
                                    </div>
                                ) : (
                                    "Add Unit"
                                )}
                            </MDBBtn>
                        </div>
                    </form>
                </MDBModalBody>
            </>
        );
    }
}
export default withTranslation()(AddVenue);
