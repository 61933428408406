import React, { Component } from "react";

import {
    MDBBtn,
    MDBModalBody,
    MDBModalHeader,
    MDBRow,
    MDBCol,
    MDBInput,
    MDBTooltip,
    MDBIcon,
} from "mdbreact";

import { withTranslation } from "react-i18next";

class AddShoppingCart extends Component {
    constructor(props) {
        super(props);

        this.state = {
            enabled: this.props.shoppingCart["enableShoppingCart"],
            publishableKey:
                this.props.shoppingCart["Stripe"]["StripePublishableKey"],
            secretKey: undefined,
            signingSecret: undefined,
            refund: this.props.shoppingCart["Stripe"]["Stripe_allowrefund"],
            shoppingCartShortURL: this.props.shoppingCartShortURL,
        };
    }

    submitAddShoppingCart = (event) => {
        event.preventDefault();
        event.target.className += " was-validated";

        if (this.state.publishableKey !== undefined) {
            this.props.submitAddShoppingCart(
                this.state.enabled,
                this.state.publishableKey,
                this.state.secretKey,
                this.state.signingSecret,
                this.state.refund,
                this.state.shoppingCartShortURL
            );
        }
    };

    changeHandler = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };

    toggleCheckboxValueEnable = () => {
        this.setState({ enabled: !this.state.enabled });
    };

    toggleCheckboxValueRefund = () => {
        this.setState({ refund: !this.state.refund });
    };

    render() {
        const { t } = this.props;
        return (
            <>
                <MDBModalHeader toggle={this.props.toggleAddShoppingCart}>
                    <h4>
                        {this.state.publishableKey !== undefined
                            ? "Modify Shopping Cart"
                            : "Add Shopping Cart"}
                    </h4>
                    <p>{t("addnewresident.required")}</p>
                </MDBModalHeader>
                <MDBModalBody>
                    <form
                        className="needs-validation"
                        onSubmit={this.submitAddShoppingCart}
                        noValidate
                    >
                        <MDBInput
                            label="Enabled/Disabled"
                            type="checkbox"
                            id="enabled"
                            name="enabled"
                            onChange={this.toggleCheckboxValueEnable}
                            checked={this.state.enabled}
                        />
                        <MDBInput
                            label="Issue Refund By Default"
                            type="checkbox"
                            id="refund"
                            name="refund"
                            onChange={this.toggleCheckboxValueRefund}
                            checked={this.state.refund}
                        >
                            <MDBTooltip
                                material
                                placement="left"
                                className="rightTooltip"
                                style={{ display: "block" }}
                            >
                                <MDBIcon far icon="question-circle" />
                                <span>
                                    When enabled, if a venue admin or subscriber
                                    cancels their service, a pro-rata refund
                                    will be automatically applied to the
                                    subscriber’s payment method. Network Admins
                                    will be able to choose if refunds should be
                                    issued at all times
                                </span>
                            </MDBTooltip>
                        </MDBInput>
                        <br />
                        <MDBRow>
                            <MDBCol col="6" sm="12" lg="12">
                                <label
                                    htmlFor="defaultFormLoginEmailEx"
                                    className="black-text"
                                >
                                    Stripe Publishable Key*
                                </label>
                                <input
                                    required
                                    id="publishableKey"
                                    name="publishableKey"
                                    className="form-control"
                                    onChange={this.changeHandler}
                                    value={this.state.publishableKey}
                                />
                                <div className="invalid-feedback">
                                    Please provide a value.
                                </div>
                            </MDBCol>
                            <MDBCol col="6"></MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol col="6" sm="12" lg="12">
                                <label
                                    htmlFor="defaultFormLoginEmailEx"
                                    className="black-text"
                                >
                                    Stripe Secret Key
                                </label>
                                <input
                                    id="secretKey"
                                    name="secretKey"
                                    className="form-control"
                                    onChange={this.changeHandler}
                                    value={this.state.secretKey}
                                />
                                <div className="invalid-feedback">
                                    Please provide a value.
                                </div>
                            </MDBCol>
                            <MDBCol col="6"></MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol col="12">
                                <label
                                    htmlFor="defaultFormLoginEmailEx"
                                    className="black-text"
                                >
                                    Stripe Webhook Signing Secret
                                </label>
                                <input
                                    name="signingSecret"
                                    id="signingSecret"
                                    className="form-control"
                                    onChange={this.changeHandler}
                                    value={this.state.signingSecret}
                                />
                                <div className="invalid-feedback">
                                    Please provide a value.
                                </div>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol col="12">
                                <label
                                    htmlFor="defaultFormLoginEmailEx"
                                    className="black-text"
                                >
                                    Shopping Cart Short URL
                                </label>
                                <input
                                    name="shoppingCartShortURL"
                                    id="shoppingCartShortURL"
                                    className="form-control"
                                    onChange={this.changeHandler}
                                    value={this.state.shoppingCartShortURL}
                                />
                                <div className="invalid-feedback">
                                    Please provide a value.
                                </div>
                            </MDBCol>
                        </MDBRow>

                        <div className="text-right submitButton">
                            <MDBBtn color="danger" type="submit">
                                {this.props.add_shopping_cart === true ? (
                                    <div
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                    >
                                        <span className="sr-only">
                                            Loading...
                                        </span>
                                    </div>
                                ) : this.props.shoppingCart["Stripe"][
                                      "StripePublishableKey"
                                  ] !== undefined ? (
                                    "Save Changes"
                                ) : (
                                    "Add Shopping Cart"
                                )}
                            </MDBBtn>
                            <MDBBtn
                                color="primary"
                                onClick={this.props.toggleAddShoppingCart}
                            >
                                Close
                            </MDBBtn>
                        </div>
                    </form>
                </MDBModalBody>
            </>
        );
    }
}

export default withTranslation()(AddShoppingCart);
