import React, { Component } from "react";
import {
    MDBBtn,
    MDBSpinner,
    MDBModal,
    MDBModalBody,
    MDBModalHeader,
    MDBModalFooter,
    MDBRow,
    MDBCol,
    toast,
    ToastContainer,
} from "mdbreact";
import RecentResidentsTable from "../../../components/VenueMgmt/RecentResidents/Datatables/RecentResidentsTable";
import AllRecentResidentsTable from "../../../components/VenueMgmt/RecentResidents/Datatables/AllRecentResidentsTable";
import ModifyResident from "../../../components/VenueMgmt/Forms/ModifyResident/ModifyResident";
import axios from "../../../axios";
import _ from "lodash";
import { Search, Grid } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
const initialState = { isLoading: false, results: [], value: "" };
let getResults;
let source;
let source1 = [];
class RecentResidents extends Component {
    constructor(props) {
        super(props);
        this.state = {
            active: true,
            recentResidents: this.props.recentResidents,
            allRecentResidents: this.props.allRecentResidents,
            venueName: this.props.venueName,
            loading: this.props.loading,
        };
    }
    toggleModifyResident = () => {
        this.setState({
            modalModifyResident: !this.state.modalModifyResident,
        });
    };
    toggleModifyService = () => {
        this.setState({
            modalModifyServiceInfo: !this.state.modalModifyServiceInfo,
        });
    };
    componentDidMount() {
        this.getVenueDetails();
    }
    getVenueDetails = (index, venueName) => {
        if (venueName === undefined) {
            venueName = this.props.venueName;
        }
        if (index === undefined) {
            index = this.props.venueID;
        }
        let body = {
            AccessToken: sessionStorage.getItem("AccessToken"),
            VenueID: index,
            UserTimeZone: new Date().getTimezoneOffset(),
        };
        let key = [];
        let iteration = 0;
        let lastName = [];
        let firstName = [];
        let unitID = [];
        let keyID = [];
        let email = [];
        let startDate = [];
        let contactpref_email = [];
        let contactpref_sms = [];
        let phoneNumber = [];
        let locale = [];
        let endDate = [];
        let floor = [];
        let building = [];
        let ssid = [];
        let servicePlanID = [];
        let servicePlan = [];
        let vlan = [];
        let residentStatus = [];
        let unitID_internal = [];
        let realpageSyncDate = [];
        let subscriberManagedviaSSO = [];
        let paymentFailed = [];
        let RealPageUnitID = [];
        let subscriptionDiscount = [];
        let venueDiscount = [];
        let externalSubscriberID = [];
        let selectedServicePlanPrice = [];
        let setupIntentID = [];
        source1 = [];
        this.setState({
            recentResidents: undefined,
            allRecentResidents: undefined,
            activeData: undefined,
            expiringData: undefined,
            searchData: undefined,
            value: "",
        });
        axios
            .post("/venuemgmtconsole/venues/listVenueDetails", body, {
                headers: {},
            })
            .then((response) => {
                let sortedMostRecentSubscriptionData = _(
                    response.data[index][
                        "RecentlyStartedSubscriptionsList_7day"
                    ]
                )
                    .map(function (v, k) {
                        // insert the key into the object
                        return _.merge({}, v, { key: k });
                    })
                    .sortBy("StartDateTimeUTC.ISO8601")
                    .reverse() // sort by name
                    .value();
                let sortedSubscriptionData = _(
                    response.data[index]["Subscriptions"]
                )
                    .map(function (v, k) {
                        // insert the key into the object
                        return _.merge({}, v, { key: k });
                    })
                    .sortBy("StartDateTimeUTC.ISO8601")
                    .reverse() // sort by name
                    .value();
                this.setState({
                    activeData: sortedMostRecentSubscriptionData,
                    recentResidents: sortedMostRecentSubscriptionData,
                    allRecentResidents: sortedSubscriptionData,
                });
                this.setState({
                    address1: response.data[index]["AddressLine1"],
                    address2: response.data[index]["AddressLine2"],
                    city: response.data[index]["City"],
                    state: response.data[index]["State"],
                    postalCode: response.data[index]["PostalCode"],
                    country: response.data[index]["Country.ISO3166A3"],
                    venueData: response.data[index],
                    activeData: sortedMostRecentSubscriptionData,
                    allActiveData: sortedSubscriptionData,
                    expiringData:
                        response.data[index][
                            "UpcomingSubscriptionEndList_7day_unconfirmed"
                        ],
                    searchData: response.data[index]["UnitsAndSubscriptions"],
                    keypoolID: Object.keys(response.data[index]["Keypools"]),
                    guestData: response.data[index]["GuestSubscriptions"],
                });
                if (this.props.toSearch === "1") {
                    key = Object.keys(
                        response.data[index]["UpcomingSubscriptionsList_1day"]
                    );
                } else if (this.props.toSearch === "7") {
                    key = Object.keys(
                        response.data[index]["UpcomingSubscriptionsList_7day"]
                    );
                } else {
                    key = Object.keys(response.data[index]["Subscriptions"]);
                }
                key.forEach((val) => {
                    if (
                        response.data[index]["Subscriptions"][key[iteration]][
                            "StartDateTimeUTC.ISO8601"
                        ] !== ""
                    ) {
                        lastName =
                            response.data[index]["Subscriptions"][
                                key[iteration]
                            ]["SubscriberFamilyName"];
                        firstName =
                            response.data[index]["Subscriptions"][
                                key[iteration]
                            ]["SubscriberGivenName"];
                        unitID =
                            response.data[index]["Subscriptions"][
                                key[iteration]
                            ]["UnitID"];
                        startDate =
                            response.data[index]["Subscriptions"][
                                key[iteration]
                            ]["StartDateTimeUTC.ISO8601"];
                        contactpref_email =
                            response.data[index]["Subscriptions"][
                                key[iteration]
                            ]["contactpref_email"];
                        contactpref_sms =
                            response.data[index]["Subscriptions"][
                                key[iteration]
                            ]["contactpref_sms"];
                        phoneNumber =
                            response.data[index]["Subscriptions"][
                                key[iteration]
                            ]["SubscriberPhoneNumber.E164"].toString();
                        locale =
                            response.data[index]["Subscriptions"][
                                key[iteration]
                            ]["SubscriberLocale"];
                        endDate =
                            response.data[index]["Subscriptions"][
                                key[iteration]
                            ]["EndDateTimeUTC.ISO8601"];
                        floor =
                            response.data[index]["Subscriptions"][
                                key[iteration]
                            ]["UnitFloor"];
                        building =
                            response.data[index]["Subscriptions"][
                                key[iteration]
                            ]["UnitBuilding"];
                        email =
                            response.data[index]["Subscriptions"][
                                key[iteration]
                            ]["SubscriberEmail"];
                        ssid =
                            response.data[index]["Subscriptions"][
                                key[iteration]
                            ]["ssid"];
                        keyID = key[iteration];
                        servicePlanID =
                            response.data[index]["Subscriptions"][
                                key[iteration]
                            ]["ServicePlan"]["ServicePlanID"];
                        servicePlan =
                            response.data[index]["Subscriptions"][
                                key[iteration]
                            ]["ServicePlan"]["ServicePlanName"];
                        vlan =
                            response.data[index]["Subscriptions"][
                                key[iteration]
                            ]["vlan"];
                        residentStatus =
                            response.data[index]["Subscriptions"][
                                key[iteration]
                            ]["Status"];
                        unitID_internal =
                            response.data[index]["Subscriptions"][
                                key[iteration]
                            ]["UnitID_internal"];
                        realpageSyncDate =
                            response.data[index]["Subscriptions"][
                                key[iteration]
                            ]["RealPageSyncDateTimeUTC.ISO8601"];
                        subscriberManagedviaSSO =
                            response.data[index]["Subscriptions"][
                                key[iteration]
                            ]["SubscriberManagedviaSSO"];
                        paymentFailed =
                            response.data[index]["Subscriptions"][
                                key[iteration]
                            ]["paymentFailed"];
                        RealPageUnitID =
                            response.data[index]["Subscriptions"][
                                key[iteration]
                            ]["RealPageUnitID"];
                        subscriptionDiscount =
                            response.data[index]["Subscriptions"][
                                key[iteration]
                            ]["VenueUnitShoppingCartPriceAdjustment"];
                            venueDiscount =
                                response.data[index]["Subscriptions"][
                                    key[iteration]
                                ]["VenueShoppingCartPriceAdjustment"];
                            externalSubscriberID =
                                response.data[index]["Subscriptions"][
                                    key[iteration]
                                ]["externalSubscriberID"];
                            selectedServicePlanPrice =
                                response.data[index]["Subscriptions"][
                                    key[iteration]
                                ]["ListBillingCyclePrice"];
                        setupIntentID =
                            response.data[index]["Subscriptions"][
                                key[iteration]
                            ]["SetupIntentId"];
                        getResults = () =>
                            _.times(1, () => ({
                                firstName: firstName,
                                lastName: lastName,
                                unitID: unitID,
                                startDate: startDate,
                                contactpref_email: contactpref_email,
                                contactpref_sms: contactpref_sms,
                                phoneNumber: phoneNumber,
                                locale: locale,
                                endDate: endDate,
                                floor: floor,
                                building: building,
                                title: firstName + " " + lastName,
                                description: `Unit: ${unitID} \r\nBuilding: ${building}, Floor: ${floor} \r\n${email}`,
                                keyid: keyID,
                                email: email,
                                ssid: ssid,
                                servicePlanID: servicePlanID,
                                servicePlan: servicePlan,
                                vlan: vlan,
                                status: residentStatus,
                                unitID_internal: unitID_internal,
                                realpageSyncDate: realpageSyncDate,
                                subscriberManagedviaSSO:
                                    subscriberManagedviaSSO,
                                paymentFailed: paymentFailed,
                                RealPageUnitID: RealPageUnitID,
                                subscriptionDiscount: subscriptionDiscount,
                                venueDiscount: venueDiscount,
                                    externalSubscriberID: externalSubscriberID,
                                    selectedServicePlanPrice:
                                        selectedServicePlanPrice,
                                setupIntentID: setupIntentID,
                            }));
                        source = _.range(0, 3).reduce((memo1) => {
                            const name = val;
                            // eslint-disable-next-line no-param-reassign
                            memo1 = {
                                name,
                                results: getResults(),
                            };
                            return memo1;
                        }, {});
                        source1.push(source);
                        iteration = iteration + 1;
                    }
                });
                this.props.updateName(venueName, index);
            })
            .catch((error) => {
                this.getVenueDetails(index);
                this.setState({ error: true });
            });
    };
    refresh = () => {
        this.setState({ refreshed: true });
    };
    handleSearchChange = (e, { value }) => {
        this.setState({ isLoading: true, value });
        let re = "";
        setTimeout(() => {
            this.setState({ results: "" });
            if (this.state.value.length < 1) return this.setState(initialState);
            re = new RegExp(_.escapeRegExp(this.state.value), "i");
            const isMatch = (result) =>
                re.test(result.title + result.description + result.email);
            this.setState({
                filteredResults: _.reduce(
                    source1,
                    (memo2, data, name) => {
                        let results = _.filter(data.results, isMatch);
                        if (results.length || results[0] !== undefined)
                            memo2[name] = { name, results }; // eslint-disable-line no-param-reassign
                        return memo2;
                    },
                    {}
                ),
            });
            this.setState({
                isLoading: false,
                results: this.state.filteredResults,
            });
        }, 300);
    };
    handleResultSelect = (e, { result }) => {
        this.setState({ SubscriberGivenName: result.firstName });
        this.setState({ SubscriberFamilyName: result.lastName });
        this.setState({ UnitID: result.unitID });
        this.setState({ StartDateTime: result.startDate });
        this.setState({ contactpref_email: result.contactpref_email });
        this.setState({ contactpref_sms: result.contactpref_sms });
        this.setState({ SubscriberPhoneNumber: result.phoneNumber.toString() });
        this.setState({ SubscriberLocale: result.locale });
        this.setState({ EndDateTime: result.endDate });
        this.setState({ UnitFloor: result.floor });
        this.setState({ UnitBuilding: result.building });
        this.setState({ UnitBuilding: result.building });
        this.setState({ SubscriberEmail: result.email });
        this.setState({ ssid: result.ssid });
        this.setState({ redirect: true });
        this.setState({ value: result.title });
        this.setState({ servicePlanID: result.servicePlanID });
        this.setState({ servicePlan: result.servicePlan });
        this.setState({ vlan: result.vlan });
        this.setState({ status: result.status });
        this.setState({
            subscriberManagedviaSSO: result.subscriberManagedviaSSO,
        });
        this.setState({ realpageSyncDate: result.realpageSyncDate });
        this.setState({
            modalModifyResident: !this.state.modalModifyResident,
        });
        this.setState({ unitID_internal: result.unitID_internal });
        this.setState({ paymentFailed: result.paymentFailed });
        this.setState({ RealPageUnitID: result.RealPageUnitID });
        this.setState({
            modalModifyResident: !this.state.modalModifyResident,
        });
        this.setState({ paymentFailed: result.paymentFailed });
        this.setState({ subscriptionDiscount: result.subscriptionDiscount });
        this.setState({ venueDiscount: result.venueDiscount });
        this.setState({
            selectedServicePlanPrice: result.selectedServicePlanPrice,
        });
        this.setState({ setupIntentID: result.setupIntentID });
    };
    modifyResident = (key) => {
        this.setState({
            modalModifyResident: !this.state.modalModifyResident,
            recentResidents: this.props.recentResidents,
        });
        this.setState({
            SubscriberGivenName: key.SubscriberGivenName,
            SubscriberFamilyName: key.SubscriberFamilyName,
            SubscriberEmail: key.SubscriberEmail,
            SubscriberPhoneNumber: key["SubscriberPhoneNumber.E164"].toString(),
            contactpref_email: key.contactpref_email,
            contactpref_sms: key.contactpref_sms,
            SubscriberLocale: key.SubscriberLocale,
            UnitFloor: key.UnitFloor,
            UnitBuilding: key.UnitBuilding,
            SSID: key.ssid,
            StartDateTime: key["StartDateTimeUTC.ISO8601"],
            EndDateTime: key["EndDateTimeUTC.ISO8601"],
            UnitID: key.UnitID,
            unitID_internal: key.UnitID_internal,
            servicePlanID: key.ServicePlan["ServicePlanID"],
            servicePlan: key.ServicePlan["ServicePlanName"],
            status: key.Status,
            realpageSyncDate: key["RealPageSyncDateTimeUTC.ISO8601"],
            subscriberManagedviaSSO: key["SubscriberManagedviaSSO"],
            StripeClientSecret: key["StripeClientSecret"],
            paymentFailed: key["paymentFailed"],
            RealPageUnitID: key["RealPageUnitID"],
            country: key["Country.ISO3166A3"],
            subscriptionDiscount: key["VenueUnitShoppingCartPriceAdjustment"],
            venueDiscount: key["VenueShoppingCartPriceAdjustment"],
            externalSubscriberID: key["externalSubscriberID"],
            selectedServicePlanPrice: key["ListBillingCyclePrice"],
            setupIntentID: key.setupIntentID,
        });
    };
    submitModify = (
        email,
        firstName,
        lastName,
        phoneNumber,
        language,
        contactpref_email,
        contactpref_sms,
        recentResidents,
        dateStart,
        oldDateStart,
        dateEnd,
        oldDateEnd,
        venueID,
        unitID,
        building,
        floor,
        servicePlanID,
        isUpdated,
        isUpdatedServicePlan,
        isUpdatedStartDate,
        isUpdatedEndDate,
        isUpdatedSubscriptionDiscount,
        unitID_internal,
        selectedServicePlanName,
        subscriberToken,
        updatedUnit,
        subscriptionDiscount,
        setupIntentID
    ) => {
        this.setState({ loading: true });
        let bodyDateStart = {
            AccessToken: sessionStorage.getItem("AccessToken"),
            EmailAddress: email,
            VenueID: venueID,
            UnitID_internal: unitID_internal,
            "StartDateTimeUTC.ISO8601": new Date(oldDateStart)
                .toISOString()
                .replace(/:00.\d+Z$/g, "Z"),
            "NewStartDateTimeUTC.ISO8601": new Date(dateStart)
                .toISOString()
                .replace(/:00.\d+Z$/g, "Z"),
            UserTimeZone: new Date().getTimezoneOffset(),
            SubscriberToken: subscriberToken,
        };
        let bodyDateEnd = {
            AccessToken: sessionStorage.getItem("AccessToken"),
            EmailAddress: email,
            VenueID: venueID,
            UnitID_internal: unitID_internal,
            "StartDateTimeUTC.ISO8601": new Date(oldDateStart).toISOString(),
            "NewEndDateTimeUTC.ISO8601":
                dateEnd !== undefined
                    ? new Date(dateEnd).toISOString().replace(/:00.\d+Z$/g, "Z")
                    : undefined,
            UserTimeZone: new Date().getTimezoneOffset(),
            SubscriberToken: subscriberToken,
        };
        let bodyServicePlan = {
            AccessToken: sessionStorage.getItem("AccessToken"),
            EmailAddress: email,
            VenueID: venueID,
            UnitID_internal: unitID_internal,
            "CurrentStartDateTimeUTC.ISO8601": new Date(
                oldDateStart
            ).toISOString(),
            NewServicePlanID: servicePlanID,
            UserTimeZone: new Date().getTimezoneOffset(),
            SubscriberToken: subscriberToken,
        };
        let bodyUpdatePII = {
            AccessToken: sessionStorage.getItem("AccessToken"),
            EmailAddress: email,
            GivenName: firstName,
            FamilyName: lastName,
            "PhoneNumber.E164": phoneNumber,
            locale: language,
            contactpref_email: true,
            contactpref_sms: contactpref_sms,
            VenueID: venueID,
            SubscriberToken: subscriberToken,
        };
        let bodyUpdateUnit = {
            AccessToken: sessionStorage.getItem("AccessToken"),
            StripeClientSecret: sessionStorage.getItem(
                "stripeClientSecretSubscriber"
            ),
            SetupIntentId: setupIntentID,
            NewUnitID_internal: unitID_internal,
            VenueID: venueID,
        };
        let bodyUpdateSubscriberPrice = {
            AccessToken: sessionStorage.getItem("AccessToken"),
            StripeClientSecret: sessionStorage.getItem(
                "stripeClientSecretSubscriber"
            ),
            SetupIntentId: setupIntentID,
            NewVenueUnitPriceAdjustment: -Math.abs(subscriptionDiscount) * 100,
            "NewEndDateTimeUTC.ISO8601":
                dateEnd !== undefined
                    ? new Date(dateEnd).toISOString().replace(/:00.\d+Z$/g, "Z")
                    : "",
        };
        Promise.all([
            new Date(dateStart) > new Date() && isUpdatedStartDate === true
                ? axios.post(
                      "/venuemgmtconsole/subscribers/resetSubscriptionStartDate",
                      bodyDateStart,
                      { headers: {} }
                  )
                : console.log(
                      "🚀 ~ file: ViewModifyResidents.js:483 ~ RecentResidents ~ bodyDateStart",
                      bodyDateStart
                  ),
            dateEnd !== undefined && isUpdatedEndDate === true
                ? axios.post(
                      "/venuemgmtconsole/subscribers/resetSubscriptionEndDate",
                      bodyDateEnd,
                      { headers: {} }
                  )
                : console.log(
                      "🚀 ~ file: ViewModifyResidents.js:491 ~ RecentResidents ~ bodyDateEnd",
                      bodyDateEnd
                  ),
            isUpdated === true
                ? axios.post(
                      "/venuemgmtconsole/subscribers/updateSubscriberPII",
                      bodyUpdatePII,
                      { headers: {} }
                  )
                : console.log(
                      "🚀 ~ file: ViewModifyResidents.js:499 ~ RecentResidents ~ bodyUpdatePII",
                      bodyUpdatePII
                  ),
            isUpdatedServicePlan === true
                ? axios.post(
                      "/venuemgmtconsole/subscribers/modifyServicePlan",
                      bodyServicePlan,
                      { headers: {} }
                  )
                : console.log(
                      "🚀 ~ file: ViewModifyResidents.js:507 ~ RecentResidents ~ bodyServicePlan",
                      bodyServicePlan
                  ),
            updatedUnit === true
                ? axios.post(
                      "/venuemgmtconsole/subscribers/modifyVenueUnit",
                      bodyUpdateUnit,
                      { headers: {} }
                  )
                : console.log(
                      "🚀 ~ file: ViewVenueSubscribers.js:229 ~ ViewVenueSubscribers ~ bodyUpdateUnit",
                      bodyUpdateUnit
                  ),
            isUpdatedSubscriptionDiscount === true
                ? axios.post(
                      "/venuemgmtconsole/subscribers/modifySubscriptionPrice",
                      bodyUpdateSubscriberPrice,
                      { headers: {} }
                  )
                : console.log(
                      "🚀 ~ file: ViewVenueSubscribers.js:229 ~ ViewVenueSubscribers ~ bodyUpdateSubscriberPrice",
                      bodyUpdateSubscriberPrice
                  ),
        ])
            .then(async ([res1, res2, res3, res4, res5, res6]) => {
                toast.success("Updated Resident!", {
                    closeButton: false,
                });
                this.toggleModifyResident();
                this.setState({ recentResidents: undefined });
                this.getVenueDetails(this.props.venueID, this.props.venueName);
                if (isUpdatedServicePlan === true) {
                    this.setState({
                        loading: false,
                        nextInvoiceAdjustedAmount:
                            res4.data["NextInvoiceAdjustedAmount.USD"] ===
                            undefined
                                ? ""
                                : res4.data["NextInvoiceAdjustedAmount.USD"] /
                                  100,
                        nextInvoiceDate:
                            res4.data["NextInvoiceDateTimeUTC.ISO8601"],
                        billingCycleAdjustment:
                            res4.data["BillingCyclePriceAdjustment"] ===
                            undefined
                                ? ""
                                : res4.data["BillingCyclePriceAdjustment"] /
                                  100,
                        subscriberName: firstName + " " + lastName,
                        subscriberEmail: email,
                        newServicePlanName: selectedServicePlanName,
                        servicePlanID: servicePlanID,
                        paymentFailed: res4.data["paymentFailed"],
                    });
                    sessionStorage.setItem(
                        "servicePlanIDSubscriber",
                        servicePlanID
                    );
                    this.toggleModifyService();
                }
                this.setState({ loading: false });
            })
            .catch((error) => {
                toast.error("There was an error!", {
                    closeButton: false,
                });
                this.toggleModifyResident();
                this.getVenueDetails(this.props.venueID, this.props.venueName);
                this.setState({ recentResidents: recentResidents });
                this.setState({ loading: false });
            });
    };
    deleteResident = (
        email,
        venueID,
        unitID,
        building,
        floor,
        recentResidents,
        oldDateStart,
        unitID_internal,
        cancellationReason
    ) => {
        this.setState({ loadingDelete: true });
        let body = {
            AccessToken: sessionStorage.getItem("AccessToken"),
            EmailAddress: email,
            VenueID: venueID,
            UnitID_internal: unitID_internal,
            "StartDateTimeUTC.ISO8601": oldDateStart,
            "NewEndDateTimeUTC.ISO8601": oldDateStart,
            endImmediately: true,
            UserTimeZone: new Date().getTimezoneOffset(),
            CancellationReasons: cancellationReason,
        };
        axios
            .post(
                "/venuemgmtconsole/subscribers/resetSubscriptionEndDate",
                body,
                {
                    headers: {},
                }
            )
            .then((response) => {
                toast.success("Deleted Resident!", {
                    closeButton: false,
                });
                this.toggleModifyResident();
                this.getVenueDetails(this.props.venueID, this.props.venueName);
                this.setState({ loadingDelete: false });
            })
            .catch((error) => {
                toast.error("There was an error!", {
                    closeButton: false,
                });
                this.setState({ error: true });
                this.setState({ loadingDelete: false });
            });
    };
    render() {
        if (this.state.postMessage1) {
            var h = document.getElementById("overlayViewModifyResident");
            h.style.display = "block";
            setTimeout(function () {
                h.style.display = "block";
            }, 5000);
            setTimeout(() => {
                h.style.display = "none";
                this.setState({ postMessage1: "" });
            }, 6000);
        }
        const { t } = this.props;
        const { isLoading, value, results } = this.state;
        return (
            <>
                <ToastContainer
                    hideProgressBar={true}
                    newestOnTop={true}
                    autoClose={10000}
                />
                <MDBModal
                    isOpen={this.state.modalModifyServiceInfo}
                    toggle={this.toggle}
                    size="md"
                    className="form"
                    centered
                >
                    <MDBModalHeader
                        toggle={
                            this.state.paymentFailed
                                ? ""
                                : this.toggleModifyService
                        }
                    >
                        <h4>Modified Service Information</h4>
                    </MDBModalHeader>
                    <MDBModalBody>
                        <div>
                            <MDBRow>
                                <MDBCol sm="6" lg="4">
                                    <p>Subscriber:</p>
                                </MDBCol>
                                <MDBCol sm="6" lg="8">
                                    {this.state.subscriberName}
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol sm="6" lg="4">
                                    <p>Email:</p>
                                </MDBCol>
                                <MDBCol sm="6" lg="8">
                                    {this.state.subscriberEmail}
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol sm="6" lg="4">
                                    <p>New Service Plan:</p>
                                </MDBCol>
                                <MDBCol sm="6" lg="8">
                                    {this.state.newServicePlanName}
                                </MDBCol>
                            </MDBRow>
                            {this.state.nextInvoiceDate !== undefined ? (
                                <>
                                    <MDBRow>
                                        <MDBCol sm="6" lg="4">
                                            <p>Next Invoice Date:</p>
                                        </MDBCol>
                                        <MDBCol sm="6" lg="8">
                                            {this.state.nextInvoiceDate !==
                                            undefined
                                                ? Intl.DateTimeFormat("en-US", {
                                                      year: "numeric",
                                                      month: "2-digit",
                                                      day: "2-digit",
                                                  }).format(
                                                      new Date(
                                                          this.state.nextInvoiceDate
                                                      )
                                                  )
                                                : ""}
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol sm="6" lg="4">
                                            <p>Next Invoice Amount:</p>
                                        </MDBCol>
                                        <MDBCol sm="6" lg="8">
                                            $
                                            {
                                                this.state
                                                    .nextInvoiceAdjustedAmount
                                            }
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow className="align-items-end">
                                        <MDBCol sm="6" lg="4">
                                            <p>
                                                Billing Cycle Adjustment Amount:
                                            </p>
                                        </MDBCol>
                                        <MDBCol sm="6" lg="8">
                                            ${this.state.billingCycleAdjustment}
                                        </MDBCol>
                                    </MDBRow>
                                </>
                            ) : (
                                ""
                            )}
                        </div>
                    </MDBModalBody>
                    <MDBModalFooter>
                        {this.state.paymentFailed ? (
                            <MDBBtn
                                color="danger"
                                className="btn Ripple-parent btn-default centered upgradePlan"
                                href={
                                    "/update/checkout?ui=" +
                                    window.location.pathname.slice(1)
                                }
                            >
                                Modify Billing CC
                            </MDBBtn>
                        ) : (
                            <MDBBtn
                                color="danger"
                                onClick={this.toggleModifyService}
                                type="submit"
                            >
                                OK
                            </MDBBtn>
                        )}
                    </MDBModalFooter>
                </MDBModal>
                <MDBModal
                    isOpen={this.state.modalModifyResident}
                    toggle={this.toggle}
                    size="fluid"
                    className="form"
                >
                    <ModifyResident
                        toggleModifyResident={this.toggleModifyResident}
                        firstName={this.state.SubscriberGivenName}
                        lastName={this.state.SubscriberFamilyName}
                        email={this.state.SubscriberEmail}
                        phoneNumber={this.state.SubscriberPhoneNumber}
                        receivetext={this.state.contactpref_email}
                        receivephone={this.state.contactpref_sms}
                        contactpref_sms={this.state.contactpref_sms}
                        contactpref_email={this.state.contactpref_email}
                        language={this.state.SubscriberLocale}
                        floor={this.state.UnitFloor}
                        building={this.state.UnitBuilding}
                        startDate={this.state.StartDateTime}
                        endDate={this.state.EndDateTime}
                        unitID={this.state.UnitID}
                        submitModify={this.submitModify}
                        submitDelete={this.deleteResident}
                        venueID={this.props.venueID}
                        recentResidents={this.state.recentResidents}
                        loading={this.state.loading}
                        SSID={this.state.SSID}
                        unitID_internal={this.state.unitID_internal}
                        servicePlanID={this.state.servicePlanID}
                        servicePlan={this.state.servicePlan}
                        availablePlans={this.props.availablePlans}
                        status={this.state.status}
                        loadingDelete={this.state.loadingDelete}
                        realpageSyncDate={this.state.realpageSyncDate}
                        subscriberManagedviaSSO={
                            this.state.subscriberManagedviaSSO
                        }
                        StripeClientSecret={this.state.StripeClientSecret}
                        paymentFailed={this.state.paymentFailed}
                        RealPageUnitID={this.state.RealPageUnitID}
                        country={this.state.country}
                        subscriptionDiscount={this.state.subscriptionDiscount}
                        venueDiscount={this.state.venueDiscount}
                        externalSubscriberID={this.state.externalSubscriberID}
                        selectedServicePlanPrice={
                            this.state.selectedServicePlanPrice
                        }
                        setupIntentID={this.state.setupIntentID}
                        showUpdate={this.props.showUpdate}
                        cancelInputType={this.props.cancelInputType}
                    />
                </MDBModal>
                <MDBModalHeader toggle={this.props.toggleViewModifyResident}>
                    <h4>
                        {this.props.venueType === "HOTSPOT"
                            ? "View/Modify Local Policies"
                            : t("residentnav.viewmodify")}
                    </h4>
                </MDBModalHeader>
                <MDBModalBody>
                    <Grid>
                        <p className="pb-1">{t("quicksearch.title")}</p>
                        <Grid.Column width={16}>
                            <Search
                                category
                                loading={isLoading}
                                onResultSelect={this.handleResultSelect}
                                onSearchChange={_.debounce(
                                    this.handleSearchChange,
                                    500,
                                    {
                                        leading: true,
                                    }
                                )}
                                results={results}
                                placeholder={
                                    this.props.venueType === "HOTSPOT"
                                        ? "Local Policy Name, Unit ID or Email"
                                        : t("quicksearch.label")
                                }
                                value={value}
                                className="pb-2"
                            />
                        </Grid.Column>
                    </Grid>
                    <div className="expandCollapseModify">
                        <p
                            onClick={() =>
                                this.setState({ active: !this.state.active })
                            }
                        >
                            {this.state.active
                                ? t("recentservice.showless")
                                : t("recentservice.showmore")}
                        </p>
                    </div>
                    {this.state.recentResidents !== undefined &&
                    this.state.allRecentResidents !== undefined ? (
                        <>
                            <div
                                className={
                                    this.state.active
                                        ? "recentresidentsExpanded"
                                        : "recentresidents"
                                }
                            >
                                <RecentResidentsTable
                                    recentresidents={this.state.recentResidents}
                                    refreshed={this.props.refreshed}
                                    modifyResident={this.modifyResident}
                                />
                            </div>
                            <div
                                className={
                                    this.state.active
                                        ? "allrecentresidentsExpanded"
                                        : "allrecentresidents"
                                }
                            >
                                <AllRecentResidentsTable
                                    allrecentresidents={
                                        this.state.allRecentResidents
                                    }
                                    refreshed={this.props.refreshed}
                                    modifyResident={this.modifyResident}
                                />
                            </div>
                        </>
                    ) : (
                        <MDBSpinner small />
                    )}
                </MDBModalBody>
            </>
        );
    }
}
export default withTranslation()(RecentResidents);
