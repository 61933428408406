import React from "react";

import { MDBDataTableV5 } from "mdbreact";

export default function Basic(props) {
    let userAttributes;
    let i = 0;

    let datatable;
    [datatable] = React.useState("");

    if (props.propMgmtGroupList !== undefined) {
        userAttributes = [];
        for (const key of Object.keys(props.propMgmtGroupList)) {
            userAttributes.push({
                groupName:
                    props.propMgmtGroupList[key]["PropertyManagementGroupName"],
                groupAddress: (
                    <>
                        <p>{props.propMgmtGroupList[key]["AddressLine1"]}</p>
                        {props.propMgmtGroupList[key]["AddressLine2"] !== "" ? (
                            <p>
                                {props.propMgmtGroupList[key]["AddressLine2"]}
                            </p>
                        ) : (
                            ""
                        )}
                        <p>
                            {props.propMgmtGroupList[key]["City"]},{" "}
                            {props.propMgmtGroupList[key]["State"]}{" "}
                            {props.propMgmtGroupList[key]["PostalCode"]}
                        </p>
                    </>
                ),
                groupEmail: props.propMgmtGroupList[key]["EmailAddress"],
                groupPhone: props.propMgmtGroupList[key]["PhoneNumber.E164"],
                groupMgmtURL: (
                    <>
                        <a
                            href={props.propMgmtGroupList[key]["ManagementURL"]}
                            target="_blank"
                            rel="noreferrer"
                        >
                            Link
                        </a>
                    </>
                ),
                groupDateAdded:
                    props.propMgmtGroupList[key]["dateAddedUTC.ISO8601"] !== ""
                        ? Intl.DateTimeFormat("en-CA", {
                              year: "numeric",
                              month: "2-digit",
                              day: "2-digit",
                              hour: "numeric",
                              minute: "numeric",
                          }).format(
                              new Date(
                                  props.propMgmtGroupList[key][
                                      "dateAddedUTC.ISO8601"
                                  ]
                              )
                          )
                        : "",
            });
            i = i + 1;
        }
    }

    datatable = {
        columns: [
            {
                label: "Group Name",
                field: "groupName",
            },
            {
                label: "Address",
                field: "groupAddress",
            },
            {
                label: "Email",
                field: "groupEmail",
            },
            {
                label: "Phone",
                field: "groupPhone",
            },
            {
                label: "Management URL",
                field: "groupMgmtURL",
            },
            {
                label: "Date Added",
                field: "groupDateAdded",
            },
        ],
        rows: userAttributes,
    };
    return (
        <MDBDataTableV5
            sortable={true}
            responsive
            hover
            data={datatable}
            searchTop={true}
            searchBottom={false}
            searchLabel="Filter"
            paging={false}
            className="recentResidents"
            info={false}
            noRecordsFoundLabel="No Venues Found"
            sortRows={["activated"]}
        />
    );
}
