import React, { Component } from "react";
import {
    MDBRow,
    MDBCol,
    MDBBtn,
    MDBSpinner,
    MDBModal,
    MDBModalHeader,
    MDBModalBody,
    MDBModalFooter,
    toast,
    MDBIcon,
} from "mdbreact";
import ModifyStaffTable from "../../../components/VenueMgmt/PropertyInfo/Datatables/ModifyStaffTable";
import AddStaff from "../../../components/VenueMgmt/Forms/AddStaff/AddStaff";
import { withTranslation } from "react-i18next";
import axios from "../../../axios";
class ModifyStaff extends Component {
    constructor(props) {
        super(props);
        this.state = {
            active: false,
            staffArrayAll: undefined,
            modalAddStaff: false,
            loading: false,
        };
    }
    componentDidMount() {
        this.getVenueDetails(this.props.venueID);
    }
    getVenueDetails = (index) => {
        let body = {
            AccessToken: sessionStorage.getItem("AccessToken"),
            VenueID: index,
            UserTimeZone: new Date().getTimezoneOffset(),
        };
        this.setState({
            venueAdmins: undefined,
            leasingAgents: undefined,
            staffArrayAll: undefined,
        });
        axios
            .post("/venuemgmtconsole/venues/listVenueDetails", body, {
                headers: {},
            })
            .then((response) => {
                this.setState({
                    venueAdmins: response.data[index]["VenueAdministrators"],
                    leasingAgents: response.data[index]["LeasingAgents"],
                });
                this.getAdmins();
            })
            .catch((error) => {
                this.getVenueDetails(index);
                this.setState({ error: true });
            });
    };
    getAdmins = () => {
        this.setState({ staffArrayAll: undefined });
        let staffArrayAll = [];
        let staffArrayVA = [];
        let staffArrayLeasing = [];
        for (var i = 0; i < this.state.venueAdmins.length; i++) {
            staffArrayVA[i] = {
                [this.state.venueAdmins[i]]: "Venue Administrator",
            };
        }
        for (var j = 0; j < this.state.leasingAgents.length; j++) {
            staffArrayLeasing[j] = {
                [this.state.leasingAgents[j]]: "Leasing Agent",
            };
        }
        staffArrayAll = staffArrayVA.concat(staffArrayLeasing);
        this.setState({ staffArrayAll: [...staffArrayAll] });
    };
    toggleConfirmDisable = (key) => {
        this.setState({
            toggleConfirm: true,
            modal: !this.state.modal,
            confirmEmail: key[0],
        });
    };
    toggleDisable = (email) => {
        this.setState({ staffArrayAll: undefined });
        let body = {
            AccessToken: sessionStorage.getItem("AccessToken"),
            VenueID: this.props.venueID,
            EmailAddress: email,
        };
        axios
            .post("/venuemgmtconsole/staff/disableStaffAccess", body, {
                headers: {},
            })
            .then(
                (response) => {
                    toast.success("Deleted", {
                        closeButton: false,
                    });
                    this.setState({
                        modal: !this.state.modal,
                    });
                    this.props.updateName(
                        this.props.venueNameSelected,
                        this.props.venueID
                    );
                    this.getVenueDetails(this.props.venueID);
                },
                (error) => {
                    toast.error("There was an error!", {
                        closeButton: false,
                    });
                }
            );
    };
    toggle = () => {
        this.setState({
            modal: !this.state.modal,
        });
    };
    toggleAddStaff = () => {
        this.setState({
            modalAddStaff: !this.state.modalAddStaff,
        });
    };
    submitHandler = (
        dateEnd,
        email,
        firstName,
        lastName,
        phoneNumber,
        language,
        contactpref_email,
        contactpref_sms,
        role,
        venueid
    ) => {
        let body = {
            AccessToken: sessionStorage.getItem("AccessToken"),
            VenueID: venueid,
            EmailAddress: email,
            GivenName: firstName,
            FamilyName: lastName,
            "PhoneNumber.E164": phoneNumber,
            locale: language,
            contactpref_email: true,
            contactpref_sms: contactpref_sms,
            Role: role,
        };
        this.setState({ loading: true });
        axios
            .post("/venuemgmtconsole/staff/enableStaffAccess", body, {
                headers: {},
            })
            .then(
                (response) => {
                    toast.success("Added staff member!", {
                        closeButton: false,
                    });
                    this.toggleAddStaff();
                    this.props.updateName(
                        this.props.venueNameSelected,
                        this.props.venueID
                    );
                    this.getVenueDetails(this.props.venueID);
                    this.setState({ loading: false });
                },
                (error) => {
                    toast.error("There was an error!", {
                        closeButton: false,
                    });
                    this.toggleAddStaff();
                    this.setState({ loading: false });
                }
            );
    };
    render() {
        return (
            <>
                {this.state.toggleConfirm ? (
                    <MDBModal isOpen={this.state.modal} toggle={this.toggle}>
                        <MDBModalHeader toggle={this.toggle}>
                            Confirm Delete
                        </MDBModalHeader>
                        <MDBModalBody>
                            <p>Email: {this.state.confirmEmail}</p>
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn
                                color="primary"
                                onClick={() =>
                                    this.toggleDisable(this.state.confirmEmail)
                                }
                            >
                                Confirm
                            </MDBBtn>
                            <MDBBtn color="primary" onClick={this.toggle}>
                                Close
                            </MDBBtn>
                        </MDBModalFooter>
                    </MDBModal>
                ) : null}
                <MDBModalHeader toggle={this.props.toggleModifyStaff}>
                    <h4>Modify Staff</h4>
                    <div className="addProfile" onClick={this.toggleAddStaff}>
                        <h5>
                            <MDBIcon icon="plus-circle" /> Add Staff
                        </h5>
                    </div>
                </MDBModalHeader>
                <MDBModalBody>
                    <MDBRow>
                        <MDBCol col="6">
                            {this.state.staffArrayAll !== undefined ? (
                                <ModifyStaffTable
                                    venueAdmins={this.props.venueAdmins}
                                    staff={this.state.staffArrayAll}
                                    toggleDisable={this.toggleConfirmDisable}
                                />
                            ) : (
                                <MDBSpinner small blue />
                            )}
                        </MDBCol>
                    </MDBRow>
                </MDBModalBody>
                <MDBModal
                    isOpen={this.state.modalAddStaff}
                    toggle={this.toggle}
                    size="lg"
                    className="form"
                    centered
                >
                    <AddStaff
                        toggleAddStaff={this.toggleAddStaff}
                        submitHandler={this.submitHandler}
                        venueID={this.props.venueID}
                        loading={this.state.loading}
                    />
                </MDBModal>
            </>
        );
    }
}
export default withTranslation()(ModifyStaff);
