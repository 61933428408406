import * as React from "react";
import {
    DataGrid,
    GridToolbarQuickFilter,
    GridToolbarContainer,
    GridToolbarExport,
} from "@mui/x-data-grid";
import { MDBBtn } from "mdbreact";
import Box from "@mui/material/Box";
import Popper from "@mui/material/Popper";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { useTranslation } from "react-i18next";
function QuickSearchToolbar() {
    return (
        <Box
            sx={{
                p: 0.5,
                pb: 0,
            }}
        >
            <GridToolbarContainer>
                <GridToolbarQuickFilter />
                <GridToolbarExport
                    csvOptions={{
                        fileName: "unitList",
                        delimiter: ",",
                        utf8WithBom: true,
                        fields: [
                            "unitID",
                            "building",
                            "floor",
                            "name",
                            "email",
                            "status",
                            "start",
                            "end",
                            "statustier",
                        ],
                    }}
                />
            </GridToolbarContainer>
        </Box>
    );
}
function isOverflown(element) {
    return (
        element.scrollHeight > element.clientHeight ||
        element.scrollWidth > element.clientWidth
    );
}
const GridCellExpand = React.memo(function GridCellExpand(props) {
    const { width, value } = props;
    const wrapper = React.useRef(null);
    const cellDiv = React.useRef(null);
    const cellValue = React.useRef(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [showFullCell, setShowFullCell] = React.useState(false);
    const [showPopper, setShowPopper] = React.useState(false);
    const handleMouseEnter = () => {
        const isCurrentlyOverflown = isOverflown(cellValue.current);
        setShowPopper(isCurrentlyOverflown);
        setAnchorEl(cellDiv.current);
        setShowFullCell(true);
    };
    const handleMouseLeave = () => {
        setShowFullCell(false);
    };
    React.useEffect(() => {
        if (!showFullCell) {
            return undefined;
        }
        function handleKeyDown(nativeEvent) {
            // IE11, Edge (prior to using Bink?) use 'Esc'
            if (nativeEvent.key === "Escape" || nativeEvent.key === "Esc") {
                setShowFullCell(false);
            }
        }
        document.addEventListener("keydown", handleKeyDown);
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [setShowFullCell, showFullCell]);
    return (
        <Box
            ref={wrapper}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            sx={{
                alignItems: "center",
                lineHeight: "24px",
                width: "100%",
                height: "100%",
                position: "relative",
                display: "flex",
            }}
        >
            <Box
                ref={cellDiv}
                sx={{
                    height: "100%",
                    width,
                    display: "block",
                    position: "absolute",
                    top: 0,
                }}
            />
            <Box
                ref={cellValue}
                sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                }}
            >
                {value}
            </Box>
            {showPopper && (
                <Popper
                    open={showFullCell && anchorEl !== null}
                    anchorEl={anchorEl}
                    style={{ width, marginLeft: -17 }}
                >
                    <Paper
                        elevation={1}
                        style={{ minHeight: wrapper.current.offsetHeight - 3 }}
                    >
                        <Typography variant="body2" style={{ padding: 8 }}>
                            {value}
                        </Typography>
                    </Paper>
                </Popper>
            )}
        </Box>
    );
});
function renderCellExpand(params) {
    return (
        <GridCellExpand
            value={params.value || ""}
            width={displayTextWidth(params.value, ".9rem Proxima Nova Rg")}
        />
    );
}
function displayTextWidth(text, font) {
    let canvas =
        displayTextWidth.canvas ||
        (displayTextWidth.canvas = document.createElement("canvas"));
    let context = canvas.getContext("2d");
    context.font = font;
    let metrics = context.measureText(text);
    return metrics.width + 25;
}
export default function Basic(props) {
    let userAttributes;
    let i = 0;
    let columns = [];
    let rows = [];
    if (props.UnitsBuildingFloorListSusbcribers !== undefined) {
        userAttributes = [];
        let Filter = "";
        if (props.filter) {
            if (props.filter === "assigned") {
                Filter = "assigned";
            } else if (props.filter === "pendingAUP") {
                Filter = "pendingAUP";
            } else if (props.filter === "unassigned") {
                Filter = "unassigned";
            }
        }
        for (let unit_id of Object.keys(
            props.UnitsBuildingFloorListSusbcribers
        )) {
            for (let building_id of Object.keys(
                props.UnitsBuildingFloorListSusbcribers[unit_id]
            )) {
                for (let floor_id of Object.keys(
                    props.UnitsBuildingFloorListSusbcribers[unit_id][
                        building_id
                    ]
                )) {
                    let currentTier = "No Service";
                    let serviceStatus = "Unassigned";
                    let subStart = "N/A";
                    let subEnd = "N/A";
                    let subName = "N/A";
                    let subEmail = "N/A";
                    if (
                        Object.keys(
                            props.UnitsBuildingFloorListSusbcribers[unit_id][
                                building_id
                            ][floor_id]["Subscriptions"]
                        ).length !== 0
                    ) {
                        subStart = Intl.DateTimeFormat("en-CA", {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                            hour: "numeric",
                            minute: "numeric",
                        }).format(
                            new Date(
                                props.UnitsBuildingFloorListSusbcribers[
                                    unit_id
                                ][building_id][floor_id]["Subscriptions"][
                                    "StartDateTimeUTC.ISO8601"
                                ]
                            )
                        );
                        props.UnitsBuildingFloorListSusbcribers[unit_id][
                            building_id
                        ][floor_id]["Subscriptions"][
                            "EndDateTimeUTC.ISO8601"
                        ] !== ""
                            ? (subEnd = Intl.DateTimeFormat("en-CA", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                  hour: "numeric",
                                  minute: "numeric",
                              }).format(
                                  new Date(
                                      props.UnitsBuildingFloorListSusbcribers[
                                          unit_id
                                      ][building_id][floor_id]["Subscriptions"][
                                          "EndDateTimeUTC.ISO8601"
                                      ]
                                  )
                              ))
                            : (subEnd = "N/A");
                        currentTier =
                            props.UnitsBuildingFloorListSusbcribers[unit_id][
                                building_id
                            ][floor_id]["Subscriptions"]["ServicePlan"]
                                .ServicePlanName;
                        subEmail =
                            props.UnitsBuildingFloorListSusbcribers[unit_id][
                                building_id
                            ][floor_id]["Subscriptions"]["SubscriberEmail"];
                        subName =
                            props.UnitsBuildingFloorListSusbcribers[unit_id][
                                building_id
                            ][floor_id]["Subscriptions"][
                                "SubscriberGivenName"
                            ] +
                            " " +
                            props.UnitsBuildingFloorListSusbcribers[unit_id][
                                building_id
                            ][floor_id]["Subscriptions"][
                                "SubscriberFamilyName"
                            ];
                        serviceStatus =
                            props.UnitsBuildingFloorListSusbcribers[unit_id][
                                building_id
                            ][floor_id]["Subscriptions"]["Status"];
                        if (
                            Filter === "" ||
                            Filter === "assigned" ||
                            (Filter === "pendingAUP" &&
                                serviceStatus === "AUP not accepted")
                        ) {
                            userAttributes.push({
                                id: i,
                                unitID: unit_id,
                                building: building_id,
                                floor: floor_id,
                                name: subName,
                                email: subEmail,
                                start: subStart,
                                end: subEnd,
                                status: serviceStatus,
                                statustier: currentTier,
                                clickEvent: () =>
                                    props.modifyResident(
                                        props.UnitsBuildingFloorListSusbcribers[
                                            unit_id
                                        ][building_id][floor_id][
                                            "Subscriptions"
                                        ]
                                    ),
                            });
                            i++;
                            columns = [
                                {
                                    headerName: "Unit",
                                    field: "unitID",
                                    renderCell: renderCellExpand,
                                },
                                {
                                    headerName: "Building",
                                    field: "building",
                                    renderCell: renderCellExpand,
                                },
                                {
                                    headerName: "Floor",
                                    field: "floor",
                                    renderCell: renderCellExpand,
                                },
                                {
                                    headerName: "Name",
                                    field: "name",
                                    minWidth: 150,
                                    renderCell: renderCellExpand,
                                },
                                {
                                    headerName: "Email",
                                    field: "email",
                                    width: 200,
                                    renderCell: renderCellExpand,
                                },
                                {
                                    headerName: "Service Status",
                                    field: "status",
                                    minWidth: 100,
                                    renderCell: renderCellExpand,
                                },
                                {
                                    headerName: "Service Start",
                                    field: "start",
                                    minWidth: 100,
                                    renderCell: renderCellExpand,
                                },
                                {
                                    headerName: "Service End",
                                    field: "end",
                                    renderCell: renderCellExpand,
                                },
                                {
                                    headerName: "Service Tier",
                                    field: "statustier",
                                    renderCell: renderCellExpand,
                                },
                            ];
                            rows = userAttributes;
                        }
                    } else if (Filter === "" || Filter === "unassigned") {
                        userAttributes.push({
                            id: i,
                            unitID: unit_id,
                            building: building_id,
                            floor: floor_id,
                            name: subName,
                            email: subEmail,
                            start: subStart,
                            end: subEnd,
                            status: serviceStatus,
                            statustier: currentTier,
                        });
                        i++;
                        columns = [
                            {
                                headerName: "Unit",
                                field: "unitID",
                                flex: 0.1,
                                minWidth: 60,
                            },
                            {
                                headerName: "Building",
                                field: "building",
                                flex: 0.1,
                                minWidth: 80,
                            },
                            {
                                headerName: "Floor",
                                field: "floor",
                                flex: 0.1,
                                minWidth: 50,
                            },
                            {
                                headerName: "Name",
                                field: "name",
                                minWidth: 150,
                                renderCell: renderCellExpand,
                            },
                            {
                                headerName: "Email",
                                field: "email",
                                width: 200,
                                renderCell: renderCellExpand,
                            },
                            {
                                headerName: "Service Status",
                                field: "status",
                                flex: 0.1,
                                minWidth: 100,
                            },
                            {
                                headerName: "Service Start",
                                field: "start",
                                flex: 0.2,
                                minWidth: 50,
                            },
                            {
                                headerName: "Service End",
                                field: "end",
                                flex: 0.2,
                                minWidth: 50,
                            },
                            {
                                headerName: "Service Tier",
                                field: "statustier",
                                flex: 0.2,
                                minWidth: 50,
                            },
                        ];
                        rows = userAttributes;
                    }
                }
            }
        }
    }
    return (
        <>
            <div style={{ height: 300, width: "100%" }}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    //   columnVisibilityModel={{
                    //     // Hide columns status and traderName, the other columns will remain visible
                    //     unitID_internal: false
                    //     }}
                    onRowClick={
                        props.filter === "" || props.filter === "unassigned"
                            ? ""
                            : (id) =>
                                  props.modifyResident(
                                      props.UnitsBuildingFloorListSusbcribers[
                                          id.row["unitID"]
                                      ][id.row["building"]][id.row["floor"]][
                                          "Subscriptions"
                                      ]
                                  )
                    }
                    getRowHeight={({ id, densityFactor }) => {
                        return 40 * densityFactor;
                    }}
                    slots={{ toolbar: QuickSearchToolbar }}
                    hideFooterPagination={false}
                    hideFooter={false}
                />
            </div>
            {props.filter === "pendingAUP" ? (
                <form
                    className="needs-validation"
                    onSubmit={function (e) {
                        props.submitHandler(e, rows);
                    }}
                    noValidate
                >
                    <div className="text-right pt-4">
                        <MDBBtn color="danger" type="submit">
                            {props.loadingResend === true ? (
                                <div
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                >
                                    <span className="sr-only">Loading...</span>
                                </div>
                            ) : (
                                "Resend Activation to All"
                            )}
                        </MDBBtn>
                    </div>
                </form>
            ) : (
                ""
            )}
        </>
    );
}
