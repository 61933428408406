import React, { Component } from "react";

import { MDBRow, MDBCol, MDBTooltip, MDBModal } from "mdbreact";

import { withTranslation } from "react-i18next";

import ViewCoreEquipment from "../../../components/VenueMgmt/Support/ViewCoreEquipment";

import ViewcpeEquipment from "../../../components/VenueMgmt/Support/ViewcpeEquipment";

import SupportNavigation from "../Navigation/SupportNavigation/SupportNavigation";

import ViewOpenTickets from "../../../components/VenueMgmt/Support/ViewOpenTickets";

import brandsData from "../../../brands/common/brands.json";

class Support extends Component {
    constructor(props) {
        super(props);
        this.state = {
            active: false,
            loading: false,
        };
    }
    toggleCoreEquipment = () => {
        this.setState({
            modalCoreEquipment: !this.state.modalCoreEquipment,
        });
    };
    togglecpeEquipment = () => {
        this.setState({
            modalcpeEquipment: !this.state.modalcpeEquipment,
        });
    };
    toggleSupport = () => {
        this.setState({
            modalSupport: !this.state.modalSupport,
        });
    };
    toggleOpenTickets = () => {
        this.setState({
            modalOpenTickets: !this.state.modalOpenTickets,
        });
    };

    render() {
        let showDevices = "";
        let showSupport = "";
        brandsData.forEach(({ url, flag_showDevices, flag_showSupport }) => {
            if (url.includes(window.location.hostname)) {
                showDevices = flag_showDevices;
                showSupport = flag_showSupport;
            }
        });
        const { t } = this.props;
        let coreEquipmentStatus = "success-light";
        let coreEquipmentDown = 0;
        let cpeEquipmentStatus = "success-light";

        if (this.props.coreEquipmentState !== undefined) {
            if (
                this.props.coreEquipmentState["UP"] !==
                this.props.coreEquipmentState["TOTAL"]
            ) {
                coreEquipmentStatus = "error-light";
                coreEquipmentDown =
                    this.props.coreEquipmentState["TOTAL"] -
                    this.props.coreEquipmentState["UP"];
            }
        }
        if (this.props.cpeEquipmentState !== undefined) {
            let cpePercentDown =
                (this.props.cpeEquipmentState["DOWN"] /
                    this.props.cpeEquipmentState["TOTAL"]) *
                100;
            if (
                (this.props.cpeEquipmentState["DOWN"] !== undefined &&
                    cpePercentDown >= 10) ||
                this.props.cpeEquipmentState["DOWN"] > 50
            ) {
                cpeEquipmentStatus = "error-light";
            } else if (
                (this.props.cpeEquipmentState["DOWN"] !== undefined &&
                    cpePercentDown > 5 &&
                    cpePercentDown < 10) ||
                (this.props.cpeEquipmentState["DOWN"] > 30 &&
                    this.props.cpeEquipmentState["DOWN"] < 49)
            ) {
                cpeEquipmentStatus = "warning-light";
            }
        }
        return (
            <div>
                <MDBModal
                    isOpen={this.state.modalCoreEquipment}
                    toggle={this.toggleCoreEquipment}
                    size="fluid"
                    centered
                >
                    <ViewCoreEquipment
                        toggleCoreEquipment={this.toggleCoreEquipment}
                        selectedName={this.props.selectedName}
                        coreEquipment={this.props.coreEquipmentState}
                        venueList={this.props.venueList}
                    />
                </MDBModal>
                <MDBModal
                    isOpen={this.state.modalcpeEquipment}
                    toggle={this.togglecpeEquipment}
                    size="fluid"
                    centered
                >
                    <ViewcpeEquipment
                        togglecpeEquipment={this.togglecpeEquipment}
                        selectedName={this.props.selectedName}
                        cpeEquipment={this.props.cpeEquipmentState}
                        venueList={this.props.venueList}
                    />
                </MDBModal>
                <MDBModal
                    isOpen={this.state.modalOpenTickets}
                    toggle={this.toggleOpenTickets}
                    size="fluid"
                    className="form"
                    centered
                >
                    <ViewOpenTickets
                        toggleOpenTickets={this.toggleOpenTickets}
                        tickets={this.props.tickets}
                        venueID={this.props.selectedID}
                    />
                </MDBModal>
                <MDBModal
                    isOpen={this.state.modalSupport}
                    toggle={this.toggleSupport}
                    size="lg"
                    centered
                >
                    <SupportNavigation
                        toggleSupport={this.toggleSupport}
                        venueNameSelected={this.props.selectedName}
                        venueListName={this.props.venueListName}
                        updateName={this.props.updateName}
                        activeData={this.props.activeData}
                        tickets={this.props.tickets}
                        venueType={this.props.venueType}
                        coreEquipmentState={this.props.coreEquipmentState}
                        venueList={this.props.venueList}
                        venueID={this.props.selectedID}
                        updateSupport={this.props.updateSupport}
                    />
                </MDBModal>

                <div className="summaryCard">
                    <a href="#support" onClick={this.toggleSupport}>
                        <h2>{t("supportsummary.title")}</h2>
                    </a>

                    <div className="summaryCard support-step">
                        <div className="support rounded">
                            {this.props.ticketCount === undefined &&
                            this.props.coreEquipmentState !== undefined &&
                            this.props.coreEquipmentState["TOTAL"] === 0 &&
                            this.props.cpeEquipmentState !== undefined &&
                            this.props.cpeEquipmentState["TOTAL"] === 0 ? (
                                <MDBRow>
                                    <MDBCol md="9">
                                        <p>
                                            Support Information Not Available.
                                        </p>
                                    </MDBCol>
                                </MDBRow>
                            ) : showSupport ? (
                                <>
                                    <MDBRow>
                                        <MDBCol md="9">
                                            <a
                                                href="#support"
                                                onClick={this.toggleOpenTickets}
                                            >
                                                <p>
                                                    {t(
                                                        "supportsummary.opentickets"
                                                    )}
                                                </p>
                                            </a>
                                        </MDBCol>
                                        <MDBCol md="3">
                                            <a
                                                href="#support"
                                                onClick={this.toggleOpenTickets}
                                            >
                                                <p>
                                                    {this.props.ticketCount ===
                                                    undefined
                                                        ? 0
                                                        : this.props
                                                              .ticketCount}
                                                </p>
                                            </a>
                                        </MDBCol>
                                    </MDBRow>
                                </>
                            ) : (
                                ""
                            )}
                            {this.props.coreEquipmentState !== undefined &&
                            showDevices ? (
                                <>
                                    <div
                                        className={
                                            this.props.coreEquipmentState !==
                                                undefined &&
                                            this.props.coreEquipmentState[
                                                "TOTAL"
                                            ] > 0
                                                ? "visible"
                                                : "invisible"
                                        }
                                    >
                                        <MDBRow>
                                            <MDBCol md="9">
                                                <a
                                                    href="#support"
                                                    onClick={
                                                        this.toggleCoreEquipment
                                                    }
                                                >
                                                    <p>
                                                        {t(
                                                            "supportsummary.corestatus"
                                                        )}
                                                    </p>
                                                </a>
                                            </MDBCol>
                                            <MDBCol md="3">
                                                <a
                                                    href="#support"
                                                    onClick={
                                                        this.toggleCoreEquipment
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            coreEquipmentStatus
                                                        }
                                                    >
                                                        &nbsp;
                                                    </div>
                                                </a>
                                                <div className="tooltipSummary">
                                                    <MDBTooltip
                                                        placement="right"
                                                        domElement
                                                        style={{
                                                            width: "150vH",
                                                        }}
                                                    >
                                                        <a
                                                            href="#support"
                                                            onClick={
                                                                this
                                                                    .toggleCoreEquipment
                                                            }
                                                        >
                                                            <p>?</p>
                                                        </a>
                                                        <a
                                                            href="#support"
                                                            onClick={
                                                                this
                                                                    .toggleCoreEquipment
                                                            }
                                                        >
                                                            <div>
                                                                {
                                                                    coreEquipmentDown
                                                                }{" "}
                                                                of{" "}
                                                                {
                                                                    this.props
                                                                        .coreEquipmentState[
                                                                        "TOTAL"
                                                                    ]
                                                                }{" "}
                                                                down
                                                            </div>
                                                        </a>
                                                    </MDBTooltip>
                                                </div>
                                            </MDBCol>
                                        </MDBRow>
                                    </div>
                                </>
                            ) : (
                                ""
                            )}
                            <div
                                className={
                                    this.props.cpeEquipmentState &&
                                    this.props.cpeEquipmentState["TOTAL"] > 0 &&
                                    showDevices
                                        ? "visible"
                                        : "invisible"
                                }
                            >
                                <MDBRow>
                                    <MDBCol md="9">
                                        <a
                                            href="#support"
                                            onClick={this.togglecpeEquipment}
                                        >
                                            <p>
                                                {t(
                                                    "supportsummary.customerpremise"
                                                )}
                                            </p>
                                        </a>
                                    </MDBCol>
                                    <MDBCol md="3">
                                        <a
                                            href="#support"
                                            onClick={this.togglecpeEquipment}
                                        >
                                            <div className={cpeEquipmentStatus}>
                                                &nbsp;
                                            </div>
                                        </a>
                                        <div className="tooltipSummary">
                                            <MDBTooltip
                                                placement="right"
                                                domElement
                                                style={{ width: "150vH" }}
                                            >
                                                <a
                                                    href="#support"
                                                    onClick={
                                                        this.togglecpeEquipment
                                                    }
                                                >
                                                    <p>?</p>
                                                </a>
                                                <a
                                                    href="#support"
                                                    onClick={
                                                        this.togglecpeEquipment
                                                    }
                                                >
                                                    {this.props
                                                        .cpeEquipmentState &&
                                                    this.props
                                                        .cpeEquipmentState[
                                                        "DOWN"
                                                    ] !== undefined
                                                        ? this.props
                                                              .cpeEquipmentState[
                                                              "DOWN"
                                                          ]
                                                        : 0}{" "}
                                                    of{" "}
                                                    {this.props
                                                        .cpeEquipmentState &&
                                                    this.props
                                                        .cpeEquipmentState[
                                                        "TOTAL"
                                                    ] !== undefined
                                                        ? this.props
                                                              .cpeEquipmentState[
                                                              "TOTAL"
                                                          ]
                                                        : 0}{" "}
                                                    down
                                                </a>
                                            </MDBTooltip>
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(Support);
