import React, { Component } from "react";

export default class PasswordConditions extends Component {
  render() {
    return (
      <div className="conditions-wrapper">
        <ul>
          <li className={this.props.charLength ? "validCharacter" : "invalidCharacter"}>
            At least 8 characters
          </li>
          <li className={this.props.lowercase ? "validCharacter" : "invalidCharacter"}>
            At least 1 Lowercase Character
          </li>
          <li className={this.props.uppercase ? "validCharacter" : "invalidCharacter"}>
            At least 1 Uppercase Character
          </li>
          <li className={this.props.number ? "validCharacter" : "invalidCharacter"}>At least 1 Number</li>
          <li className={this.props.dot ? "validCharacter" : "invalidCharacter"}>May not contain "."</li>
        </ul>
      </div>
    );
  }
}
