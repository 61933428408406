import React from "react";

import { MDBDataTableV5 } from "mdbreact";

export default function Basic(props) {
    let userAttributes;
    let i = 0;

    let datatable;
    [datatable] = React.useState("");
    if (props.venueEquipList !== undefined) {
        userAttributes = [];
        for (const key of Object.keys(props.venueEquipList)) {
            userAttributes.push({
                id: props.venueEquipList[key]["EquipmentID"],
                type: props.venueEquipList[key]["EquipmentType"],
                status: props.venueEquipList[key]["Status"],
                lastSeen:
                    props.venueEquipList[key][
                        "statusupdateDateTimeUTC.ISO8601"
                    ] !== ""
                        ? Intl.DateTimeFormat("en-CA", {
                              year: "numeric",
                              month: "2-digit",
                              day: "2-digit",
                              hour: "numeric",
                              minute: "numeric",
                          }).format(
                              new Date(
                                  props.venueEquipList[key][
                                      "statusupdateDateTimeUTC.ISO8601"
                                  ]
                              )
                          )
                        : "",
                clickEvent: () => props.modifyEquipment(key),
            });
            i = i + 1;
        }
    }

    datatable = {
        columns: [
            {
                label: "Name",
                field: "id",
            },
            {
                label: "Type",
                field: "type",
            },
            {
                label: "Status",
                field: "status",
            },
            {
                label: "Last Status Update",
                field: "lastSeen",
            },
        ],
        rows: userAttributes,
    };
    return (
        <MDBDataTableV5
            sortable={true}
            responsive
            hover
            data={datatable}
            searchTop={true}
            searchBottom={false}
            searchLabel="Filter"
            paging={false}
            className="equipment"
            info={false}
            noRecordsFoundLabel="No Equipment Found"
            sortRows={["activated"]}
        />
    );
}
