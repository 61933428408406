import React, { Component } from "react";

import { MDBSpinner, MDBModalHeader, MDBModalBody } from "mdbreact";

import VenuePropMgmtGroupsTable from "../../../components/NetworkMgmt/Venues/Datatables/VenuePropMgmtGroupsTable";

import { withTranslation } from "react-i18next";

class ViewVenuePropMgmtGroups extends Component {
    constructor(props) {
        super(props);
        this.state = {
            active: false,
            loading: false,
        };
    }

    render() {
        return (
            <>
                <MDBModalHeader toggle={this.props.toggleViewVenuePropMgmtGroups}>
                    <h4>Manage Property Management Groups</h4>
                </MDBModalHeader>
                <MDBModalBody>
                    {this.props.propMgmtGroupList !== undefined ? (
                        <>
                            <div className="allrecentresidentsExpanded">
                                <VenuePropMgmtGroupsTable
                                    propMgmtGroupList={
                                        this.props.propMgmtGroupList
                                    }
                                    refreshed={this.props.refreshed}
                                />
                            </div>
                        </>
                    ) : (
                        <MDBSpinner small />
                    )}
                </MDBModalBody>
            </>
        );
    }
}

export default withTranslation()(ViewVenuePropMgmtGroups);
