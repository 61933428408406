import React, { Component } from "react";

import { toast } from "mdbreact";

import { withTranslation } from "react-i18next";

import axios from "../../../axios";

import ModifyTandC from "../../../components/NetworkMgmt/Forms/ModifyTandC/ModifyTandC";

class TandCInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading_info: false,
        };
    }

    componentDidMount() {
        this.getTandCInfo();
    }

    getTandCInfo = () => {
        let body = {
            AccessToken: sessionStorage.getItem("AccessToken"),
        };
        let tandcData = {};
        let language;
        this.setState({
            loading_info: true,
            operatorInfo: undefined,
        });
        axios
            .post(
                "/networkoperatorconsole/equipment/listOperatorDetails",
                body,
                {
                    headers: {},
                }
            )
            .then(
                (response) => {
                    for (
                        let i = 0;
                        i <
                        Object.keys(response.data["SupportedLocales"]).length;
                        i++
                    ) {
                        if (
                            Object.keys(response.data["SupportedLocales"])[
                                i
                            ] === "ex-xl"
                        ) {
                            language = "es-xl";
                        } else {
                            language = Object.keys(
                                response.data["SupportedLocales"]
                            )[i];
                        }
                        tandcData[language] = {};
                        tandcData[language]["TandC"] =
                            response.data["TandC"][language];
                    }

                    this.setState({ loading_info: false });
                },
                (error) => {
                    this.setState({ loading_info: false });
                }
            );
        this.setState({
            allTandCData: tandcData,
        });
    };

    submitModifyTandC = (language, tandc) => {
        this.setState({ modify_tandc: true });

        let body = {
            AccessToken: sessionStorage.getItem("AccessToken"),
            locale: language,
            TandC: tandc,
        };

        axios
            .post("/networkoperatorconsole/equipment/configureTandC", body, {
                headers: {},
            })
            .then(
                (response) => {
                    toast.success("Modified Terms and Conditions!", {
                        closeButton: false,
                    });
                    this.props.toggleTandCInfo();
                    this.setState({ modify_tandc: false });
                },
                (error) => {
                    toast.error("There was an error!", {
                        closeButton: false,
                    });
                    this.setState({ modify_tandc: false });
                }
            );
    };

    render() {
        return (
            <>
                {this.state.loading_info === true ? (
                    <div
                        className="overlay"
                        id="overlay"
                        style={{ display: "block" }}
                    >
                        <div className="overlayMessage">
                            <div
                                className="spinner-border spinner-border"
                                role="status"
                            >
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>
                ) : (
                    ""
                )}
                <ModifyTandC
                    allTandCData={this.state.allTandCData}
                    toggleTandCInfo={this.props.toggleTandCInfo}
                    submitModifyTandC={this.submitModifyTandC}
                    modify_tandc={this.state.modify_tandc}
                />
            </>
        );
    }
}

export default withTranslation()(TandCInfo);
