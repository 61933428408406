import React, { Component } from "react";

import { MDBBtn, MDBModalBody, MDBModalHeader, MDBRow, MDBCol } from "mdbreact";

import { withTranslation } from "react-i18next";

class AddRealPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            url: this.props.realPage["RealPageURL"],
            apikey: this.props.realPage["RealPageAPIKey"],
        };
    }

    submitAddRealPage = (event) => {
        event.preventDefault();
        event.target.className += " was-validated";

        if (this.state.url !== undefined && this.state.apikey !== undefined) {
            this.props.submitAddRealPage(this.state.url, this.state.apikey);
        }
    };

    changeHandler = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };

    render() {
        const { t } = this.props;
        return (
            <>
                <MDBModalHeader toggle={this.props.toggleAddRealPage}>
                    <h4>
                        {this.state.apikey !== undefined
                            ? "Modify RealPage"
                            : "Add RealPage"}
                    </h4>
                    <p>{t("addnewresident.required")}</p>
                </MDBModalHeader>
                <MDBModalBody>
                    <form
                        className="needs-validation"
                        onSubmit={this.submitAddRealPage}
                        noValidate
                    >
                        <MDBRow>
                            <MDBCol col="6" sm="12" lg="12">
                                <label
                                    htmlFor="defaultFormLoginEmailEx"
                                    className="black-text"
                                >
                                    RealPage URL*
                                </label>
                                <input
                                    required
                                    id="url"
                                    name="url"
                                    className="form-control"
                                    onChange={this.changeHandler}
                                    value={this.state.url}
                                />
                                <div className="invalid-feedback">
                                    Please provide a value.
                                </div>
                            </MDBCol>
                            <MDBCol col="6"></MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol col="6" sm="12" lg="12">
                                <label
                                    htmlFor="defaultFormLoginEmailEx"
                                    className="black-text"
                                >
                                    RealPage API Key*
                                </label>
                                <input
                                    required
                                    id="apikey"
                                    name="apikey"
                                    className="form-control"
                                    onChange={this.changeHandler}
                                    value={this.state.apikey}
                                />
                                <div className="invalid-feedback">
                                    Please provide a value.
                                </div>
                            </MDBCol>
                            <MDBCol col="6"></MDBCol>
                        </MDBRow>

                        <div className="text-right submitButton">
                            <MDBBtn color="danger" type="submit">
                                {this.props.add_realpage === true ? (
                                    <div
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                    >
                                        <span className="sr-only">
                                            Loading...
                                        </span>
                                    </div>
                                ) : this.props.realPage["RealPageAPIKey"] !==
                                  undefined ? (
                                    "Save Changes"
                                ) : (
                                    "Add RealPage"
                                )}
                            </MDBBtn>
                            <MDBBtn
                                color="primary"
                                onClick={this.props.toggleAddRealPage}
                            >
                                Close
                            </MDBBtn>
                        </div>
                    </form>
                </MDBModalBody>
            </>
        );
    }
}

export default withTranslation()(AddRealPage);
