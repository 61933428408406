import React, { Component } from "react";

import {
    MDBBtn,
    MDBModal,
    MDBModalBody,
    MDBModalHeader,
    MDBModalFooter,
    MDBRow,
    MDBCol,
    toast,
} from "mdbreact";

import axios from "../../../axios";

import "react-phone-number-input/style.css";

import PhoneInput, {
    isPossiblePhoneNumber,
    isValidPhoneNumber,
} from "react-phone-number-input";

import { Editor } from "@tinymce/tinymce-react";

import $ from "jquery";

class MyAccount extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: sessionStorage.getItem("email"),
            firstName: sessionStorage.getItem("firstName"),
            lastName: sessionStorage.getItem("lastName"),
            phoneNumber: sessionStorage.getItem("phoneNumber"),
            modalAddedTicket: false,
            loading1: false,
        };
        this.handleEditorChange = this.handleEditorChange.bind(this);
    }

    componentDidMount() {
        this.validateEmail(this.state.email);
    }

    changeHandler = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };

    changeHandlerPhone = (event) => {
        this.setState({ phoneNumber: event });
        var value = event
            ? isPossiblePhoneNumber(event)
                ? undefined
                : "Please provide a valid phone number"
            : "Phone number required";
        this.setState({ invalidPhone: value });
    };

    validateEmail = (email) => {
        var mailformat = /^[\w._-]+[+]?[\w._-]+@[\w.-]+\.[a-zA-Z]{2,6}$/;
        if (!email.match(mailformat)) {
            this.setState({
                invalidEmail: "Please provide a valid email address",
            });
            $(".emailInput").addClass("is-invalid");
        } else {
            this.setState({ invalidEmail: undefined });
            $(".emailInput").removeClass("is-invalid");
        }
    };

    toggleCheckboxValueConfirmations = () => {
        this.setState({ confirmations: !this.state.confirmations });
    };

    toggleCheckboxValueUpdates = () => {
        this.setState({ updates: !this.state.updates });
    };

    toggleCheckboxValueAgree = () => {
        this.setState({ agree: !this.state.agree });
    };

    handleEditorChange(description, editor) {
        this.setState({ description });
    }

    toggleAddedTicket = () => {
        this.setState({
            modalAddedTicket: !this.state.modalAddedTicket,
        });
    };

    submitHandler = async () => {
        if (this.state.invalidEmail === undefined) {
            this.setState({ loading1: true });

            let body = {
                AccessToken: sessionStorage.getItem("AccessToken"),
                CustomUnit: undefined,
                Description: this.state.description,
                PrefContactMethod: undefined,
                TicketSubType: this.state.type,
                TicketType: "Resident Support",
                VAULTID: this.props.venueID,
                VAULTUnitID: undefined,
                contactEmailAddress: this.state.email,
                contactName: this.state.firstName + " " + this.state.lastName,
                contactPhoneNumber: this.state.phoneNumber,
                serviceboardid: undefined,
                summary: this.state.summary,
                unitID: undefined,
            };
            await axios
                .post("/venuemgmtconsole/support/addTicket", body, {
                    headers: {},
                })
                .then(
                    (response) => {
                        this.setState({ loading1: false });
                        this.setState({
                            ticketID: response.data.id,
                        });
                        this.toggleAddedTicket();
                        this.props.updateSupport(this.props.venueID);
                    },
                    (error) => {
                        toast.error("There was an error!", {
                            closeButton: false,
                        });
                        this.setState({ loading1: false });
                    }
                );
        }
    };

    render() {
        const FORMAT = "MM/dd/yyyy";
        return (
            <>
                <MDBModal
                    isOpen={this.state.modalAddedTicket}
                    toggle={this.toggleAddedTicket}
                    size="sm"
                    className="smallModal"
                    centered
                >
                    <MDBModalHeader toggle={this.toggleAddedTicket}>
                        <h4>Ticket Added</h4>
                    </MDBModalHeader>
                    <MDBModalBody>
                        <div>
                            <p>Thank you!</p>
                            <p>
                                Ticket #{this.state.ticketID} has been
                                submitted.
                            </p>
                        </div>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn
                            color="danger"
                            onClick={this.props.toggleNewTicket}
                        >
                            Ok
                        </MDBBtn>
                    </MDBModalFooter>
                </MDBModal>
                <MDBModalHeader toggle={this.props.toggleNewTicket}>
                    <h4>Create New Support Ticket</h4>
                </MDBModalHeader>
                <MDBModalBody>
                    <form className="needs-validation" noValidate>
                        <MDBRow className="mb-2">
                            <MDBCol col="6">
                                <label
                                    htmlFor="defaultFormLoginEmailEx"
                                    className="black-text"
                                >
                                    I Need Help With My
                                </label>
                                <div className="select-box">
                                    <select
                                        className="browser-default custom-select"
                                        name="type"
                                        required
                                        onChange={this.changeHandler}
                                        value={this.state.type}
                                    >
                                        <option value="" disabled selected>
                                            Select
                                        </option>
                                        <option value="TV">TV</option>
                                        <option value="Internet">
                                            Internet
                                        </option>
                                        <option value="Account">Account</option>
                                    </select>
                                </div>
                            </MDBCol>
                            <MDBCol col="6"></MDBCol>
                        </MDBRow>
                        <MDBRow className="mb-2">
                            <MDBCol col="6">
                                <label
                                    htmlFor="defaultFormLoginEmailEx"
                                    className="black-text"
                                >
                                    Summary of Issue
                                </label>
                                <input
                                    required
                                    id="profile"
                                    className="form-control"
                                    onChange={this.changeHandler}
                                    name="summary"
                                    value={this.state.summary}
                                />
                                <div className="invalid-feedback">
                                    Please provide a valid profile name.
                                </div>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className="mb-2">
                            <MDBCol col="12">
                                <label htmlFor="exampleFormControlTextarea1">
                                    Description of Issue
                                </label>
                                <textarea
                                    className="form-control"
                                    id="description"
                                    name="description"
                                    rows="10"
                                    onChange={this.changeHandler}
                                    value={this.state.description}
                                ></textarea>
                                {/* <Editor
									apiKey="gw4td27mfhpoc1zr4q0yxusw050fnnxz00us4j5645mz285d"
									value={this.state.description}
									outputFormat="html"
									init={{
										height: 300,
										menubar: true,
										plugins: [
											"advlist autolink lists link image charmap print preview anchor",
											"searchreplace visualblocks code fullscreen",
											"insertdatetime media table paste code help wordcount",
										],
										toolbar:
											"undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help | code | image |inserttable | cell row column | tableprops deletetable ",
									}}
									onEditorChange={this.handleEditorChange}
								/> */}
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className="mb-2">
                            <MDBCol col="6">
                                <MDBRow>
                                    <MDBCol lg="4" middle>
                                        <p>Email Address</p>
                                    </MDBCol>
                                    <MDBCol lg="8">
                                        <input
                                            required
                                            id="email"
                                            type="email"
                                            className="form-control emailInput"
                                            onBlur={(event) =>
                                                this.validateEmail(
                                                    this.state.email
                                                )
                                            }
                                            onChange={(event) =>
                                                this.setState({
                                                    email: event.target.value,
                                                })
                                            }
                                            value={this.state.email}
                                            error={
                                                this.state.invalidEmail !==
                                                undefined
                                                    ? "Invalid email address"
                                                    : "Email address required"
                                            }
                                        />
                                        {this.state.invalidEmail !==
                                        undefined ? (
                                            <div className="invalid">
                                                {this.state.invalidEmail}
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </MDBCol>
                                </MDBRow>
                            </MDBCol>
                            <MDBCol col="6">
                                <MDBRow>
                                    <MDBCol lg="4" middle>
                                        <p>Phone Number</p>
                                    </MDBCol>
                                    <MDBCol lg="8">
                                        <PhoneInput
                                            value={this.state.phoneNumber}
                                            format={FORMAT}
                                            onChange={this.changeHandlerPhone}
                                            name="phoneNumber"
                                            id="phoneNumber"
                                            className={
                                                this.state.loading === true
                                                    ? "form-control loading"
                                                    : "form-control"
                                            }
                                            defaultCountry="US"
                                            required
                                            error={
                                                this.state.phoneNumber
                                                    ? isValidPhoneNumber(
                                                          this.state.phoneNumber
                                                      )
                                                        ? undefined
                                                        : "Invalid phone number"
                                                    : "Phone number required"
                                            }
                                            disabled={this.state.userFound}
                                        />
                                    </MDBCol>
                                </MDBRow>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className="mb-2">
                            <MDBCol col="6">
                                <MDBRow>
                                    <MDBCol lg="4" middle>
                                        <p>First Name</p>
                                    </MDBCol>
                                    <MDBCol lg="8">
                                        <input
                                            required
                                            id="profile"
                                            className="form-control"
                                            onChange={this.changeHandler}
                                            name="firstName"
                                            value={this.state.firstName}
                                        />
                                        <div className="invalid-feedback">
                                            Please provide a valid profile name.
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                            </MDBCol>
                            <MDBCol col="6">
                                <MDBRow>
                                    <MDBCol lg="4" middle>
                                        <p>Last Name</p>
                                    </MDBCol>
                                    <MDBCol lg="8">
                                        <input
                                            required
                                            id="profile"
                                            className="form-control"
                                            onChange={this.changeHandler}
                                            name="lastName"
                                            value={this.state.lastName}
                                        />
                                        <div className="invalid-feedback">
                                            Please provide a valid profile name.
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                            </MDBCol>
                        </MDBRow>
                    </form>
                </MDBModalBody>
                <MDBModalFooter>
                    <MDBBtn
                        color="danger"
                        onClick={this.submitHandler}
                        type="submit"
                    >
                        {this.state.loading1 === true ? (
                            <div
                                className="spinner-border spinner-border-sm"
                                role="status"
                            >
                                <span className="sr-only">Loading...</span>
                            </div>
                        ) : (
                            "Submit"
                        )}
                    </MDBBtn>
                </MDBModalFooter>
            </>
        );
    }
}

export default MyAccount;
