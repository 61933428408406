import React, { Component } from "react";

import axios from "../../../axios";

import SelectSearch, { fuzzySearch } from "react-select-search";

import {
    MDBModalBody,
    MDBModalHeader,
    MDBRow,
    MDBCol,
    MDBIcon,
    MDBModal,
    toast,
    MDBModalFooter,
    MDBBtn,
    MDBTabPane,
    MDBTabContent,
    MDBNav,
    MDBNavItem,
    MDBNavLink,
} from "mdbreact";

import JSONPretty from "react-json-pretty";

import $ from "jquery";

const API_Server = "https://tools.roamingiq.com:3001";
var AccessToken = "";
class PSKValidator extends Component {
    //const API_Server="http://localhost:3001";
    constructor(props) {
        super(props);
        this.state = {
          activeItem: "1",
          selectedID: this.props.selectedVenue,
          venueName: this.props.venueName,
          nasID: this.props.nasID,
          selectSSID: this.props.keySSID,
          psk: this.props.psk,
          venueInfo: this.props.venueInfo
        };
    }
    componentDidMount() {
        this.getVenues();
        if(this.props.selectedVenue!==undefined)
        this.handleSelectVenue(this.props.selectedVenue, this.props.venueName)
    }

    toggleTab = (tab) => () => {
      if (this.state.activeItem !== tab) {
          this.setState({
              activeItem: tab,
          });
      }
  };
    getVenues = () => {
        let body = { AccessToken: sessionStorage.getItem("AccessToken") };
        axios
            .post("/networkoperatorconsole/venues/listVenues", body, {
                headers: {},
            })
            .then((response) => {
                let optionTemplateVenues = [];
                optionTemplateVenues.push({
                    value: "",
                    name: "Please Select...",
                });
                Object.keys(response.data.VenueList).map((oneKey, i) => {
                    optionTemplateVenues.push({
                        value: oneKey,
                        name: response.data.VenueList[oneKey]["VenueName"],
                    });
                    return true;
                });
                this.setState({
                    optionTemplateVenues: optionTemplateVenues,
                    loading_prop_groups: false,
                });
            })
            .catch((error) => {
                this.setState({ error: true });
            });
    };
    resetValues(all) {
        console.log(all);
        if (all) {
            $("#sel_Venue")
                .find("option")
                .remove()
                .end()
                .append(
                    '<option value="Select" disabled selected>Select Venue</option'
                )
                .val("Select");
            $("#sel_Venue").attr("disabled", true);
        }

        $("#sel_SSID")
            .find("option")
            .remove()
            .end()
            .append(
                '<option value="Select" disabled selected>Select SSID</option'
            )
            .val("Select");
        $("#sel_SSID").attr("disabled", true);
        //Not going to reset PSK just in case user wants to try different Venues/SSIDs.
        $("#txtPSK").attr("disabled", true);
        $("#btn_Test").attr("disabled", true);

        $("#txtNasIdentifier").val("NAS-Identifier");
        $("#txtNasIdentifier").attr("disabled", true);
    }
    btn_Connect = async () => {
        if ($("#txt_username").val() == "") {
            alert("Enter Username.");
        } else if ($("#txt_password").val() == "") {
            alert("Enter Password.");
        } else if ($("#txt_subscriberConsole").val() == "") {
            alert("Enter Subscriber Console.");
        } else {
            let data = {
                Username: $("#txt_username").val(),
                Password: $("#txt_password").val(),
                SubscriberConsole: $("#txt_subscriberConsole").val(),
                BaseURL: $("#sel_Environment").val(),
            };
            let responseData;
            try {
                let url = API_Server + "/login";
                responseData = await fetch(url, {
                    method: "POST",
                    body: JSON.stringify(data),
                });
            } catch (error) {
                alert("ERROR: " + error);
            }
            var bodyA = await responseData.json();
            if (bodyA["ERROR"]) {
                alert("There was an error: " + bodyA["ERROR"]);
            } else {
                if (bodyA["AuthenticationResult"]) {
                    AccessToken =
                        bodyA["AuthenticationResult"]["AuthenticationResult"][
                            "AccessToken"
                        ];
                    console.log("Access Token: " + AccessToken);
                }
            }
        }
        console.log("AccessToken: " + AccessToken);
        if (AccessToken != "") {
            $("#sel_Venue")
                .find("option")
                .remove()
                .end()
                .append(
                    '<option value="Select" disabled selected>Select Venue</option'
                )
                .val("Select");

            $("#sel_Venue").attr("disabled", true);
            let venueListData;
            try {
                let url = API_Server + "/VAULT/V1/GetVenues";
                let VenueCond = {
                    AccessToken: AccessToken,
                    SubscriberConsole: $("#txt_subscriberConsole").val(),
                    BaseURL: $("#sel_Environment").val(),
                };
                venueListData = await fetch(url, {
                    method: "POST",
                    body: JSON.stringify(VenueCond),
                });
            } catch (error) {
                alert("Error: " + error);
            }
            var venueList = await venueListData.json();
            Object.keys(venueList["VenueList"]).forEach((venue) => {
                $("#sel_Venue").append(
                    $("<option>", {
                        value: venue,
                        text: venueList["VenueList"][venue]["VenueName"],
                    })
                );
                $("#sel_Venue").removeAttr("disabled");
            });
            $("#ConnectCreds").modal("hide");
        } else {
            console.log("Error Logging In.");
        }
    };

    handleSelectVenue = (value, name) => {
        this.setState({
            selectVenue: value,
            selectedID: value,
        });
         this.props.updateName(name, value);
        if (this.props.venueInfo !== undefined) {
          console.log(this.props.venueInfo[
            "NAS-Identifier"
        ])
        this.setState({
          nasID: this.props.venueInfo[
            "NAS-Identifier"
        ]
        })
      }
        let optionTemplateSSID = [];
        if (this.props.keyPoolList !== undefined) {
            optionTemplateSSID.push({
                value: "",
                name: "Please Select...",
            });
            this.props.keyPoolList.map((oneKey, i) => {
                optionTemplateSSID.push({
                    value: this.props.keyPoolList[i]["ssid"],
                    name: this.props.keyPoolList[i]["ssid"],
                });
                return true;
            });
            this.setState({
                optionTemplateSSID: optionTemplateSSID,
                loading_prop_groups: false,
            });
        }
    };

    handleSelectSSID = (value) => {
        this.setState({ selectSSID: value });

        $("#psk").removeAttr("disabled");
        $("#selectSSID").on("change", function () {
          console.log("Sel SSID")
        });
    };

    changeHandler = (event) => {
      this.setState({ [event.target.name]: event.target.value });
  };
    testPSK = async() => {
      this.setState({
        loading: true
      })

      const host = window.location.host;
      let consoleURL;
      switch(host){
        case "localhost:3000": {
          consoleURL = "https://dish.wifikey.link"
          break;
        }
        default: {
          consoleURL = window.location.host
          break;
        }
      }
      console.log(consoleURL)
      let dataToSend = {
          SubscriberConsole: consoleURL,
          "NAS-Identifier": this.state.nasID,
          RunTests: true,
          RadiusTest: true,
          Tests: [
              {
                  PSK: this.state.psk,
                  SSID: this.state.selectSSID,
              },
          ],
      };
      console.log(dataToSend)
      let responseData = await fetch(API_Server + "/Tools/PSK_Test", {
          method: "post",
          body: JSON.stringify(dataToSend),
      });
      let TestResults = await responseData.json();
      this.setState({
        testResultsSummary : JSON.stringify(TestResults.Summary)
        .replace(/,/g, "\n")
        .replaceAll(":[", "\n")
        .replaceAll('{"RADIUS"', "")
        .replaceAll("}", "")
        .replaceAll("]", "")
        .replace(/^\s+|\s+$/g, ""),
        testResultsTestData: JSON.stringify(TestResults.TestData, null, "\t"),
        testResultsAPIResults:   JSON.stringify(TestResults.API_Results, null, "\t"),
        testResultsRadiusResults: JSON.stringify(TestResults.RADIUS_Results, null, "\t"),
        loading: false
      })
  };
    render() {
        $("#btn_Login").on("click", function () {
            console.log("Hello");
            if (
                $("#sel_Environment").val() == "SELECT" ||
                $("#sel_Environment").val() == null
            ) {
                alert("Please select an Environment to Connect.");
            } else {
                this.resetValues(true);
                $("#ConnectCreds").modal("show");
            }
        });
        //$("#txtPSK").on('change',function(){
        $("#txtPSK").keyup(function () {
            if ($("#txtPSK").val().length > 7) {
                $("#btn_Test").removeClass("disabled");
                $("#btn_Test").removeAttr("disabled");
            } else {
                $("#btn_Test").attr("disabled", true);
                $("#btn_Test").addClass("disabled");
            }
        });
        $("#txtPSK").on("paste", function () {
            if ($("#txtPSK").val().length > 7) {
                $("#btn_Test").removeClass("disabled");
                $("#btn_Test").removeAttr("disabled");
            } else {
                $("#btn_Test").attr("disabled", true);
                $("#btn_Test").addClass("disabled");
            }
        });
        $("#btn_Test").on("click", async function () {
            let dataToSend = {
                SubscriberConsole: $("#txt_subscriberConsole").val(),
                "NAS-Identifier": $("#txtNasIdentifier").val(),
                RunTests: true,
                RadiusTest: true,
                Tests: [
                    {
                        PSK: $("#txtPSK").val(),
                        SSID: $("#sel_SSID").val(),
                    },
                ],
            };
            let responseData = await fetch(API_Server + "/Tools/PSK_Test", {
                method: "post",
                body: JSON.stringify(dataToSend),
            });
            let TestResults = await responseData.json();
            this.setState({
              testResultsSummary : JSON.stringify(TestResults.Summary)
              .replace(/,/g, "\n")
              .replaceAll(":[", "\n")
              .replaceAll('{"RADIUS"', "")
              .replaceAll("}", "")
              .replaceAll("]", "")
              .replace(/^\s+|\s+$/g, ""),
              testResultsTestData: JSON.stringify(TestResults.TestData, null, "\t"),
              testResultsAPIResults:   JSON.stringify(TestResults.API_Results, null, "\t"),
              testResultsRadiusResults: JSON.stringify(TestResults.RADIUS_Results, null, "\t")
            })
            
        });

        $("#selectVenue").on("change", function () {
            ///////////$("#btn_ldKeys").on('click',function(){
            this.resetValues(false);
            this.GetPSKParameters().then((result) => {
                //$("#txtNasIdentifier").val(result[Object.keys(result)[0]]["NAS-Identifier"]);
                $("#txtNasIdentifier").val(
                    result[$("#sel_Venue").val()]["NAS-Identifier"]
                );
                $("#txtNasIdentifier").removeAttr("disabled");
                let foundSSID = 0;
                //Object.keys(result[Object.keys(result)[0]]["Keypools"]).forEach(pool=>{
                Object.keys(
                    result[$("#selectVenue").val()]["Keypools"]
                ).forEach((pool) => {
                    console.log(
                        result[$("#selectVenue").val()]["Keypools"][pool][
                            "ssid"
                        ]
                    );
                    if (
                        result[$("#selectVenue").val()]["Keypools"][pool][
                            "AuthenticationMechanism"
                        ] == "WPA2 PSK"
                    ) {
                        foundSSID++;
                        $("#selectSSID").append(
                            $("<option>", {
                                value: result[$("#sel_Venue").val()][
                                    "Keypools"
                                ][pool]["ssid"],
                                text: result[$("#sel_Venue").val()]["Keypools"][
                                    pool
                                ]["ssid"],
                            })
                        );
                    }
                    if (foundSSID > 0) {
                        $("#sel_SSID").removeAttr("disabled");
                    }
                });
                // Object.keys(result).forEach(VenID=>{
                //     $("#txtNasIdentifier").val(result[VenID]);
                // });
            });
            //PassData.data.forEach(passVal=>{
            //    $("#tbl_Generate tr:last").after(`
            //        <tr>
            //        <td>Test@test.com</td>
            //        <td>101</td>
            //        <td>Main</td>
            //        <td>1</td>
            //        <td><btn className="btn btn-sm btn-outline-secondary" id="btn_ldKeys">test key</btn></td>
            //        </tr>
            //        `)
            //});
        });
        return (
            <>
                
                <main className="col-md-9 ml-sm-auto col-lg-12">
                    <MDBModal
                        isOpen={this.props.modalPSKValidator}
                        toggle={this.props.togglePSKValidator}
                        size="lg"
                        className="form"
                        centered
                    >
                        <MDBModalHeader toggle={this.props.togglePSKValidator}>
                            PSK Validator
                        </MDBModalHeader>
                        <MDBModalBody className="venueDetails">
                            <MDBRow>
                                <MDBCol col="6" sm="12" lg="12">
                                    <label
                                        htmlFor="defaultFormLoginEmailEx"
                                        className="black-text"
                                    >
                                        Venue Name
                                    </label>
                                    <SelectSearch
                                        id="selectVenue"
                                        search={true}
                                        filterOptions={fuzzySearch}
                                        options={
                                            this.state.optionTemplateVenues
                                        }
                                        isOptionSelected={true}
                                        inputId="venueArray"
                                        classNamePrefix="venue-select"
                                        placeholder="Select..."
                                        onChange={this.handleSelectVenue}
                                        value={
                                          this.state.selectedID
                                        }
                                    />
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol col="6" sm="12" lg="12">
                                    <label
                                        htmlFor="defaultFormLoginEmailEx"
                                        className="black-text"
                                    >
                                        NAS-Identifier
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        disabled
                                        name="nasID"
                                                        id="nasID"
                                        aria-describedby="NasIDHelp"
                                        placeholder="NAS ID"
                                        onChange={
                                          this.changeHandler
                                      }
                                      value={
                                          this.state.nasID
                                      }
                                    />
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol col="6" sm="12" lg="12" className="mt-2">
                                    <label
                                        htmlFor="defaultFormLoginEmailEx"
                                        className="black-text"
                                    >
                                        SSID
                                    </label>
                                    <SelectSearch
                                        id="selectSSID"
                                        search={true}
                                        filterOptions={fuzzySearch}
                                        options={this.state.optionTemplateSSID}
                                        isOptionSelected={true}
                                        inputId="venueArray"
                                        classNamePrefix="venue-select"
                                        placeholder="Select..."
                                        onChange={this.handleSelectSSID}
                                        value={
                                            this.state.selectSSID
                                        }
                                    />
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol col="6" sm="12" lg="12" className="mt-2">
                                    <label
                                        htmlFor="defaultFormLoginEmailEx"
                                        className="black-text"
                                    >
                                        PSK To Test
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        disabled
                                        name="psk"
                                                        id="psk"
                                        aria-describedby="NasIDHelp"
                                        placeholder="PSK to test"
                                        onChange={
                                          this.changeHandler
                                      }
                                      value={
                                          this.state.psk
                                      }
                                    />
                                </MDBCol>
                            </MDBRow>
                            <br/>
                            <MDBNav className="nav-tabs">
                        <MDBNavItem>
                            <MDBNavLink
                                link
                                to="#"
                                active={this.state.activeItem === "1"}
                                onClick={this.toggleTab("1")}
                                role="tab"
                            >
                                <MDBIcon icon="user" /> Summary
                            </MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem>
                            <MDBNavLink
                                link
                                to="#"
                                active={this.state.activeItem === "2"}
                                onClick={this.toggleTab("2")}
                                role="tab"
                                disabled={
                                    this.state.selectedID === undefined
                                        ? true
                                        : false
                                }
                            >
                                <MDBIcon icon="building" /> Test Data
                            </MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem>
                            <MDBNavLink
                                link
                                to="#"
                                active={this.state.activeItem === "3"}
                                onClick={this.toggleTab("3")}
                                role="tab"
                                disabled={
                                    this.state.selectedID === undefined
                                        ? true
                                        : false
                                }
                            >
                                <MDBIcon icon="chart-line" /> API Results
                            </MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem>
                            <MDBNavLink
                                link
                                to="#"
                                active={this.state.activeItem === "4"}
                                onClick={this.toggleTab("4")}
                                role="tab"
                                disabled={
                                    this.state.selectedID === undefined
                                        ? true
                                        : false
                                }
                            >
                                <MDBIcon icon="signal" /> Radius Results
                            </MDBNavLink>
                        </MDBNavItem>
                        
                    </MDBNav>
                    <MDBTabContent
                        className="mt-1"
                        activeItem={this.state.activeItem}
                    >
                    <MDBTabPane tabId="1" role="tabpanel">
                    <div className="logList">
                                {this.state.testResultsSummary !== undefined ? (
                                    // console.log(this.state.subscriberLog)
                                    <div>
                                        <JSONPretty
                                            id="json-pretty"
                                            data={this.state.testResultsSummary}
                                        ></JSONPretty>
                                    </div>
                                ) : (
                                    ""
                                )}
                            </div>
                        </MDBTabPane>
                        <MDBTabPane tabId="2" role="tabpanel">
                        {this.state.testResultsTestData !== undefined ? (
                                    // console.log(this.state.subscriberLog)
                                    <div>
                                        <JSONPretty
                                            id="json-pretty"
                                            data={this.state.testResultsTestData}
                                        ></JSONPretty>
                                    </div>
                                ) : (
                                    ""
                                )}
                        </MDBTabPane>
                        <MDBTabPane tabId="3" role="tabpanel">
                        {this.state.testResultsAPIResults !== undefined ? (
                                    // console.log(this.state.subscriberLog)
                                    <div>
                                        <JSONPretty
                                            id="json-pretty"
                                            data={this.state.testResultsAPIResults}
                                        ></JSONPretty>
                                    </div>
                                ) : (
                                    ""
                                )}
                        </MDBTabPane>
                        <MDBTabPane tabId="4" role="tabpanel">
                        {this.state.testResultsRadiusResults !== undefined ? (
                                    // console.log(this.state.subscriberLog)
                                    <div>
                                        <JSONPretty
                                            id="json-pretty"
                                            data={this.state.testResultsRadiusResults}
                                        ></JSONPretty>
                                    </div>
                                ) : (
                                    ""
                                )}
                        </MDBTabPane>
                        </MDBTabContent>
                        </MDBModalBody>
                        <MDBModalFooter>
                            <div
                                className="text-right submitButton pt-2"
                                key={this.state.theInputKey || ""}
                            >
                                <MDBBtn
                                    color="primary"
                                    onClick={this.testPSK}
                                    
                                >
                                    {this.state.loading === true ? (
                                        <div
                                            className="spinner-border spinner-border-sm"
                                            role="status"
                                        >
                                            <span className="sr-only">
                                                Loading...
                                            </span>
                                        </div>
                                    ) : (
                                        "Confirm"
                                    )}
                                </MDBBtn>
                                <MDBBtn
                                    color="primary"
                                    onClick={
                                        this.props.togglePSKValidator
                                    }
                                >
                                    Close
                                </MDBBtn>
                            </div>
                        </MDBModalFooter>
                    </MDBModal>
                </main>
            </>
        );
    }
}

export default PSKValidator;
