import React, { Component } from "react";

import {
    MDBModalBody,
    MDBModalHeader,
    MDBRow,
    MDBCol,
    MDBIcon,
    MDBModal,
} from "mdbreact";

import ViewOpenTickets from "../../../../components/VenueMgmt/Support/ViewOpenTickets";

import ViewCoreEquipment from "../../../../components/VenueMgmt/Support/ViewCoreEquipment";

import NewTicket from "../../../../components/VenueMgmt/Support/SupportNewTicket";

import FAQ from "../../../../components/VenueMgmt/Support/FAQs";

import VenueDropdown from "../../../../components/VenueMgmt/Navigation/VenueDropdown/VenueDropdown";

import SignalLevels from "../../../../components/VenueMgmt/Support/SignalLevels";

import BandwidthUsage from "../../../../components/VenueMgmt/Support/BandwidthUsage";

import { withTranslation } from "react-i18next";

import brandsData from "../../../../brands/common/brands.json";

class SupportNavigation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalOpenTickets: false,
            loading: false,
        };
    }

    toggleCoreEquipment = () => {
        this.setState({
            modalCoreEquipment: !this.state.modalCoreEquipment,
        });
    };

    toggleNewTicket = () => {
        this.setState({
            modalNewTicket: !this.state.modalNewTicket,
        });
    };

    toggleNewTicketExternal = (external_url) => {
        window.open(external_url, "_newtab");
    };

    toggleOpenTickets = () => {
        this.setState({
            modalOpenTickets: !this.state.modalOpenTickets,
        });
    };

    toggleFAQs = () => {
        this.setState({
            modalFAQ: !this.state.modalFAQ,
        });
    };

    toggleSignalLevels = () => {
        this.setState({
            modalSignalLevels: !this.state.modalSignalLevels,
        });
    };

    toggleBandwidthUsage = () => {
        this.setState({
            modalBandwidthUsage: !this.state.modalBandwidthUsage,
        });
    };

    updateName = () => {
        //console.log("Hello");
    };
    render() {
        //console.log(this.props);
        // eslint-disable-next-line no-unused-vars
        let showSupport;
        let showDevices;
        let externalSupport;
        let externalSupportLink;
        // eslint-disable-next-line no-unused-vars
        const SUB_PAGES = brandsData.map(
            ({
                name,
                url,
                phone_number,
                email_address,
                flag_showSupport,
                flag_showDevices,
                external_support,
                external_support_link,
                // eslint-disable-next-line array-callback-return
            }) => {
                if (url.includes(window.location.hostname)) {
                    showSupport = flag_showSupport;
                    externalSupport = external_support;
                    externalSupportLink = external_support_link;
                    showDevices = flag_showDevices;
                }
            }
        );
        const { t } = this.props;
        return (
            <>
                {this.props.loading === true ? (
                    <div id="overlay" style={{ display: "block" }}>
                        <div className="overlayMessage">
                            <div
                                className="spinner-border spinner-border"
                                role="status"
                            >
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>
                ) : (
                    ""
                )}
                <MDBModalHeader toggle={this.props.toggleSupport}>
                    <VenueDropdown
                        getVenues={this.props.getVenues}
                        updateName={this.props.updateName}
                    />
                    <h4>{t("supportnav.title")}</h4>
                </MDBModalHeader>
                <MDBModalBody>
                    <div className="managementModal">
                        {externalSupport ? (
                            <>
                                <MDBRow>
                                    {externalSupportLink !== "" ? (
                                        <MDBCol size="12" md="6" lg="4">
                                            <div
                                                className="managementModalButton"
                                                onClick={() =>
                                                    this.toggleNewTicketExternal(
                                                        externalSupportLink
                                                    )
                                                }
                                            >
                                                <MDBIcon icon="edit" />
                                                <p>
                                                    {t("supportnav.newticket")}
                                                </p>
                                            </div>
                                        </MDBCol>
                                    ) : (
                                        ""
                                    )}

                                    {showDevices ? (
                                        <>
                                            <MDBCol size="12" md="6" lg="4">
                                                <div
                                                    className="managementModalButton"
                                                    onClick={
                                                        this.toggleCoreEquipment
                                                    }
                                                >
                                                    <MDBIcon icon="server" />
                                                    <p>
                                                        {t(
                                                            "supportnav.viewequip"
                                                        )}
                                                    </p>
                                                </div>
                                            </MDBCol>
                                        </>
                                    ) : (
                                        ""
                                    )}

                                    <MDBCol size="12" md="6" lg="4">
                                        <div
                                            className="managementModalButton"
                                            onClick={this.toggleSignalLevels}
                                        >
                                            <MDBIcon icon="signal" />
                                            <p>{t("supportnav.signal")}</p>
                                        </div>
                                    </MDBCol>

                                    <MDBCol size="12" md="6" lg="4">
                                        <div
                                            className="managementModalButton"
                                            onClick={this.toggleBandwidthUsage}
                                        >
                                            <MDBIcon icon="chart-line" />
                                            <p>Bandwidth Usage</p>
                                        </div>
                                    </MDBCol>
                                    <MDBCol size="12" md="6" lg="4">
                                        <div
                                            className="managementModalButton"
                                            onClick={this.toggleFAQs}
                                        >
                                            <MDBIcon icon="question" />
                                            <p>{t("supportnav.faq")}</p>
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                            </>
                        ) : (
                            <>
                                <MDBRow>
                                    <MDBCol size="12" md="6" lg="4">
                                        <div
                                            className="managementModalButton"
                                            onClick={this.toggleOpenTickets}
                                        >
                                            <MDBIcon icon="search" />
                                            <p>{t("supportnav.viewopen")}</p>
                                        </div>
                                    </MDBCol>
                                    <MDBCol size="12" md="6" lg="4">
                                        <div
                                            className="managementModalButton"
                                            onClick={this.toggleNewTicket}
                                        >
                                            <MDBIcon icon="edit" />
                                            <p>{t("supportnav.newticket")}</p>
                                        </div>
                                    </MDBCol>
                                    {showDevices ? (
                                        <MDBCol size="12" md="6" lg="4">
                                            <div
                                                className="managementModalButton"
                                                onClick={
                                                    this.toggleCoreEquipment
                                                }
                                            >
                                                <MDBIcon icon="server" />
                                                <p>
                                                    {t("supportnav.viewequip")}
                                                </p>
                                            </div>
                                        </MDBCol>
                                    ) : (
                                        ""
                                    )}
                                    <MDBCol size="12" md="6" lg="4">
                                        <div
                                            className="managementModalButton"
                                            onClick={this.toggleSignalLevels}
                                        >
                                            <MDBIcon icon="signal" />
                                            <p>{t("supportnav.signal")}</p>
                                        </div>
                                    </MDBCol>

                                    <MDBCol size="12" md="6" lg="4">
                                        <div
                                            className="managementModalButton"
                                            onClick={this.toggleBandwidthUsage}
                                        >
                                            <MDBIcon icon="chart-line" />
                                            <p>Bandwidth Usage</p>
                                        </div>
                                    </MDBCol>

                                    <MDBCol size="12" md="6" lg="4">
                                        <div
                                            className="managementModalButton"
                                            onClick={this.toggleFAQs}
                                        >
                                            <MDBIcon icon="question" />
                                            <p>{t("supportnav.faq")}</p>
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                            </>
                        )}
                    </div>
                </MDBModalBody>
                <MDBModal
                    isOpen={this.state.modalOpenTickets}
                    toggle={this.toggle}
                    size="fluid"
                    className="form"
                    centered
                >
                    <ViewOpenTickets
                        toggleOpenTickets={this.toggleOpenTickets}
                        tickets={this.props.tickets}
                        venueID={this.props.venueID}
                    />
                </MDBModal>
                <MDBModal
                    isOpen={this.state.modalCoreEquipment}
                    toggle={this.toggleCoreEquipment}
                    size="fluid"
                    centered
                >
                    <ViewCoreEquipment
                        toggleCoreEquipment={this.toggleCoreEquipment}
                        selectedName={this.props.selectedName}
                        coreEquipment={this.props.coreEquipmentState}
                        venueList={this.props.venueList}
                    />
                </MDBModal>
                <MDBModal
                    isOpen={this.state.modalNewTicket}
                    toggle={this.toggleNewTicket}
                    className="newTicketModal"
                    size="lg"
                    centered
                >
                    <NewTicket
                        toggleNewTicket={this.toggleNewTicket}
                        selectedName={this.props.selectedName}
                        coreEquipment={this.props.coreEquipmentState}
                        venueList={this.props.venueList}
                        venueID={this.props.venueID}
                        updateSupport={this.props.updateSupport}
                    />
                </MDBModal>
                <MDBModal
                    isOpen={this.state.modalBandwidthUsage}
                    toggle={this.toggleBandwidthUsage}
                    size="fluid"
                    className="form"
                    centered
                >
                    <BandwidthUsage
                        toggleBandwidthUsage={this.toggleBandwidthUsage}
                    />
                </MDBModal>
                <MDBModal
                    isOpen={this.state.modalFAQ}
                    toggle={this.toggleFAQs}
                    size="lg"
                    className="form"
                    centered
                >
                    <FAQ toggleFAQs={this.toggleFAQs} />
                </MDBModal>
                <MDBModal
                    isOpen={this.state.modalSignalLevels}
                    toggle={this.toggleSignalLevels}
                    size="lg"
                    className="form"
                    centered
                >
                    <SignalLevels
                        venueID={this.props.venueID}
                        toggleSignalLevels={this.toggleSignalLevels}
                    />
                </MDBModal>
            </>
        );
    }
}

export default withTranslation()(SupportNavigation);
