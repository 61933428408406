import React, { Component } from "react";

import { MDBRow, MDBCol, MDBModalHeader, MDBModalBody } from "mdbreact";

import ViewcpeEquipmentTable from "../../../components/VenueMgmt/Support/Datatables/ViewcpeEquipmentTable";

import { withTranslation } from "react-i18next";

class ViewcpeEquipment extends Component {
    render() {
        return (
            <>
                <MDBModalHeader toggle={this.props.togglecpeEquipment}>
                    <h4>CPE Device Status</h4>
                </MDBModalHeader>
                <MDBModalBody>
                    <MDBRow>
                        <MDBCol>
                            <div className="allrecentresidentsExpanded">
                                <ViewcpeEquipmentTable
                                    cpeEquipment={this.props.cpeEquipment}
                                    selectedName={this.props.selectedName}
                                    venueList={this.props.venueList}
                                />
                            </div>
                        </MDBCol>
                    </MDBRow>
                </MDBModalBody>
            </>
        );
    }
}

export default withTranslation()(ViewcpeEquipment);
