import React from "react";

import { MDBDataTableV5 } from "mdbreact";

import { useTranslation } from "react-i18next";

export default function Basic(props) {
    const { t } = useTranslation();
    let userAttributes;
    let i = 0;

    let datatable;
    [datatable] = React.useState("");
    if (props.recentresidents !== undefined) {
        userAttributes = [];
        for (const key of Object.keys(props.recentresidents)) {
            if (props.recentresidents[key]["EndDateTimeUTC.ISO8601"] !== "") {
                userAttributes.push({
                    unitID: props.recentresidents[key]["UnitID"],
                    name:
                        props.recentresidents[key]["SubscriberGivenName"] +
                        " " +
                        props.recentresidents[key]["SubscriberFamilyName"],
                    email: props.recentresidents[key]["SubscriberEmail"],
                    status: props.recentresidents[key]["Status"],
                    activated: Intl.DateTimeFormat("en-CA", {
                        month: "2-digit",
                        day: "2-digit",
                        year: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                    }).format(
                        new Date(
                            props.recentresidents[key][
                                "StartDateTimeUTC.ISO8601"
                            ]
                        )
                    ),
                    expiring: Intl.DateTimeFormat("en-CA", {
                        month: "2-digit",
                        day: "2-digit",
                        year: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                    }).format(
                        new Date(
                            props.recentresidents[key]["EndDateTimeUTC.ISO8601"]
                        )
                    ),
                    clickEvent: () =>
                        props.modifyResident(props.recentresidents[key]),
                });
            } else {
                userAttributes.push({
                    unitID: props.recentresidents[key]["UnitID"],
                    name:
                        props.recentresidents[key]["SubscriberGivenName"] +
                        " " +
                        props.recentresidents[key]["SubscriberFamilyName"],
                    email: props.recentresidents[key]["SubscriberEmail"],
                    status: props.recentresidents[key]["Status"],
                    activated: Intl.DateTimeFormat("en-CA", {
                        month: "2-digit",
                        day: "2-digit",
                        year: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                    }).format(
                        new Date(
                            props.recentresidents[key][
                                "StartDateTimeUTC.ISO8601"
                            ]
                        )
                    ),
                    expiring: "",
                    clickEvent: () =>
                        props.modifyResident(props.recentresidents[key]),
                });
                i = i + 1;
            }
        }
    }

    datatable = {
        columns: [
            {
                label: t("guests.name"),
                field: "name",
            },
            {
                label: t("guests.email"),
                field: "email",
            },
            {
                label: "Status",
                field: "status",
            },
            {
                label: t("guests.activated"),
                field: "activated",
            },
            {
                label: t("guests.expiring"),
                field: "expiring",
            },
        ],
        rows: userAttributes,
    };
    return (
        <MDBDataTableV5
            sortable={true}
            responsive
            hover
            data={datatable}
            searchTop={true}
            searchBottom={false}
            searchLabel="Filter"
            paging={false}
            className="recentResidents"
            info={false}
            noRecordsFoundLabel={t("guests.nomatches")}
            sortRows={["activated"]}
        />
    );
}
