import React from "react";

import { MDBDataTableV5 } from "mdbreact";

import { useTranslation } from "react-i18next";

export default function Basic(props) {
    const { t } = useTranslation();
    let userAttributes;
    let i = 0;

    let datatable;
    [datatable] = React.useState("");
    if (props.transactions !== undefined) {
        userAttributes = [];
        for (const key of Object.keys(props.transactions)) {
            userAttributes.push({
                invoiceDate:
                    props.transactions[key]["InvoiceDateTimeUTC.ISO8601"] !== ""
                        ? Intl.DateTimeFormat("en-CA", {
                              year: "numeric",
                              month: "2-digit",
                              day: "2-digit",
                          }).format(
                              new Date(
                                  props.transactions[key][
                                      "InvoiceDateTimeUTC.ISO8601"
                                  ]
                              )
                          )
                        : "",
                paymentSuccessDate:
                    props.transactions[key][
                        "PaymentSuccessDateTimeUTC.ISO8601"
                    ] !== ""
                        ? Intl.DateTimeFormat("en-CA", {
                              year: "numeric",
                              month: "2-digit",
                              day: "2-digit",
                          }).format(
                              new Date(
                                  props.transactions[key][
                                      "PaymentSuccessDateTimeUTC.ISO8601"
                                  ]
                              )
                          )
                        : "",
                name:
                    props.transactions[key]["GivenName"] +
                    " " +
                    props.transactions[key]["FamilyName"],
                email: props.transactions[key]["EmailAddress"],
                phoneNumber: props.transactions[key]["PhoneNumber.E164"],
                servicePlan: props.transactions[key]["ServicePlanName"],
                priceAdjustment:
                    props.transactions[key]["CurrencySymbol"] +
                    props.transactions[key]["VenuePriceAdjustment"] / 100,
                billingCyclePrice:
                    props.transactions[key][
                        "AmountReceived." + props.transactions[key]["Currency"]
                    ] !== "undefined"
                        ? props.transactions[key]["CurrencySymbol"] +
                          props.transactions[key][
                              "BillingCyclePrice." +
                                  props.transactions[key]["Currency"]
                          ] /
                              100
                        : "",
                venuePriceAdjustment:
                    props.transactions[key]["CurrencySymbol"] +
                    props.transactions[key]["VenuePriceAdjustment"] / 100,
                amountReceived:
                    props.transactions[key][
                        "AmountReceived." + props.transactions[key]["Currency"]
                    ] !== "undefined"
                        ? props.transactions[key]["CurrencySymbol"] +
                          props.transactions[key][
                              "AmountReceived." +
                                  props.transactions[key]["Currency"]
                          ] /
                              100
                        : "",
                receiptLink: (
                    <>
                        {props.transactions[key]["StripeRefundReceiptURL"] !==
                            "" ||
                        props.transactions[key][
                            "AmountReceived." +
                                props.transactions[key]["Currency"]
                        ] !== 0 ? (
                            <a
                                href={
                                    props.transactions[key]["StripeReceiptURL"]
                                }
                                target="_blank"
                                rel="noreferrer"
                            >
                                Click Here
                            </a>
                        ) : (
                            ""
                        )}
                    </>
                ),
            });
            i = i + 1;
        }
    }

    datatable = {
        columns: [
            {
                label: "Invoice Date",
                field: "invoiceDate",
            },
            {
                label: "Payment Success Date",
                field: "paymentSuccessDate",
            },
            {
                label: "Name",
                field: "name",
            },
            {
                label: "Email",
                field: "email",
            },
            {
                label: "Phone Number",
                field: "phoneNumber",
            },
            {
                label: "Service Plan",
                field: "servicePlan",
            },
            {
                label: "Price Adjustment",
                field: "priceAdjustment",
            },
            {
                label: "Billing Cycle Price",
                field: "billingCyclePrice",
            },
            {
                label: "Venue Price Adjustment",
                field: "venuePriceAdjustment",
            },
            {
                label: "Amount Received",
                field: "amountReceived",
            },
            {
                label: "Receipt Link",
                field: "receiptLink",
            },
        ],
        rows: userAttributes,
    };
    return (
        <MDBDataTableV5
            responsive
            hover
            data={datatable}
            searchBottom={false}
            paging={false}
            className="modifyStaff"
            info={false}
            noRecordsFoundLabel={t("recentservice.nomatches")}
            exportToCSV={props.transactions !== undefined ? true : false}
        />
    );
}
