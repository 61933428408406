import React from "react";

import { MDBDataTableV5 } from "mdbreact";

export default function Basic(props) {
    let userAttributes;
    let i = 0;

    let datatable;
    [datatable] = React.useState("");
    if (props.servicePlans !== undefined) {
        userAttributes = [];
        for (const key of Object.keys(props.servicePlans)) {
            userAttributes.push({
                servicePlanName: props.servicePlans[key]["ServicePlanName"],
                servicePlanDescription:
                    props.servicePlans[key]["ServicePlanDescription"],
                downloadkbps: props.servicePlans[key]["DownloadKbps"],
                uploadkbps: props.servicePlans[key]["UploadKbps"],
                concurrentdevices: props.servicePlans[key]["ConcurrentDevices"],
                billingCycleHours: props.servicePlans[key]["BillingCycleHours"],
                billingCyclePrice:
                    "$" +
                    props.servicePlans[key]["BillingCyclePrice.USD"] / 100,
                trialCycles: props.servicePlans[key]["TrialCycles"],
                action: (
                    <>
                        <div className="renewConfirmButton1">
                            <a
                                href="#link"
                                onClick={() => props.disablePlanConfirm(key)}
                                className="expirationAction"
                            >
                                Disable
                            </a>
                        </div>
                    </>
                ),
            });
            i = i + 1;
        }
    }

    datatable = {
        columns: [
            {
                label: "Name",
                field: "servicePlanName",
            },
            {
                label: "Description",
                field: "servicePlanDescription",
            },
            {
                label: "Download(kbps)",
                field: "downloadkbps",
            },
            {
                label: "Upload(kbps)",
                field: "uploadkbps",
            },
            {
                label: "Concurrent Devices",
                field: "concurrentdevices",
            },
            {
                label: "Billing Cycle Hours",
                field: "billingCycleHours",
            },
            {
                label: "Billing Cycle Price",
                field: "billingCyclePrice",
            },
            {
                label: "Trial Cycles",
                field: "trialCycles",
            },
            {
                label:
                    sessionStorage.getItem("customerSupport") > 0
                        ? ""
                        : "Action",
                field:
                    sessionStorage.getItem("customerSupport") > 0
                        ? ""
                        : "action",
            },
        ],
        rows: userAttributes,
    };
    return (
        <MDBDataTableV5
            sortable={true}
            responsive
            hover
            data={datatable}
            searchTop={true}
            searchBottom={false}
            searchLabel="Filter"
            paging={false}
            className="servicePlans"
            info={false}
            noRecordsFoundLabel="No Service Plans Found"
            sortRows={["activated"]}
        />
    );
}
