import React from "react";

import { MDBDataTableV5 } from "mdbreact";

import { useTranslation } from "react-i18next";

export default function Basic(props) {
    const { t } = useTranslation();
    let userAttributes;
    let i = 0;

    let datatable;
    [datatable] = React.useState("");
    //console.log(props.staff);
    if (props.pendingGatewayVLANEnable !== undefined) {
        userAttributes = [];
        for (const key of props.pendingGatewayVLANEnable) {
            userAttributes.push({
                venueID: key["VenueID"],
                UnitID_internal: key["UnitID_internal"],
                vlan: key["vlan"],
                SubscriberEmail: key["SubscriberEmail"],
                startDate: key["StartDateTimeUTC.ISO8601"],
                status: key["Status"],
            });
            i = i + 1;
        }
    }

    datatable = {
        columns: [
            {
                label: "Venue ID",
                field: "venueID",
            },
            {
                label: "Unit ID",
                field: "UnitID_internal",
            },
            {
                label: "VLAN",
                field: "vlan",
            },
            {
                label: "Subscriber Email",
                field: "SubscriberEmail",
            },
            {
                label: "Start Date",
                field: "startDate",
            },
            {
                label: "Status",
                field: "status",
            },
        ],
        rows: userAttributes,
    };
    return (
        <MDBDataTableV5
            responsive
            hover
            data={datatable}
            searchBottom={false}
            paging={false}
            className="servicePlans"
            info={false}
            noRecordsFoundLabel={t("recentservice.nomatches")}
        />
    );
}
