import React, { Component } from "react";
import Chart from "react-apexcharts";
import axios from "../../../axios";
import { MDBModalHeader, MDBModalBody } from "mdbreact";

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            options1: undefined,
            category: [],
            name: [],
            series: [],
        };
    }
    componentDidMount() {
        this.getBandwidthData();
    }
    getBandwidthData = () => {
        this.setState({ loading: true });
        let body = {
            AccessToken: sessionStorage.getItem("AccessToken"),
            VenueID: this.props.venueID,
        };
        axios
            .post("/venuemgmtconsole/venues/listRSSI", body, {
                headers: {},
            })
            .then((response) => {
                this.setState({
                    bandwidthData: response.data.RSSIDistribution,
                });
                if (this.state.bandwidthData !== undefined) {
                    this.setState({ loading1: false });
                    this.setBandwidthData();
                }
            })
            .catch((error) => {
                this.getBandwidthData();
                this.setState({ error: true });
            });
    };
    setBandwidthData = () => {
        for (var i = 0; i < this.state.bandwidthData.length; i++) {
            this.setState({
                name: [
                    ...this.state.name,
                    {
                        [this.props.callingStationID]: [
                            {
                                data: this.state.bandwidthData[i][
                                    "connections"
                                ],
                                category:
                                    "Signal Strength: " +
                                    this.state.bandwidthData[i]["rssi"],
                            },
                        ],
                    },
                ],
            });
        }
        if (this.state.name !== []) {
            this.setSeries();
        }
    };
    setSeries = () => {
        this.setState({ loading: true });
        const newSeries = [];
        let option_categories = [];
        let data = [];
        let largest = 0;
        for (var j = 0; j < this.state.name.length; j++) {
            data[j] =
                this.state.name[j][Object.keys(this.state.name[j])][0]["data"];
            option_categories.push(
                this.state.name[j][Object.keys(this.state.name[j])][0][
                    "category"
                ]
            );
            if (data[j] > largest) {
                largest = data[j];
            }
        }
        newSeries.push({
            data: data,
            name: "# Sessions",
        });
        this.setState({
            series: newSeries,
            loading: false,
            options1: {
                chart: {
                    id: "line",
                    toolbar: {
                        show: false,
                    },
                    height: "360px",
                    width: "100%",
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: "55%",
                        endingShape: "rounded",
                    },
                },
                legend: {
                    show: false,
                    showForSingleSeries: true,
                },
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ["transparent"],
                },
                xaxis: {
                    type: "category",
                    categories: option_categories,
                    title: {
                        text: "Wi-Fi Signal Strength",
                        offsetX: 0,
                        offsetY: -20,
                        style: {
                            color: undefined,
                            fontSize: "12px",
                            fontFamily: "Helvetica, Arial, sans-serif",
                            fontWeight: 600,
                            cssClass: "apexcharts-xaxis-title",
                        },
                    },
                    labels: {
                        show: false,
                    },
                    tooltip: {
                        enabled: false,
                    },
                },
                yaxis: {
                    max: largest + 5,
                    decimalsInFloat: 0,
                    title: {
                        text: "Sessions",
                    },
                    labels: {
                        formatter: function (val) {
                            return Math.floor(val);
                        },
                    },
                },
            },
        });
    };
    render() {
        return (
            <>
                {this.state.loading === true ? (
                    <div id="overlay" style={{ display: "block" }}>
                        <div className="overlayMessage">
                            <div
                                className="spinner-border spinner-border"
                                role="status"
                            >
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>
                ) : this.state.name.length !== 0 ? (
                    <>
                        <MDBModalHeader toggle={this.props.toggleSignalLevels}>
                            <h4>Wi-Fi Performance</h4>
                        </MDBModalHeader>
                        <MDBModalBody>
                            <Chart
                                options={
                                    this.state.options1 !== undefined
                                        ? this.state.options1
                                        : this.state.options
                                }
                                series={this.state.series}
                                type="bar"
                                height="360px"
                            />
                        </MDBModalBody>
                    </>
                ) : (
                    <>
                        <MDBModalHeader toggle={this.props.toggleSignalLevels}>
                            <h4>Wi-Fi Performance</h4>
                        </MDBModalHeader>
                        <MDBModalBody>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    minHeight: "210px",
                                }}
                            >
                                <h4 style={{ color: "#BBB" }}>
                                    No data to display yet
                                </h4>
                            </div>
                        </MDBModalBody>
                    </>
                )}
            </>
        );
    }
}
export default App;
