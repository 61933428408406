import React, { Component } from "react";

import { MDBBtn, MDBModalBody, MDBModalHeader, MDBRow, MDBCol } from "mdbreact";

import { withTranslation } from "react-i18next";

import { Editor } from "react-draft-wysiwyg";

import {
    EditorState,
    convertToRaw,
    convertFromRaw,
    ContentState,
    convertFromHTML,
} from "draft-js";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import draftToHtml from "draftjs-to-html";

class ModifyTandC extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Email: "",
            sms: false,
            voice: false,
            invalidPhone: null,
            loading: false,
            userFound: false,
            tandc: "Test",
            disabled:
                sessionStorage.getItem("customerSupport") > 0 ? true : false,
            editorState: EditorState.createWithContent(
                ContentState.createFromBlockArray(convertFromHTML(""))
            ),
        };
        this.handleEditorChange = this.handleEditorChange.bind(this);
    }

    componentDidMount() {
        const html = "";
        const contentBlock = convertFromHTML(html);
        if (contentBlock) {
            this.setState({
                editorState: EditorState.createWithContent(
                    ContentState.createFromBlockArray(convertFromHTML(html))
                ),
            });
        }
    }

    onEditorStateChange = (editorState) => {
        this.setState({
            editorState,
        });
    };

    submitModifyTandC = (event) => {
        event.preventDefault();
        event.target.className += " was-validated";
        let tandc;
        if (
            this.state.language !== undefined &&
            this.state.editorState !== undefined
        ) {
            tandc = draftToHtml(
                convertToRaw(this.state.editorState.getCurrentContent())
            );
            this.props.submitModifyTandC(this.state.language, tandc);
        }
    };

    changeHandlerLanguage = (event) => {
        this.setState({
            language: event.target.value,
        });
        this.handleEditorChange(
            this.props.allTandCData[
                event.target.value === undefined ? "en-us" : event.target.value
            ]["TandC"]
        );
    };

    handleEditorChange(editorState, editor) {
        this.setState({
            editorState: EditorState.createWithContent(
                ContentState.createFromBlockArray(convertFromHTML(editorState))
            ),
        });
    }

    onContentStateChange = (contentState) => {
        this.setState({
            contentState,
        });
    };

    render() {
        const { t } = this.props;
        const { editorState } = this.state;
        return (
            <>
                <MDBModalHeader toggle={this.props.toggleTandCInfo}>
                    <h4>Modify Terms and Conditions</h4>
                    <p>{t("addnewresident.required")}</p>
                </MDBModalHeader>
                <MDBModalBody>
                    <form
                        className="needs-validation"
                        onSubmit={this.submitModifyTandC}
                        noValidate
                    >
                        <MDBRow>
                            <MDBCol col="6" sm="12" lg="12">
                                <div>
                                    <label
                                        htmlFor="defaultFormLoginEmailEx"
                                        className="black-text"
                                    >
                                        {t("addnewresident.language")}
                                    </label>
                                    <div className="select-box">
                                        <select
                                            className="browser-default custom-select"
                                            name="language"
                                            required
                                            onChange={
                                                this.changeHandlerLanguage
                                            }
                                            value={this.state.language}
                                        >
                                            <option value="" disabled selected>
                                                {t("addnewresident.select")}
                                            </option>
                                            <option value="en-us">
                                                English
                                            </option>
                                            <option value="es-xl">
                                                Spanish
                                            </option>
                                        </select>
                                    </div>
                                    <div className="invalid-feedback">
                                        Please provide a valid language.
                                    </div>
                                </div>
                            </MDBCol>
                            <MDBCol col="6"></MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol sm="12" lg="12">
                                <label htmlFor="exampleFormControlTextarea1">
                                    Terms and Conditions
                                </label>
                                <Editor
                                    name="emailHeader"
                                    className="form-control"
                                    editorState={editorState}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="form-control editorTandC"
                                    onEditorStateChange={
                                        this.onEditorStateChange
                                    }
                                    toolbar={{
                                        options: [
                                            "inline",
                                            "blockType",
                                            "fontSize",
                                            "fontFamily",
                                            "list",
                                            "textAlign",
                                            "link",
                                        ],
                                    }}
                                />
                            </MDBCol>
                        </MDBRow>

                        <div className="text-right submitButton">
                            <MDBBtn
                                color="danger"
                                type="submit"
                                disabled={this.state.disabled}
                            >
                                {this.props.modify_tandc === true ? (
                                    <div
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                    >
                                        <span className="sr-only">
                                            Loading...
                                        </span>
                                    </div>
                                ) : (
                                    "Save Changes"
                                )}
                            </MDBBtn>
                        </div>
                    </form>
                </MDBModalBody>
            </>
        );
    }
}

export default withTranslation()(ModifyTandC);
