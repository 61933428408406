import React, { Component } from "react";
import {
    MDBSelect,
    MDBSelectInput,
    MDBSelectOptions,
    MDBSelectOption,
    MDBRow,
    MDBCol,
    MDBIcon,
    MDBModal,
    MDBLink,
    toast,
    MDBModalHeader,
    MDBModalBody,
    MDBBtn,
    MDBContainer,
} from "mdbreact";
import AddSubscriber from "../../../components/NetworkMgmt/Forms/AddSubscriber/AddSubscriber";
import AddNewVenue from "../../../components/NetworkMgmt/Forms/AddVenue/AddVenueWizard";
import AddUnit from "../../../components/NetworkMgmt/Forms/AddUnit/AddUnit";
import AddDomainAdministrator from "../../../components/NetworkMgmt/Forms/AddDomainAdministrator/AddDomainAdministrator";
import axios from "../../../axios";
import { withTranslation } from "react-i18next";
import _ from "lodash";
class QuickLinks extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalAddNewResident: false,
            modalAddNewGuest: false,
            modalAddModifyStaff: false,
            modalMessage: false,
            loading: false,
            building: "All",
            hasUpdated: false,
            hasUpdatedUnit: false,
            hasUpdatedBuilding: false,
            venueServicePlans: undefined,
            unitForm: (
                <MDBSelect
                    id="selectUnit"
                    getValue={this.resetBuildingForm}
                    className="form-control"
                >
                    <MDBSelectInput
                        className="selectInput selectUnit"
                        selected="Choose your unit"
                    />
                </MDBSelect>
            ),
            buildingForm: (
                <MDBSelect
                    id="selectBuilding"
                    getValue={this.handleSelectChangeBuilding}
                    className="form-control"
                >
                    <MDBSelectInput
                        className="selectInput selectBuilding"
                        selected="Choose your building"
                    />
                </MDBSelect>
            ),
            floorForm: (
                <MDBSelect
                    id="selectFloor"
                    getValue={this.handleSelectChangeFloor}
                    className="form-control"
                >
                    <MDBSelectInput
                        className="selectInput selectFloor"
                        selected="Choose your floor"
                    />
                </MDBSelect>
            ),
            addedVenue: false,
            paymentFailed: false,
            modalAddPayment: true,
            optionTemplateUnits: [],
            optionTemplateBuilding: [],
            optionTemplateFloor: [],
            optionTemplateServicePlans: [],
            servicePlans: undefined,
        };
    }
    componentDidMount() {
        this.getVenues();
        if (this.state.venueID !== undefined) {
            this.getVenueDetails(this.state.venueID);
        }
    }
    toggleAddNewSubscriber = () => {
        this.setState({
            modalAddNewSubscriber: !this.state.modalAddNewSubscriber,
        });
    };
    toggleAddNewVenue = () => {
        this.setState({
            modalAddNewVenue: !this.state.modalAddNewVenue,
            tabNumber: 1,
            tabChanged: true,
            venueID: undefined,
            toggleConfirmContinueOpen: false,
        });
        sessionStorage.removeItem("addedVenue");
    };
    toggleConfirmContinue = () => {
        this.setState({
            toggleConfirmContinueOpen: true,
            modal: !this.state.modal,
        });
    };
    toggleAddUnit = () => {
        this.setState({
            modalAddUnit: !this.state.modalAddUnit,
        });
    };
    toggleAddDomainAdministrator = () => {
        this.setState({
            modalAddDomainAdministrator:
                !this.state.modalAddDomainAdministrator,
        });
    };
    togglePayment = () => {
        this.setState({
            modalAddPayment: !this.state.modalAddPayment,
        });
    };
    submitHandler = (
        venueID,
        dateEnd,
        email,
        firstName,
        lastName,
        phoneNumber,
        language,
        unitID,
        building,
        floor,
        contactpref_email,
        contactpref_sms,
        dateStart,
        unitID_internal,
        servicePlan
    ) => {
        let isoDateString;
        let isoDateStart;
        this.setState({ loading: true });
        if (!isNaN(new Date(dateEnd))) {
            isoDateString = new Date(dateEnd)
                .toISOString()
                .replace(/:00.\d+Z$/g, "Z");
        }
        if (!isNaN(new Date(dateStart))) {
            isoDateStart = new Date(dateStart)
                .toISOString()
                .replace(/:00.\d+Z$/g, "Z");
        }
        let body = {
            AccessToken: sessionStorage.getItem("AccessToken"),
            EmailAddress: email,
            GivenName: firstName,
            FamilyName: lastName,
            "PhoneNumber.E164": phoneNumber,
            locale: language,
            "EndDateTimeUTC.ISO8601": isoDateString,
            "StartDateTimeUTC.ISO8601": isoDateStart,
            VenueID: venueID,
            PolicyforSubscriberKey: {
                UnitID_internal: unitID_internal,
            },
            contactpref_email: true,
            contactpref_sms: contactpref_sms,
            UserTimeZone: new Date().getTimezoneOffset(),
            ServicePlanID: servicePlan,
        };
        // console.log("🚀 ~ file: QuickLinks.js:154 ~ QuickLinks ~ body", body)
        axios
            .post("/venuemgmtconsole/subscribers/addSubscriber", body, {
                headers: {},
            })
            .then(
                (response) => {
                    toast.success("Added Subscriber!", {
                        closeButton: false,
                    });
                    this.toggleAddNewSubscriber();
                    this.props.updateName(this.state.venueName, venueID);
                    this.setState({ loading: false });
                    if (response.data.paymentFailed) {
                        this.setState({
                            paymentFailed: true,
                        });
                    }
                    sessionStorage.setItem(
                        "unitID_internalSubscriber",
                        unitID_internal
                    );
                    sessionStorage.setItem(
                        "stripeClientSecretSubscriber",
                        response.data.StripeClientSecret
                    );
                },
                (error) => {
                    toast.error(error.response.data.message, {
                        closeButton: false,
                    });
                    this.setState({ loading: false });
                }
            );
    };
    submitDelete = (
        email,
        venueID,
        unitID,
        building,
        floor,
        recentResidents,
        oldDateStart,
        unitID_internal,
        cancellationReason,
        refundAmount
    ) => {
        let body = {
            AccessToken: sessionStorage.getItem("AccessToken"),
            EmailAddress: email,
            VenueID: venueID,
            UnitID_internal: unitID_internal,
            endImmediately: true,
            "StartDateTimeUTC.ISO8601": new Date(oldDateStart).toISOString(),
            UserTimeZone: new Date().getTimezoneOffset(),
            CancellationReasons: cancellationReason,
            requestedRefundAmount: refundAmount,
        };
        axios
            .post(
                "/venuemgmtconsole/subscribers/resetSubscriptionEndDate",
                body,
                {
                    headers: {},
                }
            )
            .then(
                (response) => {
                    toast.success("Deleted Resident!", {
                        closeButton: false,
                    });
                    this.toggleModifyResident();
                    this.setState({ activeData: undefined });
                    this.getVenueDetails(venueID);
                },
                (error) => {
                    toast.error("There was an error!", {
                        closeButton: false,
                    });
                    this.toggleModifyResident();
                    this.setState({ recentResidents: recentResidents });
                }
            );
    };
    changeHandler = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };
    getKeyPools = () => {
        const data = {
            AccessToken: sessionStorage.getItem("AccessToken"),
        };
        axios
            .post("/networkoperatorconsole/keypools/listKeypools", data, {
                headers: {},
            })
            .then((response) => {
                var keypoolArray = [];
                let optionTemplateKeyPools = [];
                Object.keys(response.data.myKeyPoolList).map((oneKey, i) => {
                    if (oneKey === this.state.keypoolID) {
                        optionTemplateKeyPools.push(
                            <MDBSelectOption selected value={oneKey} key={i}>
                                {response.data.myKeyPoolList[oneKey]["ssid"]}
                            </MDBSelectOption>
                        );
                    } else {
                        optionTemplateKeyPools.push(
                            <MDBSelectOption value={oneKey} key={i}>
                                {response.data.myKeyPoolList[oneKey]["ssid"]}
                            </MDBSelectOption>
                        );
                    }
                    return true;
                });
                keypoolArray.push(
                    <MDBSelect
                        id="selectKeypool"
                        className="form-control"
                        getValue={this.handleSelectKeyPool}
                    >
                        <MDBSelectInput
                            className="selectInput selectKeypool"
                            selected="Choose the KeyPool"
                        />
                        <MDBSelectOptions search>
                            {optionTemplateKeyPools}
                        </MDBSelectOptions>
                    </MDBSelect>
                );
                this.setState({
                    keypoolForm: keypoolArray,
                });
            })
            .catch((error) => {
                console.log(
                    "🚀 ~ file: QuickLinks.js:270 ~ QuickLinks ~ error",
                    error
                );
            });
    };
    addVenue = (
        venueName,
        email,
        address1,
        address2,
        phoneNumber,
        country,
        city,
        region,
        zipcode,
        group,
        countdown,
        durationResident,
        durationGuest,
        firstName,
        lastName,
        minVlanRes,
        maxVlanRes,
        minVlanGuest,
        maxVlanGuest,
        minVlanRoaming,
        maxVlanRoaming,
        venueType,
        enableShoppingCart,
        sendSubscriptionAddedEmail,
        sendSubscriptionEndEmail,
        allowOverlapping,
        displayFCCLabel,
        typicalLatency
    ) => {
        if (address2 === undefined) {
            address2 = "";
        }
        this.setState({ loading: true });
        this.setState({ reloadVenueList: true });
        const data = {
            VenueName: venueName,
            AddressLine1: address1,
            AddressLine2: address2,
            City: city,
            State: region,
            PostalCode: zipcode,
            "Country.ISO3166A3": country,
            PhoneNumber: phoneNumber,
            EmailAddress: email,
            PropertyManagementGroupID: group,
            AccessToken: sessionStorage.getItem("AccessToken"),
            DefaultVLANForRoamingSubscribers: "2000",
            WelcomeEmail_countdown_hours: countdown,
            DefaultSubscriptionDurationHours_GlobalWirelessPlusLocalWiredAccess:
                durationResident,
            DefaultSubscriptionDurationHours_LocalWirelessAccess: durationGuest,
            PrimaryContactGivenName: firstName,
            PrimaryContactFamilyName: lastName,
            MinimumAutoGeneratedVLAN_GlobalWirelessPlusLocalWiredAccess:
                minVlanRes,
            MaximumAutoGeneratedVLAN_GlobalWirelessPlusLocalWiredAccess:
                maxVlanRes,
            MinimumAutoGeneratedVLAN_LocalWirelessAccess: minVlanGuest,
            MaximumAutoGeneratedVLAN_LocalWirelessAccess: maxVlanGuest,
            MinimumAutoGeneratedVLAN_GlobalWirelessRoaming: minVlanRoaming,
            MaximumAutoGeneratedVLAN_GlobalWirelessRoaming: maxVlanRoaming,
            VenueType: venueType,
            enableShoppingCart: enableShoppingCart,
            sendSubscriptionAddedEmail: sendSubscriptionAddedEmail,
            sendSubscriptionEndEmail: sendSubscriptionEndEmail,
            allowOverlappingSubscriptionWiredUnit: allowOverlapping,
            FCCLabel: {
                displayLabel: displayFCCLabel,
                TypicalLatency: typicalLatency,
            },
        };
        // console.log(data)
        axios
            .post("/networkoperatorconsole/venues/addVenue", data, {
                headers: {},
            })
            .then(
                (response) => {
                    // this.getVenues();
                    this.getVenueDetails(response.data.VenueID);
                    this.updateName(venueName, response.data.VenueID);
                    this.setState({ loading: false });
                    this.toggleConfirmContinue();
                    this.setState({
                        reloadVenueList: false,
                        venueID: response.data.VenueID,
                        tabNumber: 2,
                        tabChanged: true,
                        addedVenue: true,
                    });
                    sessionStorage.setItem("venueName", venueName);
                    sessionStorage.setItem("addedVenue", true);
                },
                (error) => {
                    toast.error(error.response.data.message, {
                        closeButton: false,
                    });
                    this.setState({
                        loading: false,
                        reloadVenueList: false,
                        tabNumber: 1,
                        tabChanged: false,
                        addedVenue: false,
                    });
                }
            );
    };
    modifyVenueInfo = (
        address1,
        address2,
        phoneNumber,
        country,
        city,
        region,
        zipcode,
        email,
        countdown,
        durationResident,
        durationGuest,
        contactFirstName,
        contactLastName,
        minVlanRes,
        maxVlanRes,
        minVlanGuest,
        maxVlanGuest,
        minVlanRoaming,
        maxVlanRoaming,
        venueType,
        enableShoppingCart,
        sendSubscriptionAddedEmail,
        sendSubscriptionEndEmail,
        allowOverlapping,
        displayFCCLabel,
        typicalLatency
    ) => {
        if (address2 === undefined) {
            address2 = "";
        }
        this.setState({ loading: true });
        this.setState({ reloadVenueList: true });
        const updateVenueAddress = {
            VenueID: sessionStorage.getItem("venueID"),
            AddressLine1: address1,
            AddressLine2: address2,
            City: city,
            State: region,
            PostalCode: zipcode,
            "Country.ISO3166A3": country,
            AccessToken: sessionStorage.getItem("AccessToken"),
        };
        let updateVenue;
        typicalLatency !== ""
            ? (updateVenue = {
                  VenueID: sessionStorage.getItem("venueID"),
                  AccessToken: sessionStorage.getItem("AccessToken"),
                  WelcomeEmail_countdown_hours: countdown,
                  DefaultSubscriptionDurationHours_GlobalWirelessPlusLocalWiredAccess:
                      durationResident,
                  DefaultSubscriptionDurationHours_LocalWirelessAccess:
                      durationGuest,
                  PrimaryContactGivenName: contactFirstName,
                  PrimaryContactFamilyName: contactLastName,
                  PrimaryContactEmailAddress: email,
                  "PrimaryContactPhoneNumber.E164": phoneNumber,
                  MinimumAutoGeneratedVLAN_GlobalWirelessPlusLocalWiredAccess:
                      minVlanRes,
                  MaximumAutoGeneratedVLAN_GlobalWirelessPlusLocalWiredAccess:
                      maxVlanRes,
                  MinimumAutoGeneratedVLAN_LocalWirelessAccess: minVlanGuest,
                  MaximumAutoGeneratedVLAN_LocalWirelessAccess: maxVlanGuest,
                  MinimumAutoGeneratedVLAN_GlobalWirelessRoaming:
                      minVlanRoaming,
                  MaximumAutoGeneratedVLAN_GlobalWirelessRoaming:
                      maxVlanRoaming,
                  VenueType: venueType,
                  enableShoppingCart: enableShoppingCart,
                  sendSubscriptionAddedEmail: sendSubscriptionAddedEmail,
                  sendSubscriptionEndEmail: sendSubscriptionEndEmail,
                  allowOverlappingSubscriptionWiredUnit: allowOverlapping,
                  FCCLabel: {
                      displayLabel: displayFCCLabel,
                      TypicalLatency: typicalLatency,
                  },
              })
            : (updateVenue = {
                  VenueID: sessionStorage.getItem("venueID"),
                  AccessToken: sessionStorage.getItem("AccessToken"),
                  WelcomeEmail_countdown_hours: countdown,
                  DefaultSubscriptionDurationHours_GlobalWirelessPlusLocalWiredAccess:
                      durationResident,
                  DefaultSubscriptionDurationHours_LocalWirelessAccess:
                      durationGuest,
                  PrimaryContactGivenName: contactFirstName,
                  PrimaryContactFamilyName: contactLastName,
                  PrimaryContactEmailAddress: email,
                  "PrimaryContactPhoneNumber.E164": phoneNumber,
                  MinimumAutoGeneratedVLAN_GlobalWirelessPlusLocalWiredAccess:
                      minVlanRes,
                  MaximumAutoGeneratedVLAN_GlobalWirelessPlusLocalWiredAccess:
                      maxVlanRes,
                  MinimumAutoGeneratedVLAN_LocalWirelessAccess: minVlanGuest,
                  MaximumAutoGeneratedVLAN_LocalWirelessAccess: maxVlanGuest,
                  MinimumAutoGeneratedVLAN_GlobalWirelessRoaming:
                      minVlanRoaming,
                  MaximumAutoGeneratedVLAN_GlobalWirelessRoaming:
                      maxVlanRoaming,
                  VenueType: venueType,
                  enableShoppingCart: enableShoppingCart,
                  sendSubscriptionAddedEmail: sendSubscriptionAddedEmail,
                  sendSubscriptionEndEmail: sendSubscriptionEndEmail,
                  allowOverlappingSubscriptionWiredUnit: allowOverlapping,
                  FCCLabel: {
                      displayLabel: displayFCCLabel,
                  },
              });
        Promise.all([
            axios.post(
                "/networkoperatorconsole/venues/updateVenueAddress",
                updateVenueAddress,
                { headers: {} }
            ),
            axios.post(
                "/networkoperatorconsole/venues/configureVenue",
                updateVenue,
                {
                    headers: {},
                }
            ),
        ])
            .then(async ([res1, res2]) => {
                toast.success("Updated Venue!", {
                    closeButton: false,
                });
                this.setState({ loading: false });
                this.updateName(
                    sessionStorage.getItem("venueName"),
                    sessionStorage.getItem("venueID")
                );
                this.setState({ loading: false });
                // this.toggleAddNewVenue();
                this.setState({
                    reloadVenueList: false,
                    venueID: sessionStorage.getItem("venueID"),
                    tabNumber: 2,
                    tabChanged: true,
                });
            })
            .catch((error) => {
                toast.error(error.response.data.message, {
                    closeButton: false,
                });
                this.setState({
                    loading: false,
                    reloadVenueList: false,
                    tabNumber: 1,
                    tabChanged: false,
                });
            });
    };
    addUnit = (
        venueID,
        unitName,
        unitBuilding,
        unitFloor,
        address1,
        address2,
        country,
        city,
        region,
        zipcode,
        purpose,
        vlan,
        selectedPlan,
        smsCode
    ) => {
        if (address2 === undefined) {
            address2 = "";
        }
        this.setState({ loadingUnit: true });
        this.setState({ reloadVenueList: true });
        const data = {
            VenueID: venueID,
            UnitID: unitName,
            UnitFloor: unitFloor,
            UnitBuilding: unitBuilding,
            AddressLine1: address1,
            AddressLine2: address2,
            City: city,
            State: region,
            PostalCode: zipcode,
            "Country.ISO3166A3": country,
            AccessToken: sessionStorage.getItem("AccessToken"),
            vlan: vlan,
            purpose: purpose,
            ServicePlanID: selectedPlan,
            SMSCode: smsCode,
        };
        axios
            .post("/networkoperatorconsole/venues/addVenueUnits", data, {
                headers: {},
            })
            .then(
                (response) => {
                    this.props.updateName(
                        sessionStorage.getItem("venueName"),
                        venueID
                    );
                    toast.success("Added Unit to Venue!", {
                        closeButton: false,
                    });
                    this.setState({ loadingUnit: false });
                    this.toggleAddUnit();
                    this.setState({ reloadVenueList: false });
                },
                (error) => {
                    toast.error("There was an error", {
                        closeButton: false,
                    });
                    this.setState({ loadingUnit: false });
                    this.setState({ reloadVenueList: false });
                }
            );
    };
    submitAddDomainAdministrators = (
        email,
        firstName,
        lastName,
        language,
        role
    ) => {
        this.setState({ add_domainadmin: true });
        let body = {
            AccessToken: sessionStorage.getItem("AccessToken"),
            EmailAddress: email,
            GivenName: firstName,
            FamilyName: lastName,
            locale: language,
            Role: role,
        };
        axios
            .post("/networkoperatorconsole/staff/enableStaffAccess", body, {
                headers: {},
            })
            .then(
                (response) => {
                    toast.success("Added Staff!", {
                        closeButton: false,
                    });
                    this.toggleAddDomainAdministrator();
                    // this.props.updateName(this.props.venueName, this.props.venueID);
                    this.setState({ add_domainadmin: false });
                },
                (error) => {
                    toast.error("There was an error!", {
                        closeButton: false,
                    });
                    this.toggleAddDomainAdministrator();
                    this.setState({ add_domainadmin: false });
                }
            );
    };
    getVenues = (selectedID) => {
        this.setState({
            venueID: undefined,
            selectedUnit: undefined,
            selectedBuilding: undefined,
            selectedFloor: undefined,
            optionTemplateBuilding: [],
            optionTemplateFloor: [],
            optionTemplateUnits: [],
            unitID_internal: undefined,
            venueName: undefined,
        });
        this.setState({ attributeForm: [] });
        let body = { AccessToken: sessionStorage.getItem("AccessToken") };
        axios
            .post("/networkoperatorconsole/venues/listVenues", body, {
                headers: {},
            })
            .then((response) => {
                var formArrayVenues = [];
                let optionTemplateVenues = [];
                this.setState({ venueList: response.data.VenueList });
                Object.keys(response.data.VenueList).map((oneKey, i) => {
                    if (selectedID === undefined) {
                        this.setState({ firstID: oneKey });
                    }
                    if (selectedID === oneKey) {
                        optionTemplateVenues.push(
                            <MDBSelectOption selected value={oneKey} key={i}>
                                {response.data.VenueList[oneKey]["VenueName"]}
                            </MDBSelectOption>
                        );
                    } else {
                        optionTemplateVenues.push(
                            <MDBSelectOption value={oneKey} key={i}>
                                {response.data.VenueList[oneKey]["VenueName"]}
                            </MDBSelectOption>
                        );
                    }
                    return true;
                });
                formArrayVenues.push(
                    <MDBSelect
                        id="selectVenueSubscriberModal"
                        getValue={this.handleSelectChangeVenue}
                        className="form-control"
                    >
                        <MDBSelectInput
                            className="selectInput venueSelectedSubscriberModal"
                            selected="Choose your venue"
                        />
                        <MDBSelectOptions search>
                            {optionTemplateVenues}
                        </MDBSelectOptions>
                    </MDBSelect>
                );
                this.setState({
                    attributeForm: formArrayVenues,
                });
            })
            .catch((error) => {
                this.setState({ error: true });
            });
    };
    getVenueDetails = (index) => {
        let body = {
            AccessToken: sessionStorage.getItem("AccessToken"),
            VenueID: index,
            UserTimeZone: new Date().getTimezoneOffset(),
        };
        this.setState({
            address1: undefined,
            address2: undefined,
            city: undefined,
            region: undefined,
            country: undefined,
            postalcode: undefined,
            unitData: undefined,
            expiringData: undefined,
            searchData: undefined,
            value: "",
            defaultSubscriptionDurationRes: 0,
            loading: true,
            unifForm: undefined,
            hasUpdated: false,
            optionTemplateUnits: [],
            servicePlans: undefined,
        });
        axios
            .post("/networkoperatorconsole/venues/listVenueDetails", body, {
                headers: {},
            })
            .then((response) => {
                var sortedKeys = _(response.data[index]["Keypools"])
                    .map(function (v, k) {
                        // insert the key into the object
                        return _.merge({}, v, { key: k });
                    })
                    .sortBy("DisplayOrder") // sort by name
                    .value();
                this.setState({
                    leasingAgents: [],
                    venueAdmins: [],
                });
                this.setState({
                    address1: response.data[index]["AddressLine1"],
                    address2: response.data[index]["AddressLine2"],
                    city: response.data[index]["City"],
                    region: response.data[index]["State"],
                    country: response.data[index]["Country.ISO3166A3"],
                    postalcode: response.data[index]["PostalCode"],
                    unitData:
                        response.data[index][
                            "Units-Building-Floor-List-Subscribers"
                        ],
                    defaultSubscriptionDurationRes:
                        response.data[index][
                            "DefaultSubscriptionDurationHours_GlobalWirelessPlusLocalWiredAccess"
                        ],
                    selectDefaultServicePlanResident:
                        response.data[index][
                            "DefaultServicePlanID_GlobalWirelessPlusLocalWiredAccess"
                        ],
                    selectDefaultServicePlanWireless:
                        response.data[index][
                            "DefaultServicePlanID_LocalWirelessAccess"
                        ],
                    venueServicePlans:
                        response.data[index]["AvailableServicePlans"],
                    hasUpdated: true,
                    activeData:
                        response.data[index][
                            "RecentlyStartedSubscriptionsList_7day"
                        ],
                    activeData1Day:
                        response.data[index]["UpcomingSubscriptionsList_1day"],
                    activeData7Day:
                        response.data[index]["UpcomingSubscriptionsList_7day"],
                    allActiveData: response.data[index]["Subscriptions"],
                    expiringData:
                        response.data[index][
                            "UpcomingSubscriptionEndList_7day_unconfirmed"
                        ],
                    expiringData14Days:
                        response.data[index][
                            "UpcomingSubscriptionEndList_14day"
                        ],
                    searchData: response.data[index]["UnitsAndSubscriptions"],
                    UnitsBuildingFloorListSusbcribers:
                        response.data[index][
                            "Units-Building-Floor-List-Subscribers"
                        ],
                    venueType: response.data[index]["VenueType"],
                    venueInfo: response.data[index],
                    keypoolID: Object.keys(response.data[index]["Keypools"])[0],
                    servicePlans: response.data[index]["AvailableServicePlans"],
                    propMgmtID:
                        response.data[index]["PropertyManagementGroup"][
                            "PropertyManagementGroupID"
                        ],
                    sharedSecret:
                        response.data[index]["PropertyManagementGroup"][
                            "SharedSecret"
                        ],
                    keyPoolList: sortedKeys,
                    venueAdminList: response.data[index]["VenueAdministrators"],
                    venueLeasingAgentList:
                        response.data[index]["LeasingAgents"],
                    venueEquipList: response.data[index]["CoreEquipmentStatus"],
                    availableServicePlans:
                        response.data[index]["AvailableServicePlans"],
                });
                let units = Object.keys(
                    response.data[index][
                        "Units-Building-Floor-List-Subscribers"
                    ]
                );
                let buildings = [];
                let objUnits = {};
                this.getAdmins();
                for (let i = 0; i < units.length; i++) {
                    buildings.push(
                        Object.keys(
                            response.data[index][
                                "Units-Building-Floor-List-Subscribers"
                            ][units[i]]
                        )
                    );
                    for (let j = 0; j < buildings[i].length; j++) {
                        let floor = Object.keys(
                            response.data[index][
                                "Units-Building-Floor-List-Subscribers"
                            ][units[i]][buildings[i][j]]
                        );
                        for (let k = 0; k < floor.length; k++) {
                            // console.log(response.data[index]["Units-Building-Floor-List-Subscribers"][units[i]][buildings[i][j]][floor[k]]["Subscriptions"])
                            if (
                                response.data[index][
                                    "Units-Building-Floor-List-Subscribers"
                                ][units[i]][buildings[i][j]][floor[k]][
                                    "PrimaryUnitID_internal"
                                ] === "" &&
                                ((response.data[index][
                                    "Units-Building-Floor-List-Subscribers"
                                ][units[i]][buildings[i][j]][floor[k]][
                                    "allowOverlappingSubscriptionWiredUnit"
                                ] === false &&
                                    Object.keys(
                                        response.data[index][
                                            "Units-Building-Floor-List-Subscribers"
                                        ][units[i]][buildings[i][j]][floor[k]][
                                            "Subscriptions"
                                        ]
                                    ).length === 0) ||
                                    response.data[index][
                                        "Units-Building-Floor-List-Subscribers"
                                    ][units[i]][buildings[i][j]][floor[k]][
                                        "allowOverlappingSubscriptionWiredUnit"
                                    ] === true)
                            ) {
                                objUnits[i] =
                                    response.data[index][
                                        "Units-Building-Floor-List-Subscribers"
                                    ][units[i]][buildings[i]];
                            }
                        }
                    }
                }

                let optionTemplateServicePlans = [];
                Object.keys(response.data[index]["AvailableServicePlans"]).map(
                    (value, i) => {
                        optionTemplateServicePlans.push({
                            value: response.data[index][
                                "AvailableServicePlans"
                            ][value]["ServicePlanID"],
                            name: response.data[index]["AvailableServicePlans"][
                                value
                            ]["ServicePlanName"],
                        });
                        return true;
                    }
                );
                let optionTemplateUnits = [];
                Object.keys(objUnits).map((oneKey, i) => {
                    optionTemplateUnits.push({
                        value: units[oneKey],
                        name: units[oneKey],
                    });
                    return true;
                });
                this.setState({
                    optionTemplateUnits: optionTemplateUnits,
                    optionTemplateServicePlans: optionTemplateServicePlans,
                    loading: false,
                });
            })
            .catch((error) => {
                console.log(
                    "🚀 ~ file: QuickLinks.js:705 ~ QuickLinks ~ error:",
                    error
                );
                // console.log("🚀 ~ file: QuickLinks.js:630 ~ QuickLinks ~ error:", error)
                // this.getVenueDetails(index);
                this.setState({
                    error: true,
                    loading: false,
                });
            });
    };
    getAdmins = () => {
        this.setState({ staffArrayAll: undefined });
        let staffArrayAll = [];
        let staffArrayVA = [];
        let staffArrayLeasing = [];
        if (this.state.venueAdminList !== undefined) {
            for (var i = 0; i < this.state.venueAdminList.length; i++) {
                staffArrayVA[i] = {
                    [this.state.venueAdminList[i]]: "Venue Administrator",
                };
            }
            for (var j = 0; j < this.state.venueLeasingAgentList.length; j++) {
                staffArrayLeasing[j] = {
                    [this.state.venueLeasingAgentList[j]]: "Leasing Agent",
                };
            }
            staffArrayAll = staffArrayVA.concat(staffArrayLeasing);
            this.setState({ staffArrayAll: [...staffArrayAll] });
        }
    };
    handleSelectVenue = (value, name) => {
        this.setState({
            selectVenue: value,
            selectedID: value,
        });
        this.props.updateName(name.name, name.value);

        this.getVenueDetails(value);
    };
    handleSelectChangeVenue = (value, name) => {
        this.setState({
            optionTemplateUnits: [],
            optionTemplateBuilding: [],
            optionTemplateFloor: [],
        });
        this.setState({ hasUpdated: false });
        var venue = value;
        var lDiv = document.createElement("div");
        document.body.appendChild(lDiv);
        lDiv.style.fontSize = "" + 20 + "px";
        lDiv.style.position = "absolute";
        lDiv.style.left = -1000;
        lDiv.style.top = -1000;
        lDiv.innerHTML = venue;
        document.body.removeChild(lDiv);
        lDiv = null;
        sessionStorage.setItem("venueName", venue);
        sessionStorage.setItem("venueID", value);
        this.setState({
            selectedID: value[0],
            hasUpdated: true,
        });
        this.getVenueDetails(value);
        this.updateName(venue, value);
    };
    handleSelectChangeVenueFromList = (venueName, venueID) => {
        sessionStorage.setItem("venueName", venueName);
        sessionStorage.setItem("venueID", venueID);
        this.getVenues(venueID);
        this.setState({ selectedID: venueID });
        this.updateName(venueName, venueID);
    };
    resetBuildingForm = (value) => {
        this.setState({
            buildingForm: (
                <MDBSelect
                    id="selectBuilding"
                    getValue={this.handleSelectChangeBuilding}
                    className="form-control"
                >
                    <MDBSelectInput
                        className="selectInput selectBuilding"
                        selected="Choose your building"
                    />
                </MDBSelect>
            ),
        });
        this.setState({
            floorForm: (
                <MDBSelect
                    id="selectFloor"
                    getValue={this.handleSelectChangeFloor}
                    className="form-control"
                >
                    <MDBSelectInput
                        className="selectInput selectFloor"
                        selected="Choose your floor"
                    />
                </MDBSelect>
            ),
        });
        setTimeout(() => {
            this.handleSelectChangeUnit(value);
        }, 1);
    };
    handleSelectChangeUnit = (value) => {
        if (this.state.unitData !== undefined) {
            this.setState({
                selectedUnit: value,
                optionTemplateBuilding: [],
                optionTemplateFloor: [],
                selectedBuilding: undefined,
                selectedFloor: undefined,
            });
            let optionTemplateBuilding = [];
            this.setState({
                optionTemplateBuilding: [],
            });
            Object.keys(this.state.unitData[value]).map((oneKey, i) => {
                if (oneKey === "DEFAULT") {
                    optionTemplateBuilding.push({
                        value: oneKey,
                        name: oneKey,
                    });
                } else {
                    optionTemplateBuilding.push({
                        value: oneKey,
                        name: oneKey,
                    });
                }
                return true;
            });
            this.setState({
                optionTemplateBuilding: optionTemplateBuilding,
            });
        }
    };
    resetFloorForm = (value) => {
        setTimeout(() => {
            this.handleSelectChangeBuilding(value);
        }, 1);
    };
    handleSelectChangeBuilding = (value) => {
        if (value !== null) {
            this.setState({ selectedBuilding: value });
            let optionTemplateFloor = [];
            Object.keys(
                this.state.unitData[this.state.selectedUnit][value]
            ).map((oneKey, i) => {
                if (oneKey === "DEFAULT") {
                    optionTemplateFloor.push({ value: oneKey, name: oneKey });
                } else {
                    optionTemplateFloor.push({ value: oneKey, name: oneKey });
                }
                return true;
            });
            this.setState({
                optionTemplateFloor: optionTemplateFloor,
            });
        }
    };
    handleSelectChangeFloor = (value) => {
        if (value !== null) {
            this.setState({ selectedFloor: value });
            this.setState({
                unitID_internal:
                    this.state.unitData[this.state.selectedUnit][
                        this.state.selectedBuilding
                    ][value]["UnitID_internal"],
            });
        }
    };
    updateName = (value, index) => {
        sessionStorage.setItem("venueName", value);
        sessionStorage.setItem("venueID", index);
        this.setState({
            venueID: index,
            venueName: value,
        });
    };
    render() {
        if (this.state.postMessage) {
            var h = document.getElementById("overlayAdd");
            h.style.display = "block";
            setTimeout(function () {
                h.style.display = "block";
            }, 5000);
            setTimeout(() => {
                h.style.display = "none";
                this.setState({ postMessage: "" });
                this.props.refresh();
            }, 6000);
        }
        return (
            <>
                {this.state.paymentFailed ? (
                    <MDBModal
                        isOpen={this.state.modalAddPayment}
                        toggle={this.togglePayment}
                        size="md"
                        className="form"
                        centered
                    >
                        <MDBModalHeader toggle={this.togglePayment}>
                            Modify Billing Credit Card Info
                        </MDBModalHeader>
                        <MDBModalBody className="error">
                            <h4>
                                The subscriber's credit card info needs to be
                                updated for billing purposes.
                            </h4>
                            <MDBBtn
                                color="danger"
                                className="btn Ripple-parent btn-default centered upgradePlan"
                                href={
                                    "/update/checkout?ui=" +
                                    window.location.pathname.slice(1)
                                }
                            >
                                Modify Billing CC
                            </MDBBtn>
                        </MDBModalBody>
                    </MDBModal>
                ) : (
                    ""
                )}
                <MDBContainer className="quicklinks">
                    <MDBRow>
                        <MDBCol size="6" md="3">
                            <div className="quicklink">
                                <MDBLink
                                    to="#"
                                    onClick={this.toggleAddNewSubscriber}
                                >
                                    <MDBIcon icon="user-plus" />
                                    <p className="">
                                        Add New
                                        <br /> Subscriber
                                    </p>
                                </MDBLink>
                            </div>
                        </MDBCol>
                        <MDBCol size="6" md="3">
                            <div className="quicklink">
                                <MDBLink
                                    to="#"
                                    onClick={this.toggleAddNewVenue}
                                    disabled={
                                        sessionStorage.getItem(
                                            "customerSupport"
                                        ) > 0
                                            ? true
                                            : false
                                    }
                                >
                                    <MDBIcon icon="stopwatch" />
                                    <p className="">
                                        Add New
                                        <br />
                                        Venue
                                    </p>
                                </MDBLink>
                            </div>
                        </MDBCol>
                        <MDBCol size="6" md="3">
                            <div className="quicklink">
                                <MDBLink
                                    to="#"
                                    onClick={this.toggleAddUnit}
                                    disabled={
                                        sessionStorage.getItem(
                                            "customerSupport"
                                        ) > 0
                                            ? true
                                            : false
                                    }
                                >
                                    <MDBIcon icon="plus-circle" />
                                    <p className="">
                                        Add New
                                        <br />
                                        Unit
                                    </p>
                                </MDBLink>
                            </div>
                        </MDBCol>
                        <MDBCol size="6" md="3">
                            <div className="quicklink">
                                <MDBLink
                                    to="#"
                                    onClick={this.toggleAddDomainAdministrator}
                                    disabled={
                                        sessionStorage.getItem(
                                            "customerSupport"
                                        ) > 0
                                            ? true
                                            : false
                                    }
                                >
                                    <MDBIcon icon="edit" />
                                    <p className="">
                                        Add
                                        <br />
                                        Administrator
                                    </p>
                                </MDBLink>
                            </div>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
                <MDBModal
                    isOpen={this.state.modalAddNewSubscriber}
                    toggle={this.toggle}
                    size="lg"
                    className="form"
                    centered
                >
                    <AddSubscriber
                        toggleAddNewSubscriber={this.toggleAddNewSubscriber}
                        submitHandler={this.submitHandler}
                        loading={this.state.loading}
                        attributeForm={this.state.attributeForm}
                        venueID={this.state.venueID}
                        venueName={this.state.venueName}
                        unitForm={this.state.unitForm}
                        selectedUnit={this.state.selectedUnit}
                        buildingForm={this.state.buildingForm}
                        selectedBuilding={this.state.selectedBuilding}
                        floorForm={this.state.floorForm}
                        selectedFloor={this.state.selectedFloor}
                        hasUpdated={this.state.hasUpdated}
                        hasUpdatedUnit={this.state.hasUpdatedUnit}
                        hasUpdatedBuilding={this.state.hasUpdatedBuilding}
                        hasUpdatedFloor={this.state.hasUpdatedFloor}
                        unitID_internal={this.state.unitID_internal}
                        optionTemplateUnits={this.state.optionTemplateUnits}
                        optionTemplateBuilding={
                            this.state.optionTemplateBuilding
                        }
                        optionTemplateFloor={this.state.optionTemplateFloor}
                        optionTemplateServicePlans={
                            this.state.optionTemplateServicePlans
                        }
                        handleSelectChangeUnit={this.handleSelectChangeUnit}
                        handleSelectChangeBuilding={
                            this.handleSelectChangeBuilding
                        }
                        handleSelectChangeFloor={this.handleSelectChangeFloor}
                        getVenues={this.getVenues}
                        optionTemplateVenues={this.props.optionTemplateVenues}
                        handleSelectVenue={this.handleSelectVenue}
                        handleSelectChangeVenue={this.handleSelectChangeVenue}
                    />
                </MDBModal>
                <MDBModal
                    isOpen={this.state.modalAddNewVenue}
                    // toggle={this.toggle}
                    size="fluid"
                    className="form"
                    centered
                >
                    <AddNewVenue
                        toggleAddNewVenue={this.toggleAddNewVenue}
                        submitHandler={this.addVenue}
                        loading={this.state.loading}
                        getVenues={this.getVenues}
                        venueID={this.state.venueID}
                        venueName={sessionStorage.getItem("venueName")}
                        modifyVenue={this.modifyVenue}
                        modifyVenueInfo={this.modifyVenueInfo}
                        submitDelete={this.submitDelete}
                        sharedSecret={this.state.sharedSecret}
                        attributeForm={this.state.attributeForm}
                        venueInfo={this.state.venueInfo}
                        loadingVenueDetails={this.state.loadingVenueDetails}
                        groupForm={this.state.groupForm}
                        keypoolForm={this.state.keypoolForm}
                        unitsArrayAll={this.state.unitsArrayAll}
                        keyPoolList={this.state.keyPoolList}
                        servicePlans={this.state.servicePlans}
                        staffArrayAll={this.state.staffArrayAll}
                        venueEquipList={this.state.venueEquipList}
                        getVenueDetails={this.getVenueDetails}
                        updateName={this.updateName}
                        keypoolID={this.state.keypoolID}
                        propMgmtID={this.state.propMgmtID}
                        tabNumber={this.state.tabNumber}
                        tabChanged={this.state.tabChanged}
                        selectDefaultServicePlanResident={
                            this.state.selectDefaultServicePlanResident
                        }
                        selectDefaultServicePlanWireless={
                            this.state.selectDefaultServicePlanWireless
                        }
                        selectedID={this.state.selectedID}
                        toggleConfirmContinue={this.toggleConfirmContinue}
                        toggleConfirmContinueOpen={
                            this.state.toggleConfirmContinueOpen
                        }
                        modal={this.state.modal}
                        addedVenue={this.state.addedVenue}
                        allowOverlapping={this.props.allowOverlapping}
                    />
                </MDBModal>
                <MDBModal
                    isOpen={this.state.modalAddUnit}
                    toggle={this.toggle}
                    size="lg"
                    className="form"
                    centered
                >
                    <AddUnit
                        toggleAddUnit={this.toggleAddUnit}
                        venueID={this.state.selectedID}
                        venueName={sessionStorage.getItem("venueName")}
                        sharedSecret={this.state.sharedSecret}
                        submitAddUnit={this.addUnit}
                        loadingUnit={this.state.loadingUnit}
                        attributeForm={this.state.attributeForm}
                        hasUpdated={this.state.hasUpdated}
                        selectedID={this.state.selectedID}
                        address1={this.state.address1}
                        address2={this.state.address2}
                        city={this.state.city}
                        region={this.state.region}
                        country={this.state.country}
                        postalcode={this.state.postalcode}
                        optionTemplatePlans={this.state.optionTemplatePlans}
                        optionTemplateServicePlans={
                            this.state.optionTemplateServicePlans
                        }
                        availableServicePlans={this.state.availableServicePlans}
                        optionTemplateVenues={this.props.optionTemplateVenues}
                        updateName={this.props.updateName}
                        handleSelectVenue={this.handleSelectVenue}
                    />
                </MDBModal>
                <MDBModal
                    isOpen={this.state.modalAddDomainAdministrator}
                    toggle={this.toggle}
                    size="lg"
                    className="form"
                    centered
                >
                    <AddDomainAdministrator
                        submitAddDomainAdministrators={
                            this.submitAddDomainAdministrators
                        }
                        toggleAddDomainAdministrator={
                            this.toggleAddDomainAdministrator
                        }
                        add_domainadmin={this.state.add_domainadmin}
                        loading={this.state.loading}
                        updateName={this.props.updateName}
                    />
                </MDBModal>
            </>
        );
    }
}
export default withTranslation()(QuickLinks);
