/* Find Deals screen */
import React, { Component, Suspense } from "react";
import axios from "../../axios";
import "../../brands/common/css/common.scss";
import Footer from "../../components/Common/Footer";
let PaymentSuccessComponent = React.lazy(() =>
    import(
        "../../brands/" +
            sessionStorage.getItem("customBrand") +
            "/components/CreateAccount/PaymentSuccess"
    )
);
let PhoneNumber;
class PaymentSuccess extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firstName: undefined,
            lastName: undefined,
            phoneNumber: undefined,
            email: undefined,
            optionTemplateUnit: undefined,
            unit: undefined,
            tandc: false,
            dateStart: null,
            disableButton: "disabled",
            message: undefined,
        };
    }
    componentDidMount() {
        this.getReceipt();
        // this.getDisplayOptions();
    }
    getReceipt = () => {
        const body = {
            StripeClientSecret: sessionStorage.getItem("clientSecret"),
        };
        axios
            .post("/operatorconsole/shoppingcart/getStripeReceiptURL", body, {
                headers: {},
            })
            .then((response) => {
                this.setState({
                    receiptURL: response.data.StripeReceiptURL,
                });
            })
            .catch((error) => {
                this.setState({
                    error: true,
                });
            });
    };
    render() {
        return (
            <>
                {this.state.message !== undefined ? (
                    <div className="overlay" style={{ display: "block" }}>
                        <div className="overlayMessage">
                            {this.state.message}
                            <div className="nextButton">
                                <br />
                                <a
                                    className="btn Ripple-parent btn-default"
                                    href="/"
                                    rel="noreferrer"
                                    onClick={this.handleAnchorClick}
                                >
                                    Return to Login
                                </a>
                            </div>
                        </div>
                    </div>
                ) : (
                    ""
                )}
                <Suspense
                    fallback={
                        <div
                            className="overlay"
                            id="overlay"
                            style={{ display: "block" }}
                        >
                            <div className="overlayMessage">
                                <div
                                    className="spinner-border spinner-border"
                                    role="status"
                                >
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                        </div>
                    }
                >
                    <PaymentSuccessComponent
                        receiptURL={this.state.receiptURL}
                        logo={this.props.logoLogin}
                        brand={this.props.brand}
                        phone={sessionStorage.getItem("supportPhone")}
                    />
                    <Footer />
                </Suspense>
            </>
        );
    }
}
export default PaymentSuccess;
