import React, { Component } from "react";

import {
    MDBSpinner,
    MDBModal,
    MDBModalHeader,
    MDBModalBody,
    MDBModalFooter,
    MDBBtn,
    toast,
} from "mdbreact";

import VenuesTable from "../../../components/NetworkMgmt/Venues/Datatables/VenuesTableWithDelete";

import ModifyVenue from "../../../components/NetworkMgmt/Forms/ModifyVenue/ModifyVenue";

import { withTranslation } from "react-i18next";

import axios from "../../../axios";

class ViewVenues extends Component {
    constructor(props) {
        super(props);
        this.state = {
            active: false,
            loading: false,
            confirmDelete: undefined,
            // venueID: this.props.venueInfo["VenueID"]
        };
    }

    toggleModifyVenue = () => {
        this.setState({
            modalModifyVenue: !this.state.modalModifyVenue,
        });
    };

    modifyVenue = (venueName, key) => {
        this.setState({
            modalModifyVenue: !this.state.modalModifyVenue,
        });
        this.setState({
            venueID: key,
        });
        this.props.updateName(venueName, key);
        // this.props.getVenues(key);
    };

    modifyVenueInfo = (
        address1,
        address2,
        phoneNumber,
        country,
        city,
        region,
        zipcode,
        email,
        countdown,
        durationResident,
        durationGuest,
        contactFirstName,
        contactLastName,
        minVlanRes,
        maxVlanRes,
        minVlanGuest,
        maxVlanGuest,
        minVlanRoaming,
        maxVlanRoaming,
        realpageSiteID,
        realpagePMCID,
        realpageSyncDate,
        venueType,
        shoppingCartPriceAdjustment,
        enableRealPageSync,
        enableShoppingCart,
        sendSubscriptionAddedEmail,
        sendSubscriptionEndEmail,
        allowOverlapping,
        trialCycleAdjustment,
        stripePublishableKey,
        stripeSecretKey,
        stripeWebhookSigningSecret,
        venueName,
        displayFCCLabel,
        typicalLatency
    ) => {
        if (address2 === undefined) {
            address2 = "";
        }
        this.setState({ loading: true });
        this.setState({ reloadVenueList: true });
        const updateVenueAddress = {
            VenueID: sessionStorage.getItem("venueID"),
            AddressLine1: address1,
            AddressLine2: address2,
            City: city,
            State: region,
            PostalCode: zipcode,
            "Country.ISO3166A3": country,
            AccessToken: sessionStorage.getItem("AccessToken"),
        };
        let updateVenue;
        typicalLatency !== ""
            ? (updateVenue = {
                  VenueID: sessionStorage.getItem("venueID"),
                  VenueName: venueName,
                  AccessToken: sessionStorage.getItem("AccessToken"),
                  WelcomeEmail_countdown_hours: countdown,
                  DefaultSubscriptionDurationHours_GlobalWirelessPlusLocalWiredAccess:
                      durationResident,
                  DefaultSubscriptionDurationHours_LocalWirelessAccess:
                      durationGuest,
                  PrimaryContactGivenName: contactFirstName,
                  PrimaryContactFamilyName: contactLastName,
                  PrimaryContactEmailAddress: email,
                  "PrimaryContactPhoneNumber.E164": phoneNumber,
                  MinimumAutoGeneratedVLAN_GlobalWirelessPlusLocalWiredAccess:
                      minVlanRes,
                  MaximumAutoGeneratedVLAN_GlobalWirelessPlusLocalWiredAccess:
                      maxVlanRes,
                  MinimumAutoGeneratedVLAN_LocalWirelessAccess: minVlanGuest,
                  MaximumAutoGeneratedVLAN_LocalWirelessAccess: maxVlanGuest,
                  MinimumAutoGeneratedVLAN_GlobalWirelessRoaming:
                      minVlanRoaming,
                  MaximumAutoGeneratedVLAN_GlobalWirelessRoaming:
                      maxVlanRoaming,
                  RealPageSiteId: realpageSiteID,
                  RealPagePMCId: realpagePMCID,
                  "RealPageSyncDateTimeUTC.ISO8601": realpageSyncDate,
                  VenueType: venueType,
                  ShoppingCartPriceAdjustment:
                      -Math.abs(shoppingCartPriceAdjustment) * 100,
                  enableRealPageSync: enableRealPageSync,
                  enableShoppingCart: enableShoppingCart,
                  sendSubscriptionAddedEmail: sendSubscriptionAddedEmail,
                  sendSubscriptionEndEmail: sendSubscriptionEndEmail,
                  allowOverlappingSubscriptionWiredUnit: allowOverlapping,
                  TrialCycleAdjustment: trialCycleAdjustment,
                  StripePublishableKey: stripePublishableKey,
                  Stripe_Secret_key: stripeSecretKey,
                  Stripe_Webhook_signingsecret: stripeWebhookSigningSecret,
                  FCCLabel: {
                      displayLabel: displayFCCLabel,
                      TypicalLatency: typicalLatency,
                  },
              })
            : (updateVenue = {
                  VenueID: sessionStorage.getItem("venueID"),
                  VenueName: venueName,
                  AccessToken: sessionStorage.getItem("AccessToken"),
                  WelcomeEmail_countdown_hours: countdown,
                  DefaultSubscriptionDurationHours_GlobalWirelessPlusLocalWiredAccess:
                      durationResident,
                  DefaultSubscriptionDurationHours_LocalWirelessAccess:
                      durationGuest,
                  PrimaryContactGivenName: contactFirstName,
                  PrimaryContactFamilyName: contactLastName,
                  PrimaryContactEmailAddress: email,
                  "PrimaryContactPhoneNumber.E164": phoneNumber,
                  MinimumAutoGeneratedVLAN_GlobalWirelessPlusLocalWiredAccess:
                      minVlanRes,
                  MaximumAutoGeneratedVLAN_GlobalWirelessPlusLocalWiredAccess:
                      maxVlanRes,
                  MinimumAutoGeneratedVLAN_LocalWirelessAccess: minVlanGuest,
                  MaximumAutoGeneratedVLAN_LocalWirelessAccess: maxVlanGuest,
                  MinimumAutoGeneratedVLAN_GlobalWirelessRoaming:
                      minVlanRoaming,
                  MaximumAutoGeneratedVLAN_GlobalWirelessRoaming:
                      maxVlanRoaming,
                  RealPageSiteId: realpageSiteID,
                  RealPagePMCId: realpagePMCID,
                  "RealPageSyncDateTimeUTC.ISO8601": realpageSyncDate,
                  VenueType: venueType,
                  ShoppingCartPriceAdjustment:
                      -Math.abs(shoppingCartPriceAdjustment) * 100,
                  enableRealPageSync: enableRealPageSync,
                  enableShoppingCart: enableShoppingCart,
                  sendSubscriptionAddedEmail: sendSubscriptionAddedEmail,
                  sendSubscriptionEndEmail: sendSubscriptionEndEmail,
                  allowOverlappingSubscriptionWiredUnit: allowOverlapping,
                  TrialCycleAdjustment: trialCycleAdjustment,
                  StripePublishableKey: stripePublishableKey,
                  Stripe_Secret_key: stripeSecretKey,
                  Stripe_Webhook_signingsecret: stripeWebhookSigningSecret,
                  FCCLabel: {
                      displayLabel: displayFCCLabel,
                  },
              });
        Promise.all([
            axios.post(
                "/networkoperatorconsole/venues/updateVenueAddress",
                updateVenueAddress,
                { headers: {} }
            ),
            axios.post(
                "/networkoperatorconsole/venues/configureVenue",
                updateVenue,
                {
                    headers: {},
                }
            ),
        ])
            .then(async ([res1, res2]) => {
                toast.success("Updated Venue!", {
                    closeButton: false,
                });
                this.setState({ loading: false });
                this.toggleModifyVenue();
                this.setState({ reloadVenueList: false });
                this.props.updateName(
                    sessionStorage.getItem("venueName"),
                    sessionStorage.getItem("venueID")
                );
            })
            .catch((error) => {
                toast.error("There was an error!", {
                    closeButton: false,
                });
                this.toggleModifyVenue();
                this.setState({ loading: false });
                this.setState({ reloadVenueList: false });
                this.props.updateName(
                    sessionStorage.getItem("venueName"),
                    sessionStorage.getItem("venueID")
                );
            });
    };

    submitDelete = (
        email,
        venueID,
        unitID,
        building,
        floor,
        recentResidents,
        oldDateStart,
        unitID_internal,
        cancellationReason,
        refundAmount
    ) => {
        let body = {
            AccessToken: sessionStorage.getItem("AccessToken"),
            EmailAddress: email,
            VenueID: venueID,
            UnitID_internal: unitID_internal,
            endImmediately: true,
            "StartDateTimeUTC.ISO8601": new Date(oldDateStart).toISOString(),
            UserTimeZone: new Date().getTimezoneOffset(),
            CancellationReasons: cancellationReason,
            requestedRefundAmount: refundAmount,
        };
        axios
            .post(
                "/venuemgmtconsole/subscribers/resetSubscriptionEndDate",
                body,
                {
                    headers: {},
                }
            )
            .then(
                (response) => {
                    toast.success("Deleted Resident!", {
                        closeButton: false,
                    });
                    this.toggleModifyResident();
                    this.props.updateName(
                        this.props.venueName,
                        this.props.venueID
                    );
                },
                (error) => {
                    toast.error("There was an error!", {
                        closeButton: false,
                    });
                    this.toggleModifyResident();
                }
            );
    };

    getSelectedVenues = (selectedRowsData) => {
        let venueIDList = [];
        for (const key of selectedRowsData) {
            let venueID = [];
            venueID.push(key.venueID, key.venueName);
            venueIDList.push(venueID);
        }
        this.setState({
            venueIDList: venueIDList,
        });
    };

    toggleConfirmVenueDeletion = (key) => {
        this.setState({
            modalConfirmVenueDeletion: !this.state.modalConfirmVenueDeletion,
        });
    };

    changeHandler = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };

    confirmDeleteVenues = async () => {
        if (this.state.confirmDelete === "DELETE") {
            this.setState({
                isDeleting: true,
            });
            let i = 0;
            let venueCount = this.state.venueIDList.length;

            let deleteVenuesAPI;
            let body;

            for (const key of this.state.venueIDList) {
                deleteVenuesAPI =
                    "/networkoperatorconsole/admin/deleteVenuePermanently";
                body = {
                    AccessToken: sessionStorage.getItem("AccessToken"),
                    VenueID: key[0],
                };

                await axios
                    .post(deleteVenuesAPI, body, {
                        headers: {},
                    })
                    .then(
                        // eslint-disable-next-line no-loop-func
                        (response) => {
                            toast.success("Venue " + key[1] + " Deleted!", {
                                closeButton: false,
                            });
                            i = i + 1;
                            if (venueCount === i) {
                                this.toggleConfirmVenueDeletion();
                                // this.props.getVenueDetails(sessionStorage.getItem("venueID"));
                                this.setState({
                                    isDeleting: false,
                                });
                            }
                            this.props.getVenuesList();
                            this.setState({
                             confirmDelete: undefined
                            })
                        },
                        (error) => {
                            toast.error(
                                "Error: Venue " + key[1] + " Was Not Deleted!",
                                {
                                    closeButton: false,
                                }
                            );
                            this.setState({
                                isDeleting: false,
                            });
                            this.cancelUploadedFile();
                        }
                    );
            }
        } else {
            console.log("Please re-enter");
        }
    };
    cancelUploadedFile() {
        const randomString = Math.random().toString(36);
        this.setState({
            theInputKey: randomString,
        });
    }
    render() {
      console.log(this.props)
        if (this.state.postMessage) {
            var h = document.getElementById("overlayResidentManagementRecent");
            h.style.display = "block";
            setTimeout(function () {
                h.style.display = "block";
            }, 5000);
            setTimeout(() => {
                h.style.display = "none";
                this.setState({ postMessage: "" });
            }, 6000);
        }
        return (
            <>
                <MDBModal
                    isOpen={this.state.modalConfirmVenueDeletion}
                    toggle={this.toggleConfirmVenueDeletion}
                >
                    <MDBModalHeader toggle={this.toggleConfirmVenueDeletion}>
                        Confirm Venues to be Deleted
                    </MDBModalHeader>
                    <MDBModalBody>
                        {this.state.venueIDList !== undefined
                            ? this.state.venueIDList.map((item, key) => {
                                  return <p>Venue Name: {item[1]}</p>;
                              })
                            : ""}
                        <p>
                            “WARNING: THIS ACTION CANNOT BE UNDONE. If the venue has any units with an active subscription, the venue and those units will not be deleted. However, all empty units in that venue will be deleted. To
                            confirm, please type “DELETE” into the text box
                            below
                        </p>
                        <input
                            required
                            name="confirmDelete"
                            id="confirmDelete"
                            className="form-control"
                            onChange={this.changeHandler}
                            value={this.state.address1}
                            placeholder="Type DELETE"
                        />
                    </MDBModalBody>
                    <MDBModalFooter>
                        <div
                            className="text-right submitButton pt-2"
                            key={this.state.theInputKey || ""}
                        >
                            <MDBBtn
                                color="primary"
                                onClick={this.confirmDeleteVenues}
                                disabled={
                                    this.state.confirmDelete === "DELETE"
                                        ? false
                                        : true
                                }
                            >
                                {this.state.isDeleting === true ? (
                                    <div
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                    >
                                        <span className="sr-only">
                                            Loading...
                                        </span>
                                    </div>
                                ) : (
                                    "Confirm"
                                )}
                            </MDBBtn>
                            <MDBBtn
                                color="primary"
                                onClick={this.toggleConfirmVenueDeletion}
                            >
                                Close
                            </MDBBtn>
                        </div>
                    </MDBModalFooter>
                </MDBModal>
                <MDBModal
                    isOpen={this.state.modalModifyVenue}
                    toggle={this.toggle}
                    size="fluid"
                    className="form"
                    centered
                >
                    <ModifyVenue
                        toggleModifyVenue={this.toggleModifyVenue}
                        modifyVenue={this.modifyVenue}
                        modifyVenueInfo={this.modifyVenueInfo}
                        venueID={this.state.venueID}
                        submitDelete={this.submitDelete}
                        centered
                        sharedSecret={this.props.sharedSecret}
                        attributeForm={this.props.attributeForm}
                        venueInfo={this.props.venueInfo}
                        loadingVenueDetails={this.props.loadingVenueDetails}
                        getVenues={this.props.getVenues}
                        groupForm={this.props.groupForm}
                        keypoolForm={this.props.keypoolForm}
                        unitsArrayAll={this.props.unitsArrayAll}
                        keyPoolList={this.props.keyPoolList}
                        servicePlans={this.props.servicePlans}
                        staffArrayAll={this.props.staffArrayAll}
                        venueEquipList={this.props.venueEquipList}
                        getVenueDetails={this.props.getVenueDetails}
                        keypoolID={this.props.keypoolID}
                        propMgmtID={this.props.propMgmtID}
                        loading={this.props.loading}
                        updateName={this.props.updateName}
                        selectedID={this.props.selectedID}
                        optionTemplateVenues={this.props.optionTemplateVenues}
                        hasUpdated={this.props.hasUpdated}
                        optionTemplateDisplayOptions={
                            this.props.optionTemplateDisplayOptions
                        }
                    />
                </MDBModal>
                <MDBModalHeader toggle={this.props.toggleViewModifyVenues}>
                    <h4>Manage Venues</h4>
                </MDBModalHeader>
                <MDBModalBody>
                    {this.props.venueListForTable !== undefined ? (
                        <>
                            <div className="allrecentresidentsExpanded">
                                <VenuesTable
                                    venueList={this.props.venueListForTable}
                                    refreshed={this.props.refreshed}
                                    modifyVenue={this.modifyVenue}
                                    getSelectedVenues={this.getSelectedVenues}
                                />
                            </div>
                            <div className="text-right submitButton pt-2">
                                <MDBBtn
                                    color="danger"
                                    onClick={this.toggleConfirmVenueDeletion}
                                    disabled={this.state.disabled}
                                >
                                    Delete Selected Venues
                                </MDBBtn>
                            </div>
                        </>
                    ) : (
                        <MDBSpinner small />
                    )}
                </MDBModalBody>
            </>
        );
    }
}

export default withTranslation()(ViewVenues);
