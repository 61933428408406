import React from "react";

import { MDBDataTableV5 } from "mdbreact";

import { useTranslation } from "react-i18next";

export default function Basic(props) {
    const { t } = useTranslation();
    let userAttributes;
    let i = 0;

    let datatable;
    [datatable] = React.useState("");
    if (props.tickets !== undefined) {
        userAttributes = [];
        for (const key of Object.keys(props.tickets)) {
            userAttributes.push({
                id: key,
                status: props.tickets[key]["status"],
                contact: props.tickets[key]["contactName"],
                dateEntered: Intl.DateTimeFormat("en-US", {
                    month: "short",
                    day: "numeric",
                    year: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric",
                }).format(new Date(props.tickets[key]["dateEntered"])),
                lastUpdated: Intl.DateTimeFormat("en-US", {
                    month: "short",
                    day: "numeric",
                    year: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric",
                }).format(new Date(props.tickets[key]["lastUpdated"])),
                summary: props.tickets[key]["summary"],
                unitID: props.tickets[key]["UnitID_support"],
                type: Object.values(props.tickets[key]),
            });
            i = i + 1;
        }
    }

    datatable = {
        columns: [
            {
                label: "ID",
                field: "id",
                sort: "asc",
                width: 200,
            },
            {
                label: "Status",
                field: "status",
                sort: "asc",
                width: 200,
            },
            {
                label: "Summary",
                field: "summary",
                sort: "asc",
                width: 200,
            },
            {
                label: "Date Entered",
                field: "dateEntered",
                sort: "asc",
                width: 200,
            },
            {
                label: "Last Updated",
                field: "lastUpdated",
                sort: "asc",
                width: 200,
            },
            {
                label: "Contact",
                field: "contact",
                sort: "asc",
                width: 200,
            },
            {
                label: "Unit ID",
                field: "unitID",
                sort: "asc",
                width: 200,
            },
        ],
        rows: userAttributes,
    };
    return (
        <MDBDataTableV5
            searchTop={true}
            searchBottom={false}
            searchLabel="Filter"
            responsive
            hover
            data={datatable}
            paging={false}
            className="modifyStaff"
            info={false}
            noRecordsFoundLabel={t("recentservice.nomatches")}
        />
    );
}
