import React, { Component } from "react";

import ViewVenueUnitSubscriptions from "../../../../components/NetworkMgmt/Venues/ViewVenueUnitSubscriptions";

import ViewVenueUnitBandwidthUsage from "../../../../components/NetworkMgmt/Venues/ViewVenueUnitBandwidthUsage";

import {
    MDBBtn,
    MDBModalBody,
    MDBModalHeader,
    MDBRow,
    MDBCol,
    MDBIcon,
    MDBTabPane,
    MDBTabContent,
    MDBNav,
    MDBNavItem,
    MDBNavLink,
    MDBSpinner,
    MDBInput,
} from "mdbreact";

import { withTranslation } from "react-i18next";

import $ from "jquery";

import {
    CountryDropdown,
    RegionDropdown,
} from "vault-react-country-region-selector";

import axios from "../../../../axios";

import { DateInput } from "semantic-ui-calendar-react";

import TransactionsTable from "../../../../components/NetworkMgmt/Transactions/Datatables/UnitTransactionsTableMUI";

import SelectSearch, { fuzzySearch } from "react-select-search";

class AddVenue extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: null,
            content: null,
            unitName: this.props.unitInfo["UnitID"],
            unitBuilding: this.props.unitInfo["Building"],
            unitFloor: this.props.unitInfo["Floor"],
            address1: this.props.unitInfo["address1"],
            address2: this.props.unitInfo["address2"],
            city: this.props.unitInfo["city"],
            state: this.props.unitInfo["state"],
            postalcode: this.props.unitInfo["postalcode"],
            country: this.props.unitInfo["country"],
            region: this.props.unitInfo["state"],
            purpose: this.props.unitInfo["Purpose"],
            serviceplan: this.props.unitInfo["ServicePlan"],
            value: "en",
            invalidCountry: null,
            invalidState: null,
            invalidVlan: undefined,
            vlan: this.props.unitInfo["vlan"],
            realpageSyncDateUnit: this.props.unitInfo["realpageSyncDate"],
            smsCode: this.props.unitInfo["smsCode"],
            activeItem: "1",
            unitID_internal: this.props.unitInfo["unitID_internal"],
            shoppingCartUnitPriceAdjustment:
                this.props.unitInfo["shoppingCartPriceAdjustment"] / 100,
            allowOverlappingUnit:
                this.props.unitInfo["allowOverlappingSubscriptionWiredUnit"],
            dateStart: undefined,
            dateEnd: undefined,
            primaryUnitID_Internal:
                this.props.unitInfo["primaryUnitID_Internal"],
            enableShoppingCart: this.props.unitInfo["enableShoppingCart"],
            disabled:
                sessionStorage.getItem("customerSupport") > 0 ? true : false,
            selectedServicePlan: this.props.unitInfo["selectedServicePlan"],
        };
    }

    componentDidMount() {
        this.getLogs();
    }

    selectCountry(val) {
        this.setState({ country: val });
    }

    selectRegion(val) {
        this.setState({ region: val });
    }

    getValueOfSelect = (e) => {
        var index = e.target.selectedIndex;
        var optionElement = e.target.childNodes[index];
        this.setState({
            propertyMUTCGroupSecret: optionElement.getAttribute("value"),
        });
        this.setState({ propertyMUTCGroup: optionElement.getAttribute("id") });
    };

    getValueOfSelectCountry = (value) => {
        this.setState({ country: value[0] });
    };

    getValueOfSelectState = (value) => {
        this.setState({ region: value[0] });
    };

    submitModifyUnitAddress = (event) => {
        event.preventDefault();

        $("#selectCountry").removeClass("is-invalid");
        $("#selectState").removeClass("is-invalid");
        $("#selectPurpose").removeClass("is-invalid");
        $("#vlan").removeClass("is-invalid");

        event.target.className += " was-validated";
        if (this.state.country === undefined) {
            this.setState({ invalidCountry: "Please provide a valid country" });
            $("#selectCountry").addClass("is-invalid");
        } else {
            this.setState({ invalidCountry: null });
            $("#selectCountry").addClass("is-valid");
        }

        if (this.state.region === undefined) {
            this.setState({
                invalidState: "Please provide a valid state/region",
            });
            $("#selectState").addClass("is-invalid");
        } else {
            this.setState({ invalidState: null });
            $("#selectState").addClass("is-valid");
        }

        if (this.state.selectedPurpose === undefined) {
            this.setState({ invalidLanguage: "Please provide a purpose" });
            $("#selectPurpose").addClass("is-invalid");
        } else {
            $("#selectPurpose").addClass("is-valid");
        }

        if (this.state.vlan < 1 || this.state.vlan > 4095) {
            this.setState({
                invalidVlan: "Please provide a valid VLAN (1 - 4095)",
            });
            $("#vlan").addClass("is-invalid");
            $("#vlan").removeClass("is-valid");
        } else {
            $("#vlan").addClass("is-valid");
        }
        if (
            this.state.address1 !== undefined &&
            this.state.country !== undefined &&
            this.state.city !== undefined &&
            this.state.region !== undefined &&
            this.state.postalcode !== undefined &&
            $(".invalid-feedback").is(":visible") !== true
        ) {
            this.props.submitModifyUnitAddress(
                this.props.venueID,
                this.state.unitName,
                this.state.unitBuilding,
                this.state.unitFloor,
                this.state.address1,
                this.state.address2,
                this.state.city,
                this.state.region,
                this.state.country,
                this.state.postalcode,
                this.state.smsCode,
                this.state.unitID_internal,
                this.state.vlan,
                this.state.shoppingCartUnitPriceAdjustment,
                this.state.allowOverlappingUnit,
                this.state.enableShoppingCart,
                this.state.selectedServicePlan
            );
        }
    };

    changeHandler = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleSelectChangePurpose = (value) => {
        this.setState({ selectedPurpose: value[0] });
    };

    toggleViewUnitSubscriptions = () => {
        let isOpen = !this.state.modalViewUnitSubscriptions;
        this.setState({
            modalViewUnitSubscriptions: !this.state.modalViewUnitSubscriptions,
        });
        if (isOpen === false) {
            this.props.getVenueDetails(this.props.venueID);
        }
    };

    toggleCheckboxValueAllowOverlapping = () => {
        this.setState({
            allowOverlappingUnit: !this.state.allowOverlappingUnit,
        });
    };

    toggleCheckboxValueEnable = () => {
        this.setState({
            enableShoppingCart: !this.state.enableShoppingCart,
        });
    };

    toggleTab = (tab) => () => {
        if (this.state.activeItem !== tab) {
            this.setState({
                activeItem: tab,
            });
        }
    };

    getLogs = () => {
        let body = {
            AccessToken: sessionStorage.getItem("AccessToken"),
            UnitID_internal: this.props.unitInfo["unitID_internal"],
        };
        axios
            .post("/networkoperatorconsole/venues/listVenueUnitLog", body, {
                headers: {},
            })
            .then((response) => {
                this.setState({
                    venueUnitLog: response.data.log,
                });
            })
            .catch((error) => {
                this.setState({ error: true });
            });
    };

    handleChange = (event, { name, value }) => {
        if (this.state.hasOwnProperty(name)) {
            this.setState({ [name]: value });
        }
    };

    findTransactions = (event) => {
        event.preventDefault();
        this.setState({ loading: true });

        if (
            this.state.dateStart !== undefined &&
            this.state.dateEnd !== undefined
        ) {
            let body = {
                AccessToken: sessionStorage.getItem("AccessToken"),
                "MinInvoiceDateTimeUTC.ISO8601": new Date(
                    this.state.dateStart
                ).toISOString(),
                "MaxinvoiceDateTimeUTC.ISO8601": new Date(
                    this.state.dateEnd
                ).toISOString(),
                UnitID_internal: this.props.unitInfo["unitID_internal"],
            };
            axios
                .post(
                    "/networkoperatorconsole/shoppingcart/listPayments",
                    body,
                    {
                        headers: {},
                    }
                )
                .then(
                    (response) => {
                        this.setState({ transactions: response.data.Payments });
                        this.setState({ loading: false });
                    },
                    (error) => {
                        this.setState({ loading: false });
                    }
                );
        }
    };

    handleSelectPlan = (value) => {
        this.setState({ selectedServicePlan: value });
    };

    render() {
        let tomorrow = new Date(
            new Date().setDate(new Date().getDate() + 1)
        ).toISOString();
        const { country, region } = this.state;
        const { t } = this.props;
        let purpose;
        switch (this.state.purpose) {
            case "wiredunit":
                purpose = "Residential Unit";
                break;
            case "localwirelessonly":
                purpose = "Guest Subscription";
                break;
            case "globalroaming":
                purpose = "Roaming Device";
                break;
            default:
                purpose = "Residential Unit";
                break;
        }
        return (
            <>
                <MDBModalHeader toggle={this.props.toggleViewModifyUnit}>
                    <h4>Modify Unit</h4>
                    <p>{t("addnewresident.required")}</p>
                </MDBModalHeader>
                <MDBModalBody>
                    <MDBNav className="nav-tabs">
                        <MDBNavItem>
                            <MDBNavLink
                                link
                                to="#"
                                active={this.state.activeItem === "1"}
                                onClick={this.toggleTab("1")}
                                role="tab"
                            >
                                <MDBIcon icon="building" /> Profile
                            </MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem>
                            <MDBNavLink
                                link
                                to="#"
                                active={this.state.activeItem === "2"}
                                onClick={this.toggleTab("2")}
                                role="tab"
                            >
                                <MDBIcon icon="user" /> Subscriptions
                            </MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem>
                            <MDBNavLink
                                link
                                to="#"
                                active={this.state.activeItem === "3"}
                                onClick={this.toggleTab("3")}
                                role="tab"
                            >
                                <MDBIcon icon="clipboard-list" /> Logs
                            </MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem>
                            <MDBNavLink
                                link
                                to="#"
                                active={this.state.activeItem === "4"}
                                onClick={this.toggleTab("4")}
                                role="tab"
                            >
                                <MDBIcon icon="shopping-cart" /> Shopping Cart
                            </MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem>
                            <MDBNavLink
                                link
                                to="#"
                                active={this.state.activeItem === "5"}
                                onClick={this.toggleTab("5")}
                                role="tab"
                            >
                                <MDBIcon icon="chart-line" /> Bandwidth
                            </MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem>
                            <MDBNavLink
                                link
                                to="#"
                                active={this.state.activeItem === "6"}
                                onClick={this.toggleTab("6")}
                                role="tab"
                            >
                                <MDBIcon icon="file-invoice-dollar" />{" "}
                                Transactions
                            </MDBNavLink>
                        </MDBNavItem>
                    </MDBNav>
                    <MDBTabContent
                        className=""
                        activeItem={this.state.activeItem}
                    >
                        <MDBTabPane tabId="1" role="tabpanel">
                            <form
                                className="needs-validation"
                                onSubmit={this.submitModifyUnitAddress}
                                noValidate
                            >
                                <MDBRow>
                                    <MDBCol col="6" sm="12" lg="12">
                                        <label
                                            htmlFor="defaultFormLoginEmailEx"
                                            className="black-text"
                                        >
                                            Venue Name
                                        </label>
                                        <input
                                            disabled
                                            name="venueName"
                                            id="venueName"
                                            className="form-control"
                                            onChange={this.changeHandler}
                                            value={sessionStorage.getItem(
                                                "venueName"
                                            )}
                                        />
                                        <div className="invalid-feedback">
                                            Please select a venue.
                                        </div>
                                    </MDBCol>
                                    <MDBCol col="6"></MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol sm="12" lg="12">
                                        <label
                                            htmlFor="defaultFormLoginEmailEx"
                                            className="black-text"
                                        >
                                            Unit Name*
                                        </label>
                                        <input
                                            disabled
                                            name="unitName"
                                            id="unitName"
                                            className="form-control"
                                            onChange={this.changeHandler}
                                            value={this.state.unitName}
                                        />
                                        <div className="invalid-feedback">
                                            Please provide a valid unit name
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol sm="12" lg="6">
                                        <label
                                            htmlFor="defaultFormLoginEmailEx"
                                            className="black-text"
                                        >
                                            Building*
                                        </label>
                                        <input
                                            disabled
                                            name="unitBuilding"
                                            id="unitBuilding"
                                            className="form-control"
                                            onChange={this.changeHandler}
                                            value={this.state.unitBuilding}
                                        />
                                        <div className="invalid-feedback">
                                            Please provide a valid building
                                        </div>
                                    </MDBCol>
                                    <MDBCol sm="12" lg="6">
                                        <label
                                            htmlFor="defaultFormLoginEmailEx"
                                            className="black-text"
                                        >
                                            Floor*
                                        </label>
                                        <input
                                            disabled
                                            className="form-control"
                                            name="unitFloor"
                                            id="unitFloor"
                                            onChange={this.changeHandler}
                                            value={this.state.unitFloor}
                                        />
                                        <div className="invalid-feedback">
                                            Please provide a valid floor
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol sm="12" lg="6">
                                        <label
                                            htmlFor="defaultFormLoginEmailEx"
                                            className="black-text"
                                        >
                                            Address Line 1*
                                        </label>
                                        <input
                                            required
                                            name="address1"
                                            id="address1"
                                            className="form-control"
                                            onChange={this.changeHandler}
                                            value={this.state.address1}
                                            disabled={this.state.disabled}
                                        />
                                        <div className="invalid-feedback">
                                            Please provide a valid address
                                        </div>
                                    </MDBCol>
                                    <MDBCol sm="12" lg="6">
                                        <label
                                            htmlFor="defaultFormLoginEmailEx"
                                            className="black-text"
                                        >
                                            Address Line 2
                                        </label>
                                        <input
                                            className="form-control"
                                            name="address2"
                                            id="address2"
                                            onChange={this.changeHandler}
                                            value={this.state.address2}
                                            disabled={this.state.disabled}
                                        />
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol sm="12" lg="12">
                                        <label
                                            htmlFor="defaultFormLoginEmailEx"
                                            className="black-text"
                                        >
                                            Country*
                                        </label>
                                        <div className="select-box">
                                            <CountryDropdown
                                                value={country}
                                                onChange={(val) =>
                                                    this.selectCountry(val)
                                                }
                                                priorityOptions={[
                                                    "CAN",
                                                    "USA",
                                                    "GBR",
                                                ]}
                                                valueType="short"
                                                countryvaluetype="short"
                                                className="form-control"
                                                id="selectCountry"
                                                disabled={this.state.disabled}
                                            />
                                        </div>
                                        {this.state.invalidCountry !== null ? (
                                            <div className="invalid">
                                                {this.state.invalidCountry}
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol sm="12" lg="4">
                                        <label
                                            htmlFor="defaultFormLoginEmailEx"
                                            className="black-text"
                                        >
                                            City*
                                        </label>
                                        <input
                                            required
                                            name="city"
                                            id="city"
                                            className="form-control"
                                            onChange={this.changeHandler}
                                            value={this.state.city}
                                            disabled={this.state.disabled}
                                        />
                                        <div className="invalid-feedback">
                                            Please provide a valid city
                                        </div>
                                    </MDBCol>
                                    <MDBCol sm="12" lg="4">
                                        <label
                                            htmlFor="defaultFormLoginEmailEx"
                                            className="black-text"
                                        >
                                            State*
                                        </label>
                                        <div className="select-box">
                                            <RegionDropdown
                                                country={country}
                                                value={region}
                                                onChange={(val) =>
                                                    this.selectRegion(val)
                                                }
                                                valueType="short"
                                                className="form-control"
                                                id="selectState"
                                                disabled={this.state.disabled}
                                            />
                                        </div>
                                        {this.state.invalidState !== null ? (
                                            <div className="invalid">
                                                {this.state.invalidState}
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </MDBCol>
                                    <MDBCol sm="12" lg="4">
                                        <label
                                            htmlFor="defaultFormLoginEmailEx"
                                            className="black-text"
                                        >
                                            Zip Code*
                                        </label>
                                        <input
                                            required
                                            name="postalcode"
                                            id="postalcode"
                                            className="form-control"
                                            onChange={this.changeHandler}
                                            value={this.state.postalcode}
                                            disabled={this.state.disabled}
                                        />
                                        <div className="invalid-feedback">
                                            Please provide a valid zipcode
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol sm="12" lg="6">
                                        <label
                                            htmlFor="defaultFormLoginEmailEx"
                                            className="black-text"
                                        >
                                            VLAN*
                                        </label>
                                        <input
                                            name="vlan"
                                            id="vlan"
                                            className="form-control"
                                            onChange={this.changeHandler}
                                            value={this.state.vlan}
                                            disabled={this.state.disabled}
                                        />
                                        <div className="invalid-feedback">
                                            Please provide a valid vlan (1 -
                                            4095)
                                        </div>
                                    </MDBCol>
                                    <MDBCol sm="12" lg="6">
                                        <label
                                            htmlFor="defaultFormLoginEmailEx"
                                            className="black-text"
                                        >
                                            Purpose*
                                        </label>
                                        <input
                                            disabled
                                            name="purpose"
                                            id="purpose"
                                            className="form-control"
                                            value={purpose}
                                        />
                                        <div className="invalid-feedback">
                                            Please provide a purpose.
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol col="6">
                                        <label
                                            htmlFor="defaultFormLoginEmailEx"
                                            className="black-text"
                                        >
                                            RealPage Sync Date
                                        </label>
                                        <input
                                            disabled
                                            name="realpageSyncDateUnit"
                                            id="realpageSyncDateUnit"
                                            className="form-control"
                                            onChange={this.changeHandler}
                                            value={
                                                this.state.realpageSyncDateUnit
                                            }
                                        />
                                        <div className="invalid-feedback">
                                            Please provide a valid number
                                        </div>
                                    </MDBCol>
                                    <MDBCol sm="12" lg="6">
                                        <label
                                            htmlFor="defaultFormLoginEmailEx"
                                            className="black-text"
                                        >
                                            SMS Code
                                        </label>
                                        <input
                                            name="smsCode"
                                            id="smsCode"
                                            className="form-control"
                                            value={this.state.smsCode}
                                            onChange={this.changeHandler}
                                            maxlength="6"
                                            pattern="[a-zA-Z0-9]*"
                                            disabled={this.state.disabled}
                                        />
                                        <div
                                            className="invalid-feedback"
                                            id="smsCodeFeedback"
                                        >
                                            Please provide a valid value
                                            (alphanumeric characters only).
                                        </div>
                                    </MDBCol>
                                    <MDBCol sm="12" lg="12">
                                        <label
                                            htmlFor="defaultFormLoginEmailEx"
                                            className="black-text"
                                        >
                                            Unit Internal ID
                                        </label>
                                        <input
                                            disabled
                                            name="unitID_internal"
                                            id="unitID_internal"
                                            className="form-control"
                                            value={this.state.unitID_internal}
                                        />
                                    </MDBCol>
                                </MDBRow>
                                {this.state.purpose === "globalroaming" &&
                                this.props.optionTemplateServicePlans !==
                                    undefined ? (
                                    <>
                                        <MDBRow>
                                            <MDBCol sm="12" lg="12">
                                                <label
                                                    htmlFor="defaultFormLoginEmailEx"
                                                    className="black-text"
                                                >
                                                    Service Plan
                                                </label>
                                                <SelectSearch
                                                    ref={this.servicePlanSelect}
                                                    id="selectServicePlan"
                                                    search={true}
                                                    filterOptions={fuzzySearch}
                                                    options={
                                                        this.props
                                                            .optionTemplateServicePlans
                                                    }
                                                    onChange={
                                                        this.handleSelectPlan
                                                    }
                                                    isOptionSelected={true}
                                                    inputId="floorArray"
                                                    classNamePrefix="floo-select"
                                                    placeholder="Select..."
                                                    emptyMessage="Select..."
                                                    value={
                                                        this.state
                                                            .selectedServicePlan
                                                    }
                                                />
                                                <div className="invalid-feedback">
                                                    Please provide a valid plan
                                                </div>
                                            </MDBCol>
                                        </MDBRow>
                                    </>
                                ) : (
                                    ""
                                )}
                                {this.state.purpose === "localwirelessonly" ? (
                                    ""
                                ) : (
                                    <MDBRow>
                                        <MDBCol col="6">
                                            <MDBInput
                                                label="Allow Overlapping Subscriptions"
                                                type="checkbox"
                                                id="allowOverlappingUnit"
                                                name="allowOverlappingUnit"
                                                onChange={
                                                    this
                                                        .toggleCheckboxValueAllowOverlapping
                                                }
                                                checked={
                                                    this.state
                                                        .allowOverlappingUnit
                                                }
                                                disabled={
                                                    (this.state
                                                        .primaryUnitID_Internal !==
                                                        "" &&
                                                        this.state
                                                            .primaryUnitID_Internal !==
                                                            this.state
                                                                .unitID_internal) ||
                                                    this.state.disabled ||
                                                    this.state.purpose ===
                                                        "globalroaming"
                                                }
                                            />
                                        </MDBCol>
                                        <MDBCol col="6"></MDBCol>
                                    </MDBRow>
                                )}
                                <MDBRow>
                                    <MDBCol col="6">
                                        <MDBInput
                                            label="Enable shopping cart?"
                                            type="checkbox"
                                            id="enableShoppingCart"
                                            name="enableShoppingCart"
                                            onChange={
                                                this.toggleCheckboxValueEnable
                                            }
                                            checked={
                                                this.state.enableShoppingCart
                                            }
                                            disabled={
                                                this.state.disabled ||
                                                this.state.purpose ===
                                                    "globalroaming"
                                            }
                                        />
                                    </MDBCol>
                                </MDBRow>
                                <div className="text-right submitButton">
                                    <MDBBtn
                                        color="danger"
                                        type="submit"
                                        disabled={this.state.disabled}
                                    >
                                        {this.props.update_unit === true ? (
                                            <div
                                                className="spinner-border spinner-border-sm"
                                                role="status"
                                            >
                                                <span className="sr-only">
                                                    Loading...
                                                </span>
                                            </div>
                                        ) : (
                                            "Save Changes"
                                        )}
                                    </MDBBtn>
                                </div>
                            </form>
                        </MDBTabPane>
                        <MDBTabPane tabId="2" role="tabpanel">
                            <ViewVenueUnitSubscriptions
                                unitSubscriptionsList={
                                    this.props.unitSubscriptionsList
                                }
                                toggleViewUnitSubscriptions={
                                    this.toggleViewUnitSubscriptions
                                }
                                venueID={this.props.venueID}
                                getVenueDetails={this.props.getVenueDetails}
                                unitsList={this.props.unitsList}
                                showUpdate={this.props.showUpdate}
                                cancelInputType={this.props.cancelInputType}
                                updateName={this.props.updateName}
                                keypoolList={this.props.keypoolList}
                            />
                        </MDBTabPane>
                        <MDBTabPane tabId="3" role="tabpanel">
                            <div className="logList">
                                {this.state.venueUnitLog !== undefined ? (
                                    // console.log(this.state.subscriberLog)
                                    <div>
                                        {this.state.venueUnitLog.map(
                                            (value, index) => (
                                                <div className="mt-2 mb-2">
                                                    {Object.entries(value).map(
                                                        (value2, index2) =>
                                                            value2[0] !==
                                                            "event" ? (
                                                                <>
                                                                    <p className="mb-0">
                                                                        <strong>
                                                                            {
                                                                                value2[0]
                                                                            }
                                                                            :{" "}
                                                                        </strong>
                                                                        {
                                                                            value2[1]
                                                                        }
                                                                    </p>
                                                                </>
                                                            ) : (
                                                                Object.entries(
                                                                    value2[1]
                                                                ).map(
                                                                    (
                                                                        value3,
                                                                        index3
                                                                    ) => (
                                                                        <>
                                                                            <p className="mb-0">
                                                                                <strong>
                                                                                    {
                                                                                        value3[0]
                                                                                    }
                                                                                    :{" "}
                                                                                </strong>
                                                                                {
                                                                                    value3[1]
                                                                                }
                                                                            </p>
                                                                        </>
                                                                    )
                                                                )
                                                            )
                                                    )}
                                                    <hr />
                                                </div>
                                            )
                                        )}
                                    </div>
                                ) : (
                                    <div
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                    >
                                        <span className="sr-only">
                                            Loading...
                                        </span>
                                    </div>
                                )}
                            </div>
                        </MDBTabPane>
                        <MDBTabPane tabId="4" role="tabpanel">
                            <form
                                className="needs-validation"
                                onSubmit={this.submitModifyUnitAddress}
                                noValidate
                            >
                                <MDBRow>
                                    <MDBCol col="6" sm="12" lg="6">
                                        <label
                                            htmlFor="defaultFormLoginEmailEx"
                                            className="black-text"
                                        >
                                            Shopping Cart Price Discount (US
                                            Dollars)
                                        </label>
                                        <input
                                            type="number"
                                            step="0.01"
                                            id="shoppingCartPriceAdjustment"
                                            name="shoppingCartUnitPriceAdjustment"
                                            className="form-control"
                                            onChange={this.changeHandler}
                                            value={
                                                -Math.abs(
                                                    this.state
                                                        .shoppingCartUnitPriceAdjustment
                                                )
                                            }
                                            disabled={this.state.disabled}
                                        />
                                        <div className="invalid-feedback">
                                            Please provide a value.
                                        </div>
                                    </MDBCol>
                                    <MDBCol col="6"></MDBCol>
                                </MDBRow>
                                <div className="text-right submitButton">
                                    <MDBBtn
                                        color="danger"
                                        type="submit"
                                        disabled={this.state.disabled}
                                    >
                                        {this.props.update_unit === true ? (
                                            <div
                                                className="spinner-border spinner-border-sm"
                                                role="status"
                                            >
                                                <span className="sr-only">
                                                    Loading...
                                                </span>
                                            </div>
                                        ) : (
                                            "Save Changes"
                                        )}
                                    </MDBBtn>
                                </div>
                            </form>
                        </MDBTabPane>
                        <MDBTabPane tabId="5" role="tabpanel">
                            <ViewVenueUnitBandwidthUsage
                                unitID_internal={
                                    this.props.unitInfo["unitID_internal"]
                                }
                            />
                        </MDBTabPane>
                        <MDBTabPane tabId="6" role="tabpanel">
                            <div className="searchForm">
                                <h4>Search for Transactions</h4>
                                <form
                                    className="needs-validation"
                                    onSubmit={this.findTransactions}
                                    noValidate
                                >
                                    <MDBRow>
                                        <MDBCol col="6">
                                            <label
                                                htmlFor="defaultFormLoginEmailEx"
                                                className="black-text"
                                            >
                                                Start Date
                                            </label>
                                            <DateInput
                                                dateFormat="MM/DD/YYYY"
                                                name="dateStart"
                                                placeholder="MM/DD/YYYY"
                                                value={this.state.dateStart}
                                                iconPosition="left"
                                                onChange={this.handleChange}
                                                className="dateTimePicker"
                                                maxDate={new Date(tomorrow)}
                                            />
                                        </MDBCol>
                                        <MDBCol col="6">
                                            <label
                                                htmlFor="defaultFormLoginEmailEx"
                                                className="black-text"
                                            >
                                                End Date
                                            </label>
                                            <DateInput
                                                dateFormat="MM/DD/YYYY"
                                                name="dateEnd"
                                                placeholder="MM/DD/YYYY"
                                                value={this.state.dateEnd}
                                                iconPosition="left"
                                                onChange={this.handleChange}
                                                className="dateTimePicker"
                                                minDate={
                                                    new Date(
                                                        this.state.dateStart
                                                    )
                                                }
                                                maxDate={new Date(tomorrow)}
                                            />
                                        </MDBCol>
                                    </MDBRow>
                                    <br />
                                    {this.state.transactions !== undefined ? (
                                        <>
                                            {this.state.loading === true ? (
                                                <MDBSpinner small />
                                            ) : (
                                                <TransactionsTable
                                                    transactions={
                                                        this.state.transactions
                                                    }
                                                />
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            {this.state.loading === true ? (
                                                <MDBSpinner small />
                                            ) : (
                                                <TransactionsTable
                                                    transactions={
                                                        this.state.transactions
                                                    }
                                                />
                                            )}
                                        </>
                                    )}
                                    <div className="text-right submitButton">
                                        <MDBBtn color="danger" type="submit">
                                            Find Transactions
                                        </MDBBtn>
                                    </div>
                                </form>
                            </div>
                        </MDBTabPane>
                    </MDBTabContent>
                </MDBModalBody>
            </>
        );
    }
}

export default withTranslation()(AddVenue);
