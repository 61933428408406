import React, { Component } from "react";

import {
    MDBBtn,
    MDBModalBody,
    MDBModalHeader,
    MDBRow,
    MDBCol,
    MDBIcon,
    MDBTabPane,
    MDBTabContent,
    MDBNav,
    MDBNavItem,
    MDBNavLink,
} from "mdbreact";

import axios from "../../../../axios";

import { withTranslation } from "react-i18next";

import $ from "jquery";

import SelectSearch, { fuzzySearch } from "react-select-search";

class AddNewVenueEquipment extends Component {
    constructor(props) {
        super(props);

        this.state = {
            Email: "",
            sms: false,
            voice: false,
            invalidPhone: null,
            loading: false,
            userFound: false,
            activeItem: "1",
            equipmentTypeOptions: [
                {
                    value: "Gateway",
                    name: "Router/Gateway",
                },
                {
                    value: "Wireless Controller",
                    name: "Wireless Controller",
                },
            ],
        };
    }

    componentDidMount() {
        this.getEquipmentDetails(this.props.equipID);
        this.getEquipmentLogs();
    }

    validateEmail = (email) => {
        var mailformat = /^[\w._-]+[+]?[\w._-]+@[\w.-]+\.[a-zA-Z]{2,6}$/;
        this.setState({
            invalidPhone: undefined,
            userFound: false,
            userFoundWithPhone: false,
        });

        if (!this.state.userName.match(mailformat)) {
            this.setState({
                invalidEmail: "Please provide a valid email address",
            });
            $(".emailInput").addClass("is-invalid");
        } else {
            this.setState({ invalidEmail: undefined });
            $(".emailInput").removeClass("is-invalid");
        }
    };

    getEquipmentDetails = (index) => {
        Object.keys(this.props.venueEquipList).map((oneKey, i) => {
            if (oneKey === this.props.equipID) {
                this.setState({
                    equipName: this.props.venueEquipList[oneKey]["EquipmentID"],
                    manufacturer:
                        this.props.venueEquipList[oneKey]["Manufacturer"],
                    model: this.props.venueEquipList[oneKey]["Model"],
                    selectedEquipmentType:
                        this.props.venueEquipList[oneKey]["EquipmentType"],
                    zone: this.props.venueEquipList[oneKey]["WirelessZone"],
                    protocol:
                        this.props.venueEquipList[oneKey]["ConnectionProtocol"],
                    ipaddy: this.props.venueEquipList[oneKey]["IPAddress"],
                    port: this.props.venueEquipList[oneKey]["Port"],
                    userName: this.props.venueEquipList[oneKey]["Username"],
                    userPassword: this.props.venueEquipList[oneKey]["Password"],
                    status: this.props.venueEquipList[oneKey]["Status"],
                    pre643: this.props.venueEquipList[oneKey]["MikrotikPre643"],
                });
            }
            return true;
        });
    };

    getEquipmentLogs = () => {
        let body = {
            AccessToken: sessionStorage.getItem("AccessToken"),
            EquipmentID: this.props.equipID,
        };
        axios
            .post("/networkoperatorconsole/equipment/listEquipmentLog", body, {
                headers: {},
            })
            .then(
                (response) => {
                    this.setState({
                        equipmentLog: response.data.log,
                    });
                },
                (error) => {
                    console.log(
                        "🚀 ~ file: ModifyEquipment.js:104 ~ AddNewVenueEquipment ~ error",
                        error
                    );
                }
            );
    };

    submitModifyEquipment = (event) => {
        event.preventDefault();
        event.target.className += " was-validated";

        if (
            this.state.equipName !== undefined &&
            this.state.manufacturer !== undefined &&
            this.state.model !== undefined &&
            this.state.selectedEquipmentType !== undefined &&
            this.state.protocol !== undefined &&
            this.state.ipaddy !== undefined &&
            this.state.port !== undefined &&
            this.state.userName !== undefined &&
            this.state.userPassword !== undefined &&
            this.state.status !== undefined &&
            this.state.selectedEquipmentType === "Gateway"
                ? this.state.pre643 !== undefined
                : this.state.zone !== undefined
        ) {
            this.props.submitModifyEquipment(
                this.state.equipName,
                this.state.manufacturer,
                this.state.model,
                this.state.selectedEquipmentType,
                this.state.zone,
                this.state.protocol,
                this.state.ipaddy,
                this.state.port,
                this.state.userName,
                this.state.userPassword,
                this.state.status,
                this.state.pre643
            );
        }
    };

    handleSelectEquipmentType = (value) => {
        this.setState({ selectedEquipmentType: value });
    };

    changeHandler = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };

    toggleTab = (tab) => () => {
        if (this.state.activeItem !== tab) {
            this.setState({
                activeItem: tab,
            });
        }
    };

    render() {
        const { t } = this.props;
        return (
            <>
                <MDBModalHeader toggle={this.props.toggleModifyEquipment}>
                    <h4>Modify Equipment</h4>
                    <p>{t("addnewresident.required")}</p>
                </MDBModalHeader>
                <MDBModalBody>
                    <MDBNav className="nav-tabs">
                        <MDBNavItem>
                            <MDBNavLink
                                link
                                to="#"
                                active={this.state.activeItem === "1"}
                                onClick={this.toggleTab("1")}
                                role="tab"
                            >
                                <MDBIcon icon="user" /> Profile
                            </MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem>
                            <MDBNavLink
                                link
                                to="#"
                                active={this.state.activeItem === "2"}
                                onClick={this.toggleTab("2")}
                                role="tab"
                            >
                                <MDBIcon icon="clipboard-list" /> Logs
                            </MDBNavLink>
                        </MDBNavItem>
                    </MDBNav>
                    <MDBTabContent
                        className=""
                        activeItem={this.state.activeItem}
                    >
                        <MDBTabPane tabId="1" role="tabpanel">
                            <form
                                className="needs-validation"
                                onSubmit={this.submitModifyEquipment}
                                noValidate
                            >
                                <MDBRow>
                                    <MDBCol col="6" sm="12" lg="12">
                                        <label
                                            htmlFor="defaultFormLoginEmailEx"
                                            className="black-text"
                                        >
                                            Venue Name*
                                        </label>
                                        <input
                                            disabled
                                            name="venueName"
                                            id="venueName"
                                            className="form-control"
                                            onChange={this.changeHandler}
                                            value={this.props.venueName}
                                        />
                                        <div className="invalid-feedback">
                                            Please provide a valid name
                                        </div>
                                    </MDBCol>
                                    <MDBCol col="6"></MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol sm="12" lg="6">
                                        <label
                                            htmlFor="defaultFormLoginEmailEx"
                                            className="black-text"
                                        >
                                            Equipment Type
                                        </label>
                                        <SelectSearch
                                            id="selectEquipmentType"
                                            search={true}
                                            filterOptions={fuzzySearch}
                                            options={
                                                this.state.equipmentTypeOptions
                                            }
                                            inputId="equipmentTypeArray"
                                            classNamePrefix="unit-select form-control"
                                            placeholder="Select..."
                                            onChange={
                                                this.handleSelectEquipmentType
                                            }
                                            value={
                                                this.state.selectedEquipmentType
                                            }
                                        />
                                        <div className="invalid-feedback">
                                            Please provide a valid type.
                                        </div>
                                    </MDBCol>
                                    <MDBCol col="6" sm="12" lg="6">
                                        <label
                                            htmlFor="defaultFormLoginEmailEx"
                                            className="black-text"
                                        >
                                            Equipment Name
                                        </label>
                                        <input
                                            disabled
                                            id="equipName"
                                            name="equipName"
                                            className="form-control"
                                            onChange={this.changeHandler}
                                            value={this.state.equipName}
                                        />
                                        <div className="invalid-feedback">
                                            Please provide a valid name.
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol sm="12" lg="6">
                                        <label
                                            htmlFor="defaultFormLoginEmailEx"
                                            className="black-text"
                                        >
                                            Manufacturer
                                        </label>
                                        <input
                                            required
                                            name="manufacturer"
                                            id="manufacturer"
                                            className="form-control"
                                            onChange={this.changeHandler}
                                            value={this.state.manufacturer}
                                        />
                                        <div className="invalid-feedback">
                                            Please provide a valid manufacturer
                                            name.
                                        </div>
                                    </MDBCol>
                                    <MDBCol sm="12" lg="6">
                                        <label
                                            htmlFor="defaultFormLoginEmailEx"
                                            className="black-text"
                                        >
                                            Model
                                        </label>
                                        <input
                                            required
                                            className="form-control"
                                            name="model"
                                            id="model"
                                            onChange={this.changeHandler}
                                            value={this.state.model}
                                        />
                                        <div className="invalid-feedback">
                                            Please provide a valid model name.
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                                {this.state.selectedEquipmentType ===
                                "Gateway" ? (
                                    <MDBRow>
                                        <MDBCol sm="12" lg="12">
                                            <label
                                                htmlFor="defaultFormLoginEmailEx"
                                                className="black-text"
                                            >
                                                MikrotikPre643
                                            </label>
                                            <div className="select-box">
                                                <select
                                                    className="browser-default custom-select"
                                                    name="pre643"
                                                    required
                                                    onChange={
                                                        this.changeHandler
                                                    }
                                                    value={this.state.pre643}
                                                >
                                                    <option
                                                        value=""
                                                        disabled
                                                        selected
                                                    >
                                                        Select
                                                    </option>
                                                    <option value="true">
                                                        True
                                                    </option>
                                                    <option value="false">
                                                        False
                                                    </option>
                                                </select>
                                            </div>
                                            <div className="invalid-feedback">
                                                Please provide a valid
                                                selection.
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                ) : (
                                    <MDBRow>
                                        <MDBCol sm="12" lg="12">
                                            <label
                                                htmlFor="defaultFormLoginEmailEx"
                                                className="black-text"
                                            >
                                                Wireless Zone
                                            </label>
                                            <input
                                                className="form-control"
                                                name="zone"
                                                id="zone"
                                                onChange={this.changeHandler}
                                                value={this.state.zone}
                                            />
                                            <div className="invalid-feedback">
                                                Please provide a valid zone.
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                )}
                                <MDBRow>
                                    <MDBCol sm="12" lg="6">
                                        <label
                                            htmlFor="defaultFormLoginEmailEx"
                                            className="black-text"
                                        >
                                            Connection Protocol
                                        </label>
                                        <input
                                            required
                                            name="protocol"
                                            id="protocol"
                                            className="form-control"
                                            onChange={this.changeHandler}
                                            value={this.state.protocol}
                                        />
                                        <div className="invalid-feedback">
                                            Please provide a protocol.
                                        </div>
                                    </MDBCol>
                                    <MDBCol sm="12" lg="6">
                                        <label
                                            htmlFor="defaultFormLoginEmailEx"
                                            className="black-text"
                                        >
                                            IP Address
                                        </label>
                                        <input
                                            required
                                            className="form-control"
                                            name="ipaddy"
                                            id="ipaddy"
                                            onChange={this.changeHandler}
                                            value={this.state.ipaddy}
                                        />
                                        <div className="invalid-feedback">
                                            Please provide a valid IP Address.
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol sm="12" lg="6">
                                        <label
                                            htmlFor="defaultFormLoginEmailEx"
                                            className="black-text"
                                        >
                                            Port
                                        </label>
                                        <input
                                            required
                                            name="port"
                                            id="port"
                                            className="form-control"
                                            onChange={this.changeHandler}
                                            value={this.state.port}
                                        />
                                        <div className="invalid-feedback">
                                            Please provide a port number.
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol sm="12" lg="6">
                                        <label
                                            htmlFor="defaultFormLoginEmailEx"
                                            className="black-text"
                                        >
                                            User Name
                                        </label>
                                        <input
                                            required
                                            name="userName"
                                            id="userName"
                                            className="form-control emailInput"
                                            onChange={(event) =>
                                                this.setState({
                                                    userName:
                                                        event.target.value,
                                                })
                                            }
                                            value={this.state.userName}
                                        />
                                        <div className="invalid-feedback">
                                            Please provide a valid email.
                                        </div>
                                    </MDBCol>
                                    <MDBCol sm="12" lg="6">
                                        <label
                                            htmlFor="defaultFormLoginEmailEx"
                                            className="black-text"
                                        >
                                            Password
                                        </label>
                                        <input
                                            required
                                            className="form-control"
                                            name="userPassword"
                                            id="userPassword"
                                            onChange={this.changeHandler}
                                            value={this.state.userPassword}
                                        />
                                        <div className="invalid-feedback">
                                            Please provide a valid password.
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol sm="12" lg="6">
                                        <label
                                            htmlFor="defaultFormLoginEmailEx"
                                            className="black-text"
                                        >
                                            Status
                                        </label>
                                        <div className="select-box">
                                            <select
                                                className="browser-default custom-select"
                                                name="status"
                                                required
                                                onChange={this.changeHandler}
                                                value={this.state.status}
                                            >
                                                <option
                                                    value=""
                                                    disabled
                                                    selected
                                                >
                                                    Select Status
                                                </option>
                                                <option value="In Service">
                                                    In Service
                                                </option>
                                                <option value="UP">Up</option>
                                                <option value="DOWN">
                                                    Down
                                                </option>
                                            </select>
                                        </div>
                                        <div className="invalid-feedback">
                                            Please provide a valid status.
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                                <div className="text-right submitButton">
                                    <MDBBtn color="danger" type="submit">
                                        {this.props.modify_equipment ===
                                        true ? (
                                            <div
                                                className="spinner-border spinner-border-sm"
                                                role="status"
                                            >
                                                <span className="sr-only">
                                                    Loading...
                                                </span>
                                            </div>
                                        ) : (
                                            "Save Changes"
                                        )}
                                    </MDBBtn>
                                </div>
                            </form>
                        </MDBTabPane>
                        <MDBTabPane tabId="2" role="tabpanel">
                            <br />
                            <div className="logList">
                                <MDBRow>
                                    <MDBCol col="12">
                                        {this.state.equipmentLog !==
                                        undefined ? (
                                            // console.log(this.state.subscriberLog)
                                            <div>
                                                {this.state.equipmentLog.map(
                                                    (value, index) => (
                                                        <div className="mt-2 mb-2">
                                                            {Object.entries(
                                                                value
                                                            ).map(
                                                                (
                                                                    value2,
                                                                    index2
                                                                ) =>
                                                                    value2[0] !==
                                                                    "event" ? (
                                                                        <>
                                                                            <p className="mb-0">
                                                                                <strong>
                                                                                    {
                                                                                        value2[0]
                                                                                    }
                                                                                    :{" "}
                                                                                </strong>
                                                                                {
                                                                                    value2[1]
                                                                                }
                                                                            </p>
                                                                        </>
                                                                    ) : (
                                                                        Object.entries(
                                                                            value2[1]
                                                                        ).map(
                                                                            (
                                                                                value3,
                                                                                index3
                                                                            ) => (
                                                                                <>
                                                                                    <p className="mb-0">
                                                                                        <strong>
                                                                                            {
                                                                                                value3[0]
                                                                                            }
                                                                                            :{" "}
                                                                                        </strong>
                                                                                        {
                                                                                            value3[1]
                                                                                        }
                                                                                    </p>
                                                                                </>
                                                                            )
                                                                        )
                                                                    )
                                                            )}
                                                            <hr />
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        ) : (
                                            <div
                                                className="spinner-border spinner-border-sm"
                                                role="status"
                                            >
                                                <span className="sr-only">
                                                    Loading...
                                                </span>
                                            </div>
                                        )}
                                    </MDBCol>
                                </MDBRow>
                            </div>
                        </MDBTabPane>
                    </MDBTabContent>
                </MDBModalBody>
            </>
        );
    }
}

export default withTranslation()(AddNewVenueEquipment);
