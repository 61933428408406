import React, { Component } from "react";
import {
    MDBBtn,
    MDBModalBody,
    MDBModalHeader,
    MDBRow,
    MDBCol,
    MDBInput,
    MDBTooltip,
    MDBIcon,
    MDBTabPane,
    MDBTabContent,
    MDBNav,
    MDBNavItem,
    MDBNavLink,
} from "mdbreact";
import { withTranslation } from "react-i18next";
import axios from "../../../../axios";
import SelectSearch, { fuzzySearch } from "react-select-search";
import $ from "jquery";
class AddNewVenueEquipment extends Component {
    constructor(props) {
        super(props);
        this.toggleCheckboxValueGloballyAvailable =
            this.toggleCheckboxValueGloballyAvailable.bind(this);
        this.state = {
            servicePlanID:
                this.props.servicePlans[this.props.servicePlanIDKey][
                    "ServicePlanID"
                ],
            servicePlanName:
                this.props.servicePlans[this.props.servicePlanIDKey][
                    "ServicePlanName"
                ],
            servicePlanDescription:
                this.props.servicePlans[this.props.servicePlanIDKey][
                    "ServicePlanDescription"
                ],
            uploadKbps:
                this.props.servicePlans[this.props.servicePlanIDKey][
                    "UploadKbps"
                ]/1000,
            downloadKbps:
                this.props.servicePlans[this.props.servicePlanIDKey][
                    "DownloadKbps"
                ]/1000,
            conDevices:
                this.props.servicePlans[this.props.servicePlanIDKey][
                    "ConcurrentDevices"
                ],
            sessionTimeout:
                this.props.servicePlans[this.props.servicePlanIDKey][
                    "SessionTimeoutSecondsPerDevice"
                ],
            maxRecurrance:
                this.props.servicePlans[this.props.servicePlanIDKey][
                    "MaximumFreeEnrollmentsPerSubscriberPerVenue"
                ],
            maxTrialCycles:
                this.props.servicePlans[this.props.servicePlanIDKey][
                    "MaximumTrialCyclesPerSubscriberPerVenue"
                ],
            billingCycleHours:
                this.props.servicePlans[this.props.servicePlanIDKey][
                    "BillingCycleHours"
                ],
            billingCyclePrice:
                this.props.servicePlans[this.props.servicePlanIDKey][
                    "BillingCyclePrice." +
                        this.props.servicePlans[this.props.servicePlanIDKey][
                            "Currencies"
                        ][0]
                ] / 100,
            spimage:
                this.props.servicePlans[this.props.servicePlanIDKey][
                    "ServicePlanImageURL"
                ],
            globallyavailable:
                this.props.servicePlans[this.props.servicePlanIDKey][
                    "GloballyAvailablePlan"
                ],
            billingCycleRecurrence:
                this.props.servicePlans[this.props.servicePlanIDKey][
                    "BillingCycleRecurrence"
                ],
            defaultForWiredUnits:
                this.props.defaultVenueConfig[
                    "DefaultServicePlanID_GlobalWirelessPlusLocalWiredAccess"
                ] ===
                this.props.servicePlans[this.props.servicePlanIDKey][
                    "ServicePlanID"
                ]
                    ? true
                    : false,
            defaultForLocalWireless:
                this.props.defaultVenueConfig[
                    "DefaultServicePlanID_LocalWirelessAccess"
                ] ===
                this.props.servicePlans[this.props.servicePlanIDKey][
                    "ServicePlanID"
                ]
                    ? true
                    : false,
            trialCycles:
                this.props.servicePlans[this.props.servicePlanIDKey][
                    "TrialCycles"
                ],
            smsCode:
                this.props.servicePlans[this.props.servicePlanIDKey]["SMSCode"],
            externalServicePlanID:
                this.props.servicePlans[this.props.servicePlanIDKey][
                    "externalServicePlanID"
                ],
            currencySymbol: this.props.servicePlans["Country.ISO3166A3"],
            servicePlanUniquePlanID:
                this.props.servicePlans[this.props.servicePlanIDKey][
                    "FCCLabel"
                ]["PlanID"],
            servicePlanDownloadSpeed:
                this.props.servicePlans[this.props.servicePlanIDKey][
                    "FCCLabel"
                ]["TypicalDownloadKbps"]/1000,
            servicePlanUploadSpeed:
                this.props.servicePlans[this.props.servicePlanIDKey][
                    "FCCLabel"
                ]["TypicalUploadKbps"]/1000,
            servicePlanPrivacyPolicyURL:
                this.props.servicePlans[this.props.servicePlanIDKey][
                    "FCCLabel"
                ]["PrivacyPolicyURL"],
            servicePlanNetworkMgmtPolicyURL:
                this.props.servicePlans[this.props.servicePlanIDKey][
                    "FCCLabel"
                ]["NetworkMgmtPolicyURL"],
            servicePlanDataPolicyURL:
                this.props.servicePlans[this.props.servicePlanIDKey][
                    "FCCLabel"
                ]["DataPolicyURL"],
            servicePlanBundleDiscountURL:
                this.props.servicePlans[this.props.servicePlanIDKey][
                    "FCCLabel"
                ]["BundleDiscountURL"],
            activeItem: "1",
            servicePlanCustomerSupportURL:
                this.props.servicePlans[this.props.servicePlanIDKey][
                    "FCCLabel"
                ]["SupportURL"],
                speedOptions: [
                 {
                     value: "kbps",
                     name: "Kbps",
                 },
                 {
                     value: "mbps",
                     name: "Mpbs",
                 },
                 {
                     value: "gbps",
                     name: "Gbps",
                 },
             ],
             selectedUploadSpeedType: "mbps",
             selectedDownloadSpeedType: "mbps",
             minUpload: 0.001,
            maxUpload: 100000,
            minDownload: 0.001,
            maxDownload: 100000
        };
    }
    componentDidMount() {
        // this.getLogs();
    }
    useRegex(input) {
        let regex = /^F[A-Z0-9]+$/;
        if (!regex.test(input)) {
            this.setState({
                invalidUniqueID: "Please provide a valid Unique Plan ID",
            });
            $(".emailInput").addClass("is-invalid");
        } else {
            this.setState({ invalidUniqueID: undefined });
            $(".emailInput").removeClass("is-invalid");
        }
    }
    submitModifyServicePlan = (event) => {
        event.preventDefault();
        event.target.className += " was-validated";
        let uploadSpeed;
        let downloadSpeed;
        // console.log(this.state.uploadKbps);
        switch (this.state.selectedUploadSpeedType) {
            case "mbps":
                uploadSpeed = this.state.uploadKbps * 1000;
                break;
            case "gbps":
                uploadSpeed = this.state.uploadKbps * 1000000;
                break;
            default:
                uploadSpeed = this.state.uploadKbps;
                break;
        }
        switch (this.state.selectedDownloadSpeedType) {
            case "mbps":
                downloadSpeed = this.state.downloadKbps * 1000;
                break;
            case "gbps":
                downloadSpeed = this.state.downloadKbps * 1000000;
                break;
            default:
                downloadSpeed = this.state.downloadKbps;
                break;
        }
        if ($(".invalid-feedback").is(":visible") !== true) {
            this.props.submitModifyServicePlan(
                this.state.servicePlanName,
                this.state.servicePlanID,
                this.state.servicePlanDescription,
                uploadSpeed,
                downloadSpeed,
                this.state.conDevices,
                this.state.sessionTimeout,
                this.state.maxRecurrance,
                this.state.maxTrialCycles,
                this.state.billingCycleHours,
                this.state.billingCyclePrice,
                this.state.billingCycleRecurrence,
                this.state.trialCycles,
                this.state.spimage,
                this.state.globallyavailable,
                this.state.defaultForWiredUnits,
                this.state.defaultForLocalWireless,
                this.state.externalServicePlanID,
                this.state.smsCode
            );
        }
    };
    submitModifyServicePlanBroadbandLabel = (event) => {
        event.preventDefault();
        event.target.className += " was-validated";
        if (
            $(".invalid-feedback").is(":visible") !== true &&
            this.state.invalidUniqueID === undefined
        ) {
            this.props.submitModifyServicePlanBroadbandLabel(
                this.state.servicePlanID,
                this.state.servicePlanUniquePlanID,
                this.state.servicePlanDownloadSpeed,
                this.state.servicePlanUploadSpeed,
                this.state.servicePlanPrivacyPolicyURL,
                this.state.servicePlanNetworkMgmtPolicyURL,
                this.state.servicePlanDataPolicyURL,
                this.state.servicePlanBundleDiscountURL,
                this.state.servicePlanCustomerSupportURL
            );
        }
    };
    changeHandler = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };
    toggleCheckboxValueGloballyAvailable = () => {
        this.setState({ globallyavailable: !this.state.globallyavailable });
    };
    toggleCheckboxValueDefaultWired = () => {
        this.setState({
            defaultForWiredUnits: !this.state.defaultForWiredUnits,
        });
    };
    toggleCheckboxValueDefaultWireless = () => {
        this.setState({
            defaultForLocalWireless: !this.state.defaultForLocalWireless,
        });
    };
    getLogs = () => {
        let body = {
            AccessToken: sessionStorage.getItem("AccessToken"),
            ServicePlanID: this.props.servicePlanID,
        };
        axios
            .post(
                "/networkoperatorconsole/equipment/listServicePlanLog",
                body,
                {
                    headers: {},
                }
            )
            .then((response) => {
                this.setState({
                    servicePlanLog: response.data.log,
                });
            })
            .catch((error) => {
                this.setState({ error: true });
            });
    };
    toggleTab = (tab) => () => {
        if (this.state.activeItem !== tab) {
            this.setState({
                activeItem: tab,
            });
        }
    };
    toggleTabPreview = (tab) => () => {
        if (this.state.activeItemPreview !== tab) {
            this.setState({
                activeItemPreview: tab,
            });
        }
    };

    handleSelectUploadSpeedType = (value) => {
     this.setState({ selectedUploadSpeedType: value });
     switch (value) {
      case "mbps":
          this.setState({
           minUpload : 0.001,
           maxUpload: 100000
          })
          break;
      case "gbps":
       this.setState({
        minUpload : .000001,
        maxUpload: 100
       })
          break;
      default:
       this.setState({
        minUpload : 1,
        maxUpload: 100000000
       })
          break;
  }
    };

    handleSelectDownloadSpeedType = (value) => {
        this.setState({ selectedDownloadSpeedType: value });
        switch (value) {
         case "mbps":
             this.setState({
              minDownload : 0.001,
              maxDownload: 100000
             })
             break;
         case "gbps":
          this.setState({
           minDownload : .000001,
           maxDownload: 100
          })
             break;
         default:
          this.setState({
           minDownload : 1,
           maxDownload: 100000000
          })
             break;
     }
    };
    render() {
        const { t } = this.props;
        return (
            <>
                <MDBModalHeader toggle={this.props.toggleModifyServicePlan}>
                    <h4>Modify Service Plan</h4>
                    <p>{t("addnewresident.required")}</p>
                </MDBModalHeader>
                <MDBModalBody>
                    <MDBNav className="nav-tabs">
                        <MDBNavItem>
                            <MDBNavLink
                                link
                                to="#"
                                active={this.state.activeItem === "1"}
                                onClick={this.toggleTab("1")}
                                role="tab"
                            >
                                <MDBIcon icon="signal" /> Plan Details
                            </MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem>
                            <MDBNavLink
                                link
                                to="#"
                                active={this.state.activeItem === "2"}
                                onClick={this.toggleTab("2")}
                                role="tab"
                            >
                                <MDBIcon icon="clipboard-list" /> Broadband
                                Label
                            </MDBNavLink>
                        </MDBNavItem>
                    </MDBNav>
                    <MDBTabContent
                        className=""
                        activeItem={this.state.activeItem}
                    >
                        <MDBTabPane tabId="1" role="tabpanel">
                            <form
                                className="needs-validation"
                                onSubmit={this.submitModifyServicePlan}
                                noValidate
                            >
                                <MDBRow>
                                    <MDBCol col="6" sm="12" lg="6">
                                        <label
                                            htmlFor="defaultFormLoginEmailEx"
                                            className="black-text"
                                        >
                                            Service Plan Name
                                        </label>
                                        <MDBTooltip
                                            material
                                            placement="left"
                                            className="rightTooltip"
                                            style={{ display: "block" }}
                                        >
                                            <MDBIcon
                                                far
                                                icon="question-circle"
                                            />
                                            <span>
                                                The name administrators and
                                                subscribers will see when adding
                                                a user to, or signing up for,
                                                the service plan
                                            </span>
                                        </MDBTooltip>
                                        <input
                                            id="servicePlanName"
                                            name="servicePlanName"
                                            className="form-control"
                                            onChange={this.changeHandler}
                                            value={this.state.servicePlanName}
                                            disabled
                                        />
                                        <div className="invalid-feedback">
                                            Please provide a valid name.
                                        </div>
                                    </MDBCol>
                                    <MDBCol col="6" sm="12" lg="6">
                                        <label
                                            htmlFor="defaultFormLoginEmailEx"
                                            className="black-text"
                                        >
                                            Service Plan Description*
                                        </label>
                                        <MDBTooltip
                                            material
                                            placement="left"
                                            className="rightTooltip"
                                            style={{ display: "block" }}
                                        >
                                            <MDBIcon
                                                far
                                                icon="question-circle"
                                            />
                                            <span>
                                                The description of the plan that
                                                administrators and subscribers
                                                will see when adding a user to,
                                                or signing up for, the service
                                                plan
                                            </span>
                                        </MDBTooltip>
                                        <input
                                            required
                                            id="servicePlanDescription"
                                            name="servicePlanDescription"
                                            className="form-control"
                                            onChange={this.changeHandler}
                                            value={
                                                this.state
                                                    .servicePlanDescription
                                            }
                                        />
                                        <div className="invalid-feedback">
                                            Please provide a valid name.
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol col="6" sm="12" lg="6">
                                        <label
                                            htmlFor="defaultFormLoginEmailEx"
                                            className="black-text"
                                        >
                                            Service Plan Image
                                        </label>
                                        <MDBTooltip
                                            material
                                            placement="left"
                                            className="rightTooltip"
                                            style={{ display: "block" }}
                                        >
                                            <MDBIcon
                                                far
                                                icon="question-circle"
                                            />
                                            <span>
                                                The image of the plan that
                                                administrators and subscribers
                                                will see when adding a user to,
                                                or signing up for, the service
                                                plan
                                            </span>
                                        </MDBTooltip>
                                        <input
                                            name="spimage"
                                            id="spimage"
                                            className="form-control"
                                            onChange={this.changeHandler}
                                            value={
                                                this.state.spimage === undefined
                                                    ? "https://s3.amazonaws.com/ui.us-east-1.wifikey.io/roamingiq/speed1.png"
                                                    : this.state.spimage
                                            }
                                            placeholder="Image path (Including https://)"
                                        />
                                        <div className="invalid-feedback">
                                            Please provide a valid value.
                                        </div>
                                    </MDBCol>
                                    <MDBCol col="6" sm="12" lg="6">
                                        <label
                                            htmlFor="defaultFormLoginEmailEx"
                                            className="black-text"
                                        >
                                            External Service Plan ID
                                        </label>
                                        <input
                                            name="externalServicePlanID"
                                            id="externalServicePlanID"
                                            className="form-control"
                                            onChange={this.changeHandler}
                                            value={
                                                this.state.externalServicePlanID
                                            }
                                        />
                                        <div className="invalid-feedback">
                                            Please provide a valid value.
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol sm="12" lg="3">
                                        <label
                                            htmlFor="defaultFormLoginEmailEx"
                                            className="black-text"
                                        >
                                            Upload Speed*
                                        </label>
                                        {/* <MDBTooltip material placement="left" className="rightTooltip" style={{ display: "block" }}>
                                    <MDBIcon far icon="question-circle" />
                                    <span>The unit you select applies to both upload and download speeds. For example, 1 Gbps = 1000 Mbps = 1,000,000 kbps.</span>
                                </MDBTooltip> */}
                                <MDBRow className="pr-0">
                                    <MDBCol sm="6" lg="6" className="pr-0">
                                        <input required type="number" name="uploadKbps" id="uploadKbps" className="form-control" onChange={this.changeHandler} value={this.state.uploadKbps} min={this.state.minUpload} max={this.state.maxUpload} />
                                        {parseInt(this.state.uploadKbps) > parseInt(this.state.maxUpload) ? <div className="invalid invalidDaysSelected">You can only select from {this.state.minUpload} to {this.state.maxUpload}</div> : ""}

                                        <div className="invalid-feedback">Please provide a valid value ({this.state.minUpload}-{this.state.maxUpload}).</div>
                                    </MDBCol>
                                    <MDBCol sm="6" lg="6">
                                        <SelectSearch
                                            id="selectedUploadSpeedType"
                                            filterOptions={fuzzySearch}
                                            options={this.state.speedOptions}
                                            inputId="equipmentTypeArray"
                                            classNamePrefix="unit-select form-control"
                                            placeholder="Select..."
                                            onChange={this.handleSelectUploadSpeedType}
                                            value={this.state.selectedUploadSpeedType}
                                        />
                                    </MDBCol>
                                </MDBRow>
                            </MDBCol>
                            <MDBCol sm="12" lg="3">
                                <label htmlFor="defaultFormLoginEmailEx" className="black-text">
                                    Download Speed*
                                </label>
                                {/* <MDBTooltip material placement="left" className="rightTooltip" style={{ display: "block" }}>
                                    <MDBIcon far icon="question-circle" />
                                    <span>The unit you select applies to both upload and download speeds. For example, 1 Gbps = 1000 Mbps = 1,000,000 kbps.</span>
                                </MDBTooltip> */}
                                <MDBRow className="pr-0">
                                    <MDBCol sm="6" lg="6" className="pr-0">
                                        <input required type="number" className="form-control" name="downloadKbps" id="downloadKbps" onChange={this.changeHandler} value={this.state.downloadKbps} min={this.state.minDownload} max={this.state.maxDownload} />
                                        {parseInt(this.state.downloadKbps) > parseInt(this.state.maxDownload) ? <div className="invalid invalidDaysSelected">You can only select from {this.state.minDownload} to {this.state.maxDownload}</div> : ""}
                                        <div className="invalid-feedback">Please provide a valid value ({this.state.minDownload}-{this.state.maxDownload}).</div>
                                    </MDBCol>
                                    <MDBCol sm="6" lg="6">
                                        <SelectSearch
                                            id="selectDownloadSpeedType"
                                            filterOptions={fuzzySearch}
                                            options={this.state.speedOptions}
                                            inputId="equipmentTypeArray"
                                            classNamePrefix="unit-select form-control"
                                            placeholder="Select..."
                                            onChange={this.handleSelectDownloadSpeedType}
                                            value={this.state.selectedDownloadSpeedType}
                                        />
                                    </MDBCol>
                                </MDBRow>
                            </MDBCol>
                                    <MDBCol sm="12" lg="3">
                                        <label
                                            htmlFor="defaultFormLoginEmailEx"
                                            className="black-text"
                                        >
                                            Concurrent Devices*
                                        </label>
                                        <MDBTooltip
                                            material
                                            placement="left"
                                            className="rightTooltip"
                                            style={{ display: "block" }}
                                        >
                                            <MDBIcon
                                                far
                                                icon="question-circle"
                                            />
                                            <span>
                                                The maximum number of concurrent
                                                devices a user can have
                                                connected at one time.
                                            </span>
                                        </MDBTooltip>
                                        <input
                                            required
                                            name="conDevices"
                                            id="conDevices"
                                            className="form-control"
                                            onChange={this.changeHandler}
                                            value={this.state.conDevices}
                                            disabled="true"
                                        />
                                        <div className="invalid-feedback">
                                            Please provide a valid value.
                                        </div>
                                    </MDBCol>
                                    <MDBCol sm="12" lg="3">
                                        <label
                                            htmlFor="defaultFormLoginEmailEx"
                                            className="black-text"
                                        >
                                            Session Timeout*
                                        </label>
                                        <MDBTooltip
                                            material
                                            placement="left"
                                            className="rightTooltip"
                                            style={{ display: "block" }}
                                        >
                                            <MDBIcon
                                                far
                                                icon="question-circle"
                                            />
                                            <span>
                                                The number of seconds a device
                                                is allowed to remain connected
                                                before being forced to
                                                reauthenticate
                                            </span>
                                        </MDBTooltip>
                                        <input
                                            required
                                            className="form-control"
                                            name="sessionTimeout"
                                            id="sessionTimeout"
                                            onChange={this.changeHandler}
                                            value={this.state.sessionTimeout}
                                            disabled="true"
                                        />
                                        <div className="invalid-feedback">
                                            Please provide a valid value.
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol sm="12" lg="4">
                                        <label
                                            htmlFor="defaultFormLoginEmailEx"
                                            className="black-text"
                                        >
                                            Billing Cycle Hours
                                        </label>
                                        <MDBTooltip
                                            material
                                            placement="left"
                                            className="rightTooltip"
                                            style={{ display: "block" }}
                                        >
                                            <MDBIcon
                                                far
                                                icon="question-circle"
                                            />
                                            <span>
                                                How often the service plan will
                                                bill the customer at the Billing
                                                Cycle Price, in hours. For
                                                monthly set to 730.5
                                            </span>
                                        </MDBTooltip>
                                        <input
                                            required
                                            name="billingCycleHours"
                                            id="billingCycleHours"
                                            className="form-control"
                                            onChange={this.changeHandler}
                                            value={this.state.billingCycleHours}
                                            disabled="true"
                                        />
                                        <div className="invalid-feedback">
                                            Please provide a valid value.
                                        </div>
                                    </MDBCol>
                                    <MDBCol sm="12" lg="4">
                                        <label
                                            htmlFor="defaultFormLoginEmailEx"
                                            className="black-text"
                                        >
                                            Billing Cycle Price
                                        </label>
                                        <MDBTooltip
                                            material
                                            placement="left"
                                            className="rightTooltip"
                                            style={{ display: "block" }}
                                        >
                                            <MDBIcon
                                                far
                                                icon="question-circle"
                                            />
                                            <span>
                                                The price for this service plan
                                                per Billing Cycle Hours
                                            </span>
                                        </MDBTooltip>
                                        <input
                                            required
                                            className="form-control"
                                            name="billingCyclePrice"
                                            id="billingCyclePrice"
                                            onChange={this.changeHandler}
                                            value={this.state.billingCyclePrice}
                                        />
                                        <div className="invalid-feedback">
                                            Please provide a valid value.
                                        </div>
                                    </MDBCol>
                                    <MDBCol sm="12" lg="4">
                                        <label
                                            htmlFor="defaultFormLoginEmailEx"
                                            className="black-text"
                                        >
                                            Maximum Billing Cycles
                                        </label>
                                        <MDBTooltip
                                            material
                                            placement="left"
                                            className="rightTooltip"
                                            style={{ display: "block" }}
                                        >
                                            <MDBIcon
                                                far
                                                icon="question-circle"
                                            />
                                            <span>
                                                The number of billing cycles a
                                                given e-mail address may be
                                                billed for. Set to 0 for
                                                unlimited.
                                            </span>
                                        </MDBTooltip>
                                        <input
                                            required
                                            name="billingCycleRecurrence"
                                            id="billingCycleRecurrence"
                                            className="form-control"
                                            onChange={this.changeHandler}
                                            value={
                                                this.state
                                                    .billingCycleRecurrence
                                            }
                                        />
                                        <div className="invalid-feedback">
                                            Please provide a valid value.
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol sm="12" lg="4">
                                        <label
                                            htmlFor="defaultFormLoginEmailEx"
                                            className="black-text"
                                        >
                                            Maximum Free Cycles
                                        </label>
                                        <MDBTooltip
                                            material
                                            placement="left"
                                            className="rightTooltip"
                                            style={{ display: "block" }}
                                        >
                                            <MDBIcon
                                                far
                                                icon="question-circle"
                                            />
                                            <span>
                                                If the NET billing cycle price
                                                is 0 for a subscription, you may
                                                limit how many billing cycles a
                                                given e-mail address may sign up
                                                for. Set to 0 for unlimited.
                                                This has no effect on a NET
                                                billing price greater than $0.
                                            </span>
                                        </MDBTooltip>
                                        <input
                                            required
                                            type="number"
                                            name="maxRecurrance"
                                            id="maxRecurrance"
                                            className="form-control"
                                            onChange={this.changeHandler}
                                            value={this.state.maxRecurrance}
                                            min="0"
                                            max="100"
                                        />
                                        <div className="invalid-feedback">
                                            Please provide a valid value.
                                        </div>
                                    </MDBCol>
                                    <MDBCol sm="12" lg="4">
                                        <label
                                            htmlFor="defaultFormLoginEmailEx"
                                            className="black-text"
                                        >
                                            Trial Cycles
                                        </label>
                                        <MDBTooltip
                                            material
                                            placement="left"
                                            className="rightTooltip"
                                            style={{ display: "block" }}
                                        >
                                            <MDBIcon
                                                far
                                                icon="question-circle"
                                            />
                                            <span>
                                                Enter the number of billing
                                                cycles you want to offer for
                                                free.
                                            </span>
                                        </MDBTooltip>
                                        <input
                                            required
                                            name="trialCycles"
                                            id="trialCycles"
                                            className="form-control"
                                            onChange={this.changeHandler}
                                            value={this.state.trialCycles}
                                        />
                                        <div className="invalid-feedback">
                                            Please provide a valid value.
                                        </div>
                                    </MDBCol>
                                    <MDBCol sm="12" lg="4">
                                        <label
                                            htmlFor="defaultFormLoginEmailEx"
                                            className="black-text"
                                        >
                                            SMS Code
                                        </label>
                                        <MDBTooltip
                                            material
                                            placement="left"
                                            className="rightTooltip"
                                            style={{ display: "block" }}
                                        >
                                            <MDBIcon
                                                far
                                                icon="question-circle"
                                            />
                                            <span>
                                                If you want to allow subscribers
                                                to sign up for this service plan
                                                over SMS, enter the alphanumeric
                                                SMS code the subscriber must
                                                send.
                                            </span>
                                        </MDBTooltip>
                                        <input
                                            name="smsCode"
                                            id="smsCode"
                                            className="form-control"
                                            onChange={this.changeHandler}
                                            value={this.state.smsCode}
                                            maxlength="6"
                                            pattern="[a-zA-Z0-9]*"
                                        />
                                        <div
                                            className="invalid-feedback"
                                            id="smsCodeFeedback"
                                        >
                                            Please provide a valid value
                                            (alphanumeric characters only).
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol sm="12" lg="6">
                                        <MDBInput
                                            label="Require Service Plan?"
                                            type="checkbox"
                                            id="globallyavailable"
                                            name="globallyavailable"
                                            onChange={
                                                this
                                                    .toggleCheckboxValueGloballyAvailable
                                            }
                                            checked={
                                                this.state.globallyavailable
                                            }
                                        >
                                            <MDBTooltip
                                                material
                                                placement="left"
                                                className="rightTooltip"
                                                style={{ display: "block" }}
                                            >
                                                <MDBIcon
                                                    far
                                                    icon="question-circle"
                                                />
                                                <span>
                                                    Check if you want this
                                                    service plan to be
                                                    automatically added and
                                                    required at all venues.
                                                </span>
                                            </MDBTooltip>
                                        </MDBInput>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol sm="12" lg="6">
                                        <MDBInput
                                            label="Default Service Plan for Residential Units"
                                            type="checkbox"
                                            id="defaultForWiredUnits"
                                            name="defaultForWiredUnits"
                                            onChange={
                                                this
                                                    .toggleCheckboxValueDefaultWired
                                            }
                                            checked={
                                                this.state.defaultForWiredUnits
                                            }
                                        />
                                    </MDBCol>
                                    <MDBCol sm="12" lg="6">
                                        <MDBInput
                                            label="Default Service Plan for Guest Subscriptions"
                                            type="checkbox"
                                            id="defaultForLocalWireless"
                                            name="defaultForLocalWireless"
                                            onChange={
                                                this
                                                    .toggleCheckboxValueDefaultWireless
                                            }
                                            checked={
                                                this.state
                                                    .defaultForLocalWireless
                                            }
                                        />
                                    </MDBCol>
                                </MDBRow>
                                <br />
                                <div className="text-right submitButton">
                                    <MDBBtn color="danger" type="submit">
                                        {this.props.modify_serviceplan ===
                                        true ? (
                                            <div
                                                className="spinner-border spinner-border-sm"
                                                role="status"
                                            >
                                                <span className="sr-only">
                                                    Loading...
                                                </span>
                                            </div>
                                        ) : (
                                            "Save Changes"
                                        )}
                                    </MDBBtn>
                                    <MDBBtn
                                        color="primary"
                                        onClick={
                                            this.props.toggleModifyServicePlan
                                        }
                                    >
                                        Close
                                    </MDBBtn>
                                </div>
                            </form>
                        </MDBTabPane>
                        <MDBTabPane tabId="2" role="tabpanel">
                            <form
                                className="needs-validation"
                                onSubmit={
                                    this.submitModifyServicePlanBroadbandLabel
                                }
                                noValidate
                            >
                                <MDBRow>
                                    <MDBCol col="6" sm="12" lg="6">
                                        <label
                                            htmlFor="defaultFormLoginEmailEx"
                                            className="black-text"
                                        >
                                            Unique Plan ID*
                                        </label>
                                        <MDBTooltip
                                            material
                                            placement="left"
                                            className="rightTooltip"
                                            style={{ display: "block" }}
                                        >
                                            <MDBIcon
                                                far
                                                icon="question-circle"
                                            />
                                            <span>
                                                Please enter a plan ID in the
                                                following format; the first
                                                character is the letter F,
                                                followed by your FCC
                                                registration number, followed by
                                                a string of 15 alphanumeric
                                                characters, excluding special
                                                characters.
                                            </span>
                                        </MDBTooltip>
                                        <input
                                            required
                                            id="servicePlanUniquePlanID"
                                            name="servicePlanUniquePlanID"
                                            className="form-control emailInput"
                                            onBlur={(event) =>
                                                this.useRegex(
                                                    this.state
                                                        .servicePlanUniquePlanID
                                                )
                                            }
                                            onChange={this.changeHandler}
                                            value={
                                                this.state
                                                    .servicePlanUniquePlanID
                                            }
                                            error={
                                                this.state.invalidUniqueID !==
                                                undefined
                                                    ? "Invalid Unique ID"
                                                    : "Unique Plan ID required"
                                            }
                                        />
                                        {this.state.invalidUniqueID !==
                                        undefined ? (
                                            <div className="invalid">
                                                {this.state.invalidUniqueID}
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </MDBCol>
                                </MDBRow>
                                <hr />
                                <h5 className="font-weight-bold pl-0 mt-2 mb-3">
                                    <strong>Speeds Provided with Plan</strong>
                                </h5>
                                <p>Note: Latency is set at the Venue</p>
                                <MDBRow>
                                    <MDBCol col="6" sm="12" lg="6">
                                        <label
                                            htmlFor="defaultFormLoginEmailEx"
                                            className="black-text"
                                        >
                                            Typical Download Speed*
                                        </label>
                                        {/* <MDBTooltip
                      material
                      placement="left"
                      className="rightTooltip"
                      style={{ display: "block" }}
                    >
                      <MDBIcon far icon="question-circle" />
                      <span>
                        The typical download speed associated with the plan
                        measured in megabits per second (Mbps), with a maximum
                        of two decimal places.
                      </span>
                    </MDBTooltip> */}
                                        <input
                                            required
                                            id="servicePlanDownloadSpeed"
                                            name="servicePlanDownloadSpeed"
                                            className="form-control"
                                            onChange={this.changeHandler}
                                            value={
                                                this.state
                                                    .servicePlanDownloadSpeed
                                            }
                                        />
                                        <div className="invalid-feedback">
                                            Please provide a valid name.
                                        </div>
                                    </MDBCol>
                                    <MDBCol col="6" sm="12" lg="6">
                                        <label
                                            htmlFor="defaultFormLoginEmailEx"
                                            className="black-text"
                                        >
                                            Typical Upload Speed*
                                        </label>
                                        {/* <MDBTooltip
                      material
                      placement="left"
                      className="rightTooltip"
                      style={{ display: "block" }}
                    >
                      <MDBIcon far icon="question-circle" />
                      <span>
                        The typical upload speed associated with the plan
                        measured in megabits per second (Mbps), with a maximum
                        of two decimal places.
                      </span>
                    </MDBTooltip> */}
                                        <input
                                            required
                                            id="servicePlanUploadSpeed"
                                            name="servicePlanUploadSpeed"
                                            className="form-control"
                                            onChange={this.changeHandler}
                                            value={
                                                this.state
                                                    .servicePlanUploadSpeed
                                            }
                                        />
                                        <div className="invalid-feedback">
                                            Please provide a valid name.
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                                <hr />
                                <h5 className="font-weight-bold pl-0 mt-2 mb-3">
                                    <strong>URLs</strong>
                                </h5>
                                <MDBRow>
                                    <MDBCol col="6" sm="12" lg="6">
                                        <label
                                            htmlFor="defaultFormLoginEmailEx"
                                            className="black-text"
                                        >
                                            Privacy Policy URL*
                                        </label>
                                        <MDBTooltip
                                            material
                                            placement="left"
                                            className="rightTooltip"
                                            style={{ display: "block" }}
                                        >
                                            <MDBIcon
                                                far
                                                icon="question-circle"
                                            />
                                            <span>
                                                Please insert the URL to your
                                                privacy policy.
                                            </span>
                                        </MDBTooltip>
                                        <input
                                            required
                                            id="servicePlanPrivacyPolicyURL"
                                            name="servicePlanPrivacyPolicyURL"
                                            className="form-control"
                                            onChange={this.changeHandler}
                                            value={
                                                this.state
                                                    .servicePlanPrivacyPolicyURL
                                            }
                                        />
                                        <div className="invalid-feedback">
                                            Please provide a valid name.
                                        </div>
                                    </MDBCol>
                                    <MDBCol col="6" sm="12" lg="6">
                                        <label
                                            htmlFor="defaultFormLoginEmailEx"
                                            className="black-text"
                                        >
                                            Network Management Policy URL*
                                        </label>
                                        <MDBTooltip
                                            material
                                            placement="left"
                                            className="rightTooltip"
                                            style={{ display: "block" }}
                                        >
                                            <MDBIcon
                                                far
                                                icon="question-circle"
                                            />
                                            <span>
                                                Please insert the URL to your
                                                network management policy.
                                            </span>
                                        </MDBTooltip>
                                        <input
                                            required
                                            id="servicePlanNetworkMgmtPolicyURL"
                                            name="servicePlanNetworkMgmtPolicyURL"
                                            className="form-control"
                                            onChange={this.changeHandler}
                                            value={
                                                this.state
                                                    .servicePlanNetworkMgmtPolicyURL
                                            }
                                        />
                                        <div className="invalid-feedback">
                                            Please provide a valid name.
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol col="6" sm="12" lg="6">
                                        <label
                                            htmlFor="defaultFormLoginEmailEx"
                                            className="black-text"
                                        >
                                            Data Policy URL
                                        </label>
                                        <MDBTooltip
                                            material
                                            placement="left"
                                            className="rightTooltip"
                                            style={{ display: "block" }}
                                        >
                                            <MDBIcon
                                                far
                                                icon="question-circle"
                                            />
                                            <span>
                                                If you have a data allowance
                                                policy, please insert the URL to
                                                that policy.
                                            </span>
                                        </MDBTooltip>
                                        <input
                                            id="servicePlanDataPolicyURL"
                                            name="servicePlanDataPolicyURL"
                                            className="form-control"
                                            onChange={this.changeHandler}
                                            value={
                                                this.state
                                                    .servicePlanDataPolicyURL
                                            }
                                        />
                                        <div className="invalid-feedback">
                                            Please provide a valid name.
                                        </div>
                                    </MDBCol>
                                    <MDBCol col="6" sm="12" lg="6">
                                        <label
                                            htmlFor="defaultFormLoginEmailEx"
                                            className="black-text"
                                        >
                                            Bundle Discount URL
                                        </label>
                                        <MDBTooltip
                                            material
                                            placement="left"
                                            className="rightTooltip"
                                            style={{ display: "block" }}
                                        >
                                            <MDBIcon
                                                far
                                                icon="question-circle"
                                            />
                                            <span>
                                                If you provide bundle discounts,
                                                please insert the URL to your
                                                policy describing your bundle
                                                discounts.
                                            </span>
                                        </MDBTooltip>
                                        <input
                                            id="servicePlanBundleDiscountURL"
                                            name="servicePlanBundleDiscountURL"
                                            className="form-control"
                                            onChange={this.changeHandler}
                                            value={
                                                this.state
                                                    .servicePlanBundleDiscountURL
                                            }
                                        />
                                        <div className="invalid-feedback">
                                            Please provide a valid name.
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol col="6" sm="12" lg="6">
                                        <label
                                            htmlFor="defaultFormLoginEmailEx"
                                            className="black-text"
                                        >
                                            Customer Support URL
                                        </label>
                                        <MDBTooltip
                                            material
                                            placement="left"
                                            className="rightTooltip"
                                            style={{ display: "block" }}
                                        >
                                            <MDBIcon
                                                far
                                                icon="question-circle"
                                            />
                                            <span>
                                                Please insert your customer
                                                support URL.
                                            </span>
                                        </MDBTooltip>
                                        <input
                                            id="servicePlanCustomerSupportURL"
                                            name="servicePlanCustomerSupportURL"
                                            className="form-control"
                                            onChange={this.changeHandler}
                                            value={
                                                this.state
                                                    .servicePlanCustomerSupportURL
                                            }
                                        />
                                        <div className="invalid-feedback">
                                            Please provide a valid name.
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                                <br />
                                <div className="text-right submitButton">
                                    <MDBBtn color="danger" type="submit">
                                        {this.props.modify_serviceplan ===
                                        true ? (
                                            <div
                                                className="spinner-border spinner-border-sm"
                                                role="status"
                                            >
                                                <span className="sr-only">
                                                    Loading...
                                                </span>
                                            </div>
                                        ) : (
                                            "Save Changes"
                                        )}
                                    </MDBBtn>
                                    <MDBBtn
                                        color="primary"
                                        onClick={
                                            this.props.toggleModifyServicePlan
                                        }
                                    >
                                        Close
                                    </MDBBtn>
                                </div>
                            </form>
                        </MDBTabPane>
                    </MDBTabContent>
                </MDBModalBody>
            </>
        );
    }
}
export default withTranslation()(AddNewVenueEquipment);
