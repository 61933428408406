import React, { Component } from "react";

import {
    MDBBtn,
    MDBModal,
    MDBModalBody,
    MDBModalHeader,
    MDBModalFooter,
    MDBRow,
    MDBCol,
    toast,
} from "mdbreact";

import { withTranslation } from "react-i18next";

import OperatorKeypoolsSubscribersTable from "../../../components/NetworkMgmt/Settings/Datatables/OperatorKeypoolsSubscribersTable";

import VenuesTable from "../../../components/NetworkMgmt/Settings/Datatables/OperatorKeypoolVenuesTable";

import axios from "../../../axios";

class OperatorInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            displayOrder:
                this.props.keypoolInfo[this.props.keyPoolID]["DisplayOrder"],
            update_keypool: false,
            isDeleting: false,
        };
    }

    componentDidMount() {
        this.getLogs();
    }

    modifyVenue = (venueName, key) => {
        this.setState({
            modalModifyVenue: !this.state.modalModifyVenue,
        });
        this.setState({
            venueID: key,
        });
        this.props.handleSelectChangeVenueFromList(venueName, key);
        this.props.getVenues(key);
    };

    toggleModifyVenue = () => {
        this.setState({
            modalModifyVenue: !this.state.modalModifyVenue,
        });
    };

    submitModifyKeypool = (event) => {
        event.preventDefault();
        this.setState({ update_keypool: true });
        let body = {
            AccessToken: sessionStorage.getItem("AccessToken"),
            KeyPoolID: this.props.keypoolInfo[this.props.keyPoolID]["key"],
            DisplayOrder: this.state.displayOrder,
        };
        axios
            .post("/networkoperatorconsole/keypools/configureKeypool", body, {
                headers: {},
            })
            .then(
                (response) => {
                    toast.success("Updated Keypool!", {
                        closeButton: false,
                    });
                    this.props.toggleKeypoolInfo();
                    this.setState({ update_keypool: false });
                    this.props.getOperatorInfo();
                },
                (error) => {
                    toast.error("There was an error!", {
                        closeButton: false,
                    });
                    this.props.toggleKeypoolInfo();
                    this.setState({ update_keypool: false });
                }
            );
    };

    getLogs = () => {
        if (window.location.pathname === "/networkdashboard") {
            let body = {
                AccessToken: sessionStorage.getItem("AccessToken"),
                KeyPoolID: this.props.keypoolInfo[this.props.keyPoolID]["key"],
            };
            axios
                .post("/networkoperatorconsole/keypools/listKeypoolLog", body, {
                    headers: {},
                })
                .then((response) => {
                    this.setState({
                        keyPoolLog: response.data.log,
                    });
                })
                .catch((error) => {
                    this.setState({ error: true });
                });
        }
    };

    changeHandler = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };

    toggleConfirmKeypoolDeletion = (key) => {
        this.setState({
            modalConfirmKeypoolDeletion:
                !this.state.modalConfirmKeypoolDeletion,
        });
    };

    confirmDeleteKeypool = () => {
        if (this.state.confirmDelete === "DELETE") {
            this.setState({
                isDeleting: true,
            });

            let deleteUnitsAPI;

            deleteUnitsAPI =
                "/networkoperatorconsole/admin/deleteKeypoolPermanently";
            let body = {
                AccessToken: sessionStorage.getItem("AccessToken"),
                KeyPoolID: this.props.keypoolInfo[this.props.keyPoolID]["key"],
            };
            axios
                .post(deleteUnitsAPI, body, {
                    headers: {},
                })
                .then(
                    (response) => {
                        toast.success("Keypool Deleted!", {
                            closeButton: false,
                        });
                        this.props.toggleKeypoolInfo();
                        this.setState({ isDeleting: false });
                        this.props.getOperatorInfo();
                    },
                    (error) => {
                        toast.error(error.response.data.message, {
                            closeButton: false,
                        });
                        this.setState({
                            isDeleting: false,
                        });
                        this.props.toggleKeypoolInfo();
                    }
                );
        }
    };

    render() {
        return (
            <>
                {this.state.isDeleting === true ? (
                    <div
                        className="overlay"
                        id="overlay"
                        style={{ display: "block" }}
                    >
                        <div className="overlayMessage">
                            <div
                                className="spinner-border spinner-border"
                                role="status"
                            >
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>
                ) : (
                    ""
                )}
                <MDBModal
                    isOpen={this.state.modalConfirmKeypoolDeletion}
                    toggle={this.toggleConfirmKeypoolDeletion}
                >
                    <MDBModalHeader toggle={this.toggleConfirmKeypoolDeletion}>
                        Confirm Keypool to be Deleted
                    </MDBModalHeader>
                    <MDBModalBody>
                        {this.props.keypoolInfo[this.props.keyPoolID]["ssid"]}
                        <p>
                            WARNING: THIS ACTION CANNOT BE UNDONE. This will
                            delete the selected keypools. Any keypools with an
                            active subscription will not be deleted. To confirm,
                            please type “DELETE” into the text box below
                        </p>
                        <input
                            required
                            name="confirmDelete"
                            id="confirmDelete"
                            className="form-control"
                            onChange={this.changeHandler}
                            placeholder="Type DELETE"
                        />
                    </MDBModalBody>
                    <MDBModalFooter>
                        <div
                            className="text-right submitButton pt-2"
                            key={this.state.theInputKey || ""}
                        >
                            <MDBBtn
                                color="primary"
                                onClick={this.confirmDeleteKeypool}
                                disabled={
                                    this.state.confirmDelete === "DELETE"
                                        ? false
                                        : true
                                }
                            >
                                {this.state.isDeleting === true ? (
                                    <div
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                    >
                                        <span className="sr-only">
                                            Loading...
                                        </span>
                                    </div>
                                ) : (
                                    "Confirm"
                                )}
                            </MDBBtn>
                            <MDBBtn
                                color="primary"
                                onClick={this.toggleConfirmKeypoolDeletion}
                            >
                                Close
                            </MDBBtn>
                        </div>
                    </MDBModalFooter>
                </MDBModal>
                <MDBModalHeader toggle={this.props.toggleKeypoolInfo}>
                    <h4>Keypool Information</h4>
                </MDBModalHeader>
                <MDBModalBody>
                    <form
                        className="needs-validation"
                        onSubmit={this.submitModifyKeypool}
                        noValidate
                    >
                        <MDBRow>
                            <MDBCol col="6">
                                <p>
                                    <strong>Keypool SSID</strong>
                                </p>
                                <p>
                                    {
                                        this.props.keypoolInfo[
                                            this.props.keyPoolID
                                        ]["ssid"]
                                    }
                                </p>
                            </MDBCol>
                            <MDBCol col="6">
                                <label
                                    htmlFor="defaultFormLoginEmailEx"
                                    className="black-text"
                                >
                                    Display Order
                                </label>
                                <input
                                    required
                                    name="displayOrder"
                                    id="displayOrder"
                                    className="form-control emailInput"
                                    onChange={this.changeHandler}
                                    value={this.state.displayOrder}
                                />
                            </MDBCol>
                        </MDBRow>
                        <br />
                        <MDBRow>
                            <MDBCol col="6">
                                <p>
                                    <strong>Scope</strong>
                                </p>
                                <p>
                                    {
                                        this.props.keypoolInfo[
                                            this.props.keyPoolID
                                        ]["Scope"]
                                    }
                                </p>
                            </MDBCol>
                            <MDBCol col="6">
                                <p>
                                    <strong>Authentication Mechanism</strong>
                                </p>
                                <p>
                                    {
                                        this.props.keypoolInfo[
                                            this.props.keyPoolID
                                        ]["AuthenticationMechanism"]
                                    }
                                </p>
                            </MDBCol>
                        </MDBRow>
                        <br />
                        <MDBRow>
                            <MDBCol col="6">
                                <p>
                                    <strong>Subscribers</strong>
                                </p>
                                <div className="keypoolInfoTable">
                                    <OperatorKeypoolsSubscribersTable
                                        subscriberList={
                                            this.props.keypoolInfo[
                                                this.props.keyPoolID
                                            ]["SubscriberList"]
                                        }
                                    />
                                </div>
                            </MDBCol>
                            <MDBCol col="6">
                                <p>
                                    <strong>Inactive Subscribers</strong>
                                </p>
                                <div className="keypoolInfoTable">
                                    <OperatorKeypoolsSubscribersTable
                                        subscriberList={
                                            this.props.keypoolInfo[
                                                this.props.keyPoolID
                                            ]["InactiveSubscriberList"]
                                        }
                                    />
                                </div>
                            </MDBCol>
                        </MDBRow>
                        <br />
                        <MDBRow>
                            <MDBCol col="12">
                                <p>
                                    <strong>Connected Venues</strong>
                                </p>
                                <div className="recentresidents">
                                    <VenuesTable
                                        venueList={
                                            this.props.keypoolInfo[
                                                this.props.keyPoolID
                                            ]["ConnectedVenueList"]
                                        }
                                    />
                                </div>
                            </MDBCol>
                        </MDBRow>
                        <br />
                        <MDBRow>
                            <MDBCol col="12">
                                <p>
                                    <strong>Logs</strong>
                                </p>
                                {this.state.keyPoolLog !== undefined ? (
                                    this.state.keyPoolLog.map(
                                        (value, index) => {
                                            return (
                                                <div key={index}>
                                                    <MDBRow className="mb-2">
                                                        <MDBCol>
                                                            <p>
                                                                <strong>
                                                                    Date Added:
                                                                </strong>
                                                                &nbsp;
                                                                {this.state
                                                                    .keyPoolLog[
                                                                    index
                                                                ][
                                                                    "dateAddedUTC.ISO8601"
                                                                ] !== undefined
                                                                    ? Intl.DateTimeFormat(
                                                                          "en-US",
                                                                          {
                                                                              year: "numeric",
                                                                              month: "numeric",
                                                                              day: "numeric",
                                                                              hour: "numeric",
                                                                              minute: "numeric",
                                                                          }
                                                                      ).format(
                                                                          new Date(
                                                                              this.state.keyPoolLog[
                                                                                  index
                                                                              ][
                                                                                  "dateAddedUTC.ISO8601"
                                                                              ]
                                                                          )
                                                                      )
                                                                    : ""}
                                                            </p>
                                                        </MDBCol>
                                                    </MDBRow>
                                                    <MDBRow>
                                                        <MDBCol>
                                                            <p>
                                                                <strong>
                                                                    Function
                                                                    Name:
                                                                </strong>
                                                                &nbsp;
                                                                {
                                                                    this.state
                                                                        .keyPoolLog[
                                                                        index
                                                                    ]
                                                                        .function_name
                                                                }
                                                            </p>
                                                        </MDBCol>
                                                    </MDBRow>
                                                    <MDBRow>
                                                        <MDBCol>
                                                            <p className="lineBreakAnywhere">
                                                                <strong>
                                                                    Event
                                                                    Message:
                                                                </strong>
                                                                &nbsp;
                                                                {
                                                                    this.state
                                                                        .keyPoolLog[
                                                                        index
                                                                    ].event
                                                                        .message
                                                                }
                                                            </p>
                                                        </MDBCol>
                                                    </MDBRow>
                                                    <MDBRow>
                                                        <MDBCol>
                                                            <p>
                                                                <strong>
                                                                    Administrator:
                                                                </strong>
                                                                &nbsp;
                                                                {
                                                                    this.state
                                                                        .keyPoolLog[
                                                                        index
                                                                    ]
                                                                        .Administrator
                                                                }
                                                            </p>
                                                        </MDBCol>
                                                    </MDBRow>
                                                    <MDBRow>
                                                        <MDBCol>
                                                            <p>
                                                                <strong>
                                                                    Firewall
                                                                    Source IP:
                                                                </strong>
                                                                &nbsp;
                                                                {
                                                                    this.state
                                                                        .keyPoolLog[
                                                                        index
                                                                    ]
                                                                        .FirewallSourceIP
                                                                }
                                                            </p>
                                                        </MDBCol>
                                                    </MDBRow>
                                                    <MDBRow>
                                                        <MDBCol>
                                                            <p>
                                                                <strong>
                                                                    Source IP:
                                                                </strong>
                                                                &nbsp;
                                                                {
                                                                    this.state
                                                                        .keyPoolLog[
                                                                        index
                                                                    ].sourceIP
                                                                }
                                                            </p>
                                                        </MDBCol>
                                                    </MDBRow>
                                                    <MDBRow>
                                                        <MDBCol>
                                                            <p>
                                                                <strong>
                                                                    Source
                                                                    Country:
                                                                </strong>
                                                                &nbsp;
                                                                {
                                                                    this.state
                                                                        .keyPoolLog[
                                                                        index
                                                                    ]
                                                                        .SourceCountry
                                                                }
                                                            </p>
                                                        </MDBCol>
                                                    </MDBRow>
                                                    <MDBRow>
                                                        <MDBCol>
                                                            <p>
                                                                <strong>
                                                                    Source
                                                                    Viewer:
                                                                </strong>
                                                                &nbsp;
                                                                {
                                                                    this.state
                                                                        .keyPoolLog[
                                                                        index
                                                                    ]
                                                                        .SourceViewer
                                                                }
                                                            </p>
                                                        </MDBCol>
                                                    </MDBRow>
                                                    <MDBRow>
                                                        <MDBCol>
                                                            <p>
                                                                <strong>
                                                                    Source
                                                                    Locale:
                                                                </strong>
                                                                &nbsp;
                                                                {
                                                                    this.state
                                                                        .keyPoolLog[
                                                                        index
                                                                    ]
                                                                        .SourceLocale
                                                                }
                                                            </p>
                                                        </MDBCol>
                                                    </MDBRow>
                                                    <MDBRow>
                                                        <MDBCol>
                                                            <p className="lineBreakAnywhere">
                                                                <strong>
                                                                    Source UA:
                                                                </strong>
                                                                &nbsp;
                                                                {
                                                                    this.state
                                                                        .keyPoolLog[
                                                                        index
                                                                    ].SourceUA
                                                                }
                                                            </p>
                                                        </MDBCol>
                                                    </MDBRow>
                                                    {index + 1 !==
                                                    this.state.keyPoolLog
                                                        .length ? (
                                                        <hr />
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>
                                            );
                                        }
                                    )
                                ) : (
                                    <div
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                    >
                                        <span className="sr-only">
                                            Loading...
                                        </span>
                                    </div>
                                )}
                            </MDBCol>
                        </MDBRow>
                        <div className="text-right submitButton">
                            <MDBBtn color="danger" type="submit">
                                {this.state.update_keypool === true ? (
                                    <div
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                    >
                                        <span className="sr-only">
                                            Loading...
                                        </span>
                                    </div>
                                ) : (
                                    "Save Changes"
                                )}
                            </MDBBtn>
                            <MDBBtn
                                color="danger"
                                onClick={this.toggleConfirmKeypoolDeletion}
                                disabled={
                                    this.props.keypoolInfo[
                                        this.props.keyPoolID
                                    ]["SubscriberCount"] > 0
                                        ? true
                                        : false
                                }
                            >
                                Delete Keypool
                            </MDBBtn>
                            <MDBBtn
                                color="primary"
                                onClick={this.props.toggleKeypoolInfo}
                            >
                                Close
                            </MDBBtn>
                        </div>
                    </form>
                </MDBModalBody>
            </>
        );
    }
}

export default withTranslation()(OperatorInfo);
