import React, { Component } from "react";

import {
    MDBBtn,
    MDBModal,
    MDBModalBody,
    MDBModalHeader,
    MDBModalFooter,
    MDBRow,
    MDBCol,
    toast,
} from "mdbreact";

import axios from "../../../axios";

import "react-phone-number-input/style.css";

import PhoneInput, {
    isPossiblePhoneNumber,
    isValidPhoneNumber,
} from "react-phone-number-input";

import { withTranslation } from "react-i18next";

import SelectSearch, { fuzzySearch } from "react-select-search";

class MyAccount extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            email: sessionStorage.getItem("email"),
            firstName: sessionStorage.getItem("firstName"),
            lastName: sessionStorage.getItem("lastName"),
            phoneNumber: sessionStorage.getItem("phoneNumber"),
            selectedLanguage: sessionStorage.getItem("i18next").toLowerCase(),
            loadingReset: false,
            languageOptions: [
                { value: "en-us", name: "English" },
                { value: "es-xl", name: "Spanish" },
            ],
        };
    }

    handleSelectChangeLanguage = (value) => {
        if (value !== null) {
            this.setState({ selectedLanguage: value });
        }
    };

    submitHandler = () => {
        this.setState({ loading: true });
        let body = {
            AccessToken: sessionStorage.getItem("AccessToken"),
            GivenName: this.state.firstName,
            FamilyName: this.state.lastName,
            "PhoneNumber.E164": this.state.phoneNumber,
            locale: this.state.selectedLanguage,
        };
        sessionStorage.setItem("email", this.state.email);
        sessionStorage.setItem("firstName", this.state.firstName);
        sessionStorage.setItem("lastName", this.state.lastName);
        sessionStorage.setItem("phoneNumber", this.state.phoneNumber);
        sessionStorage.setItem("i18next", this.state.selectedLanguage);
        axios
            .post("/subscriberconsole/updatePII", body, {
                headers: {},
            })
            .then((response) => {
                toast.success("Account Updated!", {
                    closeButton: false,
                });
                this.setState({ loading: false });
                this.props.toggleMyAccount();
            })
            .catch((error) => {
                toast.error(error.response.data.message, {
                    closeButton: false,
                });
                this.setState({ loading: false });
                this.props.toggleMyAccount();
            });
    };

    changeHandler = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };

    changeHandlerPhone = (event) => {
        this.setState({ phoneNumber: event });
        var value = event
            ? isPossiblePhoneNumber(event)
                ? undefined
                : "Please provide a valid phone number"
            : "Phone number required";
        this.setState({ invalidPhone: value });
    };

    toggleCheckboxValueConfirmations = () => {
        this.setState({ confirmations: !this.state.confirmations });
    };

    toggleCheckboxValueUpdates = () => {
        this.setState({ updates: !this.state.updates });
    };

    toggleCheckboxValueAgree = () => {
        this.setState({ agree: !this.state.agree });
    };

    toggleResetPassword = () => {
        this.setState({
            modalResetPassword: !this.state.modalResetPassword,
        });
    };

    resetPassword = () => {
        let data;
        this.setState({ loadingReset: true });
        data = {
            EmailAddress: this.state.email,
            UserTimeZone: new Date().getTimezoneOffset(),
        };
        axios
            .post("/subscriberconsole/initiateResetConsolePassword", data, {
                headers: {},
            })
            .then((response) => {
                toast.success(
                    "Password reset initiated, please check your email for instructions!",
                    {
                        closeButton: false,
                    }
                );
                this.setState({ loadingReset: false });
                this.props.toggleMyAccount();
            })
            .catch((error) => {
                toast.error("There was an error!", {
                    closeButton: false,
                });
                this.setState({ loadingReset: true });
            });
    };

    render() {
        const FORMAT = "MM/dd/yyyy";
        const { t } = this.props;

        return (
            <>
                <MDBModal
                    isOpen={this.state.modalResetPassword}
                    toggle={this.toggleResetPassword}
                    size="sm"
                    className="smallModal"
                    centered
                >
                    <MDBModalHeader toggle={this.toggleResetPassword}>
                        <h4>{t("myaccount.resetpassword")}</h4>
                    </MDBModalHeader>
                    <MDBModalBody>
                        <div>
                            <MDBBtn
                                onClick={this.resetPassword}
                                color="danger"
                                block
                                bsSize="large"
                                type="submit"
                            >
                                {t("myaccount.sendotp")}
                            </MDBBtn>
                        </div>
                    </MDBModalBody>
                </MDBModal>
                <MDBModalHeader toggle={this.props.toggleMyAccount}>
                    <h4>{t("myaccount.title")}</h4>
                </MDBModalHeader>
                <MDBModalBody>
                    <form className="needs-validation" noValidate>
                        <MDBRow className="mb-2">
                            <MDBCol col="6">
                                <MDBRow>
                                    <MDBCol sm="6" lg="4">
                                        <p>{t("myaccount.email")}</p>
                                    </MDBCol>
                                    <MDBCol sm="6" lg="8">
                                        {this.state.email}
                                    </MDBCol>
                                </MDBRow>
                            </MDBCol>
                            <MDBCol col="6"></MDBCol>
                        </MDBRow>
                        <MDBRow className="mb-2">
                            <MDBCol sm="12" lg="6">
                                <MDBRow className="mb-2">
                                    <MDBCol sm="3" lg="4" middle>
                                        <p>{t("myaccount.firstname")}</p>
                                    </MDBCol>
                                    <MDBCol sm="9" lg="8">
                                        <input
                                            required
                                            id="profile"
                                            className="form-control"
                                            onChange={this.changeHandler}
                                            name="firstName"
                                            value={this.state.firstName}
                                        />
                                        <div className="invalid-feedback">
                                            Please provide a valid profile name.
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                            </MDBCol>
                            <MDBCol sm="12" lg="6">
                                <MDBRow>
                                    <MDBCol sm="3" lg="4" middle>
                                        <p>{t("myaccount.lastname")}</p>
                                    </MDBCol>
                                    <MDBCol sm="9" lg="8">
                                        <input
                                            required
                                            id="profile"
                                            className="form-control"
                                            onChange={this.changeHandler}
                                            name="lastName"
                                            value={this.state.lastName}
                                        />
                                        <div className="invalid-feedback">
                                            Please provide a valid profile name.
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className="mb-2">
                            <MDBCol sm="12" lg="6">
                                <MDBRow>
                                    <MDBCol sm="3" lg="4" middle>
                                        <p>{t("myaccount.phone")}</p>
                                    </MDBCol>
                                    <MDBCol sm="9" lg="8">
                                        <PhoneInput
                                            placeholder="Enter Phone Number"
                                            value={this.state.phoneNumber}
                                            format={FORMAT}
                                            onChange={this.changeHandlerPhone}
                                            name="phoneNumber"
                                            id="phoneNumber"
                                            className="form-control"
                                            required
                                            error={
                                                this.state.phoneNumber
                                                    ? isValidPhoneNumber(
                                                          this.state.phoneNumber
                                                      )
                                                        ? undefined
                                                        : "Invalid phone number"
                                                    : "Phone number required"
                                            }
                                            disabled={this.state.userFound}
                                        />
                                        {this.state.invalidPhone !== null ? (
                                            <div className="invalid">
                                                {this.state.invalidPhone}
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </MDBCol>
                                </MDBRow>
                            </MDBCol>
                            <MDBCol sm="12" lg="6">
                                <MDBRow>
                                    <MDBCol sm="3" lg="4" middle>
                                        <p>{t("myaccount.language")}</p>
                                    </MDBCol>
                                    <MDBCol sm="9" lg="8">
                                        <SelectSearch
                                            id="selectLanguage"
                                            search={true}
                                            filterOptions={fuzzySearch}
                                            options={this.state.languageOptions}
                                            isOptionSelected={true}
                                            classNamePrefix="language-select"
                                            onChange={
                                                this.handleSelectChangeLanguage
                                            }
                                            value={this.state.selectedLanguage}
                                            placeholder={
                                                this.state.selectedLanguage !==
                                                undefined
                                                    ? this.state
                                                          .selectedLanguage ===
                                                      "en-us"
                                                        ? "English"
                                                        : "Spanish"
                                                    : "Select..."
                                            }
                                        />
                                        <div className="invalid-feedback">
                                            Please provide a valid language.
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                            </MDBCol>
                        </MDBRow>
                    </form>
                </MDBModalBody>
                <MDBModalFooter>
                    <MDBBtn color="black" onClick={this.resetPassword}>
                        {this.state.loadingReset === true ? (
                            <div
                                className="spinner-border spinner-border-sm"
                                role="status"
                            >
                                <span className="sr-only">Loading...</span>
                            </div>
                        ) : (
                            t("myaccount.resetpassword")
                        )}
                    </MDBBtn>
                    <MDBBtn
                        color="danger"
                        onClick={this.submitHandler}
                        type="submit"
                    >
                        {this.state.loading === true ? (
                            <div
                                className="spinner-border spinner-border-sm"
                                role="status"
                            >
                                <span className="sr-only">Loading...</span>
                            </div>
                        ) : (
                            "OK"
                        )}
                    </MDBBtn>
                </MDBModalFooter>
            </>
        );
    }
}

export default withTranslation()(MyAccount);
