import React from "react";

import { MDBDataTableV5 } from "mdbreact";

import { useTranslation } from "react-i18next";

export default function Basic(props) {
    const { t } = useTranslation();
    let userAttributes;
    let i = 0;

    let datatable;
    [datatable] = React.useState("");
    if (props.transactions !== undefined) {
        userAttributes = [];
        for (const key of Object.keys(props.transactions)) {
            userAttributes.push({
                servicePlan: (
                    <>
                        {props.transactions[key]["ServicePlanName"]}
                        <br />
                        {props.transactions[key]["ServicePlanDescription"]}
                    </>
                ),
                price:
                    props.transactions[key]["CurrencySymbol"] +
                    props.transactions[key]["BillingCyclePrice.USD"] / 100,
                qty: props.transactions[key]["BillingCycleRecurrence"],
                amountPaid:
                    props.transactions[key]["AmountReceived.USD"] ===
                    "undefined"
                        ? props.transactions[key]["CurrencySymbol"] +
                          props.transactions[key]["AmountReceived.USD"] / 100
                        : "",
                subStartDate:
                    props.transactions[key]["dateAddedUTC.ISO8601"] !== ""
                        ? Intl.DateTimeFormat("en-CA", {
                              year: "numeric",
                              month: "2-digit",
                              day: "2-digit",
                              hour: "numeric",
                              minute: "numeric",
                          }).format(
                              new Date(
                                  props.transactions[key][
                                      "dateAddedUTC.ISO8601"
                                  ]
                              )
                          )
                        : "",
                subEndDate:
                    props.transactions[key]["InvoiceDateTimeUTC.ISO8601"] !== ""
                        ? Intl.DateTimeFormat("en-CA", {
                              year: "numeric",
                              month: "2-digit",
                              day: "2-digit",
                              hour: "numeric",
                              minute: "numeric",
                          }).format(
                              new Date(
                                  props.transactions[key][
                                      "InvoiceDateTimeUTC.ISO8601"
                                  ]
                              )
                          )
                        : "",
                venueName: props.transactions[key]["VenueName"],
                venueAddress: (
                    <>
                        {props.transactions[key]["AddressLine1"]}
                        {props.transactions[key]["AddressLine2"] !== ""
                            ? "\n" + props.transactions[key]["AddressLine2"]
                            : ""}
                        <br />
                        {props.transactions[key]["City"]},{" "}
                        {props.transactions[key]["State"]}{" "}
                        {props.transactions[key]["PostalCode"]}
                    </>
                ),
            });
            i = i + 1;
        }
    }

    datatable = {
        columns: [
            {
                label: "Service Plan",
                field: "servicePlan",
            },
            {
                label: "Item Price",
                field: "price",
            },
            {
                label: "Quantity",
                field: "qty",
            },
            {
                label: "Amount Paid",
                field: "amountPaid",
            },
            {
                label: "Date Added",
                field: "subStartDate",
            },
            {
                label: "Invoice Date",
                field: "subEndDate",
            },
            {
                label: "Venue Name",
                field: "venueName",
            },
            {
                label: "Address",
                field: "venueAddress",
            },
        ],
        rows: userAttributes,
    };
    return (
        <MDBDataTableV5
            responsive
            hover
            data={datatable}
            searchBottom={false}
            paging={false}
            className="modifyStaff"
            info={false}
            noRecordsFoundLabel={t("recentservice.nomatches")}
        />
    );
}
