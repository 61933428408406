import React, { Component } from "react";

import { MDBBtn, MDBModalBody, MDBModalHeader, MDBRow, MDBCol } from "mdbreact";

import axios from "../../../../axios";

import { DateInput } from "semantic-ui-calendar-react";

import "react-phone-number-input/style.css";

import PhoneInput from "react-phone-number-input";

import { withTranslation } from "react-i18next";

import $ from "jquery";

import SelectSearch, { fuzzySearch } from "react-select-search";

class AddNewResident extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Email: "",
            sms: false,
            voice: false,
            dateStart: null,
            dateEnd: null,
            invalidPhone: null,
            loading: false,
            userFound: false,
            userFoundWithPhone: false,
            invalidEmail: null,
            email: "",

            optionTemplateUnits: [],
            optionTemplateBuilding: [],
            optionTemplateFloor: [],
            languageOptions: [
                { value: "en-us", name: "English" },
                { value: "es-xl", name: "Spanish" },
            ],
            loading_units: false,
        };
        this.buildingSelect = React.createRef();
        this.floorSelect = React.createRef();
        this.servicePlanSelect = React.createRef();
    }

    componentDidMount() {
        this.getVenueDetails(this.props.venueID);
    }

    addDefaultDurationResidents = (start) => {
        let retVal = new Date(start);
        retVal = new Date(
            retVal.setHours(
                retVal.getHours() + this.state.defaultSubscriptionDurationRes
            )
        );
        return retVal;
    };

    validateEmail = (email) => {
        var mailformat = /^[\w._-]+[+]?[\w._-]+@[\w.-]+\.[a-zA-Z]{2,6}$/;
        this.setState({
            invalidPhone: undefined,
            userFound: false,
            userFoundWithPhone: false,
        });

        if (!this.state.email.match(mailformat)) {
            this.setState({
                invalidEmail: "Please provide a valid email address",
            });
            $(".emailInput").addClass("is-invalid");
        } else {
            this.setState({ invalidEmail: undefined });
            $(".emailInput").removeClass("is-invalid");
        }
        this.setState({ loading: true });
        let body = {
            AccessToken: sessionStorage.getItem("AccessToken"),
            EmailAddress: email,
            VenueID: this.props.venueID,
        };
        axios
            .post("/venuemgmtconsole/subscribers/validateEmailAddress", body, {
                headers: {},
            })
            .then((response) => {
                this.setState({
                    firstName: response.data.GivenName,
                    lastName: response.data.FamilyName,
                    selectedLanguage: response.data.locale,
                    invalidPhone: undefined,
                    userFound: true,
                });
                if (response.data["PhoneNumber.E164"] === "") {
                    this.setState({ userFoundWithPhone: false });
                } else {
                    this.setState({
                        phoneNumber: response.data["PhoneNumber.E164"],
                        userFoundWithPhone: true,
                    });
                }
                this.setState({ loading: false });
            })
            .catch((error) => {
                this.setState({ error: true });
                this.setState({ loading: false });
                this.setState({ userFound: false });
            });
    };

    getVenueDetails = (index) => {
        let body = {
            AccessToken: sessionStorage.getItem("AccessToken"),
            VenueID: index,
            UserTimeZone: new Date().getTimezoneOffset(),
        };

        this.setState({
            unitData: undefined,
            expiringData: undefined,
            searchData: undefined,
            value: "",
            defaultSubscriptionDurationRes: 0,
            loading_units: true,
        });
        axios
            .post("/venuemgmtconsole/venues/listVenueDetails", body, {
                headers: {},
            })
            .then((response) => {
                this.setState({
                    unitData:
                        response.data[index][
                            "Units-Building-Floor-List-Subscribers"
                        ],
                    defaultSubscriptionDurationRes:
                        response.data[index][
                            "DefaultSubscriptionDurationHours-Residents"
                        ],
                    venueServicePlans:
                        response.data[index]["AvailableServicePlans"],
                });
                let units = Object.keys(
                    response.data[index][
                        "Units-Building-Floor-List-Subscribers"
                    ]
                );
                let buildings = [];
                let objUnits = {};

                for (let i = 0; i < units.length; i++) {
                    buildings.push(
                        Object.keys(
                            response.data[index][
                                "Units-Building-Floor-List-Subscribers"
                            ][units[i]]
                        )
                    );
                    for (let j = 0; j < buildings[i].length; j++) {
                        let floor = Object.keys(
                            response.data[index][
                                "Units-Building-Floor-List-Subscribers"
                            ][units[i]][buildings[i][j]]
                        );
                        for (let k = 0; k < floor.length; k++) {
                            if (
                                response.data[index][
                                    "Units-Building-Floor-List-Subscribers"
                                ][units[i]][buildings[i][j]][floor[k]][
                                    "PrimaryUnitID_internal"
                                ] === "" &&
                                ((response.data[index][
                                    "Units-Building-Floor-List-Subscribers"
                                ][units[i]][buildings[i][j]][floor[k]][
                                    "allowOverlappingSubscriptionWiredUnit"
                                ] === false &&
                                    Object.keys(
                                        response.data[index][
                                            "Units-Building-Floor-List-Subscribers"
                                        ][units[i]][buildings[i][j]][floor[k]][
                                            "Subscriptions"
                                        ]
                                    ).length === 0) ||
                                    response.data[index][
                                        "Units-Building-Floor-List-Subscribers"
                                    ][units[i]][buildings[i][j]][floor[k]][
                                        "allowOverlappingSubscriptionWiredUnit"
                                    ] === true)
                            ) {
                                objUnits[i] =
                                    response.data[index][
                                        "Units-Building-Floor-List-Subscribers"
                                    ][units[i]][buildings[i]];
                            }
                        }
                    }
                }

                let optionTemplateUnits = [];
                Object.keys(objUnits).map((oneKey, i) => {
                    optionTemplateUnits.push({
                        value: units[oneKey],
                        name: units[oneKey],
                    });
                    return true;
                });
                let optionTemplateServicePlans = [];
                Object.keys(this.props.availablePlans).map((value, i) => {
                    optionTemplateServicePlans.push({
                        value: this.props.availablePlans[value][
                            "ServicePlanID"
                        ],
                        name: this.props.availablePlans[value][
                            "ServicePlanName"
                        ],
                    });
                    return true;
                });
                this.setState({
                    optionTemplateUnits: optionTemplateUnits,
                    optionTemplateServicePlans: optionTemplateServicePlans,
                    loading_units: false,
                });
            })

            .catch((error) => {
                this.setState({
                    error: true,
                    loading_units: false,
                });
            });
    };

    handleSelectChangeUnit = (value) => {
        this.setState({
            selectedUnit: value,
            optionTemplateBuilding: [],
            selectedBuilding: undefined,
            selectedFloor: undefined,
        });
        let optionTemplateBuilding = [];
        this.setState({
            optionTemplateBuilding: [],
        });
        Object.keys(this.state.unitData[value]).map((oneKey, i) => {
            if (oneKey === "DEFAULT") {
                optionTemplateBuilding.push({ value: oneKey, name: oneKey });
            } else {
                optionTemplateBuilding.push({ value: oneKey, name: oneKey });
            }
            return true;
        });
        this.setState({
            optionTemplateBuilding: optionTemplateBuilding,
        });
    };

    handleSelectChangeBuilding = (value) => {
        if (value !== null) {
            this.setState({ selectedBuilding: value });
            let optionTemplateFloor = [];
            Object.keys(
                this.state.unitData[this.state.selectedUnit][value]
            ).map((oneKey, i) => {
                if (oneKey === "DEFAULT") {
                    optionTemplateFloor.push({ value: oneKey, name: oneKey });
                } else {
                    optionTemplateFloor.push({ value: oneKey, name: oneKey });
                }
                return true;
            });

            this.setState({
                optionTemplateFloor: optionTemplateFloor,
            });
        }
    };

    handleSelectChangeFloor = (value) => {
        if (value !== null) {
            this.setState({ selectedFloor: value });
            this.setState({
                unitID_internal:
                    this.state.unitData[this.state.selectedUnit][
                        this.state.selectedBuilding
                    ][value]["UnitID_internal"],
            });
        }
    };

    handleSelectChangeLanguage = (value) => {
        if (value !== null) {
            this.setState({ selectedLanguage: value });
        }
    };

    handleSelectChangeServicePlan = (value) => {
        if (value !== null) {
            this.setState({ selectedServicePlan: value });
        }
    };

    submitHandler = (event) => {
        event.preventDefault();
        $("#selectUnit").removeClass("is-invalid");
        $("#selectBuilding").removeClass("is-invalid");
        $("#selectFloor").removeClass("is-invalid");
        $("#selectLanguage").removeClass("is-invalid");
        event.target.className += " was-validated";
        if (this.state.selectedUnit === undefined) {
            this.setState({ invalidUnit: "Please provide a valid unit" });
            $("#selectUnit").addClass("is-invalid");
        } else {
            $("#selectUnit").addClass("is-valid");
        }
        if (this.state.selectedBuilding === undefined) {
            this.setState({ invalidBuilding: "Please provide a valid unit" });
            $("#selectBuilding").addClass("is-invalid");
        } else {
            $("#selectBuilding").addClass("is-valid");
        }
        if (this.state.selectedFloor === undefined) {
            this.setState({ invalidFloor: "Please provide a valid floor" });
            $("#selectFloor").addClass("is-invalid");
        } else {
            $("#selectFloor").addClass("is-valid");
        }
        if (this.state.selectedLanguage === undefined) {
            this.setState({
                invalidLanguage: "Please provide a valid language",
            });
            $("#selectLanguage").addClass("is-invalid");
        } else {
            $("#selectLanguage").addClass("is-valid");
        }
        if (this.state.email === undefined) {
            this.setState({
                invalidEmail: "Please provide a valid email address",
            });
        }
        if (isNaN(new Date(this.state.dateEnd))) {
            this.setState({ dateEnd: undefined });
        }
        if (
            this.state.invalidEmail === undefined &&
            this.state.firstName !== undefined &&
            this.state.lastName !== undefined &&
            this.state.selectedLanguage !== undefined &&
            this.state.selectedUnit !== undefined &&
            this.state.selectedBuilding !== undefined &&
            this.state.selectedFloor !== undefined
        ) {
            this.props.submitHandler(
                this.state.dateEnd,
                this.state.email,
                this.state.firstName,
                this.state.lastName,
                this.state.phoneNumber,
                this.state.selectedLanguage,
                this.state.selectedUnit,
                this.state.selectedBuilding,
                this.state.selectedFloor,
                this.state.sms,
                this.state.voice,
                this.state.dateStart,
                this.state.unitID_internal,
                this.state.selectedServicePlan
            );
        }
    };

    toggleCheckboxValueSMS = () => {
        this.setState({ sms: !this.state.sms });
    };

    toggleCheckboxValueVoice = () => {
        this.setState({ voice: !this.state.voice });
    };

    changeHandler = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };

    changeHandlerPhone = (event) => {
        this.setState({ phoneNumber: event });
    };
    createMask = () => {
        this.setState({
            phoneNumber: this.state.phoneNumber.replace(/\D/g, ""),
        });
    };

    setEndDate = (dateStart) => {
        var endDateYear = new Date(dateStart).getFullYear() + 1;
        var endDate = new Date(dateStart).setFullYear(endDateYear);
        if (dateStart !== null && this.state.dateEnd === null) {
            this.setState({
                dateEnd: new Intl.DateTimeFormat("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                }).format(new Date(endDate)),
            });
        }
    };

    handleChange = (event, { name, value }) => {
        if (this.state.hasOwnProperty(name)) {
            this.setState({ [name]: value });
        }
    };

    render() {
        const { t } = this.props;
        const FORMAT = "MM/dd/yyyy";
        return (
            <>
                {this.state.loading_units === true ? (
                    <div id="overlay" style={{ display: "block" }}>
                        <div className="overlayMessage">
                            <div
                                className="spinner-border spinner-border"
                                role="status"
                            >
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>
                ) : (
                    ""
                )}
                <MDBModalHeader toggle={this.props.toggleAddNewResident}>
                    <h4>
                        {this.props.venueType === "HOTSPOT"
                            ? "Add New Local Policy"
                            : t("addnewresident.title")}
                    </h4>
                    <p>{t("addnewresident.required")}</p>
                </MDBModalHeader>
                <MDBModalBody>
                    <form
                        className="needs-validation"
                        onSubmit={this.submitHandler}
                        noValidate
                    >
                        <MDBRow>
                            <MDBCol col="6" sm="12" lg="6">
                                <label
                                    htmlFor="defaultFormLoginEmailEx"
                                    className="black-text"
                                >
                                    {t("addnewresident.email")}
                                </label>
                                <input
                                    required
                                    id="email"
                                    className="form-control emailInput"
                                    onBlur={(event) =>
                                        this.validateEmail(this.state.email)
                                    }
                                    onChange={(event) =>
                                        this.setState({
                                            email: event.target.value,
                                        })
                                    }
                                    value={this.state.email}
                                    error={
                                        this.state.invalidEmail !== undefined
                                            ? "Invalid email address"
                                            : "Email address required"
                                    }
                                />
                                {this.state.invalidEmail !== undefined ? (
                                    <div className="invalid">
                                        {this.state.invalidEmail}
                                    </div>
                                ) : (
                                    ""
                                )}
                            </MDBCol>
                            <MDBCol col="6"></MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol sm="12" lg="6">
                                <label
                                    htmlFor="defaultFormLoginEmailEx"
                                    className="black-text"
                                >
                                    {t("addnewresident.firstname")}
                                </label>
                                <input
                                    required
                                    name="firstName"
                                    id="firstName"
                                    className={
                                        this.state.loading === true
                                            ? "form-control loading"
                                            : "form-control"
                                    }
                                    onChange={this.changeHandler}
                                    value={this.state.firstName}
                                    disabled={this.state.userFound}
                                />
                                <div className="invalid-feedback">
                                    Please provide a valid first name.
                                </div>
                            </MDBCol>
                            <MDBCol sm="12" lg="6">
                                <label
                                    htmlFor="defaultFormLoginEmailEx"
                                    className="black-text"
                                >
                                    {t("addnewresident.lastname")}
                                </label>
                                <input
                                    required
                                    className={
                                        this.state.loading === true
                                            ? "form-control loading"
                                            : "form-control"
                                    }
                                    name="lastName"
                                    id="lastName"
                                    onChange={this.changeHandler}
                                    value={this.state.lastName}
                                    disabled={this.state.userFound}
                                />
                                <div className="invalid-feedback">
                                    Please provide a valid last name.
                                </div>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol col="6" sm="12" lg="6">
                                <label
                                    htmlFor="defaultFormLoginEmailEx"
                                    className="black-text"
                                >
                                    {t("addnewresident.phone")}
                                </label>
                                <PhoneInput
                                    placeholder={t("addnewresident.enterphone")}
                                    value={this.state.phoneNumber}
                                    format={FORMAT}
                                    onChange={this.changeHandlerPhone}
                                    name="phoneNumber"
                                    id="phoneNumber"
                                    className={
                                        this.state.loading === true
                                            ? "form-control loading"
                                            : "form-control"
                                    }
                                    defaultCountry="US"
                                    disabled={this.state.userFoundWithPhone}
                                />
                                {this.state.invalidPhone !== null ? (
                                    <div className="invalid">
                                        {this.state.invalidPhone}
                                    </div>
                                ) : (
                                    ""
                                )}
                            </MDBCol>
                            <MDBCol col="6">
                                <div>
                                    <label
                                        htmlFor="defaultFormLoginEmailEx"
                                        className="black-text"
                                    >
                                        {t("addnewresident.language")}
                                    </label>
                                    <SelectSearch
                                        id="selectLanguage"
                                        search={true}
                                        filterOptions={fuzzySearch}
                                        options={this.state.languageOptions}
                                        isOptionSelected={true}
                                        classNamePrefix="language-select"
                                        onChange={
                                            this.handleSelectChangeLanguage
                                        }
                                        value={this.state.selectedLanguage}
                                        placeholder={
                                            this.state.selectedLanguage !==
                                            undefined
                                                ? this.state
                                                      .selectedLanguage ===
                                                  "en-us"
                                                    ? "English"
                                                    : "Spanish"
                                                : "Select..."
                                        }
                                    />
                                    <div className="invalid-feedback">
                                        Please provide a valid language.
                                    </div>
                                </div>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol col="6" sm="12" lg="6">
                                <label
                                    htmlFor="defaultFormLoginEmailEx"
                                    className="black-text"
                                >
                                    {t("addnewresident.unit")}
                                </label>
                                <SelectSearch
                                    id="selectUnit"
                                    search={true}
                                    filterOptions={fuzzySearch}
                                    options={this.state.optionTemplateUnits}
                                    onChange={this.handleSelectChangeUnit}
                                    isOptionSelected={true}
                                    inputId="unitArray"
                                    classNamePrefix="unit-select form-control"
                                    placeholder="Select..."
                                    value={this.state.selectedUnit}
                                />
                                <div className="invalid-feedback">
                                    Please provide a unit number.
                                </div>
                            </MDBCol>
                            <MDBCol col="6"></MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol col="6">
                                <label
                                    htmlFor="defaultFormLoginEmailEx"
                                    className="black-text"
                                >
                                    {t("addnewresident.building")}
                                </label>
                                <SelectSearch
                                    id="selectBuilding"
                                    options={this.state.optionTemplateBuilding}
                                    search={true}
                                    filterOptions={fuzzySearch}
                                    onChange={this.handleSelectChangeBuilding}
                                    isOptionSelected={true}
                                    inputId="buildingArray"
                                    classNamePrefix="building-select"
                                    placeholder="Select..."
                                    value={this.state.selectedBuilding}
                                />
                                <div className="invalid-feedback">
                                    Please provide a building.
                                </div>
                            </MDBCol>
                            <MDBCol col="6">
                                <label
                                    htmlFor="defaultFormLoginEmailEx"
                                    className="black-text"
                                >
                                    {t("addnewresident.floor")}
                                </label>
                                <SelectSearch
                                    ref={this.floorSelect}
                                    id="selectFloor"
                                    search={true}
                                    filterOptions={fuzzySearch}
                                    options={this.state.optionTemplateFloor}
                                    onChange={this.handleSelectChangeFloor}
                                    isOptionSelected={true}
                                    inputId="floorArray"
                                    classNamePrefix="floo-select"
                                    placeholder="Select..."
                                    emptyMessage="Select..."
                                    value={this.state.selectedFloor}
                                />
                                <div className="invalid-feedback">
                                    Please provide a floor.
                                </div>
                            </MDBCol>
                        </MDBRow>

                        <MDBRow>
                            <MDBCol col="6">
                                <label
                                    htmlFor="defaultFormLoginEmailEx"
                                    className="black-text"
                                >
                                    Service Plan
                                </label>
                                <SelectSearch
                                    ref={this.servicePlanSelect}
                                    id="selectServicePlan"
                                    search={true}
                                    filterOptions={fuzzySearch}
                                    options={
                                        this.state.optionTemplateServicePlans
                                    }
                                    onChange={
                                        this.handleSelectChangeServicePlan
                                    }
                                    isOptionSelected={true}
                                    inputId="floorArray"
                                    classNamePrefix="floo-select"
                                    placeholder="Select..."
                                    emptyMessage="Select..."
                                    value={this.state.selectedServicePlan}
                                />
                                <div className="invalid-feedback">
                                    Please provide a service plan.
                                </div>
                            </MDBCol>
                            <MDBCol col="6"></MDBCol>
                        </MDBRow>

                        <MDBRow>
                            <MDBCol sm="12" lg="6">
                                <label
                                    htmlFor="defaultFormLoginEmailEx"
                                    className="black-text"
                                >
                                    {t("addnewresident.servicestart")}
                                </label>
                                <DateInput
                                    dateFormat="MM/DD/YYYY"
                                    name="dateStart"
                                    placeholder="MM/DD/YYYY"
                                    value={this.state.dateStart}
                                    iconPosition="left"
                                    onChange={this.handleChange}
                                    onBlur={() =>
                                        this.setEndDate(this.state.dateStart)
                                    }
                                    className="dateTimePicker"
                                    minDate={new Date()}
                                />

                                <label
                                    for="serviceStart"
                                    className="black-text"
                                    style={{
                                        fontSize: "11px",
                                        position: "absolute",
                                        top: "65px",
                                        left: "15px",
                                    }}
                                >
                                    {this.props.venueType === "HOTSPOT"
                                        ? "Local Policy can use their Wi-Fi password as of this date"
                                        : t("addnewresident.servicestartlabel")}
                                </label>
                            </MDBCol>
                            <MDBCol sm="12" lg="6">
                                <label
                                    htmlFor="defaultFormLoginEmailEx"
                                    className="black-text"
                                >
                                    {t("addnewresident.serviceend")}
                                </label>

                                <DateInput
                                    dateFormat="MM/DD/YYYY"
                                    name="dateEnd"
                                    placeholder="MM/DD/YYYY"
                                    value={
                                        isNaN(new Date(this.state.dateEnd)) ===
                                            true &&
                                        this.state.invalidDateEnd === null
                                            ? Intl.DateTimeFormat("en-US", {
                                                  month: "2-digit",
                                                  day: "2-digit",
                                                  year: "numeric",
                                              }).format(
                                                  new Date(this.state.dateEnd)
                                              )
                                            : this.state.dateEnd
                                    }
                                    iconPosition="left"
                                    onChange={this.handleChange}
                                    className="dateTimePicker"
                                    minDate={new Date(this.state.dateStart)}
                                />
                                <label
                                    className="black-text"
                                    style={{
                                        fontSize: "11px",
                                        position: "absolute",
                                        top: "65px",
                                        left: "15px",
                                    }}
                                >
                                    {t("addnewresident.serviceendlabel")}
                                </label>
                            </MDBCol>
                        </MDBRow>
                        <div className="text-right submitButton">
                            <MDBBtn color="danger" type="submit">
                                {this.props.loading === true ? (
                                    <div
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                    >
                                        <span className="sr-only">
                                            Loading...
                                        </span>
                                    </div>
                                ) : this.props.venueType === "HOTSPOT" ? (
                                    "Add Local Policy"
                                ) : (
                                    t("addnewresident.addresident")
                                )}
                            </MDBBtn>
                        </div>
                    </form>
                </MDBModalBody>
            </>
        );
    }
}

export default withTranslation()(AddNewResident);
