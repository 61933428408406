import React from "react";

import { MDBDataTableV5 } from "mdbreact";

export default function Basic(props) {
    let userAttributes;
    let i = 0;

    let datatable;
    [datatable] = React.useState("");
    if (props.keyPoolList !== undefined) {
        userAttributes = [];
        for (const key of Object.keys(props.keyPoolList)) {
            userAttributes.push({
                ssid: props.keyPoolList[key]["ssid"],
                scope: props.keyPoolList[key]["Scope"],
                // dateAdded: Intl.DateTimeFormat("en-CA", {
                //   year: "numeric",
                //   month: "2-digit",
                //   day: "2-digit",
                //   hour: "numeric",
                //   minute: "numeric",
                // }).format(new Date(props.keyPoolList[key]["dateAdded_by_KeyPoolOwnerUTC.ISO8601"])),
                status:
                    props.keyPoolList[key]["currentKeypoolStatus"] ===
                    "Operational" ? (
                        <div className="active-circle"></div>
                    ) : (
                        <div className="inactive-circle"></div>
                    ),
            });
            i = i + 1;
        }
    }

    datatable = {
        columns: [
            {
                label: "SSID",
                field: "ssid",
            },
            {
                label: "Scope",
                field: "scope",
            },
            // {
            //   label: "Date Added",
            //   field: "dateAdded",
            // },
            {
                label: "Status",
                field: "status",
            },
        ],
        rows: userAttributes,
    };
    return (
        <MDBDataTableV5
            sortable={true}
            responsive
            hover
            data={datatable}
            searchTop={true}
            searchBottom={false}
            searchLabel="Filter"
            paging={false}
            className="keyPools"
            info={false}
            noRecordsFoundLabel="No Service Plans Found"
            sortRows={["activated"]}
        />
    );
}
