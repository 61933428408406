import React, { Component } from "react";
import {
    MDBModalBody,
    MDBModalHeader,
    MDBRow,
    MDBCol,
    MDBIcon,
    MDBModal,
    toast,
} from "mdbreact";
import { withTranslation } from "react-i18next";
import AddNewGuest from "../../../../components/VenueMgmt/Forms/AddNewGuest/AddNewGuest";
import ViewModifyGuests from "../../../../components/VenueMgmt/RecentResidents/ViewModifyGuests";
import ResendActivation from "../../../../components/VenueMgmt/ResendActivation/ResendActivation";
import ServicesEndingSoonGuests from "../../../../components/VenueMgmt/UpcomingExpirations/ServicesEndingSoonGuests";
import VenueDropdown from "../../../../components/VenueMgmt/Navigation/VenueDropdown/VenueDropdown";
import axios from "../../../../axios";
class GuestManagementNavigation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalAddNewGuest: false,
            modalViewModifyGuest: false,
            modalResendActivation: false,
            modalServicesEndingSoon: false,
            loading: false,
        };
    }
    toggleAddNewGuest = () => {
        this.setState({
            modalAddNewGuest: !this.state.modalAddNewGuest,
        });
    };
    toggleViewModifyGuest = () => {
        this.setState({
            modalViewModifyGuest: !this.state.modalViewModifyGuest,
        });
    };
    toggleResendActivation = () => {
        this.setState({
            modalResendActivation: !this.state.modalResendActivation,
        });
    };
    toggleServicesEndingSoon = () => {
        this.setState({
            modalServicesEndingSoon: !this.state.modalServicesEndingSoon,
        });
    };
    submitHandler = (
        dateEnd,
        email,
        firstName,
        lastName,
        phoneNumber,
        language,
        unitID,
        building,
        floor,
        contactpref_email,
        contactpref_sms,
        guest,
        dateStart,
        unitID_internal,
        servicePlan
    ) => {
        let isoDateString;
        let isoDateStart;
        this.setState({ loading: true });
        if (!isNaN(new Date(dateEnd))) {
            isoDateString = new Date(dateEnd)
                .toISOString()
                .replace(/:00.\d+Z$/g, "Z");
        }
        if (!isNaN(new Date(dateStart))) {
            isoDateStart = new Date(dateStart)
                .toISOString()
                .replace(/:00.\d+Z$/g, "Z");
        }
        let body = {
            AccessToken: sessionStorage.getItem("AccessToken"),
            EmailAddress: email,
            KeyPoolID: this.props.keypoolID[0],
            GivenName: firstName,
            FamilyName: lastName,
            "PhoneNumber.E164": phoneNumber,
            locale: language,
            "EndDateTimeUTC.ISO8601": isoDateString,
            VenueID: this.props.venueID,
            contactpref_email: true,
            contactpref_sms: contactpref_sms,
            PolicyforSubscriberKey: {
                UnitID_internal: unitID_internal,
            },
            GuestKey: guest,
            "StartDateTimeUTC.ISO8601": isoDateStart,
            UserTimeZone: new Date().getTimezoneOffset(),
            ServicePlanID: servicePlan,
        };
        axios
            .post("/venuemgmtconsole/subscribers/addSubscriber", body, {
                headers: {},
            })
            .then(
                (response) => {
                    toast.success("Added Guest!", {
                        closeButton: false,
                    });
                    this.toggleAddNewGuest();
                    this.props.updateName(
                        this.props.venueNameSelected,
                        this.props.venueID
                    );
                    this.setState({ loading: false });
                },
                (error) => {
                    toast.error(error.response.data.message, {
                        closeButton: false,
                    });
                    this.toggleAddNewGuest();
                    this.setState({ loading: false });
                }
            );
    };
    submitHandlerResendActivation = (email) => {
        let body = {
            AccessToken: sessionStorage.getItem("AccessToken"),
            EmailAddress: email,
            VenueID: this.props.venueID,
            UserTimeZone: new Date().getTimezoneOffset(),
        };
        this.setState({ loading: true });
        axios
            .post("/venuemgmtconsole/subscribers/resendCodeforTandC", body, {
                headers: {},
            })
            .then(
                (response) => {
                    toast.success("Resent Confirmation!", {
                        closeButton: false,
                    });
                    this.toggleResendActivation();
                    this.setState({ loading: false });
                },
                (error) => {
                    toast.error("There was an error!", {
                        closeButton: false,
                    });
                    this.toggleResendActivation();
                    this.setState({ loading: false });
                }
            );
    };
    convertToCSV(objArray) {
        var array =
            typeof objArray != "object" ? JSON.parse(objArray) : objArray;
        var str = "";
        for (var i = 0; i < array.length; i++) {
            var line = "";
            for (var index in array[i]) {
                if (line !== "") line += ",";
                line += array[i][index];
            }
            str += line + "\r\n";
        }
        return str;
    }
    exportCSVFile(headers, items, fileTitle) {
        if (headers) {
            items.unshift(headers);
        }
        // Convert Object to JSON
        var jsonObject = JSON.stringify(items);
        var csv = this.convertToCSV(jsonObject);
        var exportedFilename = fileTitle + ".csv" || "export.csv";
        var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        if (navigator.msSaveBlob) {
            // IE 10+
            navigator.msSaveBlob(blob, exportedFilename);
        } else {
            var link = document.createElement("a");
            if (link.download !== undefined) {
                // feature detection
                // Browsers that support HTML5 download attribute
                var url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", exportedFilename);
                link.style.visibility = "hidden";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }
    convertCSV = (data) => {
        this.setState({ loading: true });
        let dataImported = [];
        for (var i = 0; i < data.length; i++) {
            dataImported[i] = {
                AccessToken: sessionStorage.getItem("AccessToken"),
                EmailAddress: data[i]["email"],
                KeyPoolID: this.props.keypoolID[0],
                GivenName: data[i]["given_name"],
                FamilyName: data[i]["family_name"],
                "PhoneNumber.E164": "+" + data[i]["phone"],
                locale: data[i]["locale"],
                "EndDateTimeUTC.ISO8601": new Date(
                    data[i]["end_date"]
                ).toISOString(),
                "StartDateTimeUTC.ISO8601": new Date(
                    data[i]["start_date"]
                ).toISOString(),
                VenueID: this.props.venueID,
                contactpref_email:
                    data[i]["contact_by_email"] === null
                        ? false
                        : data[i]["contact_by_email"],
                contactpref_sms:
                    data[i]["contact_by_sms"] === null
                        ? false
                        : data[i]["contact_by_sms"],
                PolicyforSubscriberKey: {
                    UnitID_internal: data[i]["unit_id_internal"],
                },
                GuestKey: true,
                UserTimeZone: new Date().getTimezoneOffset(),
            };
            axios
                .post(
                    "/venuemgmtconsole/subscribers/addSubscriber",
                    dataImported[i],
                    {
                        headers: {},
                    }
                )
                .then(
                    (response) => {
                        toast.success("Added Guests", {
                            closeButton: false,
                        });
                        this.props.updateName(
                            this.props.venueNameSelected,
                            this.props.venueID
                        );
                        this.setState({ loading: false });
                    },
                    (error) => {
                        toast.error("There was an error!", {
                            closeButton: false,
                        });
                        this.setState({ loading: false });
                    }
                );
        }
    };
    render() {
        if (this.state.postMessage) {
            var h = document.getElementById("overlayResidentManagement");
            h.style.display = "block";
            setTimeout(function () {
                h.style.display = "block";
            }, 5000);
            setTimeout(() => {
                h.style.display = "none";
                this.setState({ postMessage: "" });
            }, 6000);
        }
        var itemsFormatted = [];
        for (const key of Object.keys(this.props.guestData)) {
            itemsFormatted.push({
                unitID: this.props.guestData[key]["UnitID"],
                unitBuilding: this.props.guestData[key]["UnitBuilding"],
                unitFloor: this.props.guestData[key]["UnitFloor"],
                unitIDInternal: this.props.guestData[key]["UnitID_internal"],
                purpose: this.props.guestData[key]["purpose"],
                SubscriberGivenName:
                    this.props.guestData[key]["SubscriberGivenName"],
                SubscriberFamilyName:
                    this.props.guestData[key]["SubscriberFamilyName"],
                SubscriberEmail: this.props.guestData[key]["SubscriberEmail"],
                SubscriberLocale: this.props.guestData[key]["SubscriberLocale"],
                SubscriberPhone:
                    this.props.guestData[key]["SubscriberPhoneNumber.E164"],
                SubscriberAddress1: this.props.guestData[key]["AddressLine1"],
                SubscriberAddress2: this.props.guestData[key]["AddressLine2"],
                SubscriberCity: this.props.guestData[key]["City"],
                SubscriberState: this.props.guestData[key]["State"],
                SubscriberPostalCode: this.props.guestData[key]["PostalCode"],
                SubsriberContactEmail:
                    this.props.guestData[key]["contactpref_email"],
                SubsriberContactSMS:
                    this.props.guestData[key]["contactpref_sms"],
                StartDateTimeUTC:
                    this.props.guestData[key]["StartDateTimeUTC.ISO8601"],
                EndDateTimeUTC:
                    this.props.guestData[key]["EndDateTimeUTC.ISO8601"],
            });
        }
        const { t } = this.props;
        return (
            <>
                {this.props.loading === true ? (
                    <div id="overlay" style={{ display: "block" }}>
                        <div className="overlayMessage">
                            <div
                                className="spinner-border spinner-border"
                                role="status"
                            >
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>
                ) : (
                    ""
                )}
                <MDBModalHeader toggle={this.props.toggleGuestManagement}>
                    <VenueDropdown
                        getVenues={this.props.getVenues}
                        updateName={this.props.updateName}
                    />
                    <h4>
                        {this.props.venueType === "HOTSPOT"
                            ? "Subscriber Management"
                            : t("guestnav.title")}
                    </h4>
                </MDBModalHeader>
                <MDBModalBody>
                    <div className="managementModal">
                        <MDBRow>
                            <MDBCol size="12" md="6" lg="4">
                                <div
                                    className="managementModalButton"
                                    onClick={this.toggleAddNewGuest}
                                >
                                    <MDBIcon icon="user-plus" />
                                    <p>
                                        {this.props.venueType === "HOTSPOT"
                                            ? "Add New Subscriber"
                                            : t("guestnav.addguest")}
                                    </p>
                                </div>
                            </MDBCol>
                            <MDBCol size="12" md="6" lg="4">
                                <div
                                    className="managementModalButton"
                                    onClick={this.toggleViewModifyGuest}
                                >
                                    <MDBIcon icon="edit" />
                                    <p>
                                        {this.props.venueType === "HOTSPOT"
                                            ? "View/Modify Subscriber"
                                            : t("guestnav.viewmodify")}
                                    </p>
                                </div>
                            </MDBCol>
                            <MDBCol size="12" md="6" lg="4">
                                <div
                                    className="managementModalButton"
                                    onClick={this.toggleResendActivation}
                                >
                                    <MDBIcon icon="envelope-open" />
                                    <p>{t("guestnav.resendactivation")}</p>
                                </div>
                            </MDBCol>
                            <MDBCol size="12" md="6" lg="4">
                                <div
                                    className="managementModalButton"
                                    onClick={this.toggleServicesEndingSoon}
                                >
                                    <MDBIcon icon="stopwatch" />
                                    <p>
                                        {this.props.venueType === "HOTSPOT"
                                            ? "Subscribers Ending Soon"
                                            : t("guestnav.servicesending")}
                                    </p>
                                </div>
                            </MDBCol>
                        </MDBRow>
                    </div>
                </MDBModalBody>
                <MDBModal
                    isOpen={this.state.modalAddNewGuest}
                    toggle={this.toggle}
                    size="lg"
                    className="form"
                    centered
                >
                    <AddNewGuest
                        toggleAddNewGuest={this.toggleAddNewGuest}
                        submitHandlerGuest={this.submitHandler}
                        venueID={this.props.venueID}
                        loading={this.state.loading}
                        venueType={this.props.venueType}
                    />
                </MDBModal>
                <MDBModal
                    isOpen={this.state.modalViewModifyGuest}
                    toggle={this.toggle}
                    size="fluid"
                    className="form"
                    centered
                >
                    <ViewModifyGuests
                        toggleViewModifyGuest={this.toggleViewModifyGuest}
                        guestData={this.props.guestData}
                        venueID={this.props.venueID}
                        recentResidents={this.props.activeData}
                        allRecentResidents={this.props.allActiveData}
                        venueName={this.props.venueNameSelected}
                        updateName={this.props.updateName}
                        loading={this.state.loading}
                        venueType={this.props.venueType}
                    />
                </MDBModal>
                <MDBModal
                    isOpen={this.state.modalResendActivation}
                    toggle={this.toggle}
                    size="lg"
                    className="form"
                    centered
                >
                    <ResendActivation
                        toggleResendActivation={this.toggleResendActivation}
                        recentResidents={this.props.activeData}
                        submitHandlerResendActivation={
                            this.submitHandlerResendActivation
                        }
                        loading={this.state.loading}
                    />
                </MDBModal>
                <MDBModal
                    isOpen={this.state.modalServicesEndingSoon}
                    toggle={this.toggle}
                    size="fluid"
                    className="form"
                    centered
                >
                    <ServicesEndingSoonGuests
                        toggleServicesEndingSoon={this.toggleServicesEndingSoon}
                        expirations={this.props.guestDataExpiring}
                        venueID={this.props.venueID}
                        venueName={this.props.venueNameSelected}
                        updateName={this.props.updateName}
                        venueType={this.props.venueType}
                    />
                </MDBModal>
            </>
        );
    }
}
export default withTranslation()(GuestManagementNavigation);
